import EnumBase from './Base';

export default function fieldKindEnum(){
  return new EnumBase([
    { id: 'float',           name: 'Número com decimais' },
    { id: 'integer',         name: 'Número inteiro' },
    { id: 'string',          name: 'Texto curto' },
    { id: 'text',            name: 'Texto longo' },
    { id: 'boolean',         name: 'Sim/Não' },
    { id: 'date',            name: 'Data' },
    { id: 'datetime',        name: 'Data e hora' },
    { id: 'time',            name: 'Hora' },
    { id: 'multiple_choice', name: 'Múltipla escolha' },
    { id: 'unique_choice',   name: 'Única escolha' },
    { id: 'range',           name: 'Intervalo' },
    { id: 'record',          name: 'Registro' },
  ]);
}
