import React, { Fragment, memo } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import IconVisibility from '@material-ui/icons/Visibility';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';

import Permission from '../../Permission';

import IconButton from '~/components/IconButton';

function Actions({
  actions,
  extraActions,
  showPath,
  editPath,
  onDelete,
  permissionKeyCode
}) {
  return (
    <Fragment>
      {actions.includes('show') && (
        <Link to={showPath}>
          <Tooltip title="Visualizar">
            <IconButton>
              <IconVisibility htmlColor="#35285B" />
            </IconButton>
          </Tooltip>
        </Link>
      )}

      {actions.includes('edit') && (
        <Permission keyCode={permissionKeyCode} permission="edit">
          <Link to={editPath}>
            <Tooltip title="Editar">
              <IconButton>
                <IconEdit htmlColor="#FF9800" />
              </IconButton>
            </Tooltip>
          </Link>
        </Permission>
      )}

      {actions.includes('delete') && (
        <Permission keyCode={permissionKeyCode} permission="del">
          <Tooltip title="Excluir">
            <IconButton onClick={onDelete}>
              <IconDelete htmlColor="#FF5252" />
            </IconButton>
          </Tooltip>
        </Permission>
      )}

      {extraActions && extraActions()}
    </Fragment>
  );
}

export default memo(Actions);
