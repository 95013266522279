import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '1rem 0px 1rem 0px',
  }
}));

const DividerUI = (props) => {
	const classes = useStyles();
	return <Divider classes={{root: classes.root}} {...props} />
}

export default DividerUI;