import React, { useCallback } from 'react';

import { npsFormAnswersRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import npsFormAnswerSchema from '~/records/schema/npsFormAnswerSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/npsFormAnswers';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      let requestParams = 'include=construction-customer-engineer';
      if(params){
        requestParams = `${params}&${requestParams}`
      }
      dispatch( fetchRecords(requestParams, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Respostas NPS' }]}
      permissionKeyCode="NpsFormAnswer"
      routes={{
        showPath: (id) => npsFormAnswersRoute.show.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: npsFormAnswerSchema,
        actions: ['show', 'delete'],
        permissionKeyCode: 'NpsFormAnswer',
        filterProps: {
          columns: npsFormAnswerSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'construction',
            formatterComponent: (_, row) => {
              return <div>{row.construction?.name}</div>
            }
          },
          {
            name: 'customer',
            formatterComponent: (_, row) => {
              return (
                <div>{row.customer?.name}</div>
              )
            }
          },
          {
            name: 'engineer',
            formatterComponent: (_, row) => (
              <div>{row.engineer?.name}</div>
            )
          },
        ],
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.npsFormAnswers.listIsLoading,
  rows: state.npsFormAnswers.data,
  total: state.npsFormAnswers.totalData,
});

export default connect(mapStateToProps)(List);