import SchemaBuilder from './SchemaBuilder';
import {
  constructionsAsyncFetch,
  constructionAsyncShow,
} from '~/services/selectFetch/constructionsFetch';
import {
  peopleFetchWithParams,
  peopleAsyncShow,
} from '~/services/selectFetch/peopleFetch';

const disableList = { hidden: true, tableView: false, isFilter: false };

const columns = {
  id:                               { type: 'integer', title: 'ID', width: 80 },
  form: {
    type: 'async',
    default: null,
    title: 'Formulário',
    width: 200,
    ...disableList
  },
  construction: {
    type: 'async',
    default: null,
    title: 'Obra',
    width: 200,
    filterOptions: {
      asyncFetch: constructionsAsyncFetch,
      asyncShow:  constructionAsyncShow,
    }
  },

  customer: {
    type: 'async',
    default: null,
    title: 'Cliente',
    width: 200,
    filterOptions: {
      asyncFetch: peopleFetchWithParams({ customer: true }),
      asyncShow:  peopleAsyncShow,
    }
  },

  engineer: {
    type: 'async',
    default: null,
    title: 'Engenheiro',
    width: 200,
    filterOptions: {
      asyncFetch: peopleFetchWithParams({ engineer: true }),
      asyncShow:  peopleAsyncShow,
    }
  },
  answered_at:                      { type: 'datetime', title: 'Respondido em' },
  engineering_score:                { type: 'integer',  title: 'Nota do Engenheiro', width: 200 },
  engineering_score_observation:    { type: 'string',   title: 'Observação do Engenheiro', ...disableList },
  execution_team_score:             { type: 'integer',  title: 'Nota da Equipe de Execução', width: 200 },
  execution_team_score_observation: { type: 'string',   title: 'Observação da Equipe de Execução', ...disableList },
  shopping_team_score:              { type: 'integer',  title: 'Nota da Equipe de Compras', width: 200 },
  shopping_team_score_observation:  { type: 'string',   title: 'Observação da Equipe de Compras', ...disableList },


  created_at:                       { type: 'datetime', default: null, ...disableList, title: 'Criado em', width: 200 },
  updated_at:                       { type: 'datetime', default: null, ...disableList, title: 'Atualizado em' },
}

const formAnswerSchema = new SchemaBuilder(columns);

export default formAnswerSchema;
