import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import Spinner from '~/components/Spinner';

import Request from '~/services/request';

import { reportsApi } from '~/routes/api';

function DialogDownloadDanfes({handleClose, queryString}) {
  const [archive, setArchive] = useState(null)

  useEffect( () => {
    setArchive(null)
    const fetch = async () => {
      const response = await Request.get(`${reportsApi.downloadDanfes.build()}?${queryString}`);
      setArchive(response.data.data)
    }
    fetch()
  }, [queryString])

  return(
    <Dialog
      maxWidth="xs"
      fullWidth
      isOpen
      handleClose={handleClose}
      title={'Gerando arquivo das Notas Fiscais'}
      dialogActions={
        <>
          <Button onClick={handleClose} color="primary" variant="text">
            FECHAR
          </Button>
        </>
      }
    >
      { !archive && <Spinner loadingText="Gerando arquivo das Notas Fiscais.." /> }
      { !!archive &&
        <Box display="flex" justifyContent="center">
          <Button component="a" href={`${archive.attributes.url}`}>Baixar Arquivo</Button>
        </Box>
      }
    </Dialog>
  );
}

export default DialogDownloadDanfes;
