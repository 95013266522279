import RecordBase from './Base';
import supplierSchema from './schema/supplierSchema';
import AddressRecord from './AddressRecord';
import SegmentRecord from './SegmentRecord';
import ContactRecord from '~/records/ContactRecord';

export default class SupplierRecord extends RecordBase {
  static defaultAttributes = supplierSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return supplierSchema.attributeNames }

  static belongsTo = { address: AddressRecord, segment: SegmentRecord };
  static hasMany = {
    contacts: ContactRecord,
  };

  toParams(extraParams = {}) {
    const {
      name,
      segment,
      is_legal,
      cpf_cnpj,
      rg,
      birth_date,
      address
    } = this.toObject();

    return {
      data: {
        attributes: {
          name,
          segment_id: segment?.id || null,
          is_legal,
          cpf_cnpj: cpf_cnpj?.replace(/\D/g, ''),
          rg,
          birth_date,
          address_attributes: address ? address.nestedParams() : undefined,
          contacts_attributes: this.get('contacts').toArray().map( ([, c]) => c.nestedParams() ),
          ...extraParams
        }
      }
    };
  }

  updateAddress(attrs) {
    const address = this.address || new AddressRecord();

    return { address: address.updateAttributes(attrs) };
  }
}
