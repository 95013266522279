import SchemaBuilder from './SchemaBuilder';

const disableList = {hidden: true, tableView: false, isFilter: false};
const noFilter = {isFilter: false};

const columns = {
  nf_number:    {type: 'string',  default: '', title: 'Número da NF', width: 150},
  supplier:     {type: 'string',  default: null, title: 'Fornecedor', width: 200},
  nf_date:      {type: 'date',    default: '',   title: 'Data', width: 100},
  total_value:  {type: 'currency',   default: null, title: 'Valor da NF', width: 150},
  account_plan: {type: 'string',     default: '', title: 'Serviço', width: 200},
  adm_value:    {type: 'currency',   default: null, ...noFilter, title: '% Adm.', width: 100},
  actions:      {type: 'object', default: '', ...disableList, title: 'Ações'},
};

const reportExpenseSchema = new SchemaBuilder( columns );

export default reportExpenseSchema;
