/* eslint no-useless-escape: "off" */
import isBlank from './isBlank';

const PHONE_REGEX = /(\d{2})(\d{4})(\d{4})/g;
const CELL_PHONE_REGEX = /(\d{2})(\d{5})(\d{4})/g;
const ONLY_NUMBERS_REGEX = /\D/g;

export default function formatPhone(phone) {
  if (isBlank(phone)) return '';

  const formatedPhone = phone.replace(ONLY_NUMBERS_REGEX, '');
  
  const isCellPhone = formatedPhone.length === 11;
  if (isCellPhone) {
    return formatedPhone.replace(CELL_PHONE_REGEX, "(\$1) \$2-\$3");
  } 
  
  return formatedPhone.replace(PHONE_REGEX, "(\$1) \$2-\$3");
}
