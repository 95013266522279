import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const PercentText = styled.strong`
  font-size: 10px;
  padding-left: ${props => props.paddingLeft}%;
`;

export const Percent = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #eeeeee;
`;

export const PercentageCompleted = styled.div`
  width: ${props => props.width}%;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => props.color};
`;
