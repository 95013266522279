import React, { useEffect, useState, useRef } from 'react';
import Request from '~/services/request';
import MetaFieldRecord from '~/records/MetaFieldRecord';
import parseToImmutable from '~/utils/parseToImmutable';
import { Deserializer } from 'jsonapi-serializer';
import { OrderedMap } from "immutable";
import Box from '@material-ui/core/Box';
import Button from '~/components/Button';

import FormMetaField from './componentes/Form'
import TableMetaField from './componentes/Table'
import { toast } from 'react-toastify';

import ConfirmationDialog from '~/components/ConfirmationDialog';

const { deserialize } = new Deserializer({
  keyForAttribute: 'snake_case',
});

const MetaFields = ({referenceType, referenceID}) => {
  const [metaFields, setMetaFields] = useState(OrderedMap());
  const [currentRecord, setCurrentRecord] = useState(null);
  const confirmationDialogRef = useRef(null);

  const onSave = (record) => {
    const records = metaFields.set( record.get('id'), record )
    setMetaFields( records );
    setCurrentRecord( null );
  }

  const onCloseForm = () => {
    setCurrentRecord( null );
  }

  const onDelete = (record) => {
    const { current: dialog } = confirmationDialogRef;

    dialog.open();
    dialog.onConfirm(async () => {
      try {
        await Request.del(`/api/v1/meta_fields/${referenceType}/${referenceID}/${record.get('id')}`);
        const records = metaFields.delete( record.get('id') )
        setMetaFields( records );
      } catch(err) {
        if (!Request.isCancel(err)) {
          toast.error('Ocorreu um erro ao remover o registro. Tente novamente mais tarde!');
        }
      }
    });
  }

  useEffect( () => {
    const fetchData = async () =>{
      const resp = await Request.get(`/api/v1/meta_fields/${referenceType}/${referenceID}?include=field`)
      const data = await deserialize(resp.data)

      setMetaFields( parseToImmutable(data, MetaFieldRecord) );
    }
    fetchData();
  }, [])

  return <div>
    <Box display="flex" justifyContent="flex-end" m={1} p={1} bgcolor="background.paper">
      <Button color='success' onClick={ () => setCurrentRecord( new MetaFieldRecord() ) } >Novo</Button>
    </Box>
    { currentRecord &&
      <FormMetaField
        referenceID={referenceID}
        referenceType={referenceType}
        onSave={onSave}
        metaField={currentRecord}
        onCloseForm={onCloseForm}
        currentFieldIds={metaFields.toArray().map( ([, record]) => record.get('field')?.id )}
      />
    }
    <ConfirmationDialog
      ref={confirmationDialogRef}
      title="Atenção"
      description="Deseja excluir este registro?"
    />
    <TableMetaField metaFields={metaFields} handleEdit={setCurrentRecord} onDelete={onDelete} />
  </div>
}

export default MetaFields;