import DashboardIcon from '@material-ui/icons/Dashboard';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DescriptionIcon from '@material-ui/icons/Description';
import AdministrationIcon from '@material-ui/icons/AttachMoney';
import PermissionIcon from '@material-ui/icons/Lock';
import UserIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import InputIcon from '@material-ui/icons/Input';
import CategoryIcon from '@material-ui/icons/Category';
import RegistersIcon from '@material-ui/icons/People';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MailIcon from '@material-ui/icons/Mail';
import FormIcon from '@material-ui/icons/Description';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BusinessIcon from '@material-ui/icons/Business';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import {
  peopleRoute,
  metadataRoute,
  fieldsRoute,
  rolesRoute,
  usersRoute,
  dashboardRoute,
  segmentsRoute,
  productsRoute,
  invoicesRoute,
  suppliersRoute,
  engineersRoute,
  contractorsRoute,
  accountPlansRoute,
  incomingEmailsRoute,
  npsFormsRoute,
  npsFormAnswersRoute,
  constructionsRoute,
  reportsRoute,
  customerInvoicesRoute,
} from '~/routes';

export const drawerMenuAvatar = {
  name: 'Halley Specter',
  roleName: 'Admin',
  url: 'https://66.media.tumblr.com/3e3f627161fb69b617504b320b57d480/tumblr_pojtnodtMz1rfd9dz_640.jpg',
  onClick: () => {},
};

const drawerMenuItems = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    keyCode: 'Dashboard',
    to: dashboardRoute.self.path,
  },
  {
    name: 'E-mails recebidos',
    icon: MailIcon,
    keyCode: 'IncomingEmail',
    to: incomingEmailsRoute.list.path,
  },
  {
    name: 'Notas Fiscais',
    icon: AccountBalanceWalletIcon,
    keyCode: 'Invoice',
    to: invoicesRoute.list.path,
  },
  {
    name: 'Obras',
    icon: BusinessIcon,
    keyCode: 'Construction',
    to: constructionsRoute.list.path,
  },
  {
    name: 'Cadastros',
    icon: RegistersIcon,
    collapse: [
      {
        name: 'Clientes',
        keyCode: 'Person',
        to: peopleRoute.list.path,
      },
      {
        name: 'Fornecedores',
        keyCode: 'Supplier',
        to: suppliersRoute.list.path,
      },
      {
        name: 'Empreiteiros',
        to: contractorsRoute.list.path,
        keyCode: 'Contractor',
      },
      {
        name: 'Engenheiros',
        to: engineersRoute.list.path,
        keyCode: 'Engineer',
      },
      {
        name: 'Produtos',
        to: productsRoute.list.path,
        keyCode: 'Product',
      },
    ],
  },
  {
    name: 'NPS',
    icon: FormIcon,
    collapse: [
      {
        name: 'Formulários',
        icon: FormIcon,
        keyCode: 'NpsForm',
        to: npsFormsRoute.list.path,
      },
      {
        name: 'Respostas',
        icon: QuestionAnswerIcon,
        keyCode: 'NpsFormAnswer',
        to: npsFormAnswersRoute.list.path,
      },
    ],
  },
  {
    name: 'Relatórios',
    icon: EqualizerIcon,
    collapse: [
      {
        name: 'Despesas da Obra',
        to: reportsRoute.expenses.build(),
        keyCode: 'ExpensesReport',
      },
      {
        name: 'Plano de Contas',
        to: reportsRoute.accountPlansTree.build(),
        keyCode: 'AccountPlanReport',
      },
      {
        name: 'Histórico de Despesa',
        to: reportsRoute.expensesHistory.build(),
        keyCode: 'ExpenseHistoryReport',
      },
    ],
  },
  {
    name: 'Administração',
    icon: AdministrationIcon,
    collapse: [
      {
        name: 'Planos de conta',
        keyCode: 'AccountPLan',
        to: accountPlansRoute.list.path,
      },
      {
        name: 'Permissões',
        icon: PermissionIcon,
        keyCode: 'Permission',
        to: rolesRoute.list.path,
      },
      {
        name: 'Usuários',
        icon: UserIcon,
        keyCode: 'User',
        to: usersRoute.list.path,
      },
      {
        icon: CategoryIcon,
        name: 'Segmentos',
        to: segmentsRoute.list.path,
        keyCode: 'Segment',
      },
    ],
  },
  {
    name: 'Master',
    icon: DragIndicatorIcon,
    collapse: [
      {
        name: 'Metadados',
        icon: StorageIcon,
        keyCode: 'Metadata',
        onlyMaster: true,
        to: metadataRoute.list.path,
      },
      {
        name: 'Campos',
        icon: InputIcon,
        keyCode: 'Field',
        onlyMaster: true,
        to: fieldsRoute.list.path,
      },
      {
        onlyMaster: true,
        name: 'Examples',
        icon: DescriptionIcon,
        keyCode: 'Example',
        to: '/sys/components-example',
      },
    ],
  },
];

export const customerMenuItems = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    to: dashboardRoute.self.path,
  },
  {
    name: 'Notas Fiscais',
    icon: AccountBalanceWalletIcon,
    to: customerInvoicesRoute.self.path,
    // allowCustomer: true,
  },
  {
    name: 'Relatórios',
    icon: EqualizerIcon,
    collapse: [
      {
        name: 'Plano de Contas',
        to: reportsRoute.accountPlansTree.build(),
      },
      {
        name: 'Histórico de Despesa',
        to: reportsRoute.expensesHistory.build(),
      },
    ],
  },
];

export default drawerMenuItems;
