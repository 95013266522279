import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/users';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { usersRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const USERS_SET_CURRENT = '@App/users/USERS_SET_CURRENT';
export const USERS_FETCH_START = '@App/users/USERS_FETCH_START';
export const USERS_FETCH_DONE  = '@App/users/USERS_FETCH_DONE';
export const USERS_FETCH_LIST_START = '@App/users/USERS_FETCH_LIST_START';
export const USERS_FETCH_LIST_DONE = '@App/users/USERS_FETCH_LIST_DONE';
export const USERS_SET_ERRORS = '@App/users/USERS_SET_ERRORS';
export const CLEAR_STATE = '@App/users/CLEAR_STATE';
export const FIND_USER_REQUEST = '@App/users/FIND_USER_REQUEST';
export const DESTROY_USERS_REQUEST = '@App/users/DESTROY_USERS_REQUEST';
export const UPDATE_USERS_REQUEST = '@App/users/UPDATE_USERS_REQUEST';
export const CREATE_USERS_REQUEST = '@App/users/CREATE_USERS_REQUEST';
export const FETCH_USERS_REQUEST  = '@App/users/FETCH_USERS_REQUEST';
export const USERS_CHANGE_LIST  = '@App/users/USERS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [USERS_FETCH_START]: fetchStart,
    [USERS_FETCH_DONE]:  fetchDone,
    [USERS_FETCH_LIST_START]: fetchListStart,
    [USERS_FETCH_LIST_DONE]: fetchListDone,
    [USERS_SET_ERRORS]: setErrors,
    [USERS_SET_CURRENT]: setCurrent,
    [DESTROY_USERS_REQUEST]: initLoading,
    [CREATE_USERS_REQUEST]: initLoading,
    [UPDATE_USERS_REQUEST]: initLoading,
    [USERS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}, callbackSuccess) {
  return {
    type: FIND_USER_REQUEST,
    payload: { id, params, callbackSuccess }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_USERS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_USERS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_USERS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_USERS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: USERS_SET_CURRENT,
    payload: { current }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'users',

  routes: {
    list: () => usersRoute.list.build(),
    show: (id) => usersRoute.show.build({id}),
    new: () => usersRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      USERS_FETCH_START,
    FETCH_DONE:       USERS_FETCH_DONE,
    FETCH_LIST_START: USERS_FETCH_LIST_START,
    FETCH_LIST_DONE:  USERS_FETCH_LIST_DONE,
    CHANGE_LIST:      USERS_CHANGE_LIST,
    CHANGE_CURRENT:   USERS_SET_CURRENT,
    SET_ERRORS:       USERS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_USER_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_USERS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_USERS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_USERS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_USERS_REQUEST, crudActions.fetchListAction),
]);