import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';

function DialogForm({
  steps,
  currentStep,
  hasContructionAccountPlans,
  defaultData,
  onChange
}) {
  const [estimatedValue, setEstimatedValue] = useState('');
  const [extraValue, setExtraValue] = useState('');

  useEffect(() => {
    setEstimatedValue(defaultData?.estimated_value ?? '');
    setExtraValue(defaultData?.extra_value ?? '');
  }, [defaultData]);

  const showEstimatedValueInput = (
    currentStep === steps.SECOND ||
    hasContructionAccountPlans
  );

  return (
    <Grid container>
      {showEstimatedValueInput && (
        <Grid item xs={12}>
          <InputControl
            required
            name="estimated_value"
            label="Valor"
            value={estimatedValue}
            inputComponent={InputNumber}
            onChange={({ estimated_value }) => {
              onChange({ estimated_value });
              setEstimatedValue(estimated_value);
            }}
          />
        </Grid>
      )}

      {currentStep === steps.THIRD && (
        <Grid item xs={12}>
          <InputControl
            required
            name="extra_value"
            label="Valor Adicional"
            value={extraValue}
            inputComponent={InputNumber}
            onChange={({ extra_value }) => {
              onChange({ extra_value });
              setExtraValue(extra_value);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default DialogForm;
