import React from 'react';

import { Container } from './styles';

function PrintView({ children }) {
  return(
    <Container className="printable">
      {children}
    </Container>
  );
}

export default PrintView;
