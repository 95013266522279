import React, { memo } from 'react';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import AddressRecord from '~/records/AddressRecord';

const attrName = (attr) => AddressRecord.hummanAttributeName(attr);

function AddressShow({ address }) {
  return (
    <GridContainer>
      <Col md={4}>
        <InputShow
          label={attrName('zip_code')}
          type="string"
          value={address?.zip_code}
        />
      </Col>
      <Col md={4}>
        <InputShow
          label={attrName('street')}
          type="string"
          value={address?.street}
        />
      </Col>
      <Col md={4}>
        <InputShow
          label={attrName('building_number')}
          type="string"
          value={address?.building_number}
        />
      </Col>

      <Col md={4}>
        <InputShow
          label={attrName('district')}
          type="string"
          value={address?.district}
        />
      </Col>
      <Col md={4}>
        <InputShow
          label={attrName('city')}
          type="string"
          value={address?.city?.name}
        />
      </Col>
      <Col md={4}>
        <InputShow
          label={attrName('state')}
          type="string"
          value={address?.city?.state?.name}
        />
      </Col>

      <Col md={12}>
        <InputShow
          label={attrName('complement')}
          type="string"
          value={address?.complement}
        />
      </Col>
    </GridContainer>
  );
}

export default memo(AddressShow)