import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/fields';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { fieldsRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const FIELDS_SET_CURRENT = '@App/fields/FIELDS_SET_CURRENT';
export const FIELDS_FETCH_START = '@App/fields/FIELDS_FETCH_START';
export const FIELDS_FETCH_DONE  = '@App/fields/FIELDS_FETCH_DONE';
export const FIELDS_FETCH_LIST_START = '@App/fields/FIELDS_FETCH_LIST_START';
export const FIELDS_FETCH_LIST_DONE = '@App/fields/FIELDS_FETCH_LIST_DONE';
export const FIELDS_SET_ERRORS = '@App/fields/FIELDS_SET_ERRORS';
export const CLEAR_STATE = '@App/fields/CLEAR_STATE';
export const FIND_FIELD_REQUEST = '@App/fields/FIND_FIELD_REQUEST';
export const DESTROY_FIELDS_REQUEST = '@App/fields/DESTROY_FIELDS_REQUEST';
export const UPDATE_FIELDS_REQUEST = '@App/fields/UPDATE_FIELDS_REQUEST';
export const CREATE_FIELDS_REQUEST = '@App/fields/CREATE_FIELDS_REQUEST';
export const FETCH_FIELDS_REQUEST  = '@App/fields/FETCH_FIELDS_REQUEST';
export const FIELDS_CHANGE_LIST  = '@App/fields/FIELDS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [FIELDS_FETCH_START]: fetchStart,
    [FIELDS_FETCH_DONE]:  fetchDone,
    [FIELDS_FETCH_LIST_START]: fetchListStart,
    [FIELDS_FETCH_LIST_DONE]: fetchListDone,
    [FIELDS_SET_ERRORS]: setErrors,
    [FIELDS_SET_CURRENT]: setCurrent,
    [DESTROY_FIELDS_REQUEST]: initLoading,
    [CREATE_FIELDS_REQUEST]: initLoading,
    [UPDATE_FIELDS_REQUEST]: initLoading,
    [FIELDS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id) {
  return {
    type: FIND_FIELD_REQUEST,
    payload: { id }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_FIELDS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_FIELDS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_FIELDS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_FIELDS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: FIELDS_SET_CURRENT,
    payload: { current }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'fields',

  routes: {
    list: () => fieldsRoute.list.build(),
    show: (id) => fieldsRoute.show.build({id}),
    new: () => fieldsRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      FIELDS_FETCH_START,
    FETCH_DONE:       FIELDS_FETCH_DONE,
    FETCH_LIST_START: FIELDS_FETCH_LIST_START,
    FETCH_LIST_DONE:  FIELDS_FETCH_LIST_DONE,
    CHANGE_LIST:      FIELDS_CHANGE_LIST,
    CHANGE_CURRENT:   FIELDS_SET_CURRENT,
    SET_ERRORS:       FIELDS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_FIELD_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_FIELDS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_FIELDS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_FIELDS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_FIELDS_REQUEST, crudActions.fetchListAction),
]);