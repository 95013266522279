import React, { useMemo, useCallback } from 'react';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { peopleFetchWithParams } from '~/services/selectFetch/peopleFetch'
import constructionTypeEnum from '~/enums/constructionTypeEnum'
import constructionStatusEnum from '~/enums/constructionStatusEnum'
import ConstructionRecord from '~/records/ConstructionRecord';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputCpfCnpj from '~/components/Input/InputCpfCnpj';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import InputSelect from '~/components/Input/InputSelect';
import InputNumber from '~/components/Input/InputNumber';
import InputDate from "~/components/Input/InputDate";
import Address from '~/components/Address';
import InputDropzoneFile from '~/containers/InputDropzoneFile';

const attrName = (attr) => ConstructionRecord.hummanAttributeName(attr)

export default function FormFields({record, onChange}) {
  const currentDateSting = useMemo(() => moment(new Date()).format(), []);

  const handleChangeAddress = useCallback(attrs => {
    const address = record.updateAddress(attrs);
    onChange(address);
  }, [record, onChange]);

  const onChangeFiles = useCallback(
    (attachments) => {
      onChange( {attachments} )
    },
    [record, onChange],
  )

  return (
    <>
      <Card>
        <CardContent>
          <GridContainer>
            <Col md={5}>
              <InputControl
                required
                value={record.get('customer')}
                name={'customer'}
                stateName={'customer'}
                label={attrName('customer')}
                inputComponent={InputSelectAsync}
                onChange={onChange}
                InputProps={{
                  handleFetch: peopleFetchWithParams({customer: true}),
                  createEnabled: false,
                }}
              />
            </Col>
            <Col md={4}>
              <InputControl
                required
                name="name"
                label={attrName('name')}
                value={record.get('name')}
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col md={3}>
              <InputControl
                name="cpf_cnpj"
                label={attrName('cpf_cnpj')}
                value={record.get('cpf_cnpj')}
                inputComponent={InputCpfCnpj}
                onChange={onChange}
              />
            </Col>
          </GridContainer>

          <GridContainer>
            <Col md={4}>
              <InputControl
                required
                name="adm_percent"
                label={attrName('adm_percent')}
                value={record.get('adm_percent')}
                inputComponent={InputNumber}
                onChange={onChange}
              />
            </Col>
            <Col md={4}>
              <InputControl
                required
                value={record.get('status')}
                name={'status'}
                label={attrName('status')}
                inputComponent={InputSelect}
                onChange={onChange}
                InputProps={{
                  options: constructionStatusEnum().array
                }}
              />
            </Col>
            <Col md={4}>
              <InputControl
                value={record.get('construction_type')}
                name={'construction_type'}
                label={attrName('construction_type')}
                inputComponent={InputSelect}
                onChange={onChange}
                InputProps={{
                  options: constructionTypeEnum().array,
                  isClearable: true,
                }}
              />
            </Col>
            <Col md={6}>
              <InputControl
                required
                value={record.get('engineer')}
                name={'engineer'}
                stateName={'engineer'}
                label={attrName('engineer')}
                inputComponent={InputSelectAsync}
                onChange={onChange}
                InputProps={{
                  handleFetch: peopleFetchWithParams({engineer: true}),
                  createEnabled: false,
                }}
              />
            </Col>
            <Col md={6}>
              <InputControl
                required
                value={record.get('contractor')}
                name={'contractor'}
                stateName={'contractor'}
                label={attrName('contractor')}
                inputComponent={InputSelectAsync}
                onChange={onChange}
                InputProps={{
                  handleFetch: peopleFetchWithParams({contractor: true}),
                  createEnabled: false,
                }}
              />
            </Col>
            <Col sm={6} lg={3}>
              <InputControl
                value={record.get('start_date')}
                name={'start_date'}
                label={attrName('start_date')}
                inputComponent={InputDate}
                onChange={onChange}
                InputProps={{
                  type: "date",
                  minDate: currentDateSting,
                }}
              />
            </Col>
            <Col sm={6} lg={3}>
              <InputControl
                value={record.get('end_date')}
                name={'end_date'}
                label={attrName('end_date')}
                inputComponent={InputDate}
                onChange={onChange}
                InputProps={{
                  type: "date",
                  minDate: currentDateSting,
                }}
              />
            </Col>
            <Col sm={6} lg={3}>
              <InputControl
                name="latitude"
                label={attrName('latitude')}
                value={record.get('latitude')}
                inputComponent={Input}
                InputProps={{
                  type: 'number'
                }}
                onChange={onChange}
              />
            </Col>
            <Col sm={6} lg={3}>
              <InputControl
                name="longitude"
                label={attrName('longitude')}
                value={record.get('longitude')}
                inputComponent={Input}
                InputProps={{
                  type: 'number'
                }}
                onChange={onChange}
              />
            </Col>
          </GridContainer>

          <br/>
          <Typography variant="h6">Endereço</Typography>
          <Address
            record={record}
            onChangeAddress={handleChangeAddress}
            requiredFields={{
              district: true,
              city: true,
            }}
            errors={{}}
          />

          <InputDropzoneFile
            title="Anexos"
            key={record.get('id') || 0}
            // onDelete={onDeleteFile}
            onChange={onChangeFiles}
            persistedFiles={ record.get('attachments') }
          />
        </CardContent>
      </Card>
    </>
  );
}
