import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import InputControl from '~/components/Input/InputControl';
import InputSelectAsync     from '~/components/Input/InputSelectAsync';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import RoleRecord from '~/records/RoleRecord';
import usersFetch from '~/services/selectFetch/usersFetch'

import PermissionsTable from './components/PermissionsTable';

const attrName = (attr) => RoleRecord.hummanAttributeName(attr);


export default function FormFields({ record, onChange}) {
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col xs={12}>
            <InputControl
              autoFocus
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
        </GridContainer>
        <GridContainer>
          <Col xs={12}>
            <InputControl
              name="users"
              label={attrName('users')}
              value={record.get('users')}
              onChange={onChange}
              inputComponent={InputSelectAsync}
              InputProps={ {
                handleFetch: usersFetch,
                isMulti: true
              } }
            />
          </Col>
        </GridContainer>
        <PermissionsTable record={record} onChange={onChange} />
      </CardContent>
    </Card>
  );
}