import RecordBase from './Base';
import npsFormSchema from './schema/npsFormSchema';
import PersonRecord from './PersonRecord';
import ConstructionRecord from '~/records/ConstructionRecord';
import EngineerRecord from '~/records/EngineerRecord';

export default class NpsFormRecord extends RecordBase {
  static defaultAttributes = npsFormSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return npsFormSchema.attributeNames }

  static belongsTo = {
    construction: ConstructionRecord,
    customer: PersonRecord,
    engineer: EngineerRecord,
  };

  toParams(extraParams = {}) {
    const { is_active, construction, engineer, start_date } = this.toObject();

    return {
      data: {
        attributes: {
          construction_id: construction?.get('id') || null,
          engineer_id: engineer?.get('id') || null,
          is_active,
          start_date,
          ...extraParams,
        }
      }
    };
  }
}