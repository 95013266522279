/* eslint-disable react/prop-types */
import React     from 'react';
import PropTypes from 'prop-types';
import clsx      from 'classnames';

import { Iterable } from 'immutable';

// react-select component
import ReactSelect, { components } from 'react-select';
// --------------------------------------------------------------

// material-ui components
import withStyles    from '@material-ui/core/styles/withStyles';
import Typography    from '@material-ui/core/Typography';
import TextField     from '@material-ui/core/TextField';
// import { emphasize } from '@material-ui/core/styles/colorManipulator';
// import Chip          from '@material-ui/core/Chip';
// import MenuItem      from '@material-ui/core/MenuItem';
// --------------------------------------------------------------

import Tooltip from '~/components/Tooltip';

// Helpers
// import I18n from '~/i18n';
import isObject from '~/utils/isObject';
import isPresent from '~/utils/isPresent';
import isBlank from '~/utils/isBlank';
// --------------------------------------------------------------

// isImmutable = Para cerificar se é um objeto Immutable
const isImmutable = (arg) => Iterable.isIterable(arg);

const styles = theme => ({
  inputRoot: {
    fontSize: '1rem',
    marginTop: '1px'
  },
  input: {
    display: 'flex',
  },
  inputElement: {
    'display': 'flex',
    'marginTop': '2px',
    'min-height': 'calc(1.1875em + 6px + 8px)',
    '&.input-react-select-integration': {
      padding: 0,
    }
  },
  disabled: {
    opacity: 0.5
  },
  noOptionsMessage: {
    fontSize: '1rem',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    lineHeight: 'inherit',
  },
});

// react-select Styles
// reference: https://react-select.com/styles
const selectCustomStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: '1400',
  }),
  container: (base) => ({
    ...base,
    marginTop: '16px',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '4px',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '4px',
  }),
  valueContainer: (base, state) => {
    return ({
      ...base,
      padding: 0,
      maxWidth: state.isMulti ? (state.hasValue ? (state.selectProps.isClearable ? 'calc(100% - 56px)' : 'calc(100% - 28px)') : 'initial') : 'initial'
    });
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  singleValue: (provided) => {
    const opacity = 1;
    const color = 'black';

    return { ...provided, opacity, color};
  }
};
// -------------------------------------------------------

function NoOptionsMessage(props) {
  return (
    <Typography
      color="secondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        classes: {
          root:   props.selectProps.classes.inputRoot,
          input:  props.selectProps.classes.input,
        },
        inputProps: {
          className: clsx(
            props.selectProps.classes.inputElement,
            'input-react-select-integration',
            {[props.selectProps.classes.disabled]: props.isDisabled}
          ),
          ref:       props.innerRef,
          children:  props.children,
          ...props.innerProps,
        },
      }}
    />
  );
}

function renderTooltip(children, props){
  let title = children;
  if(props.data && props.data.explain)
    title = (
      <div>
        <p><strong>{children}</strong></p>
        <span>{props.data.explain}</span>
      </div>
    );

  return (
    <Tooltip
      disableFocusListener
      placement='top'
      title={title}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

function SingleValue({children, ...props}) {
  return (
    <components.SingleValue {...props}>
      { isPresent(children) ?
        renderTooltip(children, props)
      :
        children
      }
    </components.SingleValue>
  );
}

const defaultComponents = {
  Control,
  NoOptionsMessage,
  SingleValue,
};

function handleOutputValue(objValue, type, valueKey, labelKey){
  const { value, label, ...otherAttrs } = objValue;
  let outValue;

  switch (type) {
    case 'string':
      outValue = String(value);
      break;
    case 'bool':
      outValue = Boolean(value);
      break;
    case 'number':
      outValue = Number(value);
      break;
    default:
      outValue = {
        ...otherAttrs,
        [valueKey]: value,
        [labelKey]: label,
      };
      break;
  }

  return outValue;
}

class IntegrationReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  static propTypes = {
    'classes':         PropTypes.object.isRequired,
    'disabled':        PropTypes.bool,
    'selectComponent': PropTypes.func.isRequired,
    'state-name':      PropTypes.string.isRequired,
    'menuPosition':    PropTypes.string,
    'onChange':        PropTypes.func,
    'handleOption':    PropTypes.func,
    'components':      PropTypes.object,
    'labelKey':        PropTypes.string.isRequired,
    'valueKey':        PropTypes.string.isRequired,
    'isClearable':     PropTypes.bool,

    'handleOutputValue': PropTypes.func.isRequired,
    'outValueType':      PropTypes.oneOf(['object', 'string', 'number', 'bool']).isRequired,
  };

  static defaultProps = {
    isClearable:     false,
    placeholder:     'Selecione...',
    labelKey:        'name',
    valueKey:        'id',
    outValueType:    'object',
    menuPosition:    'fixed',
    selectComponent: ReactSelect,
    handleOutputValue,
    disabled:         false,
    loadingMessage:   () => 'Carregando..',
    noOptionsMessage: () => 'Nenhuma opção disponível',
  };

  componentDidMount() {
    const { setRef } = this.props;
    if (typeof setRef === 'function'){
      setRef(this);
    }
  }

  getValueObject = (value) =>{
    const { options, defaultOptions, labelKey, valueKey, handleOption, outValueType } = this.props;
    let optionValueOk;

    // Ignorar false do tipo bool
    if( !(outValueType === 'bool' && value === false) && isBlank(value) ){
      return null;
    }else if( isImmutable(value) ){
      const record = value.toObject();
      optionValueOk = {
        ...record,
        label: value.get(labelKey),
        value: value.get(valueKey),
      };
    }else if( isObject(value) ){
      optionValueOk = {
        ...value,
        label: value[labelKey],
        value: value[valueKey],
      };
    }else if( Array.isArray(value) ){
      return value.map(this.getValueObject);
    }else if( Array.isArray(options) || (Array.isArray(defaultOptions) && isPresent(defaultOptions)) ){
      const currentOptions = isPresent(options) ? options : [...defaultOptions];
      const optionValue = currentOptions.find( (opt) => String(opt[valueKey]) === String(value) );

      optionValueOk = {
        ...optionValue,
        value: value,
        label: (optionValue ? optionValue[labelKey] : value),
      };
    }else{
      optionValueOk = {
        value: value,
        label: value,
      };
    }

    if( typeof handleOption === 'function' ){
      return handleOption(
        {
          ...optionValueOk,
          [valueKey]: optionValueOk.value,
        },
        labelKey,
        valueKey
      );
    }else{
      return optionValueOk;
    }
  };

  handleChange = (value, arg2) => {
    const { onChange, valueKey, 'state-name': stateName } = this.props;
    const fakeEvent = {type: 'change', target: {value: (value ? value[valueKey] : null)}, ...arg2};
    const val = this.formatResponseValue(value);

    onChange({[stateName]: val }, fakeEvent);
  };

  formatResponseValue = (value) => {
    if(isBlank(value)){
      return null;
    }
    if( Array.isArray(value) ){
      return value.map( this.formatSingleResponseValue );
    }else{
      return this.formatSingleResponseValue( value );
    }
  }

  formatSingleResponseValue = (objValue) => {
    const { valueKey, labelKey, handleOutputValue, outValueType } = this.props;
    return handleOutputValue( objValue, outValueType, valueKey, labelKey );
  }

  handlerOptions = (options) => {
    if (Array.isArray(options))
      return options.map(this.handleOption).filter( e => isPresent(e) );

    return undefined;
  };

  handleOption = (option) => {
    const { labelKey, valueKey, handleOption } = this.props;
    if( handleOption && typeof handleOption === 'function' ){
      return handleOption(option, labelKey, valueKey);
    } else if( isObject(option) ){
      const { [labelKey]: label, [valueKey]: value, ...rest } = option;
      return {
        ...rest,
        value: value,
        label: label,
      };
    }else if( Array.isArray(option) ){
      return {
        value: option[0],
        label: option[1],
      };
    }else{
      return {
        value: option,
        label: option,
      };
    }
  };

  render() {

    /* eslint-disable */
    const {
      onChange,
      components: customComponents,
      labelKey,
      valueKey,
      value,
      selectComponent: SelectComponent,
      options,
      defaultOptions,
      setRef,
      disabled,
      classes,
      ...rest
    } = this.props;
    /* eslint-enable */

    const handledProps = {};
    if ( isPresent(options) ){
      handledProps.options = this.handlerOptions(options);
    }else if ( isPresent(defaultOptions) ){
      handledProps.defaultOptions = this.handlerOptions(defaultOptions);
    }

    const selectValue = this.getValueObject(value);

    return (
      <SelectComponent
        ref={this.selectRef}
        menuPortalTarget={document.body}
        styles={selectCustomStyles}
        classes={classes}
        isDisabled={disabled}
        components={{...defaultComponents, ...customComponents}}
        onChange={isPresent(onChange) ? this.handleChange : undefined}
        value={ selectValue }
        {...handledProps}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(IntegrationReactSelect);
