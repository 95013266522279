import Request from '~/services/request';
import { citiesApi } from '~/routes/api';

export const cityAsyncFetch = (inputValue, page, doneCallback) => {
  return Request.get(`${citiesApi.searchCities.build()}?sort=cities.name&direction=asc&qs=${inputValue}&page=${page}&per_page=50`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: `${e.attributes.name} - ${e.attributes.state_uf}`
        }))
      }
    )
  })
  .catch(err => err);
};

export const cityAsyncShow =  (id) => {
  return Request.get(`${citiesApi.show.build({id})}?adapter=json`).then(resp => {
    if(resp.data && resp.data.city){
      const city = resp.data.city;
      return {
        ...city,
        name: `${city.name} - ${city.state_uf}`
      }
    }
    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}