import React, {useEffect, useState} from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import isPresent from '~/utils/isPresent';
import { ChartLineContainer } from './styles';

import formatToMoney from '~/utils/formatToMoney';

const MONTHS = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
              'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

const options = {
  legend: { display: false },
  tooltips: {
    callbacks: {
      label: function (item, data) {
        const idx = item.index;
        return `Total: R$ ${formatToMoney(data.datasets[0].data[idx])}`;
      },
    },
  },
}

export default function ExpensesPerMonthChart() {
  const { data } = useSelector(state => state.dashboard);
  const [labels, setLabels] = useState(MONTHS)
  const [dataValue, setDataValue] = useState([])

  useEffect(() => {
    if(isPresent(data.expenses_per_month)){
      setLabels(
        Object.values(data.expenses_per_month).map( v => `${MONTHS[v.month]}/${v.year}` )
      )
      setDataValue(
        Object.values(data.expenses_per_month).map( v => v.value )
      )
    }
  }, [data.expenses_per_month])


  return (
    <ChartLineContainer>
      <CardContent>
        <h2 style={{ margin: '5px 0 20px 35px' }}>Saídas Por mês</h2>
        <Line
          data={{
            labels,
            datasets: [
              {
                label: ' Saídas no mês',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#FF00D6',
                borderColor: '#FF00D6',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBackgroundColor: '#FF00D6',
                pointHoverRadius: 9,
                pointHoverBackgroundColor: '#FF00D6',
                pointHoverBorderColor: '#292929',
                pointRadius: 4,
                pointHitRadius: 4,
                data: dataValue
              }
            ]
          }}
          height={47}
          options={options}
        />
      </CardContent>
    </ChartLineContainer>
  );
}
