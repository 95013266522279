import RecordBase from './Base';
import expenseSchema from './schema/expenseSchema';
import AccountPlanRecord from './AccountPlanRecord';

export default class ExpenseRecord extends RecordBase {
  static defaultAttributes = expenseSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return expenseSchema.attributeNames }

  static belongsTo = {
    account_plan: AccountPlanRecord
  };

  nestedParams() {
    return {
      id: this.get('id') || undefined,
      total_value: this.get('total_value'),
      notes: this.get('notes'),
      account_plan_id: this.get('account_plan')?.get('id') || null,
      _destroy: this.get('_destroy') || undefined
    };
  }
}
