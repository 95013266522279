import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Iterable } from 'immutable';
import Typography from '@material-ui/core/Typography';
import ArchiveRecord from '~/records/ArchiveRecord';

import ArchivePreview from '~/components/ArchivePreview';
import GridContainer from '~/components/GridContainer'

const Container = styled.div`
  padding: 16px;
`;

const Title = styled(Typography)`
  margin-bottom: 8px;
`;

const isImmutable = arg => Iterable.isIterable(arg);

function formatToArchive(v){
  if( isImmutable(v) ){ return v }
  return new ArchiveRecord(v)
}

function mapValue( [, v] ){
  return formatToArchive(v)
}

export default function AttachmentsPreview({ attachments, title }) {
  const archives = useMemo(() => {
    if( Array.isArray(attachments) ){
      return attachments.map(formatToArchive)
    }else if( isImmutable(attachments) ){
      return attachments.toArray().map(mapValue)
    }
    attachments && console.error("attachmens no formato errado")
    return []
  }, [attachments])

  return (
    <Container>
      { title && <Title variant="subtitle1">{title}</Title> }
      <GridContainer>
        {archives.map( file => (
          <ArchivePreview
            key={file.idOrToken}
            file={file}
            viewMode
          />
        ))}
      </GridContainer>
    </Container>
  );
}