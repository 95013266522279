import React from 'react';
import { Switch } from 'react-router-dom';

import { accountPlansRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';

export default function AccountPlansRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={accountPlansRoute.new.path}
        component={List}
        permissionKeyCode="AccountPlan"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={accountPlansRoute.edit.path}
        component={List}
        permissionKeyCode="AccountPlan"
        permission="edit"
      />
      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={accountPlansRoute.list.path}
        component={List}
        permissionKeyCode="AccountPlan"
        permission="see"
      />
    </Switch>
  );
}