import React from 'react';

import Typography from '@material-ui/core/Typography';
import imgLogo from '~/assets/images/report-image.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LanguageIcon from '@material-ui/icons/Language';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';

import {
  Header, Image, Content, Text, Icon, Row
} from './styles'

function HeaderPrint() {
  return (
    <Header className="only-print">
      <Image src={imgLogo} />
      <Content>
        <Typography variant="subtitle1" component="h1">
          <strong>ILHA DE OBRAS</strong>
        </Typography>
        <Text component="a" href="https://goo.gl/maps/CjEDHehhbmmPghEn7" target="_blank" rel="noopener">
          <Icon><RoomIcon /></Icon>
          Avenida Nereu Ramos, 1020 E Chapecó - SC, 89801-020
        </Text>
        <Row>
          <Text component="a" href="tel:+554930260006">
            <Icon><PhoneIcon /></Icon>
            (49) 3026-0006
          </Text>
          <Icon></Icon>
          <Text component="a" href="https://api.whatsapp.com/send?phone=5549999347846">
            <Icon><WhatsAppIcon /></Icon>
            (49) 9 9934-7846
          </Text>
        </Row>
        <Text component="a" href="mailto:contato@ilhadeobras.com.br">
          <Icon><MailOutlineIcon /></Icon>
          contato@ilhadeobras.com.br
        </Text>
      </Content>
      <Content style={{textAlign: 'right', marginLeft: 'auto'}}>
        <Text component="a" href="https://ilhadeobras.com.br/" target="_blank" rel="noopener">
          <Icon><LanguageIcon /></Icon>
          ilhadeobras.com.br
        </Text>
        <Text component="a" href="https://instagram.com/ilhadeobras" target="_blank" rel="noopener">
          <Icon><InstagramIcon /></Icon>
          @ilhadeobras
        </Text>
        <Text component="a" href="https://www.facebook.com/ilhadeobras" target="_blank" rel="noopener">
          <Icon><FacebookIcon /></Icon>
          /ilhadeobras
        </Text>
      </Content>
    </Header>
  );
}

export default React.memo(HeaderPrint)
