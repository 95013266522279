import styled from 'styled-components';
import { shade } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export const AvatarContainer = styled(Grid).attrs({ item: true })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  height: 300px;

  strong {
    font-size: 1.4rem;
  }
`;

export const AvatarInput = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;
  align-self: center;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    pointer-events: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: #4F3A8B;
    border: none;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    cursor: pointer;
    transition: background-color 200ms;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #FFF;
    }

    &:hover {
      background-color: ${shade(0.2, '#4F3A8B')};
    }
  }
`;

const useStyles = makeStyles( () => ({
  list: {
    width: 500,
    height: 'inherit',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'column',
    padding: 50 ,
  },
  fullList: {
    width: 'auto',
  },
  large: {
    width: 100,
    height: 100,
  },
  avatar: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  bottomButtons:{
    alignSelf: 'flex-end',
    paddingRight: 50,

  },
  cancelButton: {
    marginRight: 25,
  },
  formDiv: {
    width: '90%',
    flexDirection: 'column',
  },
  textDivs:{
    alignSelf: 'center',
  }
}));

export default useStyles;
