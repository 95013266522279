import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Item = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 130px;
  padding: 20px;
  justify-content: space-between;

  span {
    font-size: 1rem;
    color: #000;
  }

  h3 {
    margin: 0;
    font-size: 2rem;

    span {
      font-weight: bold;
      font-size: 2rem;
    }
  }
`;
