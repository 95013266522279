import React from 'react';
import InputNumber from '~/components/Input/InputNumber';

const InputFilterNumber = ({ value, onValueChange }) => {
  const handleChange = ({number}) => {
    onValueChange(number);
  };
  return (
    <InputNumber
      state-name="number"
      value={value}
      precision={2}
      onChange={handleChange}
      style={{width: '100%'}}
    />
  );
};

export default InputFilterNumber