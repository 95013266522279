import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/products';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { productsRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const PRODUCTS_SET_CURRENT = '@App/products/PRODUCTS_SET_CURRENT';
export const PRODUCTS_FETCH_START = '@App/products/PRODUCTS_FETCH_START';
export const PRODUCTS_FETCH_DONE  = '@App/products/PRODUCTS_FETCH_DONE';
export const PRODUCTS_FETCH_LIST_START = '@App/products/PRODUCTS_FETCH_LIST_START';
export const PRODUCTS_FETCH_LIST_DONE = '@App/products/PRODUCTS_FETCH_LIST_DONE';
export const PRODUCTS_SET_ERRORS = '@App/products/PRODUCTS_SET_ERRORS';
export const CLEAR_STATE = '@App/products/CLEAR_STATE';
export const FIND_PRODUCT_REQUEST = '@App/products/FIND_PRODUCT_REQUEST';
export const DESTROY_PRODUCTS_REQUEST = '@App/products/DESTROY_PRODUCTS_REQUEST';
export const UPDATE_PRODUCTS_REQUEST = '@App/products/UPDATE_PRODUCTS_REQUEST';
export const CREATE_PRODUCTS_REQUEST = '@App/products/CREATE_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_REQUEST  = '@App/products/FETCH_PRODUCTS_REQUEST';
export const PRODUCTS_CHANGE_LIST  = '@App/products/PRODUCTS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [PRODUCTS_FETCH_START]: fetchStart,
    [PRODUCTS_FETCH_DONE]:  fetchDone,
    [PRODUCTS_FETCH_LIST_START]: fetchListStart,
    [PRODUCTS_FETCH_LIST_DONE]: fetchListDone,
    [PRODUCTS_SET_ERRORS]: setErrors,
    [PRODUCTS_SET_CURRENT]: setCurrent,
    [DESTROY_PRODUCTS_REQUEST]: initLoading,
    [CREATE_PRODUCTS_REQUEST]: initLoading,
    [UPDATE_PRODUCTS_REQUEST]: initLoading,
    [PRODUCTS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}, callbackSuccess) {
  return {
    type: FIND_PRODUCT_REQUEST,
    payload: { id, params, callbackSuccess }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_PRODUCTS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_PRODUCTS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_PRODUCTS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_PRODUCTS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: PRODUCTS_SET_CURRENT,
    payload: { current }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'products',

  routes: {
    list: () => productsRoute.list.build(),
    show: (id) => productsRoute.show.build({id}),
    new: () => productsRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      PRODUCTS_FETCH_START,
    FETCH_DONE:       PRODUCTS_FETCH_DONE,
    FETCH_LIST_START: PRODUCTS_FETCH_LIST_START,
    FETCH_LIST_DONE:  PRODUCTS_FETCH_LIST_DONE,
    CHANGE_LIST:      PRODUCTS_CHANGE_LIST,
    CHANGE_CURRENT:   PRODUCTS_SET_CURRENT,
    SET_ERRORS:       PRODUCTS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_PRODUCT_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_PRODUCTS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_PRODUCTS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_PRODUCTS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_PRODUCTS_REQUEST, crudActions.fetchListAction),
]);