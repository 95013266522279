/* eslint prefer-const: "off" */

import createReducer from '~/store/utils/createReducer';
import Notification  from './Notification';

// action types
export const NOTIFICATION_ADD    = '@App/notify/NOTIFICATION-ADD';
export const NOTIFICATION_REMOVE = '@App/notify/NOTIFICATION-REMOVE';

import { toast } from 'react-toastify';


// reducer
const initialState = {
  queue:   {},
};

export default createReducer(
  initialState,
  {
    [NOTIFICATION_ADD]: (draft, { payload}, state) => {
      let queue = {...state.queue};
      let notify = payload.record;

      if( payload && notify instanceof Notification ){
        queue[payload.id] = notify;
      }else{
        notify = new Notification(payload);
        queue[notify.id] = notify;
      }
      draft.queue = queue;

      toast[notify.get('type')](notify.get('title'));
    },

    [NOTIFICATION_REMOVE]: (draft, { payload }, state) => {
      let queue = {...state.queue};
      delete queue[payload.id];
      draft.queue = queue;
    },
  }
);

// Actions

export const addNotification = (attrs={}) => {
  const notify = new Notification(attrs);
  return {
    type:    NOTIFICATION_ADD,
    payload: {
      id: notify.id,
      record: notify
    }
  };
};

export const nofityRemove = (id) => {
  return {
    type:    NOTIFICATION_REMOVE,
    payload: { id }
  };
};