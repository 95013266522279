import SchemaBuilder from './SchemaBuilder';

const hiddenList = {tableView: false, hidden: true, isFilter:  false}
const columns = {
  id: {type: 'integer', width: 90, title: 'ID' },
  name: { type: 'string', width: 200, default: '', title: 'Nome' },
  segment:    { type: 'object', width: 200, default: '', title: 'Segmento' },
  is_legal:   { type: 'boolean', width: 60, default: '', title: 'Pessoa juridica', hidden: true },
  cpf_cnpj:   { type: 'string', width: 200, default: '', title: 'CPF/CNPJ' },
  rg:         { type: 'string', width: 100, default: '', title: 'RG', hidden: true },
  birth_date: { type: 'date', width: 100, default: '', title: 'Data de Nascimento', hidden: true },
  address:    { type: 'object', width: 100, default: '', title: 'Endereço', ...hiddenList },
  contacts:   { type: 'object', width: 100, default: '', title: 'Contatos', ...hiddenList },
  created_at: {
    type: 'datetime',
    title: 'Criado em',
    width: 150
  },
};

const supplierSchema = new SchemaBuilder( columns );

export default supplierSchema;
