import styled from 'styled-components';
import MUCardContent from '@material-ui/core/CardContent';

export const CardContent = styled(MUCardContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 40px;
`;

export const TableContainer = styled.div`
  padding-bottom: 100px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 235px;
  margin-top: 16px;
  padding-right: 30px;
  flex-direction: row;
  justify-content: flex-end;
`;
