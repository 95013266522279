import usersFetch from '~/services/selectFetch/usersFetch'
import peopleFetch from '~/services/selectFetch/peopleFetch'
import customRecordsFetch from '~/services/selectFetch/customRecordsFetch'

const getInputPropsByAsyncInput = ( metaField ) => {
  const { field } = metaField;
  let props = {}

  if( field.record_type === 'User' ){
    props.handleFetch = usersFetch;
  }else if( field.record_type === 'Person' ){
    props.handleFetch = peopleFetch;
  }else{
    props.handleFetch = customRecordsFetch(field.record_type)
  }

  return props
}

export default getInputPropsByAsyncInput;