import React, { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ConstructionIcon from '@material-ui/icons/Apartment';

import InputControl from '../Input/InputControl';
import Spinner from '../Spinner';
import Dialog from '../Dialog';

import { constructionsApi } from '~/routes/api';
import Request from '~/services/request';

import { ItemContainer } from './styles';

export default function SearchConstructionsDialog({
  onSelect,
  openDialog,
  setOpenDialog
}) {
  const [searchValue, setSearchValue] = useState('');
  const [constructions, setConstructions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleFindConstructions();
  }, []);

  const handleFindConstructions = useCallback(
    async (event) => {
      if(event){ event.preventDefault() }

      try {
        setLoading(true);

        const path = constructionsApi.search.build();
        let params = `per_page=20&qs=${searchValue}`;

        const response = await Request.get(`${path}?${params}`);
        const { data } = response.data;

        setConstructions(data);
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    },
    [searchValue]
  );

  return (
    <Dialog
      isOpen={openDialog}
      handleClose={() => setOpenDialog(false)}
      title={'Selecionar construção'}
      color="primary"
      fullWidth
      maxWidth="xs"
      keepMounted
      dialogActions={<></>}
    >
      <form onSubmit={handleFindConstructions} >
        <Grid container>
          <Grid item xs={12}>
            <InputControl
              label={'Pesquise por uma construção'}
              value={searchValue}
              inputComponent={Input}
              name={'search'}
              onChange={({ search }) => setSearchValue(search)}
              endAdornment={(
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              )}
            />
          </Grid>

          {loading && <Spinner loadingText="Buscando construcões..." />}

          <ItemContainer>
            {!loading && constructions.map(construction => (
              <div key={construction.id} onClick={() => onSelect(construction)}>
                <ConstructionIcon size={18} />
                <span>{construction.name}</span>
              </div>
            ))}
          </ItemContainer>
        </Grid>
      </form>
    </Dialog>
  );
}
