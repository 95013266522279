import React, { useEffect, useCallback, useState, useMemo } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import isPresent from '~/utils/isPresent';
import {
  fetchListRecordsRequest as fetchListPermissionsRequest
} from '~/services/fetchApi/permissions'

function handleChangePermission(permission, action, isChecked){
  permission[action] = isChecked;
  if(action !== 'see' && isChecked){
    permission.see = true;
    permission._destroy = undefined;
  }
  if(action === 'see' && !isChecked){
    permission.del = permission.edit = permission.add = false;
    permission._destroy = true;
  }
  return permission
}

export default function PermissionsTable({ record, onChange}) {
  const [isLoading, setIsLoading] = useState(true)

  const rolePermissions = useMemo(() => {
    return [...record.get('role_permissions').filter(isPresent)]
  }, [record.get('role_permissions')])

  useEffect( () => {
    const fetchPermissions = async () => {
      const response = await fetchListPermissionsRequest({sort: 'name', direction: 'asc', per_page: 9999, adapter: 'json'});
      const currentRolePermissions = [...record.get('role_permissions')]
      let newPermissions = [];
      response.data.data.forEach( (permission) => {
        let rolePermission = currentRolePermissions.find( e => Number(e.permission_id) === Number(permission.id) )
        if( rolePermission ){
          rolePermission = {...rolePermission}
          rolePermission.see = true
          rolePermission.permission = permission
        }else{
          rolePermission = { permission_id: permission.id, see: false, permission }
        }
        newPermissions = [...newPermissions, rolePermission]
      });
      onChange({role_permissions: newPermissions})
      setIsLoading(false)
    }
    fetchPermissions()
  }, [])

  const onToggleAll = useCallback(
    (isChecked, action) => {
      rolePermissions.forEach( (e) => {
        handleChangePermission(e, action, isChecked);
      });
      onChange({role_permissions: rolePermissions})
    },
    [rolePermissions, onChange, record],
  )

  const onTogglePermission = useCallback(
    (permissionID, isChecked, action) => {
      const newRolePermissions = [...rolePermissions].map( e => {
        if(e.permission_id === permissionID){
          return handleChangePermission(e, action, isChecked);
        }
        return e;
      })

      onChange({role_permissions: newRolePermissions})
    },
    [rolePermissions, onChange, record],
  )

  if(isLoading){ return <></> }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Permissão</TableCell>
            <TableCell>
              <Checkbox
                onChange={(e, isCheck) => onToggleAll(isCheck, 'see') }
                color="primary"
                style={{ padding: 0 }}
                inputProps={{ 'aria-label': 'input visualizar' }}
              />
              Visualizar
            </TableCell>
            <TableCell>
              <Checkbox
                onChange={(e, isCheck) => onToggleAll(isCheck, 'add') }
                color="primary"
                style={{ padding: 0 }}
                inputProps={{ 'aria-label': 'input adicioanr' }}
              />
              Adicionar
            </TableCell>
            <TableCell>
              <Checkbox
                onChange={(e, isCheck) => onToggleAll(isCheck, 'edit') }
                color="primary"
                style={{ padding: 0 }}
                inputProps={{ 'aria-label': 'input editar' }}
              />
              Editar
            </TableCell>
            <TableCell>
              <Checkbox
                onChange={(e, isCheck) => onToggleAll(isCheck, 'del') }
                color="primary"
                style={{ padding: 0 }}
                inputProps={{ 'aria-label': 'input excluir' }}
              />
              Excluir
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { rolePermissions && rolePermissions.map( rlp =>
            <TableRow key={rlp.permission_id}>
              <TableCell component="th" scope="row">
                {rlp.permission?.name}
              </TableCell>
              { rlp.permission?.is_crud &&
                <>
                  <TableCell>
                    <Checkbox
                      checked={rlp.see === true}
                      onChange={(e, isCheck) => onTogglePermission(rlp.permission_id, isCheck, 'see') }
                      color="primary"
                      style={{ padding: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={rlp.add === true}
                      onChange={(e, isCheck) => onTogglePermission(rlp.permission_id, isCheck, 'add') }
                      color="primary"
                      style={{ padding: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={rlp.edit === true}
                      onChange={(e, isCheck) => onTogglePermission(rlp.permission_id, isCheck, 'edit') }
                      color="primary"
                      style={{ padding: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={rlp.del === true}
                      onChange={(e, isCheck) => onTogglePermission(rlp.permission_id, isCheck, 'del') }
                      color="primary"
                      style={{ padding: 0 }}
                    />
                  </TableCell>
                </>
              }
              { !rlp.permission?.is_crud &&
                <TableCell colSpan={4}>
                 <Checkbox
                    checked={rlp.see === true}
                    onChange={(e, isCheck) => onTogglePermission(rlp.permission_id, isCheck, 'see') }
                    color="primary"
                    style={{ padding: 0 }}
                  />
                  Ação única
                </TableCell>
              }
            </TableRow>
          ) }
        </TableBody>
      </Table>
    </TableContainer>
  );
}