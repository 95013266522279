import React, { useCallback } from 'react';

import styled from 'styled-components';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { TableRowDetail} from '@devexpress/dx-react-grid-material-ui';

const ToggleCellHiddeButtom = styled(TableRowDetail.ToggleCell)`
  pointer-events: "none";
  opacity: 0.5;
  button {
    display: none
  }
`;

const ToggleCellComponent = ({ row, expanded, onToggle, ...restProps }) => {
  if (row.expenses.length === 0){
    return  <ToggleCellHiddeButtom {...restProps} row={row} />;
  }
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      onToggle();
    },
    [row, expanded, onToggle],
  )
  return <TableCell>
    <IconButton onClick={handleClick}>
      {
        expanded
          ? <VisibilityOffIcon />
          : <VisibilityIcon />
      }
    </IconButton>
  </TableCell>
};

export default ToggleCellComponent;