import React from "react";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themeStyle from '~/assets/styles/theme';

const customTheme = createMuiTheme(themeStyle)

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={customTheme}>
      {children}
    </ThemeProvider>
  );
}