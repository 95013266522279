import React, { useMemo } from 'react';
import clsx from 'clsx';
import filesize from 'filesize';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

import Col from '~/components/Col'
import Tooltip from '~/components/Tooltip';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  imgContainer: {
    width: '100%',
    height: 100,
    overflow: 'hidden'
  },
  img: {
    maxWidth: '100%',
    height:   '100%',
    margin:   'auto',
    padding:   5,
    objectFit: 'contain'
  },
}));

export default function RecipeReviewCard({archive, toggleCheck, onEdit, allowMany}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const InputCheck = useMemo(() => allowMany ? Checkbox : Radio , [allowMany])

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const subFilename = `${archive.get('filename')}`.substring(0, 15)

  return (
    <Col xs={12} sm={4} md={3} lg={2}>
      <Card className={classes.root}>
        <CardHeader
          action={
            <InputCheck
              checked={archive.get('_is_checked')}
              onChange={() => toggleCheck(archive)}
              color="primary"
            />
          }
          title={
            <Tooltip title={archive.get('filename')}>
              <Typography component='p' variant='body2'>
                { archive.get('filename').length > subFilename.length ? `${subFilename}...` : subFilename }
              </Typography>
            </Tooltip>
          }
          subheader={filesize( archive.get('file_size') )}
          titleTypographyProps={{component: 'div'}}
          subheaderTypographyProps={{variant: 'caption'}}
        />
        <div className={classes.imgContainer}>
          <CardMedia
            className={classes.img}
            component="img"
            alt={ archive.get('description') || '' }
            image={archive.get('icon')}
            title={archive.get('filename')}
          />
        </div>
        <CardActions disableSpacing>
          <IconButton onClick={onEdit} >
            <Tooltip title="Editar arquivo">
              <EditIcon />
            </Tooltip>
          </IconButton>

          <IconButton href={archive.get('url')} target="_blank" >
            <Tooltip title="Visualizar arquivo">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
          { !!archive.get('description') &&
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <Tooltip title="Ver descrição">
                <ExpandMoreIcon />
              </Tooltip>
            </IconButton>
          }
        </CardActions>
        { !!archive.get('description') &&
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                { archive.get('description') }
              </Typography>
            </CardContent>
          </Collapse>
        }
      </Card>
    </Col>
  );
}