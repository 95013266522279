import Request from '~/services/request';
import deserialize from '~/utils/deserialize';
import fieldKindEnum from '~/enums/fieldKindEnum';

const kinds = fieldKindEnum().objects();

export const fieldsAsyncFetch = (ex) => (inputValue, page, doneCallback) => {
  let uri = `/api/v1/fields?sort=name.asc&qs=${inputValue}&page=${page}&per_page=50`;
  if(ex && ex.length){
    uri = `${uri}&excepts=${ex.join('-')}`
  }
  return Request.get(uri).then( async resp => {
    const data = await deserialize( resp.data );
    const items = data.map( ({id, options, ...field}) => ({
      ...field,
      _options: options,
      name: `${field.name} [${kinds[field.kind]}]`,
      id: Number(id),
    }))
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: items
      }
    )
  })
  .catch(console.error);
};

export const fieldAsyncShow =  (id) => {
  return Request.get(`/api/v1/fields/${id}?adapter=json`).then(resp => {
    if(resp.data && resp.data.field){
      return resp.data.field;
    }
    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}