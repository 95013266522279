import React, { useState, createContext } from 'react';

const DrawerMenuContext = createContext({
  open: null,
  drawerMenuStorageState: null,
  setOpen: () => {},
});

export function DrawerMenuProvider({ children }) {
  const drawerMenuStorageState = drawerStorageState();
  const [open, setOpen] = useState(drawerMenuStorageState);

  function drawerStorageState() {
    const drawerMenuOpen = localStorage.getItem('@App/state/drawerMenuOpen');

    return drawerMenuOpen !== 'false';
  }

  return (
    <DrawerMenuContext.Provider value={{ open, setOpen, drawerMenuStorageState }}>
      {children}
    </DrawerMenuContext.Provider>
  );
}

export default DrawerMenuContext;
