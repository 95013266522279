// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputNumber   from '~/components/Input/InputNumber';

// material-ui
import Grid from '@material-ui/core/Grid';

class InputExample extends Component {
	state = {
		input1: -5890.5,
		input2: 0,
		input3: null,
	}

	handleChangeKey = ( valKey) => {
		this.setState(valKey);
	};

	render(){

		return(
			<div>
				<Grid container spacing={4}>
					<Grid item xs={4}>
						<InputControl
							id={'ID-CUSTOMIZADO'}
							value={this.state.input1}
							name={'input1'}
							label={'Valor 1'}
							inputComponent={InputNumber}
							onChange={this.handleChangeKey}
						/>
					</Grid>
					<Grid item xs={4}>
						<InputControl
							required
							value={this.state.input2}
							label={'Valor 2'}
							inputComponent={InputNumber}
							name={'input2'}
							InputProps={{
								precision: 0
							}}
							onChange={this.handleChangeKey}
						/>
					</Grid>
					<Grid item xs={4}>
						<InputControl
							required
							value={this.state.input3}
							label={'Valor 3'}
							inputComponent={InputNumber}
							name={'input3'}
							onChange={this.handleChangeKey}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default InputExample;