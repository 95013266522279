import React from "react";

import isPresent from '~/utils/isPresent';

import BaseLayout from '../BaseLayout';
import Table from '../Table';
import Nav from './Nav';

import { QueryParamsProvider } from '~/contexts/QueryParamsContext';
import { FilterProvider } from '~/contexts/FilterContext';

export default function LayoutList({
  breadcrumbs,
  navProps,
  tableProps,
  children
}) {
  return (
    <QueryParamsProvider>
      <FilterProvider>
        <BaseLayout breadcrumbs={breadcrumbs}>
          <Nav withFilters={ isPresent(tableProps?.filterProps?.columns) } {...navProps} />
          <Table {...tableProps} />
          {children}
        </BaseLayout>
      </FilterProvider>
    </QueryParamsProvider>
  );
}
