import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setGroupCondition } from '../../store/actions';
import ToggleCondition from '../ToggleCondition';

import Divider from './components/Divider';
import AddGroup from './components/AddGroup';
import AddFilter from './components/AddFilter';
import FilterItem from './components/FilterItem';
import useStyles from './styles';

function Groups() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const groups = useSelector(state => state.get('groups'));
  const condition = useSelector(state => state.get('condition'));

  return (
    <div className={classes.root}>
      {groups.map((group, indexGroup) => (
        <React.Fragment key={group.get('token')}>
          <div className={classes.group}>
            {group.get('filters').map((filter, indexFilter) => (
              <React.Fragment key={filter.get('token')}>
                <FilterItem
                  filter={filter}
                  indexGroup={indexGroup}
                  indexFilter={indexFilter}
                />

                {indexFilter < (group.get('filters').size - 1) &&
                  <Divider small condition={group.get('condition')} />
                }
              </React.Fragment>
            ))}

            <div className={classes.groupFooter}>
              <div className={classes.groupFooterLeft}>
                <ToggleCondition
                  condition={group.get('condition')}
                  setCondition={condition => dispatch(setGroupCondition(indexGroup, condition))}
                />
              </div>

              <div className={classes.groupFooterRight}>
                <AddFilter indexGroup={indexGroup} />
              </div>
            </div>
          </div>
          {indexGroup < (groups.size - 1) && <Divider condition={condition} />}
        </React.Fragment>
      ))}

      <AddGroup />
    </div>
  );
}

export default React.memo(Groups);
