import { makeStyles } from '@material-ui/core/styles';

const FOOTER_HEIGTH = 50;
export default makeStyles(theme => ({
  root: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },

  group: {
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    // minHeight: 140,
    borderRadius: 5,
    position: 'relative',
    paddingBottom: FOOTER_HEIGTH,
  },

  groupFooter: {
    width: '100%',
    minHeight: FOOTER_HEIGTH,
    borderWidth: 0,
    borderTopWidth: 2,
    borderTopColor: theme.palette.common.white,
    borderStyle: 'solid',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
  },
}));
