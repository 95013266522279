import React, { useState, useContext, useCallback } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InputControl from '../Input/InputControl';
import Input from '@material-ui/core/Input';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';

import Button from '~/components/Button';
import Col from '~/components/Col';

import getMe from '~/utils/getMe';
import useStyles from './styles';
import formatUserAccessType from '~/utils/formatUserAccessType';
import {useDispatch, useSelector} from 'react-redux';
import { signOut } from '~/store/modules/auth';
import { editUserProfileRequest } from '~/store/modules/userProfile';
import avatarImage from '~/assets/images/avatar.png';
import ProfileDrawerContext from '~/contexts/ProfileDrawerContext';

const AvatarComponent = () => {
  const reduxData = useSelector(state => state.userProfile.data || '');
  const classes = useStyles();
  const {name, access_type, avatar} = reduxData;
  // console.log({avatar})
  return(
    <div className={classes.avatar}>
      <Avatar
        alt={name}
        src={avatar?.url || avatarImage}
        className={classes.large}
      />
      <div className={classes.textDivs}>
        <div>
          <a>{name}</a>
        </div>
        <a>{formatUserAccessType(access_type)}</a>
      </div>
    </div>
  );
}

const FormField = ({onClose, id}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxData = useSelector(state => state.userProfile.data || '');
  const [values, setValues] = useState({
    name:  reduxData['name'],
    email: reduxData['email'],
    password: '',
  });
  const onSave = () => {
    dispatch(editUserProfileRequest(id, {data: values}));
    onClose();
  };
  const onChange = (attr) =>{
    setValues({...values, ...attr});
  }
  return(
    <React.Fragment>
      <div className={classes.formDiv}>
        <Field
          label={'Nome'}
          field={'name'}
          value={values['name']}
          onChange={onChange}
          />
        <Field
          label={'E-mail'}
          field={'email'}
          value={values['email']}
          onChange={onChange}
          />
        <Field
          label={'Alterar Senha'}
          field={'password'}
          value={values['password']}
          onChange={onChange}
          />
      </div>
      <FormButtons onClose={onClose} onSave={onSave}/>
    </React.Fragment>

  );
}

const Field = ({label, field, value, onChange}) => {
  return(
    <InputControl
      id={`id-${field}`}
      style={{alignSelf: 'center'}}
      value={value}
      name={field}
      label={label}
      inputComponent={Input}
      onChange={onChange}
    />
  );
}

const FormButtons = ({onClose, onSave}) => {
  const classes = useStyles();

  return(
    <Col className={classes.bottomButtons}>
      <Button
        size='small'
        color='red'
        className={classes.cancelButton}
        onClick={onClose}
      >
        Cancelar
      </Button>
      <Button
        color='success'
        size='small'
        variant='contained'
        onClick={onSave}
      >
        Salvar
      </Button>
    </Col>
  )
}


export default function SwipeableTemporaryDrawer() {
  const { id } = getMe();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { profileOpen, setProfileDrawerOpen } = useContext(ProfileDrawerContext);

  const handleSignOut = useCallback(
    () => {
      dispatch(signOut())
      setProfileDrawerOpen(false);
    },
    [dispatch]
  );

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={profileOpen}
      onClose={() => setProfileDrawerOpen(false)}
      onOpen={() => setProfileDrawerOpen(true)}
    >
      <div className={classes.list}>
        <AvatarComponent />
        <FormField id={id} onClose={() => {setProfileDrawerOpen(false)}} />

        <Button
          color="grey"
          variant="text"
          className={classes.button}
          startIcon={<ExitToAppIcon />}
          onClick={handleSignOut}
        >
          Sair
        </Button>
      </div>
    </SwipeableDrawer>
  );
}
