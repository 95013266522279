import React, { useRef, useContext } from 'react';
import Badge from '@material-ui/core/Badge';
import FilterListIcon from '@material-ui/icons/Tune';

import QueryParamsContext from '../../../contexts/QueryParamsContext';
import FilterContext from '../../../contexts/FilterContext';
import Button from '../../Button';
import InputSearch from '../../Input/InputSearch';
import Permission from '../../Permission';

import { Container, LeftContent, RightContent, Link } from './styles';

export default function Nav({
  inputSearchProps = {},
  routeNewRegister,
  leftComponent,
  rightComponent: RightComponent,
  permissionKeyCode,
  withFilters
}) {
  const { params: { size }, setParams } = useContext(QueryParamsContext);
  const { setFilterOpen } = useContext(FilterContext);
  const inputSearchRef = useRef(null);

  function handleSubmit(value) {
    const { onSubmit } = inputSearchProps;

    setParams({ qs: value });
    onSubmit && onSubmit(value);
  }

  return (
    <Container>
      <LeftContent>
        <InputSearch
          ref={inputSearchRef}
          {...inputSearchProps}
          onSubmit={handleSubmit}
        />
        { withFilters &&
          <Badge badgeContent={size} color="primary">
            <Button
              variant="text"
              startIcon={<FilterListIcon />}
              style={{ marginLeft: 10, textTransform: 'none' }}
              onClick={() => setFilterOpen(true)}
            >
              Filtros
            </Button>
          </Badge>
        }
        {leftComponent}
      </LeftContent>
      <RightContent>
        {RightComponent && <RightComponent />}
          {!!routeNewRegister && (
            <Permission keyCode={permissionKeyCode} permission="add">
              <Link to={routeNewRegister} style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="green">
                  Novo
                </Button>
              </Link>
            </Permission>
          )}
      </RightContent>
    </Container>
  );
}
