import React, { useMemo } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import makeTheme from '~/assets/styles/makePrimaryColorTheme';

const IconMakeColor = ({color, Icon, ...props}) => {
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <Icon {...props} color='primary'/>
  </ThemeProvider>
}

export default React.memo(IconMakeColor)