import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWrapper from '~/routes/RouteWrapper';
import Examples from './Examples';

export default function ExamplesRouteComponent(){
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={'/sys/components-example'}
        component={Examples}
        permissionKeyCode="Example"
        permission="see"
      />
    </Switch>
  )
}