import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import InformationIcon from '@material-ui/icons/AccountBalance';
import PlanningIcon from '@material-ui/icons/Dvr';
import PercentIcon from '@material-ui/icons/FormatListNumbered';

import { findRecord, destroyRecord, clearState } from '~/store/modules/constructions';
import { constructionsRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import Button from '~/components/Button';

import { Container, Tab, Header } from './components/Show/styles';
import InformationsTab from './components/Show/InformationsTab';
import PlanningTab from './components/Show/PlanningTab';
import PercentTab from './components/Show/PercentTab';

const TABS = {
  INFORMATIONS: 'informations',
  PLANNING: 'planning',
  PERCENT: 'percent',
}

function Show({isLoading }) {
  const [selectedTab, setSelectedTab] = useState(TABS.INFORMATIONS);
  const [construction, setConstruction] = useState();
  const [response, setResponse] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();

  const findConstructionDispatch = useCallback(
    () => {
      dispatch(
        findRecord(id, {
        include: 'customer-engineer-contractor-address.city-attachments-construction_account_plans-construction_plannings.account_plan'
        }, (current, resp) => {
          setConstruction( current )
          setResponse( resp )
        } )
      );
    },
    [id, setConstruction, setResponse],
  )

  useEffect(() => {
    findConstructionDispatch()

    return () => dispatch(clearState());
  }, []);

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )


  return (
    <LayoutShow
      breadcrumbs={[
        { path: constructionsRoute.list.path, text: 'Obras' },
        { text: 'Exibindo obra' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   constructionsRoute.new.build(),
        routeCloneRegister: constructionsRoute.clone.build({ id }),
        routeEditRegister:  constructionsRoute.edit.build({ id }),
        permissionKeyCode:  'Construction'
      }}
    >
      <Container>
        <Header>
          <Tab isSelected={selectedTab === TABS.INFORMATIONS}>
            <Button
              startIcon={<InformationIcon />}
              variant="text"
              onClick={() => setSelectedTab(TABS.INFORMATIONS)}
            >
              Informações
            </Button>
          </Tab>

          <Tab isSelected={selectedTab === TABS.PLANNING}>
            <Button
              startIcon={<PlanningIcon />}
              variant="text"
              onClick={() => setSelectedTab(TABS.PLANNING)}
            >
              Planejamento
            </Button>
          </Tab>

          <Tab isSelected={selectedTab === TABS.PERCENT}>
            <Button
              startIcon={<PercentIcon />}
              variant="text"
              onClick={() => setSelectedTab(TABS.PERCENT)}
            >
              Percentual
            </Button>
          </Tab>
        </Header>

        {selectedTab === TABS.INFORMATIONS && (
          <InformationsTab construction={construction} />
        )}

        {selectedTab === TABS.PLANNING && (
          <>
            { !!construction &&
              <PlanningTab
                response={response}
                construction={construction}
                findConstructionDispatch={findConstructionDispatch}
              />
            }
          </>
        )}

        {selectedTab === TABS.PERCENT && (
          <>
            { !!construction &&
              <PercentTab
                construction={construction}
                findConstructionDispatch={findConstructionDispatch}
              />
            }
          </>
        )}
      </Container>

    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.constructions.isLoading
});

export default connect(mapStateToProps)(Show);
