import SchemaBuilder from './SchemaBuilder';
import constructionTypeEnum from '~/enums/constructionTypeEnum'
import constructionStatusEnum from '~/enums/constructionStatusEnum'

const hiddenList = {tableView: false, hidden: true, isFilter:  false}
const columns = {
	name:        { type: 'string', default: '', title: 'Nome da Obra' },
	customer:    { type: 'object', default: '', title: 'Cliente' },
	cpf_cnpj:    { type: 'string', default: '', title: 'CPF/CNPJ', hidden: true },
	status:            {
		type: 'enum',
		default: '',
		title: 'Status',
		_enum_: constructionStatusEnum
	},
	construction_type: {
		type: 'enum',
		default: '',
		title: 'Tipo de Obra',
		_enum_: constructionTypeEnum
	},
	engineer:    { type: 'object', default: '', title: 'Engenheiro', hidden: true },
	builder:     { type: 'object', default: '', title: 'Nome do Construtor', hidden: true },
	contractor:  { type: 'object', default: '', title: 'Nome do Empreiteiro', hidden: true },
	start_date:  { type: 'date', default: '', title: 'Data início', hidden: true },
	end_date:    { type: 'date', default: '', title: 'Data Final', hidden: true },
	adm_percent: { type: 'float', default: '', title: 'Percentual de Adm'},
	latitude:    { type: 'float', default: '', title: 'Latitude', ...hiddenList },
	longitude:   { type: 'float', default: '', title: 'Longitude', ...hiddenList },
	address:     { type: 'object', default: '', title: 'Endereço', ...hiddenList },
  created_at:  { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:  { type: 'datetime', default: '', title: 'Atualizado em', hidden: true},
	attachments: { type: 'object', default: '', title: 'Anexos', ...hiddenList },
	customer_construction: { type: 'object', default: '', title: 'Cliente associado', ...hiddenList },
};

const constructionSchema = new SchemaBuilder( columns );

export default constructionSchema;