import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';

import ContactRecord from '~/records/ContactRecord';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputPhone from '~/components/Input/InputPhone';
import Button from '~/components/Button';

const attrName = (attr) => ContactRecord.hummanAttributeName(attr)

function ContactsForm({ onChange, contacts }) {

  const handleChange = useCallback(
    (token) => (attrs) => {
      const contact = contacts.get(token);
      onChange({
        contacts: contacts.set(token, contact.updateAttributes(attrs) )
      })
    },
    [contacts, onChange],
  )

  const handleDelete = useCallback(
    (token) => () => {
      const contact = contacts.get(token);
      if( contact.isPersisted() ){
        onChange({
          contacts: contacts.set(token, contact.updateAttributes({_destroy: true}) )
        })
      }else{
        onChange({
          contacts: contacts.delete(token)
        })
      }
    },
    [contacts, onChange],
  )

  const onAddContact = useCallback(
    () => {
      const contact = new ContactRecord();
      onChange({
        contacts: contacts.set(contact.idOrToken, contact )
      })
    },
    [contacts, onChange]
  )

  return (
    <>
      { contacts.filter(c => !c.get('_destroy') ).sortBy( (v) => (v.idOrToken)  ).toArray().map( ([token, contact]) => {
        return <Card key={token} style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
          <CardContent style={{ width: '100%' }}>
            <GridContainer>
              <Col>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton onClick={ handleDelete(token) }>
                    <DeleteIcon style={{ color: 'red', fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Col>
            </GridContainer>
            <GridContainer>
              <Col sm={12} md={4}>
                <InputControl
                  required
                  name={'name'}
                  label={ attrName('name') }
                  value={contact.get('name')}
                  inputComponent={Input}
                  onChange={ handleChange(token) }
                />
              </Col>
              <Col sm={12} md={4}>
                <InputControl
                  name="email"
                  label={ attrName('email') }
                  value={contact.get('email')}
                  type="email"
                  inputComponent={Input}
                  onChange={ handleChange(token) }
                />
              </Col>
              <Col sm={12} md={4}>
                <InputControl
                  name={'job_title'}
                  label={ attrName('job_title') }
                  value={contact.get('job_title')}
                  inputComponent={Input}
                  onChange={ handleChange(token) }
                />
              </Col>
            </GridContainer>
            <GridContainer>
              <Col sm={12} md={4}>
                <InputControl
                  name="comercial_phone"
                  label={ attrName('comercial_phone') }
                  value={contact.get('comercial_phone')}
                  type="number"
                  inputComponent={InputPhone}
                  onChange={ handleChange(token) }
                />
              </Col>
              <Col sm={12} md={4}>
                <InputControl
                  name="cell_phone"
                  label={ attrName('cell_phone') }
                  value={contact.get('cell_phone')}
                  type="number"
                  inputComponent={InputPhone}
                  onChange={ handleChange(token) }
                />
              </Col>
              <Col sm={12} md={4}>
                <InputControl
                  name="residential_phone"
                  label={ attrName('residential_phone') }
                  value={contact.get('residential_phone')}
                  type="number"
                  inputComponent={InputPhone}
                  onChange={ handleChange(token) }
                />
              </Col>
            </GridContainer>
          </CardContent>
        </Card>
      }) }

      <Button
        variant="contained"
        style={{ backgroundColor: '#00D1FF', marginTop: 15 }}
        onClick={onAddContact}
      >
        Adicionar Contato
      </Button>
    </>
  );
}

export default ContactsForm;