import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import useStylesHeader from './stylesHeader';

function Paper({
  Icon,
  title,
  children,
  actions,
  iconColor,
  loading,
  classes,
}) {
  const classesRoot = useStyles();
  const classesHeader = useStylesHeader();

  return (
    <Card
      classes={{
        root: clsx(classesRoot.root, classes.root),
      }}
    >
      { Icon &&
        <CardHeader
          classes={{
            root: clsx('no-printable', classesHeader.root, classesHeader[iconColor], classes.iconRootHeader),
            avatar: classesRoot.avatar
          }}
          avatar={
            loading ?
              <CircularProgress className={clsx(classesRoot.loading, classes.loading)} /> : (
                Icon ? <Icon className={clsx(classesRoot.icon, classes.iconHeader)} /> : undefined
              )
          }
        />
      }
      <CardContent>
        <div className={clsx(classesRoot.header, classes.header)}>
          <Typography variant="h5" className={clsx(classesRoot.title, classes.title)}>
            {title}
          </Typography>

          {actions}
        </div>

        {!loading && children}
      </CardContent>
    </Card>
  );
}

Paper.defaultProps = {
  iconColor: 'blue',
  classes: {},
  loading: false,
};

export const paperPropTypes = {
  Icon: PropTypes.any,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  iconColor: PropTypes.oneOf([
    'primary',
    'second',
    'success',
    'warning',
    'error',
    'blue',
    'orange',
    'green',
    'purple',
    'red',
  ]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    iconRootHeader: PropTypes.string,
    iconHeader: PropTypes.string,
    header: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.string,
  }),
  title: PropTypes.node,
  loading: PropTypes.bool,
};

Paper.propTypes = paperPropTypes;

export default Paper;
