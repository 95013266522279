import Request from '~/services/request';
import { fieldsApi } from '~/routes/api';
import queryStringParser from '~/utils/queryStringParser';

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ fieldsApi.show.build({id}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ fieldsApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( fieldsApi.destroy.build({id}), args );
};

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ fieldsApi.update.build({id}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ fieldsApi.create.build() }?${queryStringParser(args)}`, data );
};