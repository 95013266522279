import React, { useEffect, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useHotjar from 'react-use-hotjar';

import drawerMenuItems, {
  customerMenuItems,
  drawerMenuAvatar,
} from '~/routes/drawerMenu';

import DefaultOriginRouteComponent from '~/routes/DefaultOriginRoute';

import { httpStatusRoute } from '~/routes';
import NotFoundComponent from '~/pages/NotFound';

import { dashboardRoute } from '~/routes';
import DashboardComponent from '~/pages/Dashboard';

import { peopleRoute } from '~/routes';
import PeopleComponent from '~/pages/People';

import { metadataRoute } from '~/routes';
import MetadataComponent from '~/pages/Metadata';

import { fieldsRoute } from '~/routes';
import FieldsComponent from '~/pages/Fields';

import { customRecordsRoute } from '~/routes';
import CustomRecordsComponent from '~/pages/CustomRecords';

import { usersRoute } from '~/routes';
import UsersComponent from '~/pages/Users';

import { rolesRoute } from '~/routes';
import RolesComponent from '~/pages/Roles';

import ExamplesComponent from '~/pages/Examples';

import DrawerMenu from '~/components/DrawerMenu';
import ProfileMenu from '~/components/ProfileMenu';
import FormAnswer from '~/containers/FormAnswer';

import { PermissionsProvider } from './hooks/usePermissions';
import { MetadataProvider } from './hooks/useMetadata';
import { ProfileDrawerProvider } from './contexts/ProfileDrawerContext';
import { DrawerMenuProvider } from './contexts/DrawerMenuContext';
import { BreadcrumbsProvider } from './hooks/useBreadcrumbs';
import { GlobalFiltersProvider } from './hooks/useGlobalFilters';

import {
  segmentsRoute,
  productsRoute,
  invoicesRoute,
  suppliersRoute,
  buildersRoute,
  engineersRoute,
  contractorsRoute,
  accountPlansRoute,
  incomingEmailsRoute,
  npsFormsRoute,
  npsFormAnswersRoute,
  constructionsRoute,
  reportsRoute,
  customerInvoicesRoute,
} from '~/routes';
import SegmentsComponent from '~/pages/Segments';
import ProductsComponent from '~/pages/Products';
import InvoicesComponent from '~/pages/Invoices';
import SuppliersComponent from '~/pages/Suppliers';
import BuildersComponent from '~/pages/Builders';
import EngineersComponent from '~/pages/Engineers';
import ContractorsComponent from '~/pages/Contractors';
import AccountPlansComponent from '~/pages/AccountPlans';
import IncomingEmailsComponent from '~/pages/IncomingEmails';
import NpsFormsComponent from '~/pages/NpsForms';
import NpsFormAnswersComponent from '~/pages/NpsFormAnswers';
import ConstructionsComponent from '~/pages/Constructions';
import ExpenseHistoryReportComponent from '~/pages/ExpenseHistoryReport';
import AccountPlansReportComponent from '~/pages/AccountPlansReport';
import CustomerInvoicesComponent from '~/pages/CustomerPages/Invoices';
import ExpensesReportComponent from '~/pages/ExpensesReport';

const myCustomLogger = console.info;
const HOTJAR_SITE_ID = 2381705;
const HOTJAR_VERSION = 6;

function AppRoutes() {
  return (
    <Switch>
      <Route path={peopleRoute.list.path} component={PeopleComponent} />
      <Route
        path={customRecordsRoute.list.path}
        component={CustomRecordsComponent}
      />
      <Route path={rolesRoute.list.path} component={RolesComponent} />
      <Route path={usersRoute.list.path} component={UsersComponent} />

      <Route
        path={dashboardRoute.self.path}
        component={DashboardComponent}
        exact
      />

      {/* MASTER */}
      <Route path={metadataRoute.list.path} component={MetadataComponent} />
      <Route path={fieldsRoute.list.path} component={FieldsComponent} />
      <Route
        path={'/sys/components-example'}
        component={ExamplesComponent}
        exact
      />

      {/* Ilha */}
      <Route path={segmentsRoute.list.path} component={SegmentsComponent} />
      <Route path={productsRoute.list.path} component={ProductsComponent} />
      <Route path={invoicesRoute.list.path} component={InvoicesComponent} />
      <Route path={suppliersRoute.list.path} component={SuppliersComponent} />
      <Route path={buildersRoute.list.path} component={BuildersComponent} />
      <Route path={engineersRoute.list.path} component={EngineersComponent} />
      <Route
        path={contractorsRoute.list.path}
        component={ContractorsComponent}
      />
      <Route
        path={accountPlansRoute.list.path}
        component={AccountPlansComponent}
      />
      <Route
        path={incomingEmailsRoute.list.path}
        component={IncomingEmailsComponent}
      />
      <Route path={npsFormsRoute.list.path} component={NpsFormsComponent} />
      <Route
        path={npsFormAnswersRoute.list.path}
        component={NpsFormAnswersComponent}
      />
      <Route
        path={constructionsRoute.list.path}
        component={ConstructionsComponent}
      />
      <Route
        path={reportsRoute.expenses.path}
        component={ExpensesReportComponent}
      />
      <Route
        path={reportsRoute.expensesHistory.path}
        component={ExpenseHistoryReportComponent}
      />
      <Route
        path={reportsRoute.accountPlansTree.path}
        component={AccountPlansReportComponent}
      />
      <Route
        path={customerInvoicesRoute.self.path}
        component={CustomerInvoicesComponent}
      />

      <Route
        path={httpStatusRoute.notFound.path}
        component={NotFoundComponent}
      />
      <Route component={DefaultOriginRouteComponent} />
    </Switch>
  );
}

export default function PrivateRoutes() {
  const { access_type } = useSelector((state) => state.userProfile.data);
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(HOTJAR_SITE_ID, HOTJAR_VERSION, myCustomLogger);
  }, [initHotjar]);

  const menuItems = useMemo(() => {
    if (access_type === 'customer') {
      return customerMenuItems;
    }
    return drawerMenuItems;
  }, [access_type]);

  return (
    <DrawerMenuProvider>
      <BreadcrumbsProvider>
        <PermissionsProvider>
          <MetadataProvider>
            <ProfileDrawerProvider>
              <GlobalFiltersProvider>
                <DrawerMenu avatar={drawerMenuAvatar} items={menuItems} />
                <ProfileMenu />
                <AppRoutes />
                <FormAnswer />
              </GlobalFiltersProvider>
            </ProfileDrawerProvider>
          </MetadataProvider>
        </PermissionsProvider>
      </BreadcrumbsProvider>
    </DrawerMenuProvider>
  );
}
