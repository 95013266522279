// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputSelect     from '~/components/Input/InputSelect';

import CreatableSelect from 'react-select/creatable';

// material-ui
import Grid from '@material-ui/core/Grid';

const suggestions = [
  { label: 'Afghanistan' },
  { label: 'Aland Islands' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'American Samoa' },
  { label: 'Andorra' },
  { label: 'Angola' },
  { label: 'Anguilla' },
  { label: 'Antarctica' },
  { label: 'Antigua and Barbuda' },
  { label: 'Argentina' },
  { label: 'Armenia' },
  { label: 'Aruba' },
  { label: 'Australia' },
  { label: 'Austria' },
  { label: 'Azerbaijan' },
  { label: 'Bahamas' },
  { label: 'Bahrain' },
  { label: 'Bangladesh' },
  { label: 'Barbados' },
  { label: 'Belarus' },
  { label: 'Belgium' },
  { label: 'Belize' },
  { label: 'Benin' },
  { label: 'Bermuda' },
  { label: 'Bhutan' },
  { label: 'Bolivia, Plurinational State of' },
  { id: 3, label: 'Bonaire, Sint Eustatius and Saba asi  aisd ia dsia dia di daid aidaid adia ai daisdua idgad' },
  { label: 'Bosnia and Herzegovina' },
  { label: 'Botswana' },
  { label: 'Bouvet Island' },
  { label: 'Brazil', id: 1 },
  { label: 'British Indian Ocean Territory' },
  { label: 'Brunei Darussalam' },
].map(suggestion => ({
  id:   suggestion.id || suggestion.label,
  name: suggestion.label,
}));

class SelectExample extends Component {
	state = {
		inputSilge: null,
    inputCreatable: null,
		inputMulti: '1',
    inputMultiCreatable: null,
	}

	handleChangeKey = ( valKey ) => {
    this.setState(valKey);
	}

	handleChangeEvent = (attr) => ( valKey, event ) => {
		this.setState({[attr]: event.target.value});
	}

	render(){

    return <Grid container spacing={3}>
      <Grid item xs={6}>
        <InputControl
          required
          value={this.state.inputSilge}
          label={'Input Simples'}
          inputComponent={InputSelect}
          name={'inputSilge'}
          onChange={this.handleChangeKey}
          InputProps={{
            options: suggestions,
            isClearable: true
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <InputControl
          required
          value={this.state.inputCreatable}
          label={'Input Simples Creatable'}
          // inputComponent={Input}
          inputComponent={InputSelect}
          name={'inputCreatable'}
          onChange={this.handleChangeKey}
          InputProps={{
            options: suggestions,
            isClearable: true,
            selectComponent: CreatableSelect
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <InputControl
          id={'ID-CUSTOMIZADO-SELECT'}
          value={this.state.inputMulti}
          name={'inputMulti'}
          stateName={'inputMulti'}
          label={'Input Multi Simples'}
          inputComponent={InputSelect}
          onChange={this.handleChangeKey}
          InputProps={{
            options: suggestions,
            isMulti: true
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <InputControl
          value={this.state.inputMultiCreatable}
          name={'inputMultiCreatable'}
          stateName={'inputMultiCreatable'}
          label={'Input Multi Creatable'}
          inputComponent={InputSelect}
          onChange={this.handleChangeKey}
          InputProps={{
            options: suggestions,
            selectComponent: CreatableSelect,
            isMulti: true
          }}
        />
      </Grid>
    </Grid>
	}
}

export default SelectExample;