import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { CircularProgressbar } from 'react-circular-progressbar';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Typography from '@material-ui/core/Typography';
import StorageIcon from '@material-ui/icons/Storage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Request from '~/services/request';
import { fileUploadsApi } from '~/routes/api';
import ArchiveRecord from '~/records/ArchiveRecord';

import Tooltip from '~/components/Tooltip';
import IconButton from '~/components/IconButton';
import GalleryAttachments from '~/containers/GalleryAttachments';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 15
  },
  iconButton: {
    padding: 4,
  },
  divider: {
    height: 20,
    margin: 4,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  boxInput: {
    maxWidth: '50%',
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: '4px 0px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    }
  },
  fileName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    display: 'block'
  },
  boxFileName: {
    maxWidth: '100%',
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));

const isArchive = (v) => v instanceof ArchiveRecord;

function CustomizedInputBase({
  value,
  onChange,
  'state-name': stateName,
  id,
  options,
  name,
  disabled,
  isUploadingCallback
}) {
  const classes = useStyles();
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [galleryIsOpen, setGalleryIsOpen] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)

  const startLoading = useCallback(
    () => {
      setIsUploading( true )
      if( typeof isUploadingCallback === 'function' ){
        isUploadingCallback( true )
      }
    },
    [setIsUploading],
  )

  const stopLoading = useCallback(
    () => {
      setIsUploading( false )
      if( typeof isUploadingCallback === 'function' ){
        isUploadingCallback( false )
      }
    },
    [setIsUploading],
  );

  const handleChange = useCallback(
    (value) => {
      setUploadProgress(100)
      onChange({[stateName]: value})
    },
    [onChange],
  )

  const onSelectGalleryArchives = useCallback(
    (archives) => {
      onChange({[stateName]: archives.first()})
      setGalleryIsOpen(false)
    },
    [onChange, setGalleryIsOpen],
  )

  const handleUpload = useCallback(
    (dropedFiles) => {
      const archive = ArchiveRecord.buildFromFile(dropedFiles[0])

      const data = new FormData();

      data.append('file', archive.get('file'), archive.get('filename') );

      startLoading()

      Request.post(fileUploadsApi.upload.build(), data, {
        onUploadProgress: (e) => {
          const progress = (e.loaded * 100) / e.total;
          const formateProgress = parseInt(Math.round(progress));

          setUploadProgress( formateProgress )
        }
      }).then( response => {
        const data = response.data.archive;

        const newArchive =  archive.updateAttributes( data )
        handleChange(newArchive)
        stopLoading()

      } ).catch( err => {
        console.error(err)
        stopLoading()
      } )
    },
    []
  )

  const handleDelete = useCallback(
    async () => {
      setDeleteIsLoading(true)
      if( isArchive(value) ){
        try {
          if(value.isNew){
            await Request.del( fileUploadsApi.delete.build({ uuid: value.get('uuid') }) );
          }
        } catch(err) {
          console.error(err)
          alert('Não foi possível deletar o arquivo. Tente novamente mais tarde!');
        }
      }
      setDeleteIsLoading(false)
      onChange({[stateName]: null})
    },
    [setDeleteIsLoading, onChange, value]
  )


  const {getRootProps, getInputProps} = useDropzone({
    onDropAccepted: handleUpload,
    maxFiles: 1,
    ...options
  });

  return (
    <Box className={classes.root}>
      { isUploading &&
        <CircularProgressbar
          styles={{
            root: { width: 30 },
            path: { stroke: '#66BB6A' },
            text: {
              fill: '#666',
              fontSize: '30px',
              fontWeight: 'bold'
            },
          }}
          strokeWidth={10}
          value={uploadProgress}
          text={`${uploadProgress}%`}
          textSize='20px'
        />
      }

      { !isUploading && isArchive(value) &&
        <Avatar
          className={classes.avatar}
          variant="square"
          src={value.get('icon')}
          alt={ value.get('description') || '' }
          title={value.get('filename')}
        />
      }

      <Box {...getRootProps()} className={cx(classes.boxInput, {[classes.disabled]: disabled})} display="flex" alignItems="center" >
        <div className={classes.boxFileName}>
          <Tooltip title={`${isArchive(value) ? `${value.get('filename')} - ` : ''}Clique para anexar um arquivo`}>
            <Typography variant="caption" className={classes.fileName}>
              { isArchive(value) ? value.get('filename') : 'Clique aqui para anexar'}
            </Typography>
          </Tooltip>
        </div>

        <input
          id={id}
          type='file'
          {...getInputProps()}
          multiple={false}
          name={name}
          disabled={disabled}
        />
      </Box>

      <label htmlFor={id}>
        <IconButton
          component="span"
          color="grey"
          className={cx(classes.iconButton, {[classes.disabled]: disabled})}
          aria-label="upload file"
        >
          <Tooltip title="Anexar arquivo">
            <CloudUploadIcon />
          </Tooltip>
        </IconButton>
      </label>

      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        onClick={()=>setGalleryIsOpen(true)}
        disabled={disabled}
        color="primary"
        className={ cx(classes.iconButton, {[classes.disabled]: disabled}) }
        aria-label="directions"
      >
        <Tooltip title="Abrir galeria">
          <StorageIcon />
        </Tooltip>
      </IconButton>

      { isArchive(value) &&
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            onClick={handleDelete}
            color="red"
            className={cx(classes.iconButton, {[classes.disabled]: disabled})}
            aria-label="delete file"
            disabled={deleteIsLoading}
          >
            <Tooltip title="Remover arquivo">
              <DeleteForeverIcon />
            </Tooltip>
          </IconButton>
        </>
      }
       <GalleryAttachments
        isOpen={galleryIsOpen}
        onClose={ () => setGalleryIsOpen(false) }
        onSelect={onSelectGalleryArchives}
        allowMany={false}
      />
    </Box>
  );
}

CustomizedInputBase.propTypes = {
  onChange: PropTypes.func.isRequired
}


export default CustomizedInputBase