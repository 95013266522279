import jwt_decode from 'jwt-decode';
import { store } from '~/store';

const getMe = () => {
  const { token } = store.getState().auth;
  const me = jwt_decode(token).user;
  if(me)
    return me;
}

export default getMe;