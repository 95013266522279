import React from 'react';

import { Container, Content, Line, Circle, Description, Text } from './styles';

function Step({
  Icon,
  text = '',
  completed = false,
  showLeftLine = true,
  showRightLine = true,
  onClick
}) {
  return (
    <Container>
      <Content>
        <Line visible={showLeftLine} completed={completed} />
        <Circle completed={completed} onClick={onClick}>
          {Icon && <Icon style={{ color: '#FFF', fontSize: 25 }} />}
        </Circle>
        <Line visible={showRightLine} completed={completed} />
      </Content>

      <Description>
        <Text>{text}</Text>
      </Description>
    </Container>
  );
}

export default Step;
