import SchemaBuilder from './SchemaBuilder';

const columns = {
  product:     {type: 'object', default: null, title: 'Produto'},
  code:        {type: 'string', default: '',   title: 'Quantidade'},
  amount:      {type: 'float',  default: 0,    title: 'Quantidade'},
  unit_value:  {type: 'float',  default: 0,    title: 'Valor uniátrio'},
  total_value: {type: 'float',  default: 0,    title: 'Subtotal'},
  account_plan: { type: 'object',   title: 'Plano de conta'},
  created_at:  { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:  { type: 'datetime', default: '', title: 'Atualizado em'},
};

const invoiceItemSchema = new SchemaBuilder( columns );

export default invoiceItemSchema;