import React from 'react';

import { usePermissions } from '../../hooks/usePermissions';

function Permission({ keyCode, permission, children, onlyMaster, allowCustomer }) {
  const { can } = usePermissions();
  const hasPermission = can(keyCode, permission, onlyMaster, allowCustomer);

  if (!hasPermission) return <></>;

  return children;
}

Permission.defaultProps = {
  onlyMaster: false,
  allowCustomer: false,
}

export default Permission;
