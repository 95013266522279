import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import InputControl from '~/components/Input/InputControl';
import MuiInputSelect from '~/components/Input/InputSelect';

import { editFilter } from '../../../../store/actions';

function InputSelect({
  filter,
  column,
  indexGroup,
  indexFilter,
  inputProps,
}) {
  const dispatch = useDispatch();
  const options = useMemo(
    () => Object.keys(column.lookup).map(id => ({ id, name: column.lookup[id] })),
    [column.lookup]
  );

  return (
    <InputControl
      value={filter.get('val')}
      name={'val'}
      inputComponent={MuiInputSelect}
      InputProps={{
        placeholder: 'Valor',
        outValueType: 'string',
        options,
        ...inputProps,
      }}
      onChange={({ val }) => dispatch(editFilter(indexGroup, indexFilter, 'val', val))}
    />
  );
}

export default React.memo(InputSelect);
