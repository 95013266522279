import Request from '~/services/request';

export default function usersFetch(inputValue, page, doneCallback) {
  return Request.get(`/api/v1/utils/select_users?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: `${e.name} <${e.email}>`,
        }))
      }
    )
  })
  .catch(err => err);
}