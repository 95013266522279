import { store } from '~/store';
import isPresent from '~/utils/isPresent'
const UPLOAD_ROUTE = '/api/v1/file_uploads';

function config(fullScreenView, toggleFullscreen, customConfig) {
    const { auth } = store.getState();

  const defaultEditorConfig = {
    showCharsCounter: false,
    showWordsCounter: false,
    // showXPathInStatusbar: false,
    height: '250px',
    allowResizeY: true,
    globalFullsize: false,
    editorCssClass: 'tw-jodit-editor',
    placeholder: 'Diga alguma coisa...',
    buttons: [
      'undo', 'redo', 'bold', 'strikethrough', 'underline', 'italic', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', 'align', 'fontsize', 'paragraph', '|',
      'image', 'video', 'link', 'hr', 'eraser', '|',
      {
        name: 'customFullSize',
        icon: 'fullsize',
        exec: toggleFullscreen
      }, 'source',
    ],
    buttonsMD: [
      'undo', 'redo', 'bold', 'strikethrough', 'underline', 'italic', '|',
      'ul', 'ol',
      'outdent', 'indent', 'align', 'fontsize', 'paragraph',
      'image', 'link', 'hr', 'eraser',
      {
        name: 'customFullSize',
        icon: 'fullsize',
        exec: toggleFullscreen
      }, 'source',
    ],
    buttonsSM: [
      'undo', 'redo', 'bold', 'strikethrough', 'underline', 'italic', '|',
      'ul', 'ol',
      'outdent', 'indent', 'align',
      'image', 'link',
      {
        name: 'customFullSize',
        icon: 'fullsize',
        exec: toggleFullscreen
      },
    ],
    buttonsXS: [
      'undo', 'redo', 'bold', 'strikethrough', 'underline', 'italic', '|',
      'ul', 'ol', '|',
      'image', 'link',
      {
        name: 'customFullSize',
        icon: 'fullsize',
        exec: toggleFullscreen
      },
    ],
    uploader: {
      url: UPLOAD_ROUTE,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Authorization': `Bearer ${auth.token}`
      },
      process: function (resp) {
        const filterSuccess = (fl) => isPresent(fl.id);
        const filterError   = (fl) => !isPresent(fl.id);
        return {
          files:   resp.archives.filter(filterSuccess).map( (fl) => fl.url ) || [],
          path:    '',
          baseurl: '',
          error:   resp.archives.filter(filterError).map( (fl) => fl.msg ).flat(),
          msg:    []
        };
      },
      filesVariableName: function(e){ return `files[${e}]` },
      defaultHandlerSuccess: function (data) {
        let i;
        const field = 'files';
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            const jodit = this.s ? this.s : this.jodit.s;
            // Este timeout é necessário para que ai add várias imagens dê tempo de
            // atualizar todas no componente
            setTimeout(function(_jodit, file) {
              _jodit.insertImage(file);
            }, i*100, jodit, data[field][i]);
          }
        }
      },
      isSuccess: function (resp) {
        resp.files = resp.archives;
        return resp.archives.length > 0;
      },
      error: function (e) {
        this.jodit.events.fire('errorMessage', ['Arquivo não suportado.']);
        console.group('TW: Upload file error')
        console.error(e)
        console.groupEnd();
      }
    },
    // events: {
    //   getIcon: (name, control, clearName) => {
    //     let code = '';
    //     let classPrefix = 'fa fa-';

    //     switch (clearName) {
    //       case 'redo':
    //         code = 'rotate-right';
    //       break;
    //       case 'ul':
    //         code = 'list-ul';
    //       break;
    //       case 'ol':
    //         code = 'list-ol';
    //       break;
    //       case 'left':
    //         code = 'align-left';
    //       break;
    //       case 'right':
    //         code = 'align-right';
    //       break;
    //       case 'center':
    //         code = 'align-center';
    //       break;
    //       case 'justify':
    //         code = 'align-justify';
    //       break;
    //       case 'fontsize':
    //         code = 'text-height';
    //       break;
    //       case 'hr':
    //         code = 'minus';
    //       break;
    //       case 'customFullSize':
    //         classPrefix = '';
    //         code = 'clip-fullscreen';
    //       break;
    //       case 'source':
    //         code = 'code';
    //       break;
    //       default:
    //         code = name;
    //       break;

    //     }

    //     return `<i style="font-size:14px" class="${classPrefix}${code} fa-xs"></i>`;
    //   }
    // }

  };

  if( fullScreenView ){
    defaultEditorConfig.allowResizeX = false
    defaultEditorConfig.height = '94vh'
  }

  return {...defaultEditorConfig, ...customConfig};
}

export default config;