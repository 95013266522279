import React, {useRef} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import InputNumber from '~/components/Input/InputNumber/Example';
import InputCheckBox from '~/components/Input/InputCheckBox/Example';
import InputColorPicker from '~/components/Input/InputColorPicker/Example';
import InputCpfCnpj from '~/components/Input/InputCpfCnpj/Example';
import InputDate from '~/components/Input/InputDate/Example';
import InputPhone from '~/components/Input/InputPhone/Example';
import InputRegex from '~/components/Input/InputRegex/Example';
import InputSlider from '~/components/Input/InputSlider/Example';
import InputSelect from '~/components/Input/InputSelect/Example';
import InputTextEditor from '~/components/Input/InputTextEditor/Example';
import InputSelectAsync from '~/components/Input/InputSelectAsync/Example';
import InputFile from '~/components/Input/InputFile/Example';
import InputShow from '~/components/Input/InputShow';

export default function Examples() {
  const inputRef = useRef(null);

  return (
    <Card variant="outlined">
      <CardContent>
        <InputShow
          label="Nome"
          value="Jhon Cena"
        />
        <InputFile ref={inputRef} />
        <InputCheckBox />
        <InputNumber />
        <InputSlider />
        <InputColorPicker />
        <InputCpfCnpj />
        <InputDate />
        <InputPhone />
        <InputRegex />
        <InputSelect />
        <InputSelectAsync />
        <InputTextEditor />
      </CardContent>
    </Card>
  );
}
