import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createRecord, findRecord, clearState } from '~/store/modules/engineers';
import EngineerRecord from '~/records/EngineerRecord';
import { engineersRoute } from '~/routes'

import CrudNew from '~/containers/CrudNew';

import FormFields from './components/FormFields';

export default function New() {
  const { id } = useParams();
  const [record, setRecord] = useState(new EngineerRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.engineers);

  useEffect(() => {
    if(id){
      dispatch( findRecord(id) );
    }
    return () => {
      dispatch( clearState() )
    };
  }, [])

  useEffect(() => {
    if(current && id){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback(() => {
    const data = record.toParams();
    dispatch( createRecord(data) );
  }, [record]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const clearAfterSaved = useCallback(() => {
    setRecord( new EngineerRecord() )
  }, [setRecord]);

  return (
    <CrudNew
      clearAfterSaved={clearAfterSaved}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: engineersRoute.list.path, text: 'Engenheiros' },
        { text: 'Cadastrando engenheiro' },
      ]}
      footerActionsProps={{isLoading}}
    >
      <FormFields
        record={record}
        onChange={handleChange}
      />
    </CrudNew>
  );
}