import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/users';
import { usersRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';

import userAcessTypeEnum from '~/enums/userAcessTypeEnum';

function Show({ user, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: usersRoute.list.path, text: 'Usuários' },
        { text: 'Exibir Usuário' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   usersRoute.new.build(),
        routeCloneRegister: usersRoute.clone.build({ id }),
        routeEditRegister:  usersRoute.edit.build({ id }),
        permissionKeyCode:  'User'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col md={6}>
              <InputShow
                label="Nome"
                type="string"
                value={user?.name}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label="Email"
                value={user?.email}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col md={6}>
              <InputShow
                label="Tipo"
                type="string"
                value={ user ? userAcessTypeEnum().objects()[user.access_type] : ''}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.users.isLoading,
  user: state.users.current,
});

export default connect(mapStateToProps)(Show);