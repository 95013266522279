import { makeStyles } from '@material-ui/core/styles';

const FOOTER_HEIGHT = 60;

export default makeStyles(theme => ({
  root: {
    width: '75vw',
    minWidth: 400,
    padding: 20,
    marginBottom: FOOTER_HEIGHT,
    overflow: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconColor: {
    color: theme.palette.red.main,
  },
  content: {
    marginTop: 20,
    marginBottom: 20,
  },
  footer: {
    height: FOOTER_HEIGHT,
    boxShadow: theme.shadows[10],
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: 20,
    paddingTop: 10,
  },
}));
