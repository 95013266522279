const formatUserAccessType = (value) => {
  const access_object = {
    master: 'Gerência',
    admin: 'Administrador',
    user: 'Usuário',
    customer: 'Cliente'
  }
  
  return access_object[value] || 'Cliente';
}

export default formatUserAccessType;
