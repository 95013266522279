import React from 'react';

import BaseLayout from '~/components/BaseLayout';
import Errors from '~/components/Errors';
import CrudActions from '~/components/CrudActions';
import isPresent from '~/utils/isPresent';
import Spinner from '~/components/Spinner';
import CrudForm from '~/containers/CrudForm';

import { ErrorsContainer } from './styles';

export default function CrudEdit({
  breadcrumbs,
  crudActionsProps,
  errors,
  footerActionsProps,
  children,
  isLoading,
  ...formProps
}) {
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      { isPresent(crudActionsProps) && <CrudActions {...crudActionsProps} /> }
      <ErrorsContainer>
        <Errors {...errors} />
      </ErrorsContainer>
      <CrudForm {...formProps} footerActionsProps={footerActionsProps}>
        {children}
      </CrudForm>
      {isLoading && <Spinner fullScreen loadingText="Carregando.." /> }
    </BaseLayout>
  );
}
