import RecordBase from './Base';
import accountPlanSchema from './schema/accountPlanSchema';

export default class AccountPlanRecord extends RecordBase {
  static defaultAttributes = accountPlanSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return accountPlanSchema.attributeNames }

  static belongsTo = {
    parent: AccountPlanRecord
  };

  toParams() {
    const {
      id,
      name,
      code,
      parent
    } = this.toObject();

    return {
      data:{
        attributes:{
          id,
          name,
          code,
          parent_id: parent?.get('id') || null
        }
      }
    };
  }
}