import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useGlobalFilters } from '../../hooks/useGlobalFilters';

import { Button } from './styles';

function SelectedConstructionButton({ onClick, customStyle = {} }) {
  const { construction } = useGlobalFilters();

  return (
    <Button style={customStyle} onClick={onClick}>
      <span>{construction.name}</span>
      <ArrowDropDownIcon />
    </Button>
  )
}

export default SelectedConstructionButton;
