import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Request from '~/services/request';

import Spinner from '~/components/Spinner';

import StatusPercent from './StatusPercent';
import { Container } from './styles';

const colors = [
  '#05FF00',
  '#00FFFF',
  '#FA00E1',
  '#FF0000',
  '#FFF500',
  '#4200FF',
  '#4F3A8B',
  '#1B2430',
]

function Item({ item }) {
  return (
    <div className="item">
      <span>{item.account_plan_name}</span>
      <div className="status-percent">
        <StatusPercent
          color={colors[item.key] || colors.pop()}
          percentage={item.percentage || 0}
        />
      </div>
    </div>
  );
}

function PhysicalConstructionIndicators({ constructionID }) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const resp = await Request.get(`/api/v1/customers/dashboards/${constructionID}/physical_indicators`)
      setData(resp.data.data)
      setIsLoading(false)
    }
    fetchData()

  }, [constructionID])

  return (
    <Paper style={{padding: 16}}>
      <Typography variant="h6">Indicadores Físicos</Typography>
      <Container>
        { isLoading &&  <Spinner /> }
        {!isLoading && !data?.length && (
          <div className="no-item">
            <span>Ops, nenhuma informação encontrada</span>
          </div>
        )}

        { !isLoading && data?.map((item, key) => (
          <Item
            key={item.id}
            item={{
              key,
              account_plan_name: `${item.account_plan_code} - ${item.account_plan_name}`,
              percentage: item.percentage,
            }}
          />
        ))}
      </Container>
    </Paper>
  );
}

export default PhysicalConstructionIndicators;