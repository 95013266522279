import React, { useState, createContext, useCallback, useContext } from 'react';

const BreadcrumbsContext = createContext({
  breadcrumbs: [],
  setBreadcrumbs() {},
});

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const handleSetBreadcrumbs = useCallback(
    (newBreadcrumbs) => {
      setBreadcrumbs(newBreadcrumbs);
    },
    []
  );

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs: handleSetBreadcrumbs
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext);

  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider');
  }

  return context;
};

export default BreadcrumbsContext;
