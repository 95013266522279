import Fuse from 'fuse.js';

import isPresent from './isPresent';

function formatFuseData(data = []) {
  const newData = [];

  data.forEach(row => {
    const id = row.item.id;

    newData.push({
      id,
      ...row.item.attributes
    });
  })

  return newData;
}

export default function(columns, formatTreeColumnData, fuseOptions = {}) {
  const fuse = new Fuse(columns, { ...fuseOptions, threshold: 0 });
  const newColumns = [];

  columns.forEach(column => {
    const isTreeColumn = !column.attributes['parent_id'];

    if (isTreeColumn) {
      const childColumns = formatFuseData(fuse.search(column['id']));

      childColumns.forEach(childColumn => {
        const sonColumns = formatFuseData(fuse.search(childColumn['id']));

        if (isPresent(sonColumns)) {
          childColumn.items = sonColumns;
        }
      });

      const columnData = {
        id: column.id,
        ...column.attributes,
        items: childColumns
      };

      newColumns.push(
        formatTreeColumnData
          ? formatTreeColumnData(columnData)
          : columnData
      );
    }
  });

  return newColumns;
}
