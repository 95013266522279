import React  from 'react';
import Input  from '@material-ui/core/Input';

import CreatableSelect from 'react-select/creatable';

import isPresent from '~/utils/isPresent';
import toInt from '~/utils/Number/toInt';
import uniqArray from '~/utils/uniqArray';
import FieldRecord from '~/records/FieldRecord';
import fieldKindEnum from '~/enums/fieldKindEnum';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputSelect from '~/components/Input/InputSelect';
import InputNumber from '~/components/Input/InputNumber';
import InputCheckBox from '~/components/Input/InputCheckBox';
import InputSelectAsync     from '~/components/Input/InputSelectAsync';
import InputControl from '~/components/Input/InputControl';

import { fetchRecordTypeOptions } from '~/services/fetchRecordTypeOptions';

const attrName = (attr) => FieldRecord.hummanAttributeName(attr);

const KIND_WITH_OTHER_OPTION = ['multiple_choice', 'unique_choice']
const KIND_WITH_MAX_AND_MIN_VALUE = ['float', 'integer', 'range']
const KIND_WITH_EDITOR_VALUE = ['text']
const KIND_WITH_SIZE_VALUE = ['string','float']
const KIND_WITH_RECORD_TYPE = ['record']

const optionsSelectcomponents = {
  DropdownIndicator: null,
};

const createOption = (name) => ({
  id: name,
  name,
});

export default function FormFieldsOfField({record, handleChange }) {
  const [inputValue, SetInputValue] = React.useState('')

  const handleInputChange = (val) => {
    SetInputValue(val)
  }
  const handleKeyDownOptions = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        // eslint-disable-next-line
        let newOptions = [...(record.get('options') || []), createOption(inputValue)];
        newOptions = uniqArray( newOptions, (val) => val.id )
        handleChange({options: newOptions});
        SetInputValue('')
    }
  };

  return (
    <>
      <GridContainer spacing={1}>
        <Col xs={12} sm={6}>
          <InputControl
            required
            label={attrName('name')}
            value={record.get('name')}
            inputComponent={Input}
            name={'name'}
            onChange={handleChange}
          />
        </Col>
        <Col xs={12} sm={6}>
          <InputControl
            required
            disabled={ isPresent(record.get('id')) }
            label={attrName('kind')}
            value={record.get('kind')}
            inputComponent={InputSelect}
            name={'kind'}
            onChange={handleChange}
            InputProps={{
              options: fieldKindEnum().array,
              outValueType: 'string'
            }}
          />
        </Col>
      </GridContainer>

      <GridContainer spacing={1} justify="flex-end">
        { KIND_WITH_MAX_AND_MIN_VALUE.includes( record.get('kind') ) && <>
          <Col xs={12} sm={4}>
            <InputControl
              label={attrName('min_value')}
              value={record.get('min_value')}
              inputComponent={InputNumber}
              name={'min_value'}
              onChange={handleChange}
              InputProps={{precision: (record.get('kind') === 'float' ? toInt(record.get('size_value')) : 0)}}
            />
          </Col>
          <Col xs={12} sm={4}>
            <InputControl
              label={attrName('max_value')}
              value={record.get('max_value')}
              inputComponent={InputNumber}
              name={'max_value'}
              onChange={handleChange}
              InputProps={{precision: (record.get('kind') === 'float' ? toInt(record.get('size_value')) : 0)}}
            />
          </Col>
        </> }
        { KIND_WITH_EDITOR_VALUE.includes( record.get('kind') ) &&
          <Col xs={12} sm={4}>
            <InputControl
              label={attrName('apply_editor')}
              value={record.get('apply_editor')}
              inputComponent={InputCheckBox}
              name={'apply_editor'}
              onChange={handleChange}
            />
          </Col>
        }
        { KIND_WITH_OTHER_OPTION.includes( record.get('kind') ) &&
          <>
            <Col xs={12} sm={8}>
              <InputControl
                required
                label={attrName('options')}
                value={record.get('options')}
                inputComponent={InputSelect}
                name={'options'}
                onChange={handleChange}
                InputProps={{
                  isMulti: true,
                  selectComponent: CreatableSelect,
                  inputValue: inputValue,
                  components: optionsSelectcomponents,
                  menuIsOpen: false,
                  onKeyDown: handleKeyDownOptions,
                  onInputChange: handleInputChange
                }}
              />
            </Col>
            <Col xs={12} sm={4}>
              <InputControl
                required
                label={attrName('allow_other')}
                value={record.get('allow_other')}
                inputComponent={InputCheckBox}
                name={'allow_other'}
                onChange={handleChange}
              />
            </Col>
          </>
        }
        { KIND_WITH_SIZE_VALUE.includes( record.get('kind') ) &&
          <Col xs={12} sm={4}>
            <InputControl
              required
              label={record.sizeValueName}
              value={record.get('size_value')}
              inputComponent={InputNumber}
              name={'size_value'}
              onChange={handleChange}
              InputProps={{precision: 0}}
            />
          </Col>
        }
        { KIND_WITH_RECORD_TYPE.includes( record.get('kind') ) &&
          <Col xs={12} sm={4}>
            <InputControl
              required
              disabled={ isPresent(record.get('id')) }
              label={attrName('record_type')}
              value={record.get('record_type')}
              inputComponent={InputSelectAsync}
              name={'record_type'}
              onChange={handleChange}
              InputProps={{
                handleFetch: fetchRecordTypeOptions,
                outValueType: 'string',
                forceLoadOptions: true
              }}
            />
          </Col>
        }
        <Col xs={12}>
          <InputControl
            label={attrName('description')}
            value={record.get('description')}
            inputComponent={Input}
            name={'description'}
            onChange={handleChange}
            InputProps={{multiline: true}}
          />
        </Col>
      </GridContainer>
    </>
  );
}
