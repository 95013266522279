import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    margin: '25px 0',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '6px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    overflow: 'initial',
  },
  avatar: {
    margin: '0px'
  },
  icon: {
    paddingTop: '3px',
    paddingLeft: '4px',
    paddingRight: '5px',
    color: '#FFFFFF',
    width: '33px',
    height: '27px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    paddingBottom: 15,
    marginTop: 0,
    marginBottom: 3,
    color: '#1B3453',
    textDecoration: 'none',
  },
  loading: {
    color: theme.palette.primary.contrastText,
  },
}));
