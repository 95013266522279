import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    margin: '-20px 15px 0',
    borderRadius: 3,
    padding: 15,
    float: 'left',
    boxShadow: '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)'
  },
  avatar: {
    margin: '0px'
  },
  blue: {
    background: theme.palette.blue.main,
  },
  orange: {
    background: theme.palette.orange.main,
  },
  green: {
    background: theme.palette.green.main,
  },
  purple: {
    background: theme.palette.purple.main,
  },
  red: {
    background: theme.palette.red.main,
  },

  primary: { background: theme.palette.blue.main },
  second: { background: theme.palette.orange.main },
  success: { background: theme.palette.green.main },
  warning: { background: theme.palette.purple.main },
  error: { background: theme.palette.red.main },
}));
