import RecordBase from './Base';
import contactSchema from './schema/contactSchema';

export default class ContactRecord extends RecordBase {
  static defaultAttributes = contactSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return contactSchema.attributeNames }

  nestedParams() {
    return {
      id:                this.get('id') || undefined,
      name:              this.get('name'),
      email:             this.get('email'),
      comercial_phone:   this.get('comercial_phone'),
      cell_phone:        this.get('cell_phone'),
      residential_phone: this.get('residential_phone'),
      job_title:         this.get('job_title'),
      _destroy:          this.get('_destroy') || undefined
    };
  }
}