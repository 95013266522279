import React from 'react';

import InputShow from '~/components/Input/InputShow';

export default function CustomFieldInputShow({value, metaField}){
  const { field } = metaField;
  const props = {type: field.kind};

  switch (field.kind) {
    case 'record':
    case 'unique_choice':
      props.type = 'string';
      props.digObject = ['name'];
      break;
    case 'multiple_choice':
      props.type = 'string';
      if( Array.isArray(value) ){
        value = value.map( v => `[${v.name}]` ).join(' + ')
      }
      break;
    case 'time':
      props.type = 'string';
      break;
    case 'range':
      props.type = 'string';
      break;
    case 'text':
      props.multiline = true;
      props.rows = 3;
      props.isHtml = field.apply_editor
      break;
  }

  return (
    <InputShow
      label={field.name}
      value={value}
      {...props}
    />
  )
}