import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  memo,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef
} from 'react';
import {
  SortingState,
  PagingState,
  CustomPaging,
  DataTypeProvider,
  TreeDataState,
  CustomTreeData,
  IntegratedSelection,
  SelectionState,
  SummaryState,
  IntegratedSummary,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table as DXTable,
  TableHeaderRow,
  TableSelection,
  TableFixedColumns,
  TableColumnResizing,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
  DragDropProvider,
  PagingPanel,
  TableColumnReordering,
  TableTreeColumn,
  TableSummaryRow,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import ColumnVisibilityIcon from '@material-ui/icons/ViewColumnRounded';
import PropTypes from 'prop-types';
import IconButton from '~/components/IconButton';

import ConfirmationDialog from '../ConfirmationDialog';
import Filter from '../Filter';
import Loading from './Loading';
import NoDataRow from './NoDataRow';
import Actions from './Actions';

import DrawerMenuContext from '../../contexts/DrawerMenuContext';
import QueryParamsContext from '../../contexts/QueryParamsContext';
import FilterContext from '../../contexts/FilterContext';
import Route from '../../services/route';
import Request from '../../services/request';
import isPresent from '../../utils/isPresent';
import arrayDiff from '../../utils/arrayDiff';
import updateTreeRow from './utils/updateTreeRow';
import deleteTreeRow from './utils/deleteTreeRow';
import findRowsByIndex from './utils/findRowsByIndex';
import updateRowsBySelection from './utils/updateRowsBySelection';

import DateTimeTypeProvider from './components/DateTimeTypeProvider';
import DateTypeProvider from './components/DateTypeProvider';
import EnumTypeProvider from './components/EnumTypeProvider';
import NumberTypeProvider from './components/NumberTypeProvider';

import {
  fetchDataRequest as _fetchDataRequest,
  formatResponseColumns as _formatResponseColumns
} from './utils/tablePropsFunctions';

import { Container, Paper, ToolbarRootComponent } from './styles';

const actionColumnName = 'actions';
let firstLoading = true;

const GridDetailContainer = ({ row, tableDetails }) => {
  const [dataProviderKeys] = useState(useMemo(
    () => tableDetails.columnsOverride?.map(column => column.name) || [],
    [tableDetails.columnsOverride]
  ));

  function onFormatterComponent({ row, column }) {
    const columnOverride = tableDetails.columnsOverride.filter(
      colOver => colOver.name === column.name
    );
    const [{ formatterComponent }] = columnOverride;

    return formatterComponent(column.name, row);
  }

  return (
    <div>
      <Paper>
        <Grid
          rows={tableDetails.formatRowValue(row)}
          columns={tableDetails.columns}
        >
          <DataTypeProvider
            for={dataProviderKeys}
            formatterComponent={onFormatterComponent}
          />
          <DXTable
            columnExtensions={tableDetails.rowColumns}
            noDataRowComponent={({ children }) => (
              <NoDataRow loading={false} colSpan={children.length} />
            )}
          />
          <TableHeaderRow />
        </Grid>
      </Paper>
    </div>
  );
};

function Table({
  tableCellColumns,
  tableRowColumnsComponent,
  tableHeaderCellComponent,
  tableRowColumns,
  tableTree,
  tableDetails,
  onTableDataChange,
  columnsVisibility,
  columnsOverride,
  fixedColumns,
  showPaggination,
  tableSummary,
  showSorting,
  defaultPaging,
  actions,
  enableColumnsResizing,
  enableTableSelection,
  extraActions,
  route,
  filterProps,
  customSearchUrl,
  permissionKeyCode,
  fetchDataRequest,
  formatResponseColumns
}, tableRef) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pageSizes] = useState([5, 10, 20, 50, 100, 200]);
  const [tableColumnExtensions] = useState(tableRowColumns);
  const [columns] = useState(tableCellColumns);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [columnOrder, setColumnOrder] = useState(
    useMemo(() => columns.map(column => column.name), [columns])
  );
  const [dataProviderKeys] = useState(useMemo(
    () => columnsOverride.map(column => column.name).concat(actionColumnName),
    [columnsOverride]
  ));
  const [selectedRows, setSelectedRows] = useState([]);
  const { drawerMenuStorageState } = useContext(DrawerMenuContext);
  const { params, setParams, paramsAlreadyIncluded } = useContext(QueryParamsContext);
  const { filterOpen, setFilterOpen } = useContext(FilterContext);
  const requestSource = useMemo(() => Request.CancelToken.source(), []);
  const confirmationDialogRef = useRef(null);

  const getChildRows = useCallback(
    (row, rootRows) => {
      return !row ? rootRows : row.items;
    },
    []
  );

  useEffect(() => () => {
    firstLoading = true;
    requestSource.cancel('Table component unmounted');
  }, []);

  useEffect(
    () => {
      if (!params.finishedInitialLoading) return;

      if (firstLoading && isPresent(defaultPaging)) {
        firstLoading = false;

        if (!paramsAlreadyIncluded(defaultPaging)) {
          setParams(defaultPaging);
          return;
        }
      }

      searchRecords();
    },
    [
      params.page,
      params.per_page,
      params.sort,
      params.direction,
      params.filters,
      params.qs,
      params.finishedInitialLoading
    ]
  );

  useEffect(() => {
    onTableDataChange && onTableDataChange(rows);
  }, [rows]);



  async function searchRecords() {
    try {
      setLoading(true);

      const apiPath = customSearchUrl
        ? customSearchUrl(route)
        : route.getPath();

      const response = await fetchDataRequest(apiPath, requestSource);

      await formatResponseColumns( response, tableTree, setRows, setParams );

      setLoading(false);
    } catch (err) {
      console.log("ERRO = ", err)
      if (!Request.isCancel(err)) {
        setError('Não foi possível buscar os dados. Tente novamente mais tarde!');
      }
    }
  }

  function onSortingChange([{ columnName, direction }]) {
    setParams({
      sort: columnName,
      direction
    });
  }

  function changePageSize(size) {
    setParams({ per_page: size });
  }

  function onCurrentPageChange(newPage) {
    setParams({ page: ++newPage }, true);
  }

  async function onDelete({ id }) {
    const { current: dialog } = confirmationDialogRef;

    dialog.open();
    dialog.onConfirm(async () => {
      try {
        setLoading(true);
        await Request.del(route.getDeletePath(id), {
          cancelToken: requestSource.token
        });
        searchRecords();
      } catch(err) {
        if (!Request.isCancel(err)) {
          setError('Ocorreu um erro ao remover o registro. Tente novamente mais tarde!');
        }
      }
    });
  }

  function setError(message) {
    toast.error(message);
    setLoading(false);
  }

  function onFilter(compress, _json, size) {
    setFilterOpen(false);
    setParams({ filters: compress, size });
  }

  function onFormatterComponent({ row, column }) {
    if (column.name === actionColumnName) {
      return (
        <Actions
          actions={actions}
          permissionKeyCode={permissionKeyCode}
          extraActions={extraActions ? () => extraActions(row) : null}
          showPath={route.getShowPath(row.id)}
          editPath={route.getEditPath(row.id)}
          onDelete={() => onDelete(row)}
        />
      );
    }

    const columnOverride = columnsOverride.filter(
      colOver => colOver.name === column.name
    );

    try {
      const [{ formatterComponent }] = columnOverride;
      return formatterComponent(column.name, row);
    } catch(e) {
      console.error(e);
      return '>>ERROR<<'
    }

  }

  useImperativeHandle(tableRef, () => ({
    updateRows(newRow) {
      let updatedRows = updateTreeRow(newRow, rows);

      setRows(updatedRows);

      return updatedRows;
    },
    deleteRow(rowId) {
      setRows(oldRows => deleteTreeRow(rowId, oldRows));
    },
    selectedRows() {
      return selectedRows;
    },
    setRows(newRows) {
      setRows(newRows);
    },
    setSelectedRows(selectedRows) {
      setSelectedRows(selectedRows);
    },
    getRows() {
      return rows;
    },
    setLoading(loadingState) {
      setLoading(loadingState)
    },
    searchRecords() {
      searchRecords();
    }
  }), [rows, setRows, setSelectedRows, selectedRows, setLoading, searchRecords]);

  const dateColumns = useMemo(
    () => columns.filter(e => e.type === 'date').map(e => e.name),
    [columns]
  );

  const datetimeColumns = useMemo(
    () => columns.filter(e => e.type === 'datetime').map(e => e.name),
    [columns]
  );

  const enumColumns = useMemo(
    () => columns.filter(e => e.type === 'enum').map(e => e.name),
    [columns]
  );

  const numericColumns = useMemo(
    () => columns.filter(
      e => ['integer','float', 'numeric', 'currency'].includes(e.type)
    ).map(e => e.name),
    [columns]
  );

  return (
    <Container drawerOpen={drawerMenuStorageState}>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
        >
          {tableTree.enable && [
            <TreeDataState key="@dx-table-tds" />,
            <CustomTreeData
              key="@dx-table-ctd"
              getChildRows={getChildRows}
            />
          ]}

          { datetimeColumns.length > 0 &&  <DateTimeTypeProvider for={ datetimeColumns } /> }
          { dateColumns.length > 0 &&  <DateTypeProvider for={ dateColumns } /> }
          { enumColumns.length > 0 &&  <EnumTypeProvider for={ enumColumns } /> }
          { numericColumns.length > 0 &&  <NumberTypeProvider for={ numericColumns } /> }

          <DataTypeProvider
            for={dataProviderKeys}
            formatterComponent={onFormatterComponent}
          />

          <DragDropProvider />

          {showSorting && (
            <SortingState onSortingChange={onSortingChange} />
          )}

          {showPaggination && [
            <PagingState
              key="@dx-paging-ps"
              currentPage={params.page - 1}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={params.per_page}
              onPageSizeChange={changePageSize}
            />,
            <CustomPaging key="@dx-paging-cp" totalCount={params.total} />
          ]}

          {tableDetails.visible && (
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={setExpandedRowIds}
            />
          )}

          <SummaryState {...tableSummary.state} />
          <IntegratedSummary />

          <SelectionState
            selection={selectedRows}
            onSelectionChange={(selection) => {
              const newSelectedRows = [...selection];
              const selectionDiff = arrayDiff(selectedRows, selection);
              const isAdding = selection.length > selectedRows.length;

              selectionDiff.forEach(rowPos => {
                const rowsFound = findRowsByIndex(
                  rowPos,
                  rows,
                  newSelectedRows
                );

                rowsFound.forEach(rowFound => {
                  if (isAdding && !newSelectedRows.includes(rowFound)) {
                    newSelectedRows.push(rowFound);
                  } else if (!isAdding) {
                    const rowIndex = newSelectedRows.findIndex(
                      row => row === rowFound
                    );

                    if (rowIndex !== -1) {
                      delete newSelectedRows[rowIndex];
                    }
                  }
                });
              });

              setSelectedRows(newSelectedRows.filter(
                selected => Number.isInteger(selected)
              ));

              const updatedRows = updateRowsBySelection(rows, newSelectedRows);
              setRows(updatedRows);
              tableTree?.onSelectedRowsChange?.([...updatedRows]);
            }}
          />
          <IntegratedSelection />

          <DXTable
            columnExtensions={tableColumnExtensions}
            noDataRowComponent={({ children }) => (
              <NoDataRow loading={loading} colSpan={children.length} />
            )}
            cellComponent={
              tableRowColumnsComponent
                ? tableRowColumnsComponent
                : (props) => <DXTable.Cell {...props} />
            }
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
          />

          {enableColumnsResizing && tableColumnExtensions.length && (
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
          )}

          <TableHeaderRow
            showSortingControls={showSorting}
            cellComponent={(props) => {
              if (tableHeaderCellComponent) {
                return tableHeaderCellComponent(TableHeaderRow.Cell, props);
              }

              return <TableHeaderRow.Cell {...props} />;
            }}
          />

          {enableTableSelection && <TableSelection />}

          {tableTree.enable && (
            <TableTreeColumn
              for="name"
              showSelectionControls={tableTree.showSelectionControls}
              showSelectAll
            />
          )}

          {tableDetails.visible && (
            <TableRowDetail
              contentComponent={({ row }) => <GridDetailContainer row={row} tableDetails={tableDetails} />}
            />
          )}

          {tableSummary.visible && <TableSummaryRow {...tableSummary.summary} />}

          <TableFixedColumns
            leftColumns={fixedColumns.leftColumns}
            rightColumns={fixedColumns.rightColumns}
          />

          <Toolbar
            rootComponent={({ children }) => (
              <ToolbarRootComponent>
                {children}
                {/* Implement more components here */}
              </ToolbarRootComponent>
            )}
          />

          {columnsVisibility.visible && [
            <TableColumnVisibility
              key="@dx-table-tcv"
              defaultHiddenColumnNames={columnsVisibility.defaultHidden}
              columnExtensions={columnsVisibility.columnsExtensions}
            />,
            <ColumnChooser
              key="@dx-table-ch"
              toggleButtonComponent={({ buttonRef, onToggle}) => (
                <IconButton ref={buttonRef} onClick={onToggle}>
                  <Tooltip title="Selecionar Colunas">
                    <ColumnVisibilityIcon />
                  </Tooltip>
                </IconButton>
              )}
            />
          ]}

          {showPaggination && (
            <PagingPanel
              pageSizes={pageSizes}
              messages={{ rowsPerPage: 'Registros por página' }}
            />
          )}
        </Grid>
        {loading && <Loading />}
      </Paper>
      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Atenção"
        description="Deseja excluir este registro?"
      />
      { isPresent( filterProps?.columns ) &&
        <Filter
          {...filterProps}
          open={filterOpen}
          filter={params.filters}
          onCancel={() => setFilterOpen(false)}
          onFilter={onFilter}
        />
      }
    </Container>
  )
}

const TableComponent = forwardRef(Table);

TableComponent.defaultProps = {
  actions: [],
  permissionKeyCode: '',
  enableColumnsResizing: true,
  enableTableSelection: false,
  extraActions: null,
  tableTree: {
    enable: false,
    showSelectionControls: false,
  },
  tableCellColumns: [],
  tableRowColumns: [],
  columnsVisibility: {},
  columnsOverride: [],
  fixedColumns: {},
  tableSummary: {
    visible: false,
    state: {
      totalItems: [],
      groupItems: [],
      treeItems: []
    },
    summary: null,
  },
  tableDetails: {
    visible: false,
    columnsOverride: [],
  },
  showPaggination: true,
  showSorting: true,
  defaultPaging: {},
  filterProps: {
    columns: []
  },
  fetchDataRequest: _fetchDataRequest,
  formatResponseColumns: _formatResponseColumns
}

TableComponent.propTypes = {
  route: PropTypes.instanceOf(Route),
  permissionKeyCode: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.oneOf(['show', 'edit', 'delete'])),
  extraActions: PropTypes.func,
  enableColumnsResizing: PropTypes.bool,
  enableTableSelection: PropTypes.bool,
  tableHeaderCellComponent: PropTypes.func,
  tableTree: PropTypes.shape({
    enable: PropTypes.bool,
    showSelectionControls: PropTypes.bool,
    pathToColumnData: PropTypes.string,
    onSelectedRowsChange: PropTypes.func,
    formatTreeColumnData: PropTypes.func,
  }),
  tableSummary: PropTypes.shape({
    visible: PropTypes.bool,
    state: PropTypes.object,
    summary: PropTypes.object,
  }),
  tableDetails: PropTypes.shape({
    visible: PropTypes.bool,
    formatRowValue: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      getCellValue: PropTypes.func,
    })),
    rowColumns: PropTypes.arrayOf(PropTypes.shape({
      columnName: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      align: PropTypes.oneOf(['left', 'right', 'center']),
      wordWrapEnabled: PropTypes.bool,
    })),
    columnsOverride: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      formatterComponent: PropTypes.func
    })),
  }),
  tableCellColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    getCellValue: PropTypes.func,
  })),
  tableRowColumns: PropTypes.arrayOf(PropTypes.shape({
    columnName: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    align: PropTypes.oneOf(['left', 'right', 'center']),
    wordWrapEnabled: PropTypes.bool,
  })),
  tableRowColumnsComponent: PropTypes.func,
  onTableDataChange: PropTypes.func,
  columnsVisibility: PropTypes.shape({
    visible: PropTypes.bool,
    defaultHidden: PropTypes.array,
    columnsExtensions: PropTypes.arrayOf(PropTypes.shape({
      columnName: PropTypes.string,
      togglingEnabled: PropTypes.bool
    }))
  }),
  columnsOverride: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    formatterComponent: PropTypes.func
  })),
  fixedColumns: PropTypes.shape({
    leftColumns: PropTypes.array,
    rightColumns: PropTypes.array
  }),
  showPaggination: PropTypes.bool,
  showSorting: PropTypes.bool,
  defaultPaging: PropTypes.shape({
    page: PropTypes.number,
    per_page: PropTypes.number,
    sort: PropTypes.string,
    direction: PropTypes.string,
    filters: PropTypes.string,
    size: PropTypes.number,
    total: PropTypes.number
  }),
  // filterProps: PropTypes.shape({
  //   anchor: Filter.propTypes.shape,
  //   columns: Filter.propTypes.columns
  // }),
  customSearchUrl: PropTypes.func,
}

export default memo(TableComponent);