import React from 'react';
import { Switch } from 'react-router-dom';

import { reportsRoute } from '~/routes';
import RouteWrapper from '~/routes/RouteWrapper';
import AccountPlansReport from './AccountPlansReport';

export default function AccountPlansReportRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        allowCustomer
        path={reportsRoute.accountPlansTree.path}
        component={AccountPlansReport}
        permissionKeyCode="AccountPlanReport"
        permission="see"
      />
    </Switch>
  );
}
