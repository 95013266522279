import React, { useCallback } from 'react';

import { useBreadcrumbs } from '~/hooks/useBreadcrumbs';
import { Breadcrumbs, Typography, Link } from './styles';
import { Link as RouteLink } from 'react-router-dom';

function BreadcrumbsComponent() {
  const { breadcrumbs } = useBreadcrumbs();

  const handleClick = useCallback((event, breadcrumb) => {
    if (breadcrumb.onClick) {
      breadcrumb.onClick(event, breadcrumb);
      return;
    }
  }, []);

  if (!breadcrumbs?.length) return <></>;

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="/">
      {breadcrumbs.map((breadcrumb, idx) => {
        const isLastBreadcrumb = idx === (breadcrumbs.length - 1);

        if (isLastBreadcrumb) {
          return <Typography key={idx}>{breadcrumb.text}</Typography>;
        }

        return (
          <Link
            component={RouteLink}
            key={idx}
            to={breadcrumb.path}
            onClick={(e) => handleClick(e, breadcrumb)}
          >
            {breadcrumb.text}
          </Link>
        );
      })}
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent