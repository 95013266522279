import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { usersRoute } from '~/routes'
import { updateRecord, findRecord, clearState } from '~/store/modules/users';
import UserRecord from '~/records/UserRecord';
import CrudEdit from '~/containers/CrudEdit';

import FormFields from './components/FormFields';

export default function Edit() {
  const [record, setRecord] = useState(new UserRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.users);
  const { id } = useParams();

  useEffect(() => {
    dispatch( findRecord(id, {include: 'address-avatar'}) );
    return () => {
      dispatch( clearState() )
    };
  }, [])

  useEffect(() => {
    if(current){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback( () => {
    const data = record.toParams();
    dispatch( updateRecord(id, data) );
  }, [record, id]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  return (
    <CrudEdit
      isLoading={isLoading}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: usersRoute.list.path, text: 'Usuários' },
        { text: 'Editando Usuário' },
      ]}
      footerActionsProps={{isLoading, showSaveAndContinue: false}}
    >
      <FormFields
        record={record}
        onChange={handleChange}
      />
    </CrudEdit>
  );
}