import styled from 'styled-components';
import MUMenu from '@material-ui/core/Menu';
import MUListItemIcon from '@material-ui/core/ListItemIcon';
import MUMenuItem from '@material-ui/core/MenuItem';
import Button from '../Button';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 35px 0 20px;
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
`;

export const IconButton = styled(Button)`
  margin-right: 10px;
`;

export const OptionsButton = styled(Button)`
  margin-right: 10px;
  background-color: #FFF;
  color: #FF4081;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-right: 10px;

  &:hover {
    background-color: rgb(251, 251, 251);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const ListItemIcon = styled(MUListItemIcon)`
  min-width: 40px;
`;

export const MenuItem = styled(MUMenuItem)`
  &:focus {
    background-color: #FF4081;
    & .MuiListItemIcon-root, & .MuiListItemText-primary {
      color: #FFF;
    }
  }
`;

export const Menu = styled(MUMenu).attrs({
  elevation: 0,
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
})`
  & .MuiMenu-paper {
    border: 1px solid #DEDEDE;
  }
`;
