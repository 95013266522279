import React from 'react';
import { Switch } from 'react-router-dom';

import { reportsRoute } from '~/routes';
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';

export default function ExpensesReportRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.expenses.path}
        component={List}
        permissionKeyCode="ExpensesReport"
        permission="see"
      />
    </Switch>
  );
}
