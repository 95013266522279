function findConstructionAccountPlan(row, constructionAccountPlans) {
  let wantedRow = null;

  for (const accountPlan of constructionAccountPlans) {
    if (Number(row.id) === accountPlan.account_plan_id) {
      wantedRow = accountPlan;
      break;
    }
  }

  return wantedRow;
}

function formatAccountPlain(row, constructionAccountPlan) {
  return {
    ...row,
    selected: true,
    construction_account_plan_id: constructionAccountPlan.id,
    construction_id: constructionAccountPlan.construction_id,
    estimated_value: constructionAccountPlan.estimated_value,
    extra_value: constructionAccountPlan.extra_value,
  }
}

export default function updateSelectedRowsByConstructionAccountPlans(
  rows,
  constructionAccountPlans
) {
  let counterIndex = 0;
  const selectedRows = [];

  const updatedRows = rows.map(grandPaRow => {
    const selectedConstructionAccountPlan = findConstructionAccountPlan(
      grandPaRow,
      constructionAccountPlans
    );

    if (selectedConstructionAccountPlan) {
      grandPaRow =
        formatAccountPlain(grandPaRow, selectedConstructionAccountPlan);

      selectedRows.push(counterIndex);
    }

    counterIndex++;

    if (grandPaRow.items) {
      grandPaRow.items = grandPaRow.items.map(fatherRow => {
        const selectedConstructionAccountPlan = findConstructionAccountPlan(
          fatherRow,
          constructionAccountPlans
        );

        if (selectedConstructionAccountPlan) {
          fatherRow =
            formatAccountPlain(fatherRow, selectedConstructionAccountPlan);
    
          selectedRows.push(counterIndex);
        }
    
        counterIndex++;

        if (fatherRow.items) {
          fatherRow.items = fatherRow.items.map(sonRow => {
            const selectedConstructionAccountPlan = findConstructionAccountPlan(
              sonRow,
              constructionAccountPlans
            );

            if (selectedConstructionAccountPlan) {
              sonRow =
                formatAccountPlain(sonRow, selectedConstructionAccountPlan);
        
              selectedRows.push(counterIndex);
            }

            counterIndex++;

            return sonRow;
          });
        }

        return fatherRow;
      });
    }

    return grandPaRow;
  });

  return { updatedRows, selectedRows };
} 
