import React, {
  useMemo,
  useCallback,
  useEffect ,
  useState,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from "immutable";
import styled from 'styled-components';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import Typography from '@material-ui/core/Typography';

import Request from '~/services/request';
import { fileUploadsApi } from '~/routes/api';
import ArchiveRecord from '~/records/ArchiveRecord';

import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import GridContainer from '~/components/GridContainer'
import Spinner from '~/components/Spinner';

import ImageView from './components/ImageView';
import InputSearch from './components/InputSearch';
import DrawerEditFile from '~/containers/DrawerEditFile';

const Container = styled(GridContainer)`
  margin-top: 20px;
`;

function buildOrderedMap(data){
  return OrderedMap(
    data.map(e => [e.id, new ArchiveRecord({...e, fromGallery: true})])
  )
}

const GalleryAttachments = ({isOpen, onSelect, onClose, allowMany}) => {
  const [files, setFiles] = useState( OrderedMap() )
  const [page, setPage] = useState( 1 )
  const [isLoading, setIsLoading] = useState( true )
  const [editCurrent, setEditCurrent] = React.useState(null);
  const inputRef = useRef(null);

  const selectedFiles = useMemo(() => {
    return files.filter( (v) => v.get('_is_checked') )
  }, [files])

  const resetState = useMemo(() => {
    return () => {
      setFiles( OrderedMap() )
      setPage( 1 )
      setIsLoading( true )
    }
  }, [])

  useEffect(() => {
    if( isOpen ){
      Request.get(`${fileUploadsApi.list.build()}?page=${page}&per_page=30&adapter=json&direction=desc&sort=created_at`)
      .then(resp => {
        setFiles(
          files.merge(
            buildOrderedMap(resp.data.data)
          )
        )
        setIsLoading( false )
      })
    }
  }, [isOpen, setFiles, setIsLoading])

  const onPaginate = () => {
    const search = (inputRef.current && inputRef.current.value) || ''
    setIsLoading( true )
    Request.get(`${fileUploadsApi.list.build()}?qs=${search}&page=${page+1}&per_page=30&adapter=json&direction=desc&sort=created_at`)
      .then(resp => {
        setFiles(
          files.merge(
            buildOrderedMap(resp.data.data)
          )
        )
        setIsLoading( false )
        setPage( page+1 )
      })
  }

  const onSearch = useCallback(
    () => {
      const search = (inputRef.current && inputRef.current.value) || ''
      setPage(1)
      setIsLoading( true )
      Request.get(`${fileUploadsApi.list.build()}?qs=${search}&page=1&per_page=30&adapter=json&direction=desc&sort=created_at`)
      .then(resp => {
        setFiles(
          buildOrderedMap(resp.data.data)
        )

        setIsLoading( false )
      })
    },
    [setFiles, inputRef, setPage],
  )

  const onSubmit = useCallback(
    () => {
      onSelect( selectedFiles )

      resetState()
    },
    [files, setFiles],
  )

  const handleClose = useCallback(
    () => {
      onClose()
      resetState()
    },
    [onClose],
  )

  const onToggleCheck = useCallback(
    (archive) => {
      const newArchive = archive.set('_is_checked', !archive.get('_is_checked') )
      if( allowMany ){
        setFiles( files.set(archive.id, newArchive) )
      } else {

        let newFiles = files.set(archive.id, newArchive)
        let selected = selectedFiles.first();
        if(selected){
          selected = selected.set('_is_checked', false )
          newFiles = newFiles.set(selected.id, selected)
        }
        setFiles( newFiles )
      }
    },
    [files, setFiles, selectedFiles],
  )

  const onUpdate = useCallback(
    (changed) => {
      setFiles( files.set(changed.idOrToken, changed) )
      setEditCurrent( null )
    },
    [files, setFiles],
  )

  return (
    <Dialog
      icon={<AttachFileIcon />}
      maxWidth='lg'
      fullWidth
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Texto do título'}
      dialogActions={
        <React.Fragment>
          <Button onClick={handleClose} color="red" variant="text">
            Cancelar
          </Button>
          <Button disabled={selectedFiles.size === 0} onClick={onSubmit} color="primary" variant="text">
            Utilizar { selectedFiles.size > 0 && ` (${selectedFiles.size})` }
          </Button>
        </React.Fragment>
      }
    >
      <InputSearch inputRef={inputRef} onSearch={onSearch}/>
      <Container>
        { files.toArray().map( ([k, file]) =>
          <ImageView
            key={k}
            archive={file}
            toggleCheck={onToggleCheck}
            onEdit={ () => { setEditCurrent(file) } }
            allowMany={allowMany}
          />
        ) }
        { isLoading && <Spinner loadingText="Carregando.." /> }
        { files.size === 0 && !isLoading &&
          <Typography variant="h6" style={{width: '100%', margin: '100px 0px'}} gutterBottom display="block" align="center">
            Nenhum arquivo encontrado!
           </Typography>
        }
        { files.size > 1 &&
          <div style={{width: '100%', marginTop: 16, textAlign: 'center'}}>
            <Button disabled={isLoading} onClick={onPaginate} type="button">
              Carregar mais
            </Button>
          </div>
        }
        <DrawerEditFile
          isOpen={ !!editCurrent }
          archive={editCurrent}
          onChange={onUpdate}
          onClose={ () => setEditCurrent(null) }
        />
      </Container>
    </Dialog>
  )
}

GalleryAttachments.propTypes = {
  isOpen:   PropTypes.bool.isRequired,
  allowMany: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose:  PropTypes.func.isRequired,
}

GalleryAttachments.defaultProps = {
  allowMany: true
}

export default GalleryAttachments