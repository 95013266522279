import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { addGroup } from '../../../../store/actions';
import useStyles from './styles';

function AddGroup() {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <div className={classes.line} />

      <IconButton
        onClick={() => dispatch(addGroup())}
        classes={{
          root: classes.button,
        }}
      >
        <AddCircleIcon className={classes.icon} />
      </IconButton>
    </div>
  );
}

export default React.memo(AddGroup);
