import React, { memo } from 'react';

import { Tr, Row, Text } from './styles';

function NoDataRow({ loading, colSpan }) {
  return (
    <Tr>
      <td colSpan={colSpan}>
        {!loading && (
          <Row>
            <Text>Nenhuma informação encontrada</Text>
          </Row>
        )}
      </td>
    </Tr>
  )
}

export default memo(NoDataRow);
