import React, {useCallback, memo} from 'react';

import Typography from '@material-ui/core/Typography';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import Button from '~/components/Button';

import BankSlipItem from './components/BankSlipItem'

function BankSlips({ record, onChange}) {
  const handleChange = useCallback(
    (newBankSlip) => {
      const bank_slips = record.get('bank_slips').setIn(
        [newBankSlip.idOrToken],
        newBankSlip
      );
      onChange( {bank_slips} );
    },
    [record, onChange]
  );

  const handleDelete = useCallback(
    (bankSlip) => {
      let bank_slips = record.get('bank_slips')
      if(bank_slips.size > 0){
        if( bankSlip.isPersisted() ){
          bank_slips = bank_slips.set(
            bankSlip.idOrToken,
            bankSlip.set('_destroy', true)
          );
        }else{
          bank_slips = bank_slips.delete(bankSlip.idOrToken);
        }

        onChange( {bank_slips} );
      }
    },
    [record, onChange]
  );

  const handleAddBankSlip = useCallback(
    () => {
      const newRecord = record.addBankSlip();
      onChange( {bank_slips: newRecord.get('bank_slips')} );
    },
    [record, onChange]
  );

  return (
    <GridContainer>
      <Col xs={12}>
        <Typography variant="h6">Boletos</Typography>
      </Col>
      <Col xs={12}>
        <GridContainer direction="row" alignItems="flex-start">
          { record.get('bank_slips').filter( r => !r.get('_destroy') ).sortBy( e => e.get('due_date')).valueSeq().toArray().map( (bankSlip) => (
            <BankSlipItem
              key={bankSlip.idOrToken}
              bankSlip={bankSlip}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          )) }
          <Col xs={12} sm={6} md={4} style={{textAlign: 'right'}}>
            <GridContainer direction="row" justify="center" alignItems="center">
              <Col xs={12}style={{textAlign: 'center'}}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#00D1FF', marginTop: 15 }}
                  onClick={handleAddBankSlip}
                >
                  + Boleto
                </Button>
              </Col>
            </GridContainer>
          </Col>
        </GridContainer>
      </Col>
    </GridContainer>
  );
}

export default memo(BankSlips);
