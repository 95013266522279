'use strict'

/* USAGE
  array1 = [1,2,3,4,2,3]
  uniq(array1)
  => [1,2,3,4]

  array2 = [{id: 1},{id: 2},{id: 1},{id: 2}]
  uniq(array2, (v) => v.id )
  => [{id: 1},{id: 2}]
*/

const uniq = (array, fnValue=null) => {
  if( typeof fnValue === 'function' ){
    let indexs = []
    const values = array.map(fnValue);

    values.filter((item, pos) => {
      const isTrue = values.indexOf(item) === pos;
      if(isTrue){
        indexs = [...indexs, pos]
      }
      return isTrue;
    })

    return array.filter( (item, pos) => indexs.includes(pos) )
  }
  return array.filter((item, pos) => array.indexOf(item) === pos);
};
module.exports = uniq;
