import styled, { css } from 'styled-components';

const Tab = styled.div`
  border-bottom-color: #4F3A8B;
  border-bottom-style: solid;
  background-color: #FFF;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 0 0 10px 0;
  margin-left: 20px;
  max-width: 100%;

  ${props => !props.isSelected && css`
    border: none;
  `}

  button {
    padding: 15px 30px 15px 30px;
    text-transform: none;
    font-size: 1.1rem;
    width: 100%;
  }

  button:hover {
    background-color: #fff;
  }

  button:focus {
    background-color: #fff;
  }
`;

export default Tab;
