import React from 'react';
import styled from 'styled-components';
import MDListItem from "@material-ui/core/ListItem";

// eslint-disable-next-line
export const ListItem = styled(({ isCollapsedItem, ...otherProps }) => <MDListItem {...otherProps} />)`
  padding-left: ${props => props.isCollapsedItem ? '50px' : '25px'};

  &:hover {
    background-color: #10151C;
  }

  &.Mui-selected {
    background-color: #10151C;
  }

  &.MuiListItem-root:hover {
    background-color: #10151C;
  }
`;
