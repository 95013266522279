// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputCheckBox   from '~/components/Input/InputCheckBox';

// material-ui
import Grid from '@material-ui/core/Grid';

class InputExample extends Component {
	state = {
		input1: true,
		input2: false,
	}

	handleChangeKey = ( valKey ) => {
		this.setState(valKey);
	};

	render(){

		return(
			<div>
				<Grid container spacing={4}>
					<Grid item xs={4}>
						<InputControl
							value={this.state.input1}
							name={'input1'}
							label={'Valor 1'}
							inputComponent={InputCheckBox}
							onChange={this.handleChangeKey}
						/>
					</Grid>
					<Grid item xs={4}>
						<InputControl
							value={this.state.input2}
							name={'input2'}
							label={'Valor 2'}
							inputComponent={InputCheckBox}
							onChange={this.handleChangeKey}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default InputExample;