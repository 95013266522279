import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import constructionTypeEnum from '~/enums/constructionTypeEnum'
import constructionStatusEnum from '~/enums/constructionStatusEnum'

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import ConstructionRecord from '~/records/ConstructionRecord';
import AttachmentsPreview from '~/components/AttachmentsPreview';
import AddressShow from '~/components/AddressShow';

const attrName = (attr) => ConstructionRecord.hummanAttributeName(attr)

export default function InformationsTab ({construction}) {
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col md={5}>
            <InputShow
              label={attrName('customer')}
              type="string"
              value={construction?.customer?.name}
            />
          </Col>
          <Col md={4}>
            <InputShow
              label={attrName('name')}
              type="string"
              value={construction?.name}
            />
          </Col>
          <Col md={3}>
            <InputShow
              label={attrName('cpf_cnpj')}
              type="cpf_cnpj"
              value={construction?.cpf_cnpj}
            />
          </Col>
          <Col md={4}>
            <InputShow
              label={attrName('adm_percent')}
              type="float"
              value={construction?.adm_percent}
            />
          </Col>
          <Col md={4}>
            <InputShow
              label={attrName('status')}
              type="string"
              value={constructionStatusEnum().objects()[construction?.status]}
            />
          </Col>
          <Col md={4}>
            <InputShow
              label={attrName('construction_type')}
              type="string"
              value={constructionTypeEnum().objects()[construction?.construction_type]}
            />
          </Col>
          <Col md={4}>
            <InputShow
              label={attrName('engineer')}
              type="string"
              value={construction?.engineer?.name}
            />
          </Col>
          <Col md={4}>
            <InputShow
              label={attrName('builder')}
              type="string"
              value={construction?.builder?.name}
            />
          </Col>

          <Col md={4}>
            <InputShow
              label={attrName('contractor')}
              type="string"
              value={construction?.contractor?.name}
            />
          </Col>
          <Col sm={6} lg={3}>
            <InputShow
              label={attrName('start_date')}
              type="date"
              value={construction?.start_date}
            />
          </Col>
          <Col sm={6} lg={3}>
            <InputShow
              label={attrName('end_date')}
              type="date"
              value={construction?.end_date}
            />
          </Col>
          <Col sm={6} lg={3}>
            <InputShow
              label={attrName('latitude')}
              type="string"
              value={construction?.latitude}
            />
          </Col>

          <Col sm={6} lg={3}>
            <InputShow
              label={attrName('longitude')}
              type="string"
              value={construction?.longitude}
            />
          </Col>
        </GridContainer>

        <br/>
        <Typography variant="h6">Endereço</Typography>
        { !!construction?.address && <AddressShow address={construction.address} /> }

        <br/>
        <AttachmentsPreview
          title="Anexos"
          attachments={construction?.attachments}
        />
      </CardContent>
    </Card>
  );
}