import React from 'react';
import { useDispatch } from 'react-redux';

import InputControl from '~/components/Input/InputControl';
import Input from '@material-ui/core/Input';

import { editFilter } from '../../../../store/actions';

function InputString(props) {
  const {
    filter,
    indexGroup,
    indexFilter,
    inputProps,
    ...ownProps
  } = props;
  const dispatch = useDispatch();

  return (
    <InputControl
      value={filter.get('val')}
      name={'val'}
      inputComponent={Input}
      InputProps={{
        placeholder: 'Valor',
        ...inputProps,
      }}
      onChange={({ val }) => dispatch(editFilter(indexGroup, indexFilter, 'val', val))}
      {...ownProps}
    />
  );
}

export default React.memo(InputString);
