import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  line: {
    position: 'absolute',
    backgroundColor: theme.palette.grey[200],
    top: 0,
    height: 11,
    width: 5,
  },
  button: {
    position: 'relative',
    marginTop: 20,
    backgroundColor: theme.palette.grey[200],
  },
  icon: {
    color: theme.palette.green.main,
  },
}));
