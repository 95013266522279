// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { useState } from "react";

import InputControl from "~/components/Input/InputControl";
import InputDate from "~/components/Input/InputDate";

// material-ui
import Grid from "@material-ui/core/Grid";

const InputExample = () => {
  const [state, setState] = useState({
    input1: "",
    input2: ""
  });

  const handleChangeKey = (valKey) => {
    setState({ ...state, ...valKey });
  };

  // const handleChangeEvent = attr => (valKey, event) => {
  //   setState({ [attr]: event.target.value });
  // };
  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <InputControl
            id={"ID-CUSTOMIZADO-DATA"}
            value={state.input1}
            name="input1"
            label={"Data 1"}
            inputComponent={InputDate}
            onChange={handleChangeKey}
            InputProps={{
              type: "date",
              minDate: "2020-01-01"
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputControl
            id={"ID-CUSTOMIZADO-DATA2"}
            value={state.input2}
            name="input2"
            label={"Data 2"}
            inputComponent={InputDate}
            onChange={handleChangeKey}
            InputProps={{
              type: "datetime",
              minDate: "2020-01-01"
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InputExample;
