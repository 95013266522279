import RecordBase from './Base';
import addressSchema from './schema/addressSchema';

export default class AddressRecord extends RecordBase {
  static defaultAttributes = addressSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    return addressSchema.attributeNames;
  }

  toParams(withAPIStructure = true) {
    const {
      id,
      name,
      city,
      district,
      street,
      building_number,
      complement,
      zip_code
    } = this.toObject();

    const address = {
      id,
      name,
      city_id: city?.id || null,
      district,
      street,
      building_number,
      complement,
      zip_code
    }

    if (!withAPIStructure) return address;

    return { data: { attributes: address } };
  }

  nestedParams() {
    return {
      id:              this.get('id') || undefined,
      name:            this.get('name'),
      city_id:         this.get('city')?.id,
      district:        this.get('district'),
      street:          this.get('street'),
      building_number: this.get('building_number'),
      complement:      this.get('complement'),
      zip_code:        this.get('zip_code'),
      _destroy:        this.get('_destroy') || undefined
    };
  }

  attrName(attr) {
    const [attrName] = attr.split('.');

    return AddressRecord.hummanAttributeName(attrName);
  }
}
