import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import BuilderRecord from '~/records/BuilderRecord';

const attrName = (attr) => BuilderRecord.hummanAttributeName(attr);

export default function FormFields({record, onChange}) {
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col xs={12}>
            <InputControl
              required
              autoFocus
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
        </GridContainer>
      </CardContent>
    </Card>
  );
}