import { Record, List, Map } from 'immutable';
import * as types from './types';

import uuid from '../../../utils/createUUID';

class FilterRecord extends Record({
  token: null,
  col: '',
  ope: '',
  val: '',
}) {
  constructor(props={}) {
    super({ ...props, token: props.token || uuid()});
  }
}

class GroupRecord extends Record({
  token: null,
  condition: 'AND',
  filters: List([
    new FilterRecord(),
  ]),
}) {
  constructor(props={}) {
    super({ ...props, token: props.token || uuid()});
  }
}

export default (state={}, action) => {
  switch (action.type) {
    case types.INIT:
      return (() => {
        const filter = { ...action.payload.filter };
        const columns = [ ...action.payload.columns ];

        filter.groups = List(
            filter.groups.map(group => new GroupRecord({
            ...group,
            filters: List(
              group.filters.map(filter => new FilterRecord({
                ...filter,
                col: filter.col,
              }))
            ),
          }))
        );

        return Map({
          ...filter,
          columns,
        });
      })();
    case types.SET_CONDITION:
      return state.set('condition', action.payload.condition);
    case types.ADD_GROUP:
      return state.updateIn(
        ['groups'],
        groups => groups.push(new GroupRecord())
      );
    case types.DEL_GROUP:
      return state.deleteIn(['groups', action.payload.indexGroup]);
    case types.SET_GROUP_CONDITION:
      return state.setIn(
        ['groups', action.payload.indexGroup, 'condition'],
        action.payload.condition
      );
    case types.ADD_FILTER:
      return state.updateIn(
        ['groups', action.payload.indexGroup, 'filters'],
        filters => filters.push(new FilterRecord())
      );
    case types.DEL_FILTER:
      state = state.deleteIn(
        ['groups', action.payload.indexGroup, 'filters', action.payload.indexFilter]
      );

      if (state.getIn(['groups', action.payload.indexGroup, 'filters']).size === 0)
        state = state.deleteIn(['groups', action.payload.indexGroup]);

      return state;
    case types.EDIT_FILTER:
      return (() => {
        state = state.setIn(
          ['groups', action.payload.indexGroup, 'filters', action.payload.indexFilter, action.payload.key],
          action.payload.value,
        );

        if (action.payload.key === 'col')
          state = state.setIn(
            ['groups', action.payload.indexGroup, 'filters', action.payload.indexFilter, 'val'],
            '',
          );

        return state;
      })();
    default:
      return state;
  }
};
