import Request from '~/services/request';
import { customRecordsApi } from '~/routes/api';
import queryStringParser from '~/utils/queryStringParser';

export const findRecordRequest = (id, {sys_key, ...args}) => {
  return Request.get( `${ customRecordsApi.show.build({id, sys_key}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = ({sys_key, ...args}, requestSource) => {
  return Request.get( `${ customRecordsApi.list.build({sys_key}) }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, {sys_key, ...args}) => {
  return Request.del( customRecordsApi.destroy.build({id, sys_key}), args );
};

export const updateRecordRequest = (id, data={}, {sys_key, ...args} ) => {
  return Request.put( `${ customRecordsApi.update.build({id, sys_key}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, {sys_key, ...args}) => {
  return Request.post( `${ customRecordsApi.create.build({sys_key}) }?${queryStringParser(args)}`, data );
};