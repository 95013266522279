import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const EnumFormatter = ({ value, column }) => {
  return value ? column._enum_().getName(value) : ''
}
const EnumTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={EnumFormatter}
    {...props}
  />
);

export default EnumTypeProvider;