import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Request from '~/services/request';
import formatToMoney from '~/utils/formatToMoney';
import toFloat from '~/utils/Number/toFloat';
import round from '~/utils/Number/round';

import Spinner from '~/components/Spinner';

import StatusPercent from './StatusPercent';
import { Container } from './styles';

const colors = [
  '#05FF00',
  '#00FFFF',
  '#FA00E1',
  '#FF0000',
  '#FFF500',
  '#4200FF',
  '#4F3A8B',
  '#1B2430',
];

function getRandomColor() {
  const random = Math.floor(Math.random() * colors.length);
  return colors[random];
}

function Item({ item, showTitle }) {
  const total = toFloat(item.total);
  const accumulative =
    toFloat(item.estimated_value) + toFloat(item.extra_value);
  const percentage =
    accumulative > 0 ? round((total / accumulative) * 100, 1) : 100;

  return (
    <div className="item">
      <div className="header">
        <span className="name">{item.name}</span>
        {!!showTitle && (
          <>
            <span className="value">Planejado</span>
            <span className="aditional">Adicional</span>
            <span className="final_value">Valor Final</span>
          </>
        )}
      </div>
      <div className="content">
        <div className="name">
          <StatusPercent
            color={colors[item.key] || getRandomColor()}
            percentage={percentage}
            total={total}
          />
        </div>
        <span className="value">R$ {formatToMoney(item.estimated_value)}</span>
        <span className="aditional">R$ {formatToMoney(item.extra_value)}</span>
        <span className="final_value">R$ {formatToMoney(accumulative)}</span>
      </div>
    </div>
  );
}

function FinancialConstructionIndicators({ constructionID }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await Request.get(
        `/api/v1/customers/dashboards/${constructionID}/finance_progress`,
      );
      setData(resp.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, [constructionID]);

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h6">Indicadores Financeiros</Typography>
      <Container>
        {isLoading && <Spinner />}
        {!isLoading && !data?.length && (
          <div className="no-item">
            <span>Ops, nenhuma informação encontrada</span>
          </div>
        )}

        {!isLoading &&
          data?.map((item, key) => (
            <Item key={item.id} showTitle={key === 0} item={{ key, ...item }} />
          ))}
      </Container>
    </Paper>
  );
}

export default FinancialConstructionIndicators;
