import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import isPresent from '~/utils/isPresent';
import { fieldsRoute } from '~/routes'
import { fetchRecords, destroyRecord, clearState, setCurrentRecord, findRecord } from '~/store/modules/fields';
import { dialogFormToggle } from '~/store/modules/crud';
import fieldSchema from '~/records/schema/fieldSchema';
import FieldRecord from '~/records/FieldRecord';

import CrudLayoutList from '~/containers/CrudLayoutList';
import Form from './Form';

function List({ isLoading, location, history, rows, total, dispatch }) {
  const { id } = useParams();
  const isRouteEdit = useMemo(() => {
    return isPresent(id)
      ? location.pathname === fieldsRoute.edit.build({ id })
      : false;
  }, [location.pathname, id])
  const isRouteNew = useMemo(() => location.pathname === fieldsRoute.new.path , [location.pathname])

  useEffect(() => {
    return () => dispatch( clearState() );
  }, []);

  useEffect(
    () => {
      if (isRouteEdit){
        dispatch( setCurrentRecord(null) )
        dispatch( findRecord( id ) )
        dispatch( dialogFormToggle(true) )
      }
      if (isRouteNew){
        dispatch( setCurrentRecord( FieldRecord.defaultAttributes ) )
        dispatch( dialogFormToggle(true) )
      }
    },
    [isRouteEdit, isRouteNew]
  );

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch(
        fetchRecords(
          params,
          requestSource
        )
      )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  const onEdit = useCallback(
    (row, ev) => {
      ev.preventDefault()
      dispatch( setCurrentRecord( row ) )
      dispatch( dialogFormToggle(true) )
      dispatch( findRecord( row.id ) )
    },
    []
  )

  const onNew = useCallback(
    (ev) => {
      ev.preventDefault()
      dispatch( setCurrentRecord( FieldRecord.defaultAttributes ) )
      dispatch( dialogFormToggle(true) )
    },
    []
  )

  return (
    <>
      <CrudLayoutList
        breadcrumbs={[{ text: 'Campos personalizados' }]}
        permissionKeyCode="Field"
        routes={{
          newPath:  ()  =>  fieldsRoute.new.build(),
          editPath: (id) => fieldsRoute.edit.build({id})
        }}
        navProps={{onNew}}
        tableProps={{
          onEdit: onEdit,
          isLoading,
          rows,
          total,
          handleFetch,
          onDelete,
          schema: fieldSchema,
          actions: ['edit', 'delete'],
          columnsOverride: [
            {
              name: 'id',
              formatterComponent: function IdCol(_, row){
                return <div>{String(row.id).padStart(6, 0)}</div>
              }
            },
          ],
          filterProps: {
            columns: fieldSchema.filterColumns
          },
          defaultPaging: {
            direction: 'desc',
            sort: 'id',
            per_page: 10
          },
        }}
      />
      <Form handleFetch={handleFetch} location={location} history={history}/>
    </>
  );
}

const mapStateToProps = state => ({
  isLoading: state.fields.listIsLoading,
  rows: state.fields.data,
  total: state.fields.totalData,
});

export default connect(mapStateToProps)(List);