import arrayToObject from '~/utils/arrayToObject';
const RIGHT_COLUM_TYPES = ['numeric', 'integer', 'float', 'currency'];
const CENTER_COLUM_TYPES = ['date', 'datetime', 'boolean'];
const convertToArray = (cols) => Object.keys(cols).map( col => ({name: col, ...cols[col]}) )

function getColumnExtensionsProps(col){
  const opts = {}
  if(RIGHT_COLUM_TYPES.includes(col.type)){
    col.align = 'right'
  }
  if(CENTER_COLUM_TYPES.includes(col.type)){
    col.align = 'center'
  }
  return opts;
}

export const mapDefaultAttributes = (cols) => arrayToObject(convertToArray(cols), 'name', (item) => {
  return typeof item.default === 'undefined' ? null : item.default;
})

export const mapFilterColumns = (cols) => convertToArray(cols)
  .filter(col => col.isFilter !== false )
  .map(({name, type, title, _enum_, filterOptions}) => ({name, type, title, _enum_, ...filterOptions}) );

export const mapAttributeNames = (cols) => arrayToObject(convertToArray(cols), 'name', 'title')

export const mapTableRowColumns = (cols) => convertToArray(cols)
  .filter(col => col.tableView !== false )
  .map(({name: columnName, ...col}) => (
    {width: 130, columnName, ...getColumnExtensionsProps(col), ...col}
  ));

export const mapTableCellColumns = (cols) => convertToArray(cols)
  .filter(col => col.tableView !== false )

export const mapHiddenRownColumns = (cols) => convertToArray(cols)
  .filter(col => col.hidden ).map( e => e.name )