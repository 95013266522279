import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';
import Input from '../Input';

import makeOperationOptions from './utils/makeOperationOptions';
import { editFilter, delFilter } from '../../../../store/actions';
import sortArray from '~/utils/sortArray';

import useStyles from './styles';

function FilterItem({
  filter,
  indexGroup,
  indexFilter,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const columns = useSelector(state => sortArray(state.get('columns'), 'asc', 'title') );
  const column = columns.find(({ name }) => name === filter.get('col'));
  let styleValue = {};
  let type = column ? column.type : null;
  if(column && column.lookup ){
    type = 'options'
  }

  const options = makeOperationOptions()[type];

  if(filter.get('ope') === 'isBlank' || filter.get('ope') === 'isPresent'){
    styleValue = {
      display: 'none'
    };
  }

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={4}>
        <div className={classes.columnRoot}>
          <div>
            <IconButton onClick={() => dispatch(delFilter(indexGroup, indexFilter))}>
              <RemoveCircleIcon className={classes.iconRemove} />
            </IconButton>
          </div>

          <div className={classes.columnFieldRoot}>
            <InputControl
              autoFocus={indexFilter === 0}
              value={filter.get('col')}
              name={'value'}
              inputComponent={InputSelect}
              onChange={({ value }) => {
                dispatch(editFilter(indexGroup, indexFilter, 'col', value));
                dispatch(editFilter(indexGroup, indexFilter, 'ope', ''));
              }}
              InputProps={{
                outValueType: 'string',
                placeholder: 'Coluna',
                labelKey: 'title',
                valueKey: 'name',
                options: columns,
              }}
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={2}>
        <InputControl
          value={filter.get('ope')}
          name={'value'}
          inputComponent={InputSelect}
          onChange={({ value }) => dispatch(editFilter(indexGroup, indexFilter, 'ope', value))}
          InputProps={{
            outValueType: 'string',
            disabled: !type,
            placeholder: 'Operação',
            options,
          }}
        />
      </Grid>

      <Grid item className={classes.lastCol} style={styleValue}>
        <Input
          autoComplete={filter.get('col') ? `filter-value-${filter.get('col')}` : 'off'}
          column={column}
          filter={filter}
          indexGroup={indexGroup}
          indexFilter={indexFilter}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(FilterItem);
