import React from 'react';

import BaseLayout from '../BaseLayout';
import Errors from '../Errors';
import FooterActions from '../FooterActions';

import { EmptyContainer, ErrorsContainer } from './styles';

export default function LayoutNew({
  breadcrumbs,
  errors,
  footerActionsProps,
  children
}) {
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <ErrorsContainer>
        <Errors {...errors} />
      </ErrorsContainer>  

      {children}

      <EmptyContainer />
      <FooterActions {...footerActionsProps} />
    </BaseLayout>
  );
}
