import createReducer from '~/store/utils/createReducer';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Request from '~/services/request';
import history from '~/services/history';
import Route from '~/services/route';
import { authApi } from '~/routes/api';
import { authRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const RESET_PASSWORD_REQUEST = '@App/auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@App/auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '@App/auth/RESET_PASSWORD_FAILURE';

/* =================================================
  REDUX REDUCER
================================================= */
const INITIAL_STATE = {
  loading: false
};

export default createReducer(
  INITIAL_STATE,
  {
    [RESET_PASSWORD_REQUEST]: (draft) => { draft.loading = true; },
    [RESET_PASSWORD_SUCCESS]: (draft) => { draft.loading = false; },
    [RESET_PASSWORD_FAILURE]: (draft) => { draft.loading = false; }
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function resetPasswordRequest(password, passwordConfirmation) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: {
      password,
      password_confirmation: passwordConfirmation
    }
  }
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS
  }
}

export function resetPasswordFailure() {
  return {
    type: RESET_PASSWORD_FAILURE
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
export function* resetPassword({ payload }) {
  const { password, password_confirmation } = payload;

  try {
    const route = new Route();
    const { id } = route.getCurrentUrlParams();
    const response = yield call(Request.put, authApi.usersPassword.build(), {
      reset_password_token: id ?? '',
      password,
      password_confirmation
    });

    if (response.status === Request.HTTP_STATUS.NO_CONTENT) {
      yield put(resetPasswordFailure());
      toast.success('Senha atualizada com sucesso. Faça seu logon para continuar!');
      history.replace(authRoute.signIn.path, {});
    }
  } catch(err) {
    const expiredToken = err.message[0] === 'não é válido'
    if (expiredToken) {
      toast.error('Seu link de recuperação de senha expirou');
      history.replace(authRoute.forgotPassword.build(), {});
      return;
    }

    let message = 'Aconteceu um problema ao atualizar sua senha. Tente novamente mais tarde!';

    if (err.status === Request.HTTP_STATUS.UNPROCESSABLE_ENTITY) {
      message = 'Nova senha difere da senha de confirmação';
    }

    yield put(resetPasswordSuccess(err));
    toast.error(message);
  }
}

export const saga = all([
  takeLatest(RESET_PASSWORD_REQUEST, resetPassword)
]);