import styled from 'styled-components';

const drawerMenuPadding = '40px';
const drawerMenuClosePadding = '120px';

export const Section = styled.section`
  flex-grow: 1;
  padding: 15px ${drawerMenuPadding} ${drawerMenuPadding} ${drawerMenuPadding};
  transition: padding-left ${props => props.drawerOpen ? '0ms' : '200ms'} ease-in-out;
  padding-left: ${
    props => props.drawerOpen
      ? drawerMenuPadding
      : drawerMenuClosePadding
  };
  @media print {
    padding: 0px
  }
`;
