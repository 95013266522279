import React from 'react';
import { Switch } from 'react-router-dom';

import { invoicesRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';
import Show from './Show';
import Edit from './Edit';
import New  from './New';
import ImportXML  from './ImportXML';

export default function InvoicesRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={invoicesRoute.new.path}
        component={New}
        permissionKeyCode="Invoice"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={invoicesRoute.clone.path}
        component={New}
        permissionKeyCode="Invoice"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={invoicesRoute.newXml.path}
        component={ImportXML}
        permissionKeyCode="Invoice"
        permission="new"
      />
      <RouteWrapper
        exact
        isPrivate
        path={invoicesRoute.show.path}
        component={Show}
        permissionKeyCode="Invoice"
        permission="see"
      />
      <RouteWrapper
        exact
        isPrivate
        path={invoicesRoute.edit.path}
        component={Edit}
        permissionKeyCode="Invoice"
        permission="edit"
      />


      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={invoicesRoute.list.path}
        component={List}
        permissionKeyCode="Invoice"
        permission="see"
      />
    </Switch>
  );
}
