import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import ProductRecord from '~/records/ProductRecord';
import InputNumber from '~/components/Input/InputNumber';
import InputSelect     from '~/components/Input/InputSelect';

import { utilsApi } from '~/routes/api';
import Request from '~/services/request';

const attrName = (attr) => ProductRecord.hummanAttributeName(attr);

export default function FormFields({record, onChange}) {
  const [allAccountPlans, setAllAccountPlans] = useState([])

  useEffect( () => {
    Request.get( utilsApi.loadAccountPlans.build() ).then( resp => {
      setAllAccountPlans(resp.data.data)
    } )
  }, [])

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col sm={6}>
            <InputControl
              required
              label={attrName('name')}
              value={record.get('name')}
              inputComponent={Input}
              name={'name'}
              onChange={onChange}
            />
          </Col>
          <Col sm={6}>
            <InputControl
              label={attrName('ncm')}
              value={record.get('ncm')}
              inputComponent={Input}
              name={'ncm'}
              onChange={onChange}
            />
          </Col>
          <Col sm={6}>
            <InputControl
              label={attrName('ean')}
              value={record.get('ean')}
              inputComponent={Input}
              name={'ean'}
              onChange={onChange}
            />
          </Col>
          <Col sm={6}>
            <InputControl
              required
              label={attrName('base_value')}
              value={record.get('base_value')}
              inputComponent={InputNumber}
              name={'base_value'}
              onChange={onChange}
            />
          </Col>
          <Col sm={6}>
            <InputControl
              id={'ID-CUSTOMIZADO'}
              value={ record.accountPlanFormattedObject }
              name={'account_plan'}
              label={attrName('account_plan')}
              inputComponent={InputSelect}
              onChange={onChange}
              InputProps={{
                options: allAccountPlans
              }}
            />
          </Col>
        </GridContainer>
      </CardContent>
    </Card>
  );
}