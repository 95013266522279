import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  CustomTreeData,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableTreeColumn,
  TableColumnVisibility,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';

import { useGlobalFilters } from '~/hooks/useGlobalFilters';
import Request from '~/services/request';
import formatToMoney from '~/utils/formatToMoney';
import toFloat from '~/utils/Number/toFloat';
import toInt from '~/utils/Number/toInt';

import BaseLayout from '~/components/BaseLayout';
import SelectedConstructionButton from '~/components/SelectedConstructionButton';
import SearchConstructionsDialog from '~/components/SearchConstructionsDialog';
import Spinner from '~/components/Spinner';

import RowExpenseDetail from './components/RowExpenseDetail';
import InputFilterNumber from './components/InputFilterNumber';
import ToggleCellComponent from './components/ToggleCellComponent';

const filterRowMessages = {
  filterPlaceholder: 'Filtrar...',
  contains: 'Contém', // tring  'Contains'  The 'contains' filter operation name.
  notContains: 'Não contém', // string  'Does not contain'  The 'notContains' filter operation name.
  startsWith: 'Inicia com', // tring  'Starts with' The 'startsWith' filter operation name.
  endsWith: 'Termina com', // tring  'Ends with' The 'endsWith' filter operation name.
  equal: 'Exatamente igual', // string  'Equals'  The 'equal' filter operation name.
  notEqual: 'Diferente de', // tring  'Does not equal'  The 'notEqual' filter operation name.
  greaterThan: 'Maior', // string  'Greater than'  The 'greaterThan' filter operation name.
  greaterThanOrEqual: 'Maior ou igual', // tring  'Greater than or equal to'  The 'greaterThanOrEqual' filter operation name.
  lessThan: 'Menor', // tring  'Less than' The 'lessThan' filter operation name.
  lessThanOrEqual: 'Menor ou igual', // string  'Less than or equal to'
};

const getChildRows = (row, rows) => {
  const childRows = rows.filter((r) => r.parent_id === (row ? row.id : 0));
  return childRows.length ? childRows : null;
};

const styleByLevel = {
  0: { color: 'darkblue', fontWeight: 700 },
  1: { color: 'dodgerblue', fontWeight: 500 },
  2: { color: 'inherit', fontWeight: 300 },
};

const ColumnStyleProviderFormatter = ({ value, row }) => {
  return <Typography style={styleByLevel[row.level]}>{value}</Typography>;
};
const ColumnStyleProvider = (props) => (
  <DataTypeProvider
    formatterComponent={ColumnStyleProviderFormatter}
    {...props}
  />
);

const TotalValueProviderFormatter = ({ value, row }) => {
  return (
    <Typography style={styleByLevel[row.level]}>
      {`R$ ${formatToMoney(value)}`}
    </Typography>
  );
};
const TotalValueProvider = (props) => (
  <DataTypeProvider
    formatterComponent={TotalValueProviderFormatter}
    {...props}
  />
);

export default function List() {
  const [accounPlans, setAccountPLans] = useState([]);
  const [dataValues, setDataValues] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [sumValuesOk, setSumValuesOk] = useState(false);
  const { construction, setConstruction } = useGlobalFilters();
  const [tableColumnExtensions] = useState([
    { columnName: 'total', align: 'right', width: 250 },
  ]);

  const [columns] = useState([
    { name: 'name', title: 'Serviço' },
    { name: 'total', title: 'Valor Total' },
  ]);
  const [currencyFilterOperations] = useState([
    'equal',
    'notEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
  ]);

  useEffect(() => {
    if (!construction?.id) {
      setOpenDialog(true);
    }
  }, []);

  useEffect(() => {
    if (construction.id) {
      const fetchData = async () => {
        const resp = await Request.get(
          `/api/v1/constructions/${construction.id}/account_plans`,
        );
        setAccountPLans(
          resp.data.data.map((e) => ({
            id: e.id,
            name: `${e.full_code} - ${e.name}`,
            parent_id: e.parent_id || 0,
            level: toInt(e.level),
            total: 0,
            expenses: [],
          })),
        );
      };
      fetchData();
    }
  }, [construction?.id]);

  useEffect(() => {
    if (!sumValuesOk && accounPlans.length > 0) {
      const fetchData = async () => {
        const resp = await Request.get(
          `/api/v1/customers/reports/expenses/${construction.id}`,
        );
        const expenses = resp.data.data;

        let newAccounPlans = JSON.parse(JSON.stringify(accounPlans));
        newAccounPlans = newAccounPlans.map((acp) => {
          expenses.forEach((exp) => {
            const expAccountPlan = exp.account_plan;

            // Despesa vinculada diretamente ao plano de conta
            if (acp.id === expAccountPlan.id) {
              acp.total = acp.total + toFloat(exp.total_value);
              acp.expenses = [...acp.expenses, exp];
            }

            // Despesa vinculada INdiretamente ao plano de conta
            if (
              acp.id === expAccountPlan.parent_id ||
              acp.id === expAccountPlan.granparent_id
            ) {
              acp.total = acp.total + toFloat(exp.total_value);
            }
          });

          return acp;
        });
        setSumValuesOk(true);
        setDataValues(newAccounPlans);
      };
      fetchData();
    }
  }, [accounPlans, sumValuesOk, construction?.id]);

  return (
    <BaseLayout breadcrumbs={[{ text: 'Relatório do Plano de Contas' }]}>
      <SearchConstructionsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onSelect={(newConstruction) => {
          setConstruction(newConstruction);
          setOpenDialog(false);
          setSumValuesOk(false);
        }}
      />
      <Box display="flex" flexDirection="row-reverse">
        <SelectedConstructionButton onClick={() => setOpenDialog(true)} />
      </Box>
      {!sumValuesOk && construction?.id && (
        <Spinner fullScreen loadingText="Calculando..." />
      )}
      {dataValues.length && (
        <TableContainer
          component={Paper}
          style={{ padding: 16, marginTop: 16 }}
        >
          <Grid rows={dataValues} columns={columns}>
            <TreeDataState />
            <SortingState />
            <RowDetailState />
            <FilteringState />

            <TotalValueProvider
              for={['total']}
              availableFilterOperations={currencyFilterOperations}
              editorComponent={InputFilterNumber}
            />
            <ColumnStyleProvider for={['name']} />

            <CustomTreeData getChildRows={getChildRows} />

            <IntegratedFiltering />
            <IntegratedSorting />
            <Table columnExtensions={tableColumnExtensions} />
            <TableColumnVisibility />

            <TableFilterRow showFilterSelector messages={filterRowMessages} />

            <TableHeaderRow showSortingControls />
            <TableTreeColumn for="name" />

            <TableRowDetail
              contentComponent={RowExpenseDetail}
              toggleCellComponent={ToggleCellComponent}
            />
          </Grid>
          <Typography
            style={{ marginTop: 16 }}
            color="primary"
            align="right"
            gutterBottom
          >
            <strong>
              TOTAL: R${' '}
              {formatToMoney(
                dataValues
                  .filter((row) => row.parent_id === 0)
                  .reduce((a, b) => a + (b.total || 0), 0.0),
              )}
            </strong>
          </Typography>
        </TableContainer>
      )}
    </BaseLayout>
  );
}
