import SchemaBuilder from './SchemaBuilder';
import incomingEmailStatusEnum from '~/enums/incomingEmailStatusEnum';

const disableList = {hidden: true, tableView: false, isFilter: false}
const hidden = {hidden: true}

const columns = {
  nf_number:          {type: 'string', default: '', title: 'Número da NF', width: 150},
  status: {
    type: 'enum',
    width: 100,
    default: 'reconciled',
    title: 'Status',
    _enum_: incomingEmailStatusEnum
  },
  /* eslint-disable */
  receiver_name:      {type: 'string', default: '', title: 'Destinatario', width: 200},
  receiver_cpf_cnpj:  {type: 'string', default: '', ...hidden, title: 'Cpf\Cnpj do destinatario', width: 150},
  supplier_name:      {type: 'string', default: '', title: 'Nome do fornecedor', width: 200},
  supplier_cpf_cnpj:  {type: 'string', default: '', ...hidden, title: 'Cpf\Cnpj do fornecedor', width: 150},
  message_id:         {type: 'string', default: '', ...hidden, title: 'Indentificação do Email', width: 450},
  from:               {type: 'string', default: '', ...hidden, title: 'Remetente do Email', width: 200},
  date:               {type: 'date', default: '',   title: 'Data', width: 150},
  created_at:         {type: 'datetime', default: '', ...hidden, title: 'Criado em', width: 200 },
  updated_at:         {type: 'datetime', default: '', ...hidden, title: 'Atualizado em'},
  annex:              {type: 'object', default: null, ...disableList, title: 'XML'},
 /* eslint-enable */
};

const incomingEmailSchema = new SchemaBuilder( columns );

export default incomingEmailSchema;