import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  @media print {
    width: 100%;
    flex-direction: column;
    display: flex !important;
  }
`;
