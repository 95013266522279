import React, { useState, createContext } from 'react';

const ProfileDrawerContext = createContext({
  profileOpen: false,
  setProfileDrawerOpen() {}
});

export function ProfileDrawerProvider({ children }) {
  const [profileOpen, setProfileDrawerOpen] = useState(false);

  return (
    <ProfileDrawerContext.Provider value={{ profileOpen, setProfileDrawerOpen }}>
      {children}
    </ProfileDrawerContext.Provider>
  );
}

export default ProfileDrawerContext;
