import SchemaBuilder from './SchemaBuilder';

const string = {
  type: 'string',
  default: '',
}

const hidden = {hidden: true}

const columns = {
  id:             { type: 'integer',  title: 'ID' },
  behavior:       { type: 'string',   title: 'Comportamento', default: 'user', ...hidden }, // [:user, :system]
  suggestion:     { ...string,        title: 'Sugestão' },
  competence:     { ...string,        title: 'Utilizado em', ...hidden, default: 'record'},
  reference_type: { ...string,        title: 'Tipo Referência', ...hidden},
  reference_id:   { type: 'integer',  title: 'ID Referência', ...hidden},
  field_size:     { type: 'integer',  title: 'Tamanho do campo', default: 6 },
  required:       { type: 'boolean',  title: 'Obrigatório' },
  active:         { type: 'boolean',  title: 'Ativo', default: true },
  sorter:         { type: 'float',    title: 'Ordenação', ...hidden},
  created_at:     { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:     { type: 'datetime', default: '', title: 'Atualizado em', ...hidden },
  field:          { type: 'object',   title: 'Campo'}
};

const metaFieldSchema = new SchemaBuilder( columns );

export default metaFieldSchema;