import React, { useCallback } from 'react';

import {
  Container,
  PercentText,
  Percent,
  PercentageCompleted
} from './styles';

function StatusPercent({ percentage, color }) {
  const getTextPercentage = useCallback(() => {
    if (percentage > 96) {
      return 96;
    }

    return percentage;
  }, [percentage]);

  const getPercentage = useCallback(() => {
    if (percentage > 100) {
      return 100;
    }

    return percentage;
  }, [percentage]);

  return (
    <Container>
      <PercentText paddingLeft={getTextPercentage()}>
        {percentage}%
      </PercentText>
      <Percent>
        <PercentageCompleted color={color} width={getPercentage()} />
      </Percent>
    </Container>
  );
}

export default StatusPercent;
