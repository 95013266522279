import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import InputControl from '~/components/Input/InputControl';
import InputSlider   from '~/components/Input/InputSlider';
import sortArray from '~/utils/sortArray';

function getCode(obj){
  return obj?.account_plan?.code || 0
}

function DialogForm({
  defaultData,
  onChange
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (defaultData?.length && !data.length) {
      setData(defaultData);
    }
  }, [defaultData]);

  const updateData = useCallback(
    (id, percentage) => {
      setData(oldData => {
        const updatedData = [];

        oldData.forEach(field => {
          const fieldPercentage =
            field.id === id ? percentage : field.percentage;

            updatedData.push({
            ...field,
            percentage: fieldPercentage,
          });
        });

        return updatedData;
      })
    },
    [setData]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        {sortArray(data, 'asc', getCode).map(field => (
          <InputControl
            key={field.id}
            name={'percentage'}
            label={`${field.account_plan.code} - ${field.account_plan.name}`}
            value={field.percentage}
            inputComponent={InputSlider}
            onChange={({ percentage }) => {
              onChange({ ...field, percentage });
              updateData(field.id, percentage);
            }}
            InputProps={{
              precision: 0,
              step: 1,
              min: 0,
              max: 100,
              valueLabelFormat: (v) => `${v}%`
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default DialogForm;
