import RecordBase from './Base';
import fieldSchema from './schema/fieldSchema';

export default class MetaFieldRecord extends RecordBase {
  static defaultAttributes = fieldSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return fieldSchema.attributeNames }

  get sizeValueName() {
    if( this.get('kind') === 'float' ){ return 'Precisão' }
    return MetaFieldRecord.hummanAttributeName('size_value')
  }

  toParams(extraParams = {}) {
    return {
      data:{
        attributes:{
          name:         this.get('name'),
          kind:         this.get('kind'),
          key:          this.get('key'),
          min_value:    this.get('min_value'),
          max_value:    this.get('max_value'),
          record_type:  this.get('record_type'),
          size_value:   this.get('size_value'),
          description:  this.get('description'),
          options:      this.get('options'),
          apply_editor: this.get('apply_editor'),
          allow_other:  this.get('allow_other'),
          ...extraParams,
        }
      }
    };
  }
}
