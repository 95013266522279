import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RegisterPlanIcon from '@material-ui/icons/Equalizer';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Send';

import InputControl from '~/components/Input/InputControl';
import InputSelectAsync     from '~/components/Input/InputSelectAsync';
import Dialog from '~/components/Dialog';
import Button from '~/components/Button';
import Errors from '~/components/Errors';

import { accountPlansApi } from '~/routes/api';
import Request from '~/services/request';
import AccountPlanRecord from '~/records/AccountPlanRecord';
import deserialize from '~/utils/deserialize';
import sortArray from '~/utils/sortArray';

const attrName = (attr) => AccountPlanRecord.hummanAttributeName(attr);

export default function DsialogForm({
  record,
  onChange,
  isRouteNew,
  isOpen,
  onSave,
  closeDialog
}) {
  const [saveAndContinue, setSaveAndContinue] = useState(false);
  const { isLoading, errors } = useSelector(state => state.accountPlans);

  const findAccountPlansToInputAsync = useCallback(
    async (input, page, resolve) => {
      try {
        const path = `${accountPlansApi.list.path}/find_valid_accounts`;
        const response = await Request.get(`${path}?qs=${input}&page=${page}`);
        const responseData = await deserialize( response.data )

        resolve(
          {
            total_count: response.data?.meta?.total,
            items: sortArray(
              responseData.map(accountPlan => {
                return {
                  ...accountPlan,
                  name: `${accountPlan.full_code} - ${accountPlan.name}`
                }
              }),
              'asc',
              'name'
            )
          }
        )
      } catch(err) {}
    },
    []
  );

  return (
    <Dialog
      icon={<RegisterPlanIcon />}
      isOpen={isOpen}
      handleClose={closeDialog}
      title={'Novo Plano de Contas'}
      color="primary"
      fullWidth
      maxWidth="xs"
      keepMounted
      dialogActions={
        <>
        {isRouteNew && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={saveAndContinue}
                onChange={() => setSaveAndContinue(!saveAndContinue)}
                color="primary"
              />
            )}
            label="Salvar e continuar"
          />
        )}

          <Button
            startIcon={<CancelIcon />}
            onClick={closeDialog}
            color="red"
            variant="text"
          >
            Cancelar
          </Button>
          <Button
            startIcon={<SaveIcon />}
            disabled={isLoading}
            onClick={() => onSave(saveAndContinue)}
            color="green"
            variant="text"
          >
            Salvar
          </Button>
        </>
      }
    >
      <Errors errors={errors} />
      <Grid container>
        <Grid item xs={12}>
          <InputControl
            required
            label={attrName('name')}
            value={record.get('name')}
            inputComponent={Input}
            name={'name'}
            onChange={onChange}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <InputControl
            required
            label={attrName('code')}
            value={record.get('code')}
            inputComponent={Input}
            name={'code'}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputControl
            label={attrName('parent')}
            value={record.get('parent')}
            inputComponent={InputSelectAsync}
            name={'parent'}
            stateName={'parent'}
            InputProps={{
              handleFetch: findAccountPlansToInputAsync,
              createEnabled: false
            }}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
