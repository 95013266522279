/* eslint-disable react/prop-types */
import React     from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    minHeight: 35,
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center'
  },
  slider: {
    flexGrow: 1,
  },
  labelValue: {
    fontSize: '1rem',
    marginLeft: '1rem',
    fontWeight: 'bold'
  }
});


const InputSlider = ({
  value,
  onChange,
  'state-name': stateName,
  step,
  min,
  max,
  valueLabelFormat,
  marks,
  SliderComponent,
  SliderProps
}) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange({[stateName]: newValue}, event);
  };

  return (
    <div className={classes.root}>
      <SliderComponent
        className={classes.slider}
        value={ value || 0 }
        onChange={handleChange}
        defaultValue={0}
        valueLabelFormat={valueLabelFormat || (() => `${value || 0}`) }
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        marks={marks}
        step={step}
        min={min}
        max={max}
        {...SliderProps}
      />
      <span className={classes.labelValue}>{Array.isArray(value) ? `${value[0]}-${value[1]}` : value}</span>
    </div>
  )
}

InputSlider.defaultProps = {
  marks: false,
  SliderComponent: Slider,
  SliderProps: {}
}

export default InputSlider;
