import React, { useCallback, useState, useMemo } from 'react';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Input from '@material-ui/core/Input';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { suppliersApi, constructionsApi } from '~/routes/api';
import InvoiceRecord from '~/records/InvoiceRecord';
import invoiceStatusEnum from '~/enums/invoiceStatusEnum';
import invoiceExpenseTypeEnum from '~/enums/invoiceExpenseTypeEnum';

import Divider from '~/components/Divider';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import InputDate from '~/components/Input/InputDate';
import InputNumber from '~/components/Input/InputNumber';

import InputDropzoneFile from '~/containers/InputDropzoneFile';

import ItemTable from '../ItemTable'
import Expenses from './components/Expenses'
import BankSlips from './components/BankSlips'
import RateItemsDialog from './components/RateItemsDialog'

import Request from '~/services/request';

const attrName = (attr) => InvoiceRecord.hummanAttributeName(attr);

const onCreateSupplierCallback = (inputValue, childRef, resolve) => {
  Request.post(suppliersApi.create.build(), {
    data: {
      attributes: { name: inputValue }
    }
  }).then( resp => {
    childRef.handleChange(
      childRef.handleOption( {
        id:   resp.data.data.id,
        name: resp.data.data.attributes.name
      } )
    );
    resolve();
  }).catch( () => {
    resolve()
  })
};

function Form({record, onChange}) {
  const [rateExpense, setRateExpense] = useState(null)

  const hasItems = useMemo(() => record.get('invoice_items').size > 0, [record.get('invoice_items').size])

  const itemsWithoutAccoutPlan = useMemo(function (){

    const _items = record.get('invoice_items').filter( i => {
      return !i.get('account_plan') || `${i.get('account_plan')?.get('id')}` === `${rateExpense?.get('account_plan')?.get('id')}`
    })

    return _items.valueSeq().toArray().map(e => ({
      idOrToken:   e.idOrToken,
      accountPlanID: e.get('account_plan')?.get('id'),
      product:     e.get('product')?.get('name'),
      amount:      e.get('amount'),
      unit_value:  e.get('unit_value'),
      total_value: e.get('total_value')
    }))
  }, [record.get('invoice_items'), record.get('expenses'), rateExpense])

  const onChangeFiles = useCallback(
    (attachments) => {
      onChange( {attachments} )
    },
    [record, onChange],
  )

  const onSelectRateItems = useCallback(
    (itemIDs) => {
      const selectedItems = record.get('invoice_items').filter( item => itemIDs.includes(item.idOrToken) ).valueSeq().toArray()
      const value = selectedItems.reduce((a,v) =>  a = a + v.get('total_value') , 0.0 )

      const newExpense = rateExpense.updateAttributes({total_value: value});
      const expenses = record.get('expenses').setIn(
        [newExpense.idOrToken],
        newExpense
      );

      let invoice_items = record.get('invoice_items');
      selectedItems.forEach( invoiceItem => {
        invoice_items = invoice_items.setIn(
          [invoiceItem.idOrToken],
          invoiceItem.updateAttributes({ account_plan: rateExpense.get('account_plan') })
        )
      })

      // Para desassociar os itens que foram desmarcados
      record.get('invoice_items').filter( item =>
        !itemIDs.includes(item.idOrToken) &&
        `${item.get('account_plan')?.get('id')}` === `${rateExpense?.get('account_plan')?.get('id')}`
      ).valueSeq().toArray().forEach( invoiceItem => {
        invoice_items = invoice_items.setIn(
          [invoiceItem.idOrToken],
          invoiceItem.updateAttributes({ account_plan: null })
        )
      })

      onChange( {expenses, invoice_items} );
      setRateExpense(null)

    },
    [rateExpense, record.get('expenses'), onChange, record.get('invoice_items')],
  )

  return (
    <>
      <Card>
        <CardHeader title="Dados Básicos" />
        <CardContent>
          <GridContainer>
            <Col sm={4}>
              <InputControl
                required
                value={record.get('construction')}
                name={'construction'}
                stateName={'construction'}
                label={attrName('construction')}
                inputComponent={InputSelectAsync}
                onChange={onChange}
                InputProps={{
                  fetchUrl: constructionsApi.list.build(),
                  createEnabled: false,
                }}
              />
            </Col>
            <Col sm={4}>
              <InputControl
                required
                value={record.get('supplier')}
                name={'supplier'}
                stateName={'supplier'}
                label={attrName('supplier')}
                inputComponent={InputSelectAsync}
                onChange={onChange}
                InputProps={{
                  selectComponent: AsyncCreatableSelect,
                  fetchUrl: suppliersApi.list.build(),
                  onCreateCallback: onCreateSupplierCallback,
                  createEnabled: true,
                }}
              />
            </Col>
            <Col md={4}>
              <InputControl
                required
                name="nf_date"
                label={attrName('nf_date')}
                value={record.get('nf_date')}
                type="date"
                inputComponent={InputDate}
                onChange={onChange}
              />
            </Col>
            <Col md={4} lg={3}>
              <InputControl
                required
                value={record.get('nf_number')}
                name={'nf_number'}
                stateName={'nf_number'}
                label={attrName('nf_number')}
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col md={4} lg={3}>
              <InputControl
                required
                value={record.get('expense_type')}
                name={'expense_type'}
                label={attrName('expense_type')}
                inputComponent={InputSelect}
                InputProps={{
                  options: invoiceExpenseTypeEnum().array,
                  outValueType: 'string'
                }}
                onChange={onChange}
              />
            </Col>
            <Col md={4} lg={3}>
              <InputControl
                required
                value={record.get('status')}
                name={'status'}
                label={attrName('status')}
                inputComponent={InputSelect}
                InputProps={{
                  options: invoiceStatusEnum().array,
                  outValueType: 'string'
                }}
                onChange={onChange}
              />
            </Col>
            <Col md={4} lg={3} >
              <InputControl
                required
                value={record.get('total_value')}
                label={attrName('total_value')}
                name={'total_value'}
                inputComponent={InputNumber}
                onChange={onChange}
                startAdornment={<span>R$</span>}
              />
            </Col>
            { !!record.get('nf_date') && moment(record.get('nf_date')).isBefore(moment().startOf('month')) &&
              <Col md={12} >
                <InputControl
                  required
                  autoComplete="off"
                  value={record.get('password')}
                  label={attrName('password')}
                  name={'password'}
                  inputComponent={Input}
                  onChange={onChange}
                />
              </Col>
            }
          </GridContainer>

          <Divider/>
          <Expenses
            record={record}
            onChange={onChange}
            setRateExpense={setRateExpense}
            invoiceItems={record.get('invoice_items')}
          />

          <Divider/>
          <BankSlips record={record} onChange={onChange} />
        </CardContent>
      </Card>

      { hasItems && <ItemTable items={record.get('invoice_items')} /> }
      { hasItems &&
        <RateItemsDialog
          expense={rateExpense}
          invoiceItems={ itemsWithoutAccoutPlan }
          handleClose={ () => setRateExpense(null) }
          onSelect={ onSelectRateItems }
        />
      }

      <InputDropzoneFile
        title="Anexos"
        key={record.get('id') || 0}
        // onDelete={onDeleteFile}
        onChange={onChangeFiles}
        persistedFiles={ record.get('attachments') }
      />
    </>
  );
}

export default React.memo(Form);