import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    display: 'flex',
    marginLeft: 6,
    marginRight: 6,
  },
  switchBase: {
    'padding': 2,
    'color': theme.palette.common.white,
    '& + $track': {
      opacity: 1,
      backgroundColor: theme.palette.blue.main,
      borderColor: theme.palette.blue.main,
    },
    '&$checked': {
      'transform': 'translateX(16px)',
      'color': theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.red.main,
        borderColor: theme.palette.red.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
  detail: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
