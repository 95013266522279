import { combineReducers } from 'redux';

import crud from './modules/crud';
import notifications from './modules/notifications';
import forgotPassword from './modules/forgotPassword';
import resetPassword from './modules/resetPassword';
import userProfile from './modules/userProfile';
import auth from './modules/auth';
import users from './modules/users';
import fields from './modules/fields';
import people from './modules/people';
import roles from './modules/roles';
import metadata from './modules/metadata';
import customRecords from './modules/customRecords';

import suppliers from './modules/suppliers';
import segments from './modules/segments';
import products from './modules/products';
import invoices from './modules/invoices';
import accountPlans from './modules/accountPlans';
import constructions from './modules/constructions';
import dashboard from './modules/dashboard';
import builders from './modules/builders';
import engineers from './modules/engineers';
import contractors from './modules/contractors';
import incomingEmails from './modules/incomingEmails';
import npsForms from './modules/npsForms';
import npsFormAnswers from './modules/npsFormAnswers';
import reportExpenses from './modules/reportExpenses';

// TODO: Importar demais modulos

export default combineReducers({
  crud,
  notifications,
  auth,
  userProfile,
  people,
  roles,
  metadata,
  forgotPassword,
  resetPassword,
  users,
  fields,
  customRecords,

  suppliers,
  segments,
  products,
  invoices,
  accountPlans,
  constructions,
  dashboard,
  builders,
  engineers,
  contractors,
  incomingEmails,
  npsForms,
  npsFormAnswers,
  reportExpenses,
});
