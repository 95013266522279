import SchemaBuilder from './SchemaBuilder';

const disableList = {hidden: true, tableView: false, isFilter: false}

const columns = {
  id:         { type: 'integer',  title: 'ID' },
  name:       { type: 'string',   default: '', title: 'Descrição' },
  created_at: { type: 'datetime', default: '', title: 'Criado em' },
  updated_at: { type: 'datetime', default: '', title: 'Atualizado em' },

  users:      {type: 'array', default: [], ...disableList, title: 'Usuários'},
  role_permissions: {type: 'array', default: [], ...disableList, title: 'Permissões'},
};

const roleSchema = new SchemaBuilder( columns );

export default roleSchema;