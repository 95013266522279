import SchemaBuilder from './SchemaBuilder';

const string = {
  type: 'string',
  default: '',
}

const hidden = {hidden: true}

const columns = {
  id:                        { type: 'integer',  title: 'ID' },
  name:                      { ...string, title: 'Descrição', width: 150 },
  competence:                { type: 'string',  default: 'record', title: 'Tipo' },
  usability:                 { type: 'string',  default: 'user',   title: 'Usabilidade', ...hidden },
  sys_key:                   { ...string, title: 'Chave única', ...hidden },
  record_plural_name:        { ...string, title: 'Nome plural do registro' },
  record_singular_name:      { ...string, title: 'Nome singular do registro', ...hidden },
  record_item_plural_name:   { ...string, title: 'Nome plural dos itens', ...hidden },
  record_item_singular_name: { ...string, title: 'Nome singular dos itens', ...hidden },
  menu:                      { ...string, title: 'Menu', ...hidden, isFilter: false },
  define_fields_in_record:   { type: 'boolean', default: false, title: 'Definir campos nos registros', ...hidden },
  active:                    { type: 'boolean', default: true, title: 'Ativo' },
  icon_lib_record:           { ...string, title: 'Ícone registro', ...hidden, isFilter: false },
  icon_lib_record_item:      { ...string, title: 'Ícone itens', ...hidden, isFilter: false },
  created_at:                { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:                { type: 'datetime', default: '', title: 'Atualizado em', ...hidden },
  meta_fields:               { type: 'object', title: 'Campos', ...hidden, isFilter: false },
};

const metadatumSchema = new SchemaBuilder( columns );

export default metadatumSchema;