import Route from '~/services/route';

export const customRecordsApi = {
  show:    new Route('/api/v1/custom_records/:sys_key/:id'),
  update:  new Route('/api/v1/custom_records/:sys_key/:id'),
  destroy: new Route('/api/v1/custom_records/:sys_key/:id'),
  create:  new Route('/api/v1/custom_records/:sys_key'),
  list:    new Route('/api/v1/custom_records/:sys_key'),
}

export const fieldsApi = {
  show:    new Route('/api/v1/fields/:id'),
  update:  new Route('/api/v1/fields/:id'),
  destroy: new Route('/api/v1/fields/:id'),
  create:  new Route('/api/v1/fields'),
  list:    new Route('/api/v1/fields'),
}

export const metadataApi = {
  show:    new Route('/api/v1/metadata/:id'),
  update:  new Route('/api/v1/metadata/:id'),
  destroy: new Route('/api/v1/metadata/:id'),
  create:  new Route('/api/v1/metadata'),
  list:    new Route('/api/v1/metadata'),
}

export const peopleApi = {
  show:    new Route('/api/v1/people/:id'),
  update:  new Route('/api/v1/people/:id'),
  destroy: new Route('/api/v1/people/:id'),
  create:  new Route('/api/v1/people'),
  list:    new Route('/api/v1/people'),
}

export const rolesApi = {
  show:    new Route('/api/v1/roles/:id'),
  update:  new Route('/api/v1/roles/:id'),
  destroy: new Route('/api/v1/roles/:id'),
  create:  new Route('/api/v1/roles'),
  list:    new Route('/api/v1/roles'),
}

export const permissionsApi = {
  show:    new Route('/api/v1/permissions/:id'),
  update:  new Route('/api/v1/permissions/:id'),
  destroy: new Route('/api/v1/permissions/:id'),
  create:  new Route('/api/v1/permissions'),
  list:    new Route('/api/v1/permissions'),
}

export const usersApi = {
  show:    new Route('/api/v1/admin/users/:id'),
  update:  new Route('/api/v1/admin/users/:id'),
  destroy: new Route('/api/v1/admin/users/:id'),
  create:  new Route('/api/v1/admin/users'),
  list:    new Route('/api/v1/admin/users'),
  profile: new Route('/api/v1/user_profile'),
}

export const authApi = {
  getToken: new Route('/oauth/token'),
  usersPassword: new Route('/api/v1/users/password')
}

export const fileUploadsApi = {
  list:   new Route('/api/v1/file_uploads'),
  update: new Route('/api/v1/file_uploads/:uuid'),
  upload: new Route('/api/v1/file_uploads'),
  delete: new Route('/api/v1/file_uploads/:uuid'),
}

export const utilsApi = {
  searchCep: new Route('/api/v1/utils/search_cep/:cep'),
  loadAccountPlans: new Route('/api/v1/utils/load_account_plans'),
}

export const citiesApi = {
  searchCities: new Route('/api/v1/cities'),
  show: new Route('/api/v1/cities/:id'),
}

export const statesApi = {
  search: new Route('/api/v1/states'),
  show: new Route('/api/v1/states/:id'),
}

export const invoicesApi = {
  show:    new Route('/api/v1/invoices/:id'),
  update:  new Route('/api/v1/invoices/:id'),
  destroy: new Route('/api/v1/invoices/:id'),
  create:  new Route('/api/v1/invoices'),
  list:    new Route('/api/v1/invoices'),
  getXmlInfo: new Route('/api/v1/invoices/get_xml_info/:uuid')
}

export const incomingEmailApi = {
  show:    new Route('/api/v1/incoming_emails/:id'),
}

export const customerInvoiceApi = {
  show:    new Route('/api/v1/customers/invoices/:construction_id/:id'),
  list:    new Route('/api/v1/customers/invoices/:construction_id'),
}

export const suppliersApi = {
  show:    new Route('/api/v1/suppliers/:id'),
  update:  new Route('/api/v1/suppliers/:id'),
  destroy: new Route('/api/v1/suppliers/:id'),
  create:  new Route('/api/v1/suppliers'),
  list:    new Route('/api/v1/suppliers'),
}

export const segmentsApi = {
  show:    new Route('/api/v1/segments/:id'),
  update:  new Route('/api/v1/segments/:id'),
  destroy: new Route('/api/v1/segments/:id'),
  create:  new Route('/api/v1/segments'),
  list:    new Route('/api/v1/segments'),
}

export const constructionsApi = {
  show:    new Route('/api/v1/constructions/:id'),
  update:  new Route('/api/v1/constructions/:id'),
  destroy: new Route('/api/v1/constructions/:id'),
  create:  new Route('/api/v1/constructions'),
  list:    new Route('/api/v1/constructions'),
  search:    new Route('/api/v1/search/constructions'),
}

export const productsApi = {
  show:    new Route('/api/v1/products/:id'),
  update:  new Route('/api/v1/products/:id'),
  destroy: new Route('/api/v1/products/:id'),
  create:  new Route('/api/v1/products'),
  list:    new Route('/api/v1/products'),
  search:  new Route('/api/v1/search/products'),
  linkSupplierCode:  new Route('/api/v1/products/update_supplier_product/:id'),
}

export const accountPlansApi = {
  show:    new Route('/api/v1/account_plans/:id'),
  update:  new Route('/api/v1/account_plans/:id'),
  destroy: new Route('/api/v1/account_plans/:id'),
  create:  new Route('/api/v1/account_plans'),
  list:    new Route('/api/v1/account_plans'),
}

export const buildersApi = {
  show:    new Route('/api/v1/builders/:id'),
  update:  new Route('/api/v1/builders/:id'),
  destroy: new Route('/api/v1/builders/:id'),
  create:  new Route('/api/v1/builders'),
  list:    new Route('/api/v1/builders'),
}

export const engineersApi = {
  show:    new Route('/api/v1/engineers/:id'),
  update:  new Route('/api/v1/engineers/:id'),
  destroy: new Route('/api/v1/engineers/:id'),
  create:  new Route('/api/v1/engineers'),
  list:    new Route('/api/v1/engineers'),
}

export const contractorsApi = {
  show:    new Route('/api/v1/contractors/:id'),
  update:  new Route('/api/v1/contractors/:id'),
  destroy: new Route('/api/v1/contractors/:id'),
  create:  new Route('/api/v1/contractors'),
  list:    new Route('/api/v1/contractors'),
}

export const incomingEmailsApi = {
  show:    new Route('/api/v1/incoming_emails/:id'),
  update:  new Route('/api/v1/incoming_emails/:id'),
  destroy: new Route('/api/v1/incoming_emails/:id'),
  create:  new Route('/api/v1/incoming_emails'),
  list:    new Route('/api/v1/incoming_emails'),
}

export const dashboardApi = {
  self: new Route('/api/v1/dashboard')
}

export const npsFormsApi = {
  show:    new Route('/api/v1/forms/:id'),
  update:  new Route('/api/v1/forms/:id'),
  destroy: new Route('/api/v1/forms/:id'),
  create:  new Route('/api/v1/forms'),
  list:    new Route('/api/v1/forms'),
}

export const npsFormAnswersApi = {
  show:    new Route('/api/v1/form_answers/:id'),
  update:  new Route('/api/v1/form_answers/:id'),
  destroy: new Route('/api/v1/form_answers/:id'),
  create:  new Route('/api/v1/form_answers'),
  list:    new Route('/api/v1/form_answers'),
}

export const expensesApi = {
  history: new Route('/api/v1/expenses/history')
}

export const reportsApi = {
  expenses: new Route('/api/v1/admin/reports/expenses'),
  filters: new Route('/api/v1/admin/reports/expenses/filters'),
  downloadDanfes: new Route('/api/v1/admin/reports/download_danfes'),
}

export const customersBankSlipsApi = {
  paid: new Route('/api/v1//customers/bank_slips/:construction_id/:bank_slip_id/paid'),
}
