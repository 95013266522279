import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import AnimatedNumber from "animated-number-react";

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import formatToMoney from '~/utils/formatToMoney';

import { Item } from './styles';

const permittedUsers = ['master', 'admin'];

const RenderAnimatedNumber = ({value, ...props}) => {
  if(typeof value !== 'undefined'){
    return <AnimatedNumber
      value={value || 0}
      {...props}
    />
  }
  return (
    <Typography component="div" key={'body1'} variant={'body1'}>
      <Skeleton />
    </Typography>
  )
}

export default function Totalizers() {
  const { data } = useSelector(state => state.dashboard);
  const { data: user } = useSelector(state => state.userProfile);

  return (
    <Grid container spacing={2} style={{ flexGrow: 1, marginTop: 10 }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Item>
              <span>Total de Despesas</span>
              <h3>
                {'R$ '}
                <RenderAnimatedNumber
                  value={data.total_expenses}
                  formatValue={formatToMoney}
                />
              </h3>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item>
              <span>Total de XMLs importados</span>
              <h3>
                <RenderAnimatedNumber
                  value={data.total_xml_imported}
                  formatValue={value => parseInt(value)}
                  duration={700}
                />
              </h3>
            </Item>
          </Grid>
          {permittedUsers.includes(user.access_type) && (
            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <span>Média de NPS</span>
                <h3>
                  <RenderAnimatedNumber
                    value={data.average_nps}
                    formatValue={value => value.toFixed(2)}
                    duration={700}
                  />
                </h3>
              </Item>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

