import Request from '~/services/request';

const customRecordsFetch = (sysKeyOrID) => (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/custom_records/${sysKeyOrID}?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name
        }))
      }
    )
  })
  .catch(err => err);
};

export default customRecordsFetch