import Request from '~/services/request';
import { usersApi } from '~/routes/api';
import queryStringParser from '~/utils/queryStringParser';

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ usersApi.show.build({id}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ usersApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( usersApi.destroy.build({id}), args );
};

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ usersApi.update.build({id}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ usersApi.create.build() }?${queryStringParser(args)}`, data );
};