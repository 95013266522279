import React from 'react';
import Chip        from '~/components/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));


const RenderChip = (props) => {
  const classes = useStyles();

  const handleDelete = () => {
    alert('You clicked the delete icon.');
  };

  const handleClick = () => {
    alert('You clicked the Chip.');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.root}>
        <Chip {...props} onClick={handleClick} label="Clickable"/>
        <Chip {...props}
          avatar={<Avatar alt="Natacha" src="https://material-ui.com/static/images/avatar/1.jpg" />}
          onDelete={handleDelete}
          label="Deletable"
        />
        <Chip label="Basic" {...props} />
        <Chip label="Disabled" disabled {...props} />
        <Chip
          icon={<FaceIcon />}
          label="Clickable deletable"
          onClick={handleClick}
          onDelete={handleDelete}
          {...props}
        />
        <Chip
          label="Custom delete icon"
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={<DoneIcon />}
          {...props}
        />
        <Chip label="Clickable link" component="a" href="#chip" clickable {...props} />
        <Chip
          avatar={<Avatar>M</Avatar>}
          label="Letter clickable"
          clickable
          onDelete={handleDelete}
          deleteIcon={<DoneIcon />}
          {...props}
        />
        <Chip
          icon={<FaceIcon />}
          label="Icon clickable"
          clickable
          onDelete={handleDelete}
          deleteIcon={<DoneIcon />}
          {...props}
        />
        <Chip label={`Deletable ${props.color}`} onDelete={handleDelete} {...props} />
      </Grid>
    </Grid>
  )
};

const RenderChips = ({color}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Typography variant={'subtitle1'}>{color ? color : 'Default'}</Typography>
      </Grid>
      <Grid item xs={5}>
        <RenderChip color={color ? color : 'Default'} variant='default' />
      </Grid>
      <Grid item xs={5}>
        <RenderChip color={color ? color : 'Default'} variant='outlined' />
      </Grid>
    </Grid>
  )
};

const ExampleChip = () => {


  return(
    <div style={{margin: 15}}>
      <div>
        <RenderChips />
        <RenderChips color="primary"/>
        <RenderChips color="secondary"/>
        <RenderChips color="success"/>
        <RenderChips color="warning"/>
        <RenderChips color="error"/>
        <RenderChips color="rose"/>
        <RenderChips color="blue"/>
        <RenderChips color="purple"/>
        <RenderChips color="black"/>
        <RenderChips color="green"/>
        <RenderChips color="orange"/>
        <RenderChips color="red"/>
        <RenderChips color="grey"/>
      </div>
      <hr />
      <div>
        <Typography>Tamanhos</Typography>
        <Chip color="primary" size='small' label="Chip :small" />
        <span>{' > '}</span>
        <Chip color="primary" size='medium' label="Chip :medium" />
      </div>
    </div>
  );
};

export default ExampleChip;
