import SchemaBuilder from './SchemaBuilder';

const columns = {
  id: {
    type: 'integer',
    width: 150,
    title: 'ID'
  },
  name: {
    type: 'string',
    width: 200,
    default: '',
    title: 'Nome'
  },
  created_at: {
    type: 'datetime',
    title: 'Criado em',
    width: 150
  },
};

const builderSchema = new SchemaBuilder( columns );

export default builderSchema;
