import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import {
  createRecord,
  findRecord,
  clearState,
  setNewRecord
} from '~/store/modules/invoices';
import InvoiceRecord from '~/records/InvoiceRecord';
import { invoicesRoute } from '~/routes'

import Button from '~/components/Button';
import CrudNew from '~/containers/CrudNew';

import FormFields from './components/FormFields';

export default function New() {
  const { id } = useParams();
  const [record, setRecord] = useState(new InvoiceRecord({expenses: [{}]}));
  const dispatch = useDispatch();
  const { isLoading, current, errors, newRecord } = useSelector(state => state.invoices);

  useEffect(() => {
    if(id){
      dispatch( findRecord(id) );
    }
    if( newRecord ){
      setRecord( newRecord )
    }
    return () => {
      dispatch( clearState() )
      dispatch( setNewRecord(null) )
    };
  }, [])

  useEffect(() => {
    if(current && id){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback( () => {
    const data = record.toParams();
    dispatch( createRecord(data) );
  }, [record]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const clearAfterSaved = useCallback(() => {
    setRecord( new InvoiceRecord() )
  }, [setRecord]);

  return (
    <CrudNew
      clearAfterSaved={clearAfterSaved}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: invoicesRoute.list.path, text: 'Notas fiscais' },
        { text: 'Cadastrando Nota Fiscal' },
      ]}
      footerActionsProps={{isLoading}}
    >
      <Box display="flex" p={1} justifyContent="flex-end">
        <Button href={ invoicesRoute.newXml.build() }>
          Importar XML
        </Button>
      </Box>

      <FormFields
        record={record}
        onChange={handleChange}
      />
    </CrudNew>
  );
}