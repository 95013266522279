import SchemaBuilder from './SchemaBuilder';

const columns = {
  name:              { type: 'string', title: 'Nome' },
  email:             { type: 'string', title: 'E-mail' },
  comercial_phone:   { type: 'string', title: 'Tel. Comercial' },
  cell_phone:        { type: 'string', title: 'Tel. Celular' },
  residential_phone: { type: 'string', title: 'Tel. Residencial' },
  job_title:         { type: 'string', title: 'Cargo' },
};

const contactSchema = new SchemaBuilder( columns );

export default contactSchema;