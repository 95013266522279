import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';


import InputControl from '~/components/Input/InputControl';
import InputCpfCnpj from '~/components/Input/InputCpfCnpj';
import InputDate from '~/components/Input/InputDate';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputSelectAsync from '~/components/Input/InputSelectAsync';

import SupplierRecord from '~/records/SupplierRecord';

import Address from '~/components/Address';
import ContactsForm from '~/containers/ContactsForm';

const attrName = (attr) => SupplierRecord.hummanAttributeName(attr);

export default function FormFields({ record, onChange}) {
  const handleChangeAddress = useCallback(attrs => {
    const address = record.updateAddress(attrs);
    onChange(address);
  }, [record, onChange]);

  const handleChangeIsLegal = useCallback(
    (event) => {
      onChange({ is_legal: event.target.value === 'juridical' });
    },
    [onChange]
  );

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col xs={12} sm={6}>
            <InputControl
              autoFocus
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <InputControl
              value={record.get('segment')}
              name={'segment'}
              stateName={'segment'}
              label={attrName('segment')}
              inputComponent={InputSelectAsync}
              onChange={onChange}
              InputProps={{
                handleFetch: () => alert('TODO: request')
              }}
            />
          </Col>
          <Col md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              value={record.get('is_legal') ? 'juridical' : 'physical'}
              onChange={handleChangeIsLegal}
              label="Tipo"
            >
              <FormControlLabel
                value="physical"
                control={<Radio color="primary" />}
                label="Pessoa Física"
              />
              <FormControlLabel
                value="juridical"
                control={<Radio color="primary" />}
                label="Pessoa Jurídica"
              />
            </RadioGroup>
          </Col>
          <Col xs={12} sm={6}>
            <InputControl
              required
              name="cpf_cnpj"
              label={record.get('is_legal') ? 'CNPJ' : 'CPF'}
              value={record.get('cpf_cnpj')}
              inputComponent={InputCpfCnpj}
              onChange={onChange}
              InputProps={{
                force: record.get('is_legal') ? 'cnpj' : 'cpf'
              }}
            />
          </Col>
          { !record.get('is_legal') &&
            <Col sm={6} md={4}>
              <InputControl
                name="rg"
                label={attrName('rg')}
                value={record.get('rg')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
          }
          <Col md={4}>
            <InputControl
              name="birth_date"
              label={record.get('is_legal') ? 'Data da fundação' : 'Data de nascimento'}
              value={record.get('birth_date')}
              type="date"
              inputComponent={InputDate}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <br/>
        <Typography variant="h6">Endereço</Typography>
        <Address
          record={record}
          onChangeAddress={handleChangeAddress}
          requiredFields={{
            district: true,
            city: true,
          }}
          errors={{}}
        />

        <br/>
        <Typography variant="h6">Contatos</Typography>
        <ContactsForm onChange={onChange} contacts={record.get('contacts')} />
      </CardContent>
    </Card>
  );
}