import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  h1 {
    color: #1B2430;
    font-size: 1.8rem;
    margin-top: 35px;
  }

  h4 {
    color: #898886;
    font-size: 0.8rem;
    margin-top: -20px;
    font-weight: 600;
  }
`;
