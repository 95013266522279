const contains = {
  id: 'like',
  name: 'contém'
};

const notContains = {
  id: 'not_like',
  name: 'não contém'
};

const equal = {
  id: 'equal_to',
  name: 'igual a'
};

const different = {
  id: 'different_to',
  name: 'diferente de'
};

const lessThan = {
  id: 'less_or_equal_to',
  name: 'menor que'
};

const greaterThan = {
  id: 'greater_or_equal_to',
  name: 'maior que'
};

const between = {
  id: 'between',
  name: 'entre'
};

const isBlank = {
  id: 'blank',
  name: 'em branco'
};

const isPresent = {
  id: 'no_blank',
  name: 'algum valor'
};

const optionsNumeric = [
  equal,
  different,
  lessThan,
  greaterThan,
  between,
  isBlank,
  isPresent
];

const optionsConditions = [
  equal,
  different,
  isBlank,
  isPresent
]

export default () => ({
  async: [equal, different, isPresent, isBlank],
  enum:  [equal, different, isPresent, isBlank],
  integer: optionsNumeric,
  float: optionsNumeric,
  numeric: optionsNumeric,
  date: optionsNumeric,
  datetime: optionsNumeric,
  time: optionsNumeric,
  currency: optionsNumeric,
  boolean: [equal],
  string: [
    contains,
    notContains,
    equal,
    different,
    isBlank,
    isPresent
  ],
  object: optionsConditions,
  options: optionsConditions,
});
