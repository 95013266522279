import React, { useCallback } from 'react';

import { invoicesRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import invoiceSchema from '~/records/schema/invoiceSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/invoices';
import invoiceStatusEnum from '~/enums/invoiceStatusEnum';

import Chip from '~/components/Chip';
import Button from '~/components/Button';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      let requestParams = 'include=construction-expenses-supplier';
      if(params){
        requestParams = `${params}&${requestParams}`
      }
      dispatch( fetchRecords(requestParams, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Notas fiscais' }]}
      permissionKeyCode="Invocie"
      routes={{
        newPath:  ()  =>  invoicesRoute.new.build(),
        showPath: (id) => invoicesRoute.show.build({id}),
        editPath: (id) => invoicesRoute.edit.build({id})
      }}
      navProps={{
        rightComponent: <div style={{marginRight: 10}}>
          <Button href={ invoicesRoute.newXml.build() }>
            Importar XML
          </Button>
        </div>
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: invoiceSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Product',
        filterProps: {
          columns: invoiceSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'construction',
            formatterComponent: (_, row) => {
              return <div>{row.construction?.name}</div>
            }
          },
          {
            name: 'supplier',
            formatterComponent: (_, row) => (
              <div>{row.supplier?.name}</div>
            )
          },
          {
            name: 'status',
            formatterComponent: (_, row) => (
              <div>
                <Chip
                  color={row.status === 'pendent' ? 'orange' : 'green' }
                  variant="outlined"
                  size="small"
                  label={invoiceStatusEnum().objects()[row.status]} />
              </div>
            )
          }
        ],
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.invoices.listIsLoading,
  rows: state.invoices.data,
  total: state.invoices.totalData,
});

export default connect(mapStateToProps)(List);