import React from 'react';
import { Switch } from 'react-router-dom';

import { fieldsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';

export default function FieldsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={fieldsRoute.edit.path}
        component={List}
        permissionKeyCode="Field"
        permission="edit"
      />
      <RouteWrapper
        exact
        isPrivate
        path={fieldsRoute.new.path}
        component={List}
        permissionKeyCode="Field"
        permission="add"
      />
      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={fieldsRoute.list.path}
        component={List}
        permissionKeyCode="Field"
        permission="see"
      />
    </Switch>
  );
}