import React, { useCallback } from 'react';

import { suppliersRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import supplierSchema from '~/records/schema/supplierSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/suppliers';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      let requestParams = 'include=segment';
      if(params){
        requestParams = `${params}&${requestParams}`
      }
      dispatch( fetchRecords(requestParams, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Fornecedores' }]}
      permissionKeyCode="Supplier"
      routes={{
        newPath:  ()  =>  suppliersRoute.new.build(),
        showPath: (id) => suppliersRoute.show.build({id}),
        editPath: (id) => suppliersRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: supplierSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Supplier',
        filterProps: {
          columns: supplierSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'segment',
            formatterComponent: (_, row) => (
              <div>{row?.segment?.name}</div>
            )
          }
        ],
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.suppliers.listIsLoading,
  rows: state.suppliers.data,
  total: state.suppliers.totalData,
});

export default connect(mapStateToProps)(List);