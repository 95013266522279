const styles =  {
  formControlRoot: {
    margin: '10px 0px 10px 0px',
  },
  formControlDisabled: {
    cursor: 'not-allowed',
  },
  inputRoot: {
    fontSize:   '1rem',
  },
  inputWithoutLabel: {
    marginTop: '0 !important',
  },
  inputElement: {
    padding: '6px 0 8px',
    marginTop: '2px',
    height: 'auto', // Isso é necessário para os input de select múltiplo
  },
  labelRoot: {
    color:      '#505050',
    fontWeight: '500',
    fontSize:   '1rem',
  },
  labelFocused: {
    '&.focused': {
      color: '#505050'
    }
  },
  labelShrink: {
    transform: 'translate(0, 1.5px) scale(0.8)',
  },
  labelFormControlWYSIWYG: {
    position: 'relative',
    marginBottom: 8
  },
  labelDisabled:{
    fontWeight: 'normal',
  },
  inputDisabled:{
    color:'black',
  },
  dropzone:{
    backgroundColor: 'red'
  }
};

export default styles;
