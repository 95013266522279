import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import { customRecordsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';

import RecordList from './List';
import RecordShow from './Show';
import RecordEdit from './Edit';
import RecordNew  from './New';

import MetadataProvider from '~/hooks/useMetadata';
import Spinner from '~/components/Spinner';

const HandleProvider = ({match, Component, ...props}) => {
  const { sys_key } = match.params;

  return (
    <MetadataProvider.Consumer>
      { ({metadata}) => {
        const metaSettings = metadata.get( sys_key )
        if(!metaSettings){ return <Spinner /> }

        return <Component match={match} sys_key={sys_key} metaSettings={metaSettings} {...props}  />
      } }
    </MetadataProvider.Consumer>
  )
}


const List = (props) => {
  return <HandleProvider Component={RecordList} {...props} />
}

const Show = (props) => {
  return <HandleProvider Component={RecordShow} {...props} />
}

const Edit = (props) => {
  return <HandleProvider Component={RecordEdit} {...props} />
}

const New = (props) => {
  return <HandleProvider Component={RecordNew} {...props} />
  }

export default function CrudPageRoute(props) {
  const sysKey = props?.match?.params?.sys_key;
  const permissionKeyCode = useMemo(() => `CustomRecord-${sysKey}` , [sysKey])

  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={customRecordsRoute.new.path}
        permissionKeyCode={permissionKeyCode}
        permission="add"
        component={New}
        {...props}
      />
      <RouteWrapper
        exact
        isPrivate
        path={customRecordsRoute.clone.path}
        permissionKeyCode={permissionKeyCode}
        permission="add"
        component={New}
        {...props}
      />
      <RouteWrapper
        exact
        isPrivate
        path={customRecordsRoute.show.path}
        permissionKeyCode={permissionKeyCode}
        permission="see"
        component={Show}
        {...props}
      />
      <RouteWrapper
        exact
        isPrivate
        path={customRecordsRoute.edit.path}
        permissionKeyCode={permissionKeyCode}
        permission="edit"
        component={Edit}
        {...props}
      />

      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={customRecordsRoute.list.path}
        permissionKeyCode={permissionKeyCode}
        permission="see"
        component={List}
        {...props}
      />
    </Switch>
  );
}
