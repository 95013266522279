import React from 'react';
import styled from 'styled-components';
import mediumLogo from '~/assets/images/medium-logo.png';

const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #4F3A8B;
`;

const AnimatedLoading = styled.div`
  background-image: url(${mediumLogo});
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  height: 100px;
  width: 140px;

  transform: scale(1);
  animation: pulse 1300ms infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.85);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.85);
    }
  }
`;

export default function AnimatedLoadingFull() {
  return (
    <ContainerLoading>
      <AnimatedLoading />
    </ContainerLoading>
  )
}
