import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { setCondition } from '../../store/actions';
import ToggleCondition from '../ToggleCondition';
import useStyles from './styles';

function Title() {
  const classes = useStyles();
  const condition = useSelector(state => state.get('condition'));
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Grid container alignItems="center" justify="space-between">
        <Grid item component="label">
          <Typography variant="subtitle2">
            FILTROS
          </Typography>
        </Grid>

        <Grid item>
          <ToggleCondition
            condition={condition}
            setCondition={c => dispatch(setCondition(c))}
          />
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.detail}>
        {condition === 'OR' 
          ? 'Qualquer uma das condições deve ser atendida'
          : 'Todas as condições devem sem atendidas'
        }
      </Typography>

      <Divider />
    </Fragment>
  );
}

export default React.memo(Title);
