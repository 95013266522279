import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '~/components/Button';
import { useDispatch, useStore } from 'react-redux';
import { toast } from 'react-toastify';
import json from 'json-url';
import PropTypes from 'prop-types';

import { init } from './store/actions';
import Title from './components/Title';
import Groups from './components/Groups';
import filterValidGroups from './utils/filterValidGroups';
import useStyles from './styles';
import FILTER_TYPES from './utils/types';

const jsonPack = json('pack');

function Filter({
  open,
  anchor,
  onCancel,
  onFilter,
  columns,
  filter,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  const defaultFilter = filter || Filter.defaultProps.filter;

  useEffect(() => {
    if (typeof defaultFilter === 'string') {

      if (defaultFilter.includes('{')) {
        try {
          const json = JSON.parse(defaultFilter);
          dispatch(init(json, columns));
          return;
        } catch(e) {
          console.error(e);
        }
      }

      jsonPack
        .decompress(defaultFilter)
        .then(json => dispatch(init(json, columns)));
    } else {
      dispatch(init(defaultFilter, columns));
    }
  }, [open]);

  function applyFilter() {
    const state = store.getState();
    const condition = state.get('condition');
    const groups = state.get('groups');
    let count = 0;

    const filter = {
      condition,
      groups: filterValidGroups(groups),
    };

    if (filter.groups.length === 0)
      return onFilter(null, {}, 0);

      filter.groups.forEach(group =>
        count = count + group.filters.length
      );

    jsonPack.compress(filter).then(compressed => {
      if (compressed.length > 2000) {
        return toast.error(
          'Tamanho máximo de filtros foi atingido, por favor remova alguns filtros!'
        );
      }

      onFilter(compressed, filter, count);
    });
  }

  function onKeyPress(event) {
    if (event.key === 'Enter')
      applyFilter();
  }

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onCancel}
    >
      <div
        className={classes.root}
        role="presentation"
        onKeyPress={onKeyPress}
      >
        <div className={classes.row}>
          <Typography variant="h6">
            Editar Filtros
          </Typography>

          <IconButton
            onClick={onCancel}
          >
            <CloseIcon className={classes.iconColor} />
          </IconButton>
        </div>

        <Divider />

        <div className={classes.content}>
          <Title />
          <Groups />
        </div>
      </div>

      <footer className={classes.footer}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              onClick={onCancel}
              color="success"
              variant="outlined"
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={applyFilter} color="success">
              FILTRAR
            </Button>
          </Grid>
        </Grid>
      </footer>
    </Drawer>
  );
}

Filter.defaultProps = {
  anchor: 'right',
  filter: {
    condition: 'AND',
    groups: [
      {
        condition: 'AND',
        filters: [
          {
            col: '',
            ope: '',
            val: '',
          },
        ],
      },
    ],
  },
};

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  anchor: PropTypes.oneOf(['left', 'top', 'bottom', 'right']),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(FILTER_TYPES).isRequired,
      lookup: PropTypes.object,
      precision: PropTypes.number,
      currencyCode: PropTypes.string,
      asyncFetch: PropTypes.func,
      asyncShow: PropTypes.func,
    })
  ).isRequired,
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      condition: PropTypes.oneOf(['AND', 'OR']).isRequired,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          condition: PropTypes.oneOf(['AND', 'OR']).isRequired,
          filters: PropTypes.arrayOf(
            PropTypes.shape({
              col: PropTypes.string.isRequired,
              ope: PropTypes.string.isRequired,
              val: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.shape({
                  id:   PropTypes.number,
                  name: PropTypes.string,
                })
              ]).isRequired,
            })
          ),
        })
      ),
    }),
  ]),
};

export default Filter;
