import RecordBase from './Base';
import metadatumSchema from './schema/metadatumSchema';
import MetaFieldRecord from './MetaFieldRecord'

export default class MetadatumRecord extends RecordBase {
  static defaultAttributes = metadatumSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return metadatumSchema.attributeNames }

  static hasMany = { meta_fields: MetaFieldRecord };

  toParams(extraParams = {}) {
    const {
      name,
      competence,
      sys_key,
      record_plural_name,
      record_singular_name,
      record_item_plural_name,
      record_item_singular_name,
      menu,
      define_fields_in_record,
      active,
      icon_lib_record,
      icon_lib_record_item,
    } = this.toObject();

    return {
      data:{
        attributes:{
          name,
          competence,
          sys_key,
          record_plural_name,
          record_singular_name,
          record_item_plural_name,
          record_item_singular_name,
          menu,
          define_fields_in_record,
          active,
          icon_lib_record,
          icon_lib_record_item,
          ...extraParams,
        }
      }
    };
  }
}
