import React, { useCallback } from 'react';

import {
  Container,
  PercentText,
  Percent,
  PercentageCompleted
} from './styles';

const percentContainerWidth = 180;

function StatusPercent({ percentage }) {
  const calculateWidth = useCallback(
    () => {
      if (percentage === 0) return 0;

      const calculatedWidth = Math.floor((percentage * percentContainerWidth) / 100);

      return calculatedWidth;
    },
    [percentage]
  );

  const calculatePaddingLeft = useCallback(
    () => {
      if (percentage < 7) return 0;

      const calculatedWidth = calculateWidth();
      const halfWidthPercentText = 7;
      const paddingLeft = calculatedWidth - halfWidthPercentText;
      const maxLeftPadding = percentContainerWidth - 20;

      return paddingLeft > maxLeftPadding ? maxLeftPadding : paddingLeft;
    },
    [percentage]
  );

  return (
    <Container>
      <PercentText paddingLeft={calculatePaddingLeft()}>
        {percentage}%
      </PercentText>
      <Percent>
        <PercentageCompleted width={calculateWidth()} />
      </Percent>
    </Container>
  );
}

export default StatusPercent;
