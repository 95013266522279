import styled from 'styled-components';

export const ItemContainer = styled.div`
  width: 100%;
  margin-top: 17px;
  
  div {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    padding: 10px 15px 10px 5px;
    cursor: pointer;
    border-bottom: solid 1px #ddd;

    &:last-child {
      border-bottom: none;
    }

    svg {
      font-size: 21px;
    }

    span {
      margin-left: 10px;
    }

    &:hover {
      background-color: #f3f3f3;
    }
  }
`;
