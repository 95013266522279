import React from 'react';

import MuiTooltip from '@material-ui/core/Tooltip';

import useStyles from './styles';

function Tooltip(props) {
  const classes = useStyles();

  return (
    <MuiTooltip classes={classes} {...props} />
  );
}

Tooltip.propTypes = {
  ...MuiTooltip.propTypes,
};

Tooltip.defaultProps = {
  ...MuiTooltip.defaultProps,
  placement: 'top',
};

export default Tooltip;

//   {props.children}
// </MuiTooltip>
