import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  ({
  button: {
    marginTop: 16,
    marginRight: 30,
  },
  checkBox: {
    padding: 0,
    marginTop: 10,
    marginRight: 40,
  },
  footerDrawerClosed: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '66px',
    bottom: 0,
    right: 0,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '6px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    alignContent: 'center'
  }
}));

