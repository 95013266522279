import React, { useState, createContext, useContext, useCallback } from 'react';
import moment from 'moment';

const GlobalFiltersContext = createContext({
  startDate: '',
  setStartDate: () => {},
  endDate: '',
  setEndDate: () => {},
  construction: {
    id: '',
    name: ''
  },
  setConstruction: () => {},
});

const currentStartDate = moment().startOf('month').subtract(11, 'months').format('YYYY-MM-DD');
const currentEndDate   = moment().endOf('month').format('YYYY-MM-DD');

export const GlobalFiltersProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(currentStartDate);
  const [endDate, setEndDate] = useState(currentEndDate);
  const [construction, setConstruction] = useState(() => {
    const item = localStorage.getItem('@App:construction');

    if (item) {
      return JSON.parse(item);
    }

    return {
      id: '',
      name: 'Selecione uma construção'
    };
  });

  const handleSetConstruction = useCallback((newConstruction) => {
    setConstruction(newConstruction);
    localStorage.setItem('@App:construction', JSON.stringify(newConstruction));
  }, [setConstruction]);

  return (
    <GlobalFiltersContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        construction,
        setConstruction: handleSetConstruction
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};

export const useGlobalFilters = () => {
  const context = useContext(GlobalFiltersContext);

  if (!context) {
    throw new Error('useGlobalFilters must be used within a GlobalFiltersProvider');
  }

  return context;
};

export default GlobalFiltersContext;
