import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import NpsFormRecord from '~/records/NpsFormRecord';
import { constructionsSiwthParamsAsyncFetch } from '~/services/selectFetch/constructionsFetch';
import { peopleFetchWithParams } from '~/services/selectFetch/peopleFetch'

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputDate from '~/components/Input/InputDate';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import InputCheckBox from '~/components/Input/InputCheckBox';

const attrName = (attr) => NpsFormRecord.hummanAttributeName(attr);

export default function FormFields({record, onChange}) {
  const handleChangeConstruction = useCallback(
    ({construction}) => {
      return onChange({
        construction,
        customer: construction?.customer,
        engineer: construction?.engineer
      })
    },
    [onChange],
  )
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col sm={5}>
            <InputControl
              required
              value={record.get('construction')}
              name={'construction'}
              label={attrName('construction')}
              inputComponent={InputSelectAsync}
              onChange={handleChangeConstruction}
              InputProps={{
                handleFetch: constructionsSiwthParamsAsyncFetch({include: 'construction-customer-engineer'})
              }}
            />
          </Col>
          <Col sm={5}>
            <InputControl
              disabled
              label={attrName('customer')}
              value={record.get('construction')?.get('customer')?.get('name') || ''}
              inputComponent={Input}
              name={'customer'}
            />
          </Col>
          <Col sm={2}>
            <InputControl
              label={attrName('is_active')}
              value={record.get('is_active')}
              inputComponent={InputCheckBox}
              name={'is_active'}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <GridContainer>
          <Col sm={6}>
            <InputControl
              required
              value={record.get('engineer')}
              name={'engineer'}
              label={attrName('engineer')}
              inputComponent={InputSelectAsync}
              onChange={onChange}
              InputProps={{
                handleFetch: peopleFetchWithParams({engineer: true}),
                createEnabled: false,
              }}
            />
          </Col>
          <Col sm={6}>
            <InputControl
              required
              name="start_date"
              label={attrName('start_date')}
              value={record.get('start_date')}
              inputComponent={InputDate}
              onChange={onChange}
            />
          </Col>
        </GridContainer>
      </CardContent>
    </Card>
  );
}