import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function GridContainer({ children, ...props }) {
  return(
    <Grid container spacing={1} {...props}>
      {children}
    </Grid>
  );
}
