import React, { useCallback } from 'react';

import { productsRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import productSchema from '~/records/schema/productSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/products';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {

      let requestParams = 'include=account_plan';
      if(params){
        requestParams = `${params}&${requestParams}`
      }

      dispatch( fetchRecords(requestParams, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Produtos' }]}
      permissionKeyCode="Product"
      routes={{
        newPath:  ()  =>  productsRoute.new.build(),
        showPath: (id) => productsRoute.show.build({id}),
        editPath: (id) => productsRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: productSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Product',
        columnsOverride: [
          {
            name: 'account_plan',
            formatterComponent: (_, row) => {
              const acPlan = row.account_plan;
              if( !acPlan ) return ''
              return <div>{`${acPlan.full_code} - ${acPlan.name}`}</div>
            }
          }
        ],
        filterProps: {
          columns: productSchema.filterColumns
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.products.listIsLoading,
  rows: state.products.data,
  total: state.products.totalData,
});

export default connect(mapStateToProps)(List);