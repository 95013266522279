import RecordBase from './Base';
import productSchema from './schema/productSchema';
import AccountPlanRecord from './AccountPlanRecord';

export default class ProductRecord extends RecordBase {
  static defaultAttributes = productSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    return productSchema.attributeNames;
  }

  static belongsTo = {
    account_plan: AccountPlanRecord
  };

  get accountPlanFormattedObject(){
    if(!this.get('account_plan')) return null
    const acPlan = this.get('account_plan')
    const code = acPlan.get('full_code')

    return {
      id: acPlan.get('id'),
      name: `${code ? `${code} - ` : ''}${acPlan.get('name')}`
    }
  }

  toParams() {
    const { name, ncm, ean, base_value } = this.toObject();

    return {
      data: {
        attributes: {
          name,
          ncm,
          ean,
          base_value,
          account_plan_id: this.get('account_plan')?.get('id') || null,
        }
      }
    };
  }
}
