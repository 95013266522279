import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  columnRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  columnFieldRoot: {
    width: '100%',
  },
  iconRemove: {
    color: theme.palette.grey[500],
  },
  lastCol: {
    marginRight: 15,
    flex: 1,
  },
}));
