import React from 'react';
import { Switch } from 'react-router-dom';

import { constructionsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';
import Show from './Show';
import Edit from './Edit';
import New  from './New';

export default function ConstructionsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={constructionsRoute.new.path}
        component={New}
        permissionKeyCode="Construction"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={constructionsRoute.clone.path}
        component={New}
        permissionKeyCode="Construction"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={constructionsRoute.show.path}
        component={Show}
        permissionKeyCode="Construction"
        permission="see"
      />
      <RouteWrapper
        exact
        isPrivate
        path={constructionsRoute.edit.path}
        component={Edit}
        permissionKeyCode="Construction"
        permission="edit"
      />

      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={constructionsRoute.list.path}
        component={List}
        permissionKeyCode="Construction"
        permission="see"
      />
    </Switch>
  );
}
