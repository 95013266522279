import React, { memo } from 'react';

import Spinner from '../Spinner';

import { Container } from './styles';

function FullScreenLoading(props) {
  return (
    <Container open={true} onClick={() => {}}>
      <Spinner
        fillColor="#1B2430"
        activeBorderWidth={4.5}
        loadingTextStyle={{
          fontWeight: '600',
          color: '#4F3A8B',
          fontSize: '1.3rem'
        }}
        {...props}
      />
    </Container>
  );
}

export default memo(FullScreenLoading);
