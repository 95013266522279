import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  section > div {
    order: 2;
  }

  section .hidden-print > div {
    &:first-child {
      margin-top: 10px;
    }
  }

  section > div {
    position: relative;
  }

  @media print {
    .MuiPaper-root {
      box-shadow: none;
    }
    .MuiTable-root,
    .TableContainer-root-987 {
      border-radius: 5px;
      border: 1px solid lightgray;
    }
  }
`;
export const Header = styled.div`
  order: 1;
`;
export const Footer = styled.div`
  order: 3;
`;

export const ButtonContent = styled.div`
  button {
    margin-left: 15px;
  }
`;

export const PrintFooterInfo = styled.div`
  @media print {
    span, p {
      font-size: 14pt !important;
    }
  }
`;


export const InputContent = styled.div`
  position: absolute;
  margin-top: -10px;
  left: 0px;

  .MuiFormControl-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    height: 35px;
    border-radius: 8px;
    padding: 0 15px;
  }

  .MuiInputBase-input {
    margin-top: 0px;
  }

  span {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
    font-size: 0.875rem;
  }

  & .MuiFormControl-fullWidth {
    background-color: #4F3A8B;
    border-radius: 10px;

    input {
      padding-left: 11px;
      color: #fff;
    }

    svg {
      color: #fff;
    }

    & .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
  }
  
  & .MuiInput-underline::before {
    display: none;
  }
`;
