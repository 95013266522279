'use strict';

const sortArray = (array, direction, key=null) => {
  const func = direction === 'desc' ? sortDesc : sortAsc;
  return [...array].sort( (a, b) => {
    const valA = key ? getValue(a, key) : a;
    const valB = key ? getValue(b, key) : b;
    return func(valA, valB);
  } );
};

function getValue(obj, key){
  if(typeof key === 'function'){
    return key(obj)
  }
  return obj[key]
}

function sortDesc(a, b){
  if (a < b) return 1;
  if (b < a) return -1;

  return 0;
}

function sortAsc(a, b){
  if (a > b) return 1;
  if (b > a) return -1;

  return 0;
}
export default sortArray;
