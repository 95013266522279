import React, {useEffect, useState, useCallback, useRef} from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import MUiTableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import GetApp from '@material-ui/icons/GetApp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button        from '~/components/Button';

import Spinner from '~/components/Spinner';
import Tooltip from '~/components/Tooltip';
import IconButton from '~/components/IconButton';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import CustomerInvoicesShow from '~/containers/CustomerInvoicesShow';

import Request from '~/services/request';
import formatToMoney from '~/utils/formatToMoney';
import truncate from '~/utils/truncate';
import sortArray from '~/utils/sortArray';
import isPresent from '~/utils/isPresent';
import { customersBankSlipsApi } from '~/routes/api';

import { EmptyPendencies } from './styles';

const TableCell = withStyles({
  root: {
    fontSize: '0.7rem',
  }
})(MUiTableCell);

const ButtonLink = withStyles({
  root: {
    padding: 0,
    fontSize: '0.7rem',
  }
})(Button);

function handleData(respData){
  return respData.map( function(slip) {
    let invoice = slip.invoice;
    const pdfs = invoice.attachments.filter( e => e.filename.includes('.pdf') )

    const firstPdf = sortArray(pdfs, 'asc',  (e) => new Date(e.created_at) )[0]
    if(firstPdf){
      invoice.pdf_url = firstPdf.url
    }

    return {
      ...slip,
      invoice
    }
  } )
}

function Pendencies({ constructionID }) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showID, setShowID] = useState(null);
  const confirmationDialogRef = useRef(null);

  const openShow = useCallback(
    (id) => {
      setShowID(id)
    },
    [setShowID],
  )

  const closeShow = useCallback(
    () => {
      setShowID(null)
    },
    [setShowID],
  );


  const fetchData = useCallback(
    async () => {
      setIsLoading(true)
      const resp = await Request.get(`/api/v1/customers/dashboards/${constructionID}/pendencies`);
      setData( handleData(resp.data.data)  );
      setIsLoading(false);
  }, [setData, setIsLoading, constructionID])

  useEffect(() => {
    fetchData();
  }, [constructionID])

  const handleClick = useCallback(
    (bankSlip) => () => {
      Request.put(`/api/v1/customers/bank_slips/${bankSlip.id}/download`)
  }, [data])

  const setPaid = useCallback(
    (bankSlip) => () => {
      const { current: dialog } = confirmationDialogRef;

      dialog.open();
      dialog.onConfirm(async () => {
        try {
          setIsLoading(true);
          await Request.put(customersBankSlipsApi.paid.build({construction_id: constructionID, bank_slip_id: bankSlip.id}), {})
          fetchData()
        } catch(err) {}
      });
  }, [data, confirmationDialogRef])

  return (
    <Paper style={{padding: 16}}>
      <CustomerInvoicesShow
        id={showID}
        constructionID={constructionID}
        onClose={closeShow}
        isOpen={ isPresent(showID) }
      />
      <Typography variant="h6">Pendências de pagamentos</Typography>
      { isLoading && <Spinner /> }
      { !isLoading && <>
        {data.length > 0 &&
          <TableContainer>
            <Table size="small" aria-label="Pendências">
              <TableHead>
                <TableRow>
                  <TableCell>NF</TableCell>
                  <TableCell>Fornecedor</TableCell>
                  <TableCell component="th" align="right">Vencimento</TableCell>
                  <TableCell component="th" align="right">Valor</TableCell>
                  <TableCell component="th" align="right">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                { data.map( pendency => (
                  <TableRow key={pendency.id}>
                    <TableCell>
                      { isPresent(pendency.invoice.pdf_url) &&
                        <ButtonLink variant="text" size="small" href={ pendency.invoice.pdf_url } target="_blank" component="a">
                          <span>{pendency.invoice.nf_number}</span>
                        </ButtonLink>
                      }
                      { !isPresent(pendency.invoice.pdf_url) &&
                        <ButtonLink variant="text" size="small" onClick={ () => openShow(pendency.invoice.id)}>
                          <span>{pendency.invoice.nf_number}</span>
                        </ButtonLink>
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip title={pendency.invoice?.supplier?.name}>
                        <span>{truncate(pendency.invoice?.supplier?.name, 18)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">{moment(pendency.due_date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="right">{`R$ ${formatToMoney(pendency.invoice?.total_value)}`}</TableCell>
                    <TableCell align="right">
                      <Box display="flex" alignItems="flex-end" flexDirection="inherit">
                        <IconButton onClick={setPaid(pendency)} >
                          <Tooltip title="Marcar como pago">
                            <CheckCircleOutlineIcon htmlColor="#55b103" />
                          </Tooltip>
                        </IconButton>
                        { pendency.attachment?.url &&
                          <IconButton
                            target="_blank"
                            href={pendency.attachment?.url}
                            onClick={handleClick(pendency)}
                          >
                            <Tooltip title="Baixar boleto">
                              <GetApp htmlColor="#35285B" />
                            </Tooltip>
                          </IconButton>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <ConfirmationDialog
          ref={confirmationDialogRef}
          title="Marcar boleto como pago"
          description="Tem certeza que deseja marcar o boleto como pago??"
        />
        { data.length === 0 && (
          <EmptyPendencies>
            <Typography variant="h6">Relaxe! Você está com as contas em dia!</Typography>
          </EmptyPendencies>
        )}
      </> }
    </Paper>
  )
}

export default Pendencies;
