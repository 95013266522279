import React from 'react';
import PropTypes from 'prop-types';

import FILTER_TYPES from '../../../../utils/types';

import InputString from './String';
import InputNumeric from './Numeric';
import InputSelect from './Select';
import InputBoolean from './Boolean';
import InputDate from './Date';
import InputAsync from './InputAsync';
import SelectEnum from './SelectEnum';

const INPUT = {
  OPTIONS: 'options',
  STRING: 'string',
  NUMERIC: 'numeric',
  INTEGER: 'integer',
  FLOAT:  'float',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATETIME: 'datetime',
  CURRENCY: 'currency',
  ENUM: 'enum',
  ASYNC:    'async'
}

function Input(props) {
  const { column = {}, precision = 0 } = props;
  let filterType = column.type;

  if (column.lookup && Object.keys(column.lookup).length) {
    filterType = INPUT.OPTIONS;
  }

  switch(filterType) {
    case INPUT.STRING:
      return <InputString {...props} />;
    case INPUT.NUMERIC:
      return <InputNumeric {...props} precision={precision || 0}/>;
    case INPUT.INTEGER:
      return <InputNumeric {...props} precision={0} />;
    case INPUT.FLOAT:
      return <InputNumeric {...props} precision={precision || 2} />;
    case INPUT.ASYNC:
      return <InputAsync {...props} asyncFetch={column.asyncFetch} asyncShow={column.asyncShow} />;
    case INPUT.OPTIONS:
      return <InputSelect {...props} />;
    case INPUT.BOOLEAN:
      return <InputBoolean {...props} />;
    case INPUT.DATE:
    case INPUT.DATETIME:
      return <InputDate {...props} />;
    case INPUT.ENUM:
      return <SelectEnum {...props} />;
    case INPUT.CURRENCY:
      return (
        <InputNumeric {...props} precision={precision || 2} startAdornment={column.currencyCode || 'R$'} />
      );
    default:
      return (
        <InputString {...props} inputProps={{ disabled: true, }} />
      );
  }
}

Input.defaultProps = {
  type: null,
};

Input.propTypes = {
  filter: PropTypes.shape({
    col: PropTypes.string.isRequired,
    ope: PropTypes.string.isRequired,
    val: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        id:   PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    ]).isRequired,
  }).isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFilter: PropTypes.number.isRequired,
  column:  PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(FILTER_TYPES).isRequired,
    lookup: PropTypes.object,
    precision: PropTypes.number,
    currencyCode: PropTypes.string,
    asyncFetch: PropTypes.func,
    asyncShow: PropTypes.func,
  }),
};

export default React.memo(Input);
