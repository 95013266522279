import React from 'react';
import { Switch } from 'react-router-dom';

import { incomingEmailsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';

export default function IncomingEmailsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={incomingEmailsRoute.list.path}
        component={List}
        permissionKeyCode="IncomingEmail"
        permission="see"
      />
    </Switch>
  );
}
