import React, {
  useMemo,
  useCallback,
  useEffect ,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { OrderedMap } from "immutable";

import Box from '@material-ui/core/Box';
import StorageIcon from '@material-ui/icons/Storage';
import BackupIcon from '@material-ui/icons/Backup';

import Request from '~/services/request';
import { fileUploadsApi } from '~/routes/api';
import ArchiveRecord from '~/records/ArchiveRecord';

import Button from '~/components/Button';
import GalleryAttachments from '~/containers/GalleryAttachments';

import DrawerEditFile from '~/containers/DrawerEditFile';

import FileListPreview from './FileListPreview';
import {
  Container,
  DropContainer,
  Input,
  Text,
  Title,
} from './styles';

// Esta variavel é necessário para conseguir atualizar o
// progresso dos arquivos quando estão em upload.
let filesScope = OrderedMap();

function notDestroy(v){
  return !v.get('_destroy')
}

function mapValue([, v]){ return v }

function DropzoneFile({onChange, onDelete, persistedFiles, title, ...props}, inputRef) {
  const [files, setFiles] = useState( OrderedMap() );
  const [galleryIsOpen, setGalleryIsOpen] = useState(false)
  const [editCurrent, setEditCurrent] = React.useState(null);

  const setNewFiles = useCallback(
    (newValue) => {
      filesScope = newValue;
      setFiles(newValue)
    },
    [setFiles],
  )

  useEffect(() => {
    filesScope = OrderedMap();
  }, [])

  useImperativeHandle(inputRef, () => ({
    getFiles() {
      return files
    }
  }));

  const onSelectGalleryArchives = useCallback(
    (archives) => {
      const newFiles = persistedFiles.merge(archives);
      onChange( newFiles )
      setGalleryIsOpen(false)
    },
    [persistedFiles, setGalleryIsOpen, onChange],
  )

  const updateFile = useCallback(
    (token, data) => {
      let current = filesScope.get(token)
      current = current.updateAttributes(data)

      setNewFiles( filesScope.set(token, current) );
    },
    [files, setNewFiles],
  )

  const processUpload = useCallback(
    async ([token, uploadedFile]) => {
      const data = new FormData();

      data.append('file', uploadedFile.get('file'), uploadedFile.get('filename') );

      try {
        const response = await Request.post(fileUploadsApi.upload.build(), data, {
          onUploadProgress: (e) => {
            const progress = (e.loaded * 100) / e.total;
            const formateProgress = parseInt(Math.round(progress));

            updateFile(token, {
              progress: formateProgress
            });
          }
        })

        if (response.data) {
          const data = response.data.archive;
          return uploadedFile.updateAttributes( data )
        }
      } catch(err) {
        const errors = uploadedFile.errors.clone();
        errors.clear();
        errors.add('base', err.message)

        return uploadedFile.set('errors', errors);
      }
    },
    [updateFile]
  )

  const handleDelete = useCallback(
    async (token) => {
      let archive = persistedFiles.get(token)
      let newPersistedFiles = persistedFiles;
      try {

        if(archive.isNew){
          await Request.del( fileUploadsApi.delete.build({ uuid: archive.get('uuid') }) );
          newPersistedFiles = persistedFiles.delete(token)
        }else{
          archive = archive.set('_destroy', true)
          newPersistedFiles = persistedFiles.set(token, archive)
        }

        onChange( newPersistedFiles )

        if(typeof onDelete === 'function'){
          onDelete( archive )
        }
      } catch(err) {
        console.error(err)
        alert('Não foi possível deletar o arquivo. Tente novamente mais tarde!');
      }
    },
    [persistedFiles, onDelete, onChange]
  )

  const handleUpload = useCallback(
    (dropedFiles) => {
      const uploadedFiles = dropedFiles.map(file =>
        ArchiveRecord.buildFromFile(file)
      );

      const newFiles = OrderedMap( uploadedFiles.map(e => [e.idOrToken, e]) )
      setNewFiles( files.merge(newFiles) );

      Promise.all(
        newFiles.toArray().map(processUpload)
      ).then( respFiles => {
        let newFiles = files;
        let newPersistedFiles = persistedFiles;

        respFiles.forEach( file => {
          if( file.isValid() ){
            newFiles = newFiles.delete(file.idOrToken)
            newPersistedFiles = newPersistedFiles.set( file.idOrToken, file )
          }
        } )

        setNewFiles( newFiles )
        onChange( newPersistedFiles )
      } )
    },
    [files, persistedFiles, setNewFiles, processUpload, onChange]
  )

  const onEdit = useCallback(
    (archive) => {
      setEditCurrent( archive )
    },
    [setEditCurrent],
  )

  const handleUpdate = useCallback(
    (changed) => {
      const newFiles = persistedFiles.set(changed.idOrToken, changed);
      onChange( newFiles )
      setEditCurrent( null )
    },
    [persistedFiles, onChange, setEditCurrent],
  )

  const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
    onDropAccepted: handleUpload,
    ...props,
  });

  const renderDropContent = useMemo(() => {
    return function DropContent(){
      if (!isDragActive) {
        return (
          <>
            <Text>Solte seus arquivos aqui, ou clique para anexá-los</Text>
            <BackupIcon fontSize="large" />
          </>
        );
      }

      if (isDragReject) {
        return <Text color="error">Arquivo não suportado</Text>;
      }

      return <Text color="info">Solte seus arquivos aqui</Text>;
    }
  }, [isDragActive, isDragReject])

  return (
      <Container>
        <Box display="flex" p={1} justifyContent="space-between">
          <Box p={1}>
            { title && <Title variant="h6">{title}</Title> }
          </Box>
          <Box p={1} >
            <Button
              variant="text"
              type="button"
              onClick={()=>setGalleryIsOpen(true)}
              startIcon={<StorageIcon />}
            >
              Galeria
            </Button>
          </Box>
        </Box>
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <Input {...getInputProps()}/>
          {renderDropContent()}
        </DropContainer>
        { (files.size > 0 || persistedFiles.size > 0) && (
          <FileListPreview
            persistedFiles={persistedFiles.filter(notDestroy).sortBy( (v) => (v.id * -1)  ).toArray().map(mapValue)}
            files={files.toArray().map(mapValue) }
            onDelete={handleDelete}
            onEdit={onEdit}
          />
        )}
        <GalleryAttachments
          isOpen={galleryIsOpen}
          onClose={ () => setGalleryIsOpen(false) }
          onSelect={onSelectGalleryArchives}
        />
        <DrawerEditFile
          isOpen={ !!editCurrent }
          archive={editCurrent}
          onChange={ handleUpdate }
          onClose={ () => setEditCurrent(null) }
        />
      </Container>
  );
}

const InputDropzoneFile = forwardRef(DropzoneFile);

InputDropzoneFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  persistedFiles: PropTypes.instanceOf(OrderedMap).isRequired,
  title: PropTypes.node,
  onDelete: PropTypes.func,
}

InputDropzoneFile.displayName = 'InputDropzoneFile'

export default InputDropzoneFile;