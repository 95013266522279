import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { customRecordsRoute } from '~/routes'
import CustomRecordRecord from '~/records/CustomRecordRecord';
import { updateRecord, findRecord, clearState } from '~/store/modules/customRecords';

import CrudEdit from '~/containers/CrudEdit';

import FormFields from './components/FormFields';

export default function Edit({sys_key, metaSettings}) {
  const [record, setRecord] = useState(new CustomRecordRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.customRecords);
  const { id } = useParams();

  useEffect(() => {
    dispatch( findRecord(id, {sys_key}) );
    return () => dispatch( clearState() );
  }, [])

  useEffect(() => {
    if(current && id){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback(() => {
    const data = record.toParams();
    dispatch( updateRecord(id, data, {sys_key}) );
  }, [record, id]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  return (
    <CrudEdit
      key={`${sys_key}-${id}`}
      isLoading={isLoading || record.isNewRecord()}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: customRecordsRoute.list.build({sys_key}), text: metaSettings.get('record_plural_name') },
        { text: `Editando ${metaSettings.get('record_singular_name')}` },
      ]}
      footerActionsProps={{ isLoading, showSaveAndContinue: false }}
    >
      <FormFields
        metaSettings={metaSettings}
        record={record}
        onChange={handleChange}
      />
    </CrudEdit>
  );
}