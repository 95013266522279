/* eslint camelcase: "off" */
/* eslint no-extra-boolean-cast: "off" */

const defaultProps =  {
  id:              null,
  created_at:      null,
  queue:           3,
  duration:        6000,
  action:          false, // {label: 'Desfazer', callback: function(){}}
  actionComponent: false, // <Node onClick={...}/>
  title:           null,
  message:         '',
  list:            [],
  type:           'success', // info, success, warning, danger, primary, rose
};

export default class Notification {
  constructor(args={}) {
    if (Object.isFrozen(args)) { args = {...args}; }
    if (!Boolean(args['id'])) { args['id'] = new Date().getTime(); }
    if (!Boolean(args.created_at)) { args.created_at = new Date(); }

    this.props = {...defaultProps, ...args};
  }

  get id(){
    return this.get('id');
  }

  get = (attr) => ( this.props[attr] )
}