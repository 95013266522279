import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Filter from './Filter';

function FilterContainer(props) {
  return (
    <Provider store={store}>
      <Filter {...props} />
    </Provider>
  );
}

FilterContainer.defaultProps = Filter.defaultProps;
FilterContainer.propTypes = Filter.propTypes;

export default FilterContainer;
