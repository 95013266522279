import React, { useCallback } from 'react';

import { buildersRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import builderSchema from '~/records/schema/builderSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/builders';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch( fetchRecords(params, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Construtores' }]}
      permissionKeyCode="Builder"
      routes={{
        newPath:  ()  =>  buildersRoute.new.build(),
        showPath: (id) => buildersRoute.show.build({id}),
        editPath: (id) => buildersRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: builderSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Builder',
        filterProps: {
          columns: builderSchema.filterColumns
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.builders.listIsLoading,
  rows: state.builders.data,
  total: state.builders.totalData,
});

export default connect(mapStateToProps)(List);