import Request from '~/services/request';
import formatTableTreeData from '~/utils/formatTableTreeData';
import deserialize from '~/utils/deserialize';

// Essa function pode ser sobrescrita para cada tabela se desejar fazer
// a requisição diferente do padrão;
export const fetchDataRequest = async (apiPath, requestSource) => {
  return await Request.get(apiPath, {
    cancelToken: requestSource.token
  });
};

export const formatResponseColumns = async (response, tableTree, setRows, setParams) => {
  if (response.data) {
    const { data, meta } = response.data;
    let columnsData = [];

    if (tableTree.enable) {
      columnsData = formatTableTreeData(
        data,
        tableTree.formatTreeColumnData,
        { keys: [tableTree.pathToColumnData || "attributes.parent_id"] }
      );
    } else {
      columnsData = await deserialize(response.data);
    }

    setRows(columnsData);
    setParams({ total: meta.total });
  }
}

