import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  memo,
} from 'react';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';

function ErrorsComponent({ title, onClose, errors = [] }, errorsRef) {
  const [open, setOpen] = useState(true);

  useImperativeHandle(errorsRef, () => ({
    setOpen: (open) => setOpen(open)
  }), [setOpen]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }
    
    setOpen(false);
  }, [onClose]);

  useEffect(() => {
    setOpen(true);
  }, [errors]);

  if (!errors?.length) return <></>;

  return (
    <Collapse in={open}>
      <Alert severity="error" onClose={handleClose}>
        <AlertTitle>{title}</AlertTitle>
        <ul>
          {errors.map((error, idx) => (
            <li key={`${idx}-${error}`}>{error}</li>
          ))}
        </ul>
      </Alert>
    </Collapse>
  )
}

const Errors = forwardRef(ErrorsComponent);

Errors.defaultProps = {
  title: 'Ops, não foi possível salvar',
  errors: [],
}

Errors.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string),
}

export default memo(Errors);
