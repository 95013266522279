import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 5px 10px;

  :not(:last-child) {
    border-bottom: solid 1px #ccc;
  }


  strong {
    display: flex;
    flex: 1;
    padding-right: 10px;
  }

  & .MuiFormControl-root {
    width: 400px;
    margin-right: 10px;
  }

  button {
    width: 143px;
    height: 36px;
    text-transform: none;
    margin-left: 10px;

    p {
      padding-left: 8px;
    }
  }
`;
