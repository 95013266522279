import { Path as PathParser } from 'path-parser';
import QueryString from 'query-string';

import history from './history';

export default class Route extends PathParser {
  constructor(path = '/') {
    super(path);
  }

  addApiParam(param) {
    return `${this.path}/${param}`;
  }

  getPath() {
    const { location } = history;

    return `${this.path}${location.search}`;
  }

  getShowPath(id) {
    const { location } = history;

    return `${location.pathname}/${id}/show`;
  }

  getEditPath(id) {
    const { location } = history;

    return `${location.pathname}/${id}/edit`;
  }

  getDeletePath(id) {
    return `${this.path}/${id}`;
  }

  getParamsFromPath(path) {
    const pathArr = path.split('?');

    return pathArr[1] ?? '';
  }

  getCurrentUrlParams() {
    const { location } = history;

    return QueryString.parse(location.search);
  }

  removeInvalidParams(params) {
    const invalidValues = ['', undefined, null];

    Object.keys(params).forEach(param => {
      const paramValue = params[param];

      if (invalidValues.includes(paramValue)) {
        delete params[param];
      }
    });

    return params;
  }

  buildParams(params) {
    const stringifyParams = QueryString.stringify(params);

    return `?${stringifyParams}`;
  }

  setParams(params = {}, options = {}) {
    const { clearCurrentParams } = options;
    const currentUrlParams = this.getCurrentUrlParams();
    const newParams = clearCurrentParams
      ? params
      : Object.assign({}, currentUrlParams, params);
    const formatedParams = this.removeInvalidParams(newParams);

    this.queryParams = Object.keys(formatedParams);

    const stringifyParams = this.getParamsFromPath(
      this.buildParams(newParams)
    );

    if (stringifyParams) {
      history.push({ search: stringifyParams });
    }
  }
}
