import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import Input from '@material-ui/core/Input';
import CardContent from '@material-ui/core/CardContent';
import NPSIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import MUiSlider from '@material-ui/core/Slider';

import formatResponseError from '~/utils/formatResponseError';
import isBlank from '~/utils/isBlank';
import FormAnswerRecord from '~/records/FormAnswerRecord';
import deserialize from '~/utils/deserialize';
import Request from '~/services/request';

import Dialog from '~/components/Dialog';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSlider   from '~/components/Input/InputSlider';
import Button from '~/components/Button';
import Errors from '~/components/Errors';
import InputShow from '~/components/Input/InputShow';

const Slider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(MUiSlider);

const sliderProps = {
  SliderComponent: Slider,
  step: 5,
  min: 0,
  max: 100
}

function DialogComponent({ open, loading, onClick, children }) {
  return (
    <Dialog
      icon={<NPSIcon />}
      isOpen={open}
      showButtonClose={false}
      title="Avaliação"
      color="primary"
      fullScreen
      keepMounted
      dialogContentStyle={{ backgroundColor: '#fafbfc' }}
      dialogActions={
        <Button
          startIcon={<SaveIcon />}
          disabled={loading}
          onClick={onClick}
          color="green"
          variant="text"
        >
          Salvar
        </Button>
      }
    >
      {children}
    </Dialog>
  );
}

export default function FormAnswer() {
  const [record, setRecord] = useState(new FormAnswerRecord({}));
  const [NPSForm, setNPSForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors]   = useState([]);
  const { data: user } = useSelector(state => state.userProfile);

  useEffect(() => {
    if (user.access_type === 'customer') {
      Request.get('/api/v1/customers/nps_form_answares/current_form').then( resp => {
        if(resp.data){
          deserialize( resp.data ).then( formData => {
            setNPSForm( formData )
          } )
        }
      } )
    }
  }, []);

  const handleChangeFormAnswer = useCallback(attrs => {
    setRecord( record.updateAttributes(attrs) );
  }, [record, setRecord]);

  const handleSave = useCallback(() => {
    const fetchCreate = async () => {
      try {
        await Request.post(
          `/api/v1/customers/nps_form_answares/${NPSForm.id}`,
          record.toParams()
        )
        toast.success('Avaliação enviada com sucesso! Obrigado =)');
        setNPSForm(null)
      } catch(err) {
        if (err.status === Request.HTTP_STATUS.UNPROCESSABLE_ENTITY) {
          setErrors(
            formatResponseError(err.errors)
          );
        } else {
          toast.error('Não foi possível salvar a avaliação. Favor entre em contato com o administrador');
          setNPSForm(null)
        }
      }
      setLoading(false)
    }
    setLoading(true)
    fetchCreate()
  }, [record, NPSForm, setNPSForm, setErrors, setLoading]);

  if (user?.access_type !== 'customer' || isBlank(NPSForm)) {
    return <></>;
  }

  return (
    <DialogComponent
      open
      loading={loading}
      onClick={handleSave}
    >
      <Errors errors={errors} />
      { NPSForm &&
        <GridContainer>
          <Card style={{ width: '100%', marginTop: 15}}>
            <CardContent>
              <GridContainer>
                <Col sm={6}>
                  <InputShow
                    label={'Obra'}
                    type="string"
                    value={NPSForm.construction?.name}
                  />
                </Col>
                <Col sm={6}>
                  <InputShow
                    label={'Engenheiro'}
                    type="string"
                    value={NPSForm.engineer?.name}
                  />
                </Col>
                <Col sm={12}>
                </Col>
              </GridContainer>
              <GridContainer>
                <Col xs={12} md={6}>
                  <InputControl
                    required
                    name={'engineering_score'}
                    label={"1 - Dê uma nota para o engenheiro da sua obra"}
                    value={record.get('engineering_score')}
                    inputComponent={InputSlider}
                    InputProps={sliderProps}
                    onChange={handleChangeFormAnswer}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputControl
                    required={ record.get('engineering_score') < 80 }
                    name="engineering_score_observation"
                    label={"Observação para o engenheiro"}
                    value={record.get('engineering_score_observation')}
                    type="text"
                    inputComponent={Input}
                    InputProps={{multiline: true}}
                    onChange={handleChangeFormAnswer}
                  />
                </Col>
              </GridContainer>
              <GridContainer>
                <Col xs={12} md={6}>
                  <InputControl
                    required
                    name={'execution_team_score'}
                    label={"2 - Dê uma nota em relação a equipe de execução atual"}
                    value={record.get('execution_team_score')}
                    inputComponent={InputSlider}
                    InputProps={sliderProps}
                    onChange={handleChangeFormAnswer}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputControl
                    required={ record.get('execution_team_score') < 80 }
                    name="execution_team_score_observation"
                    label={"Observação para equipe de execução"}
                    value={record.get('execution_team_score_observation')}
                    type="text"
                    inputComponent={Input}
                    InputProps={{multiline: true}}
                    onChange={handleChangeFormAnswer}
                  />
                </Col>
              </GridContainer>
              <GridContainer>
                <Col xs={12} md={6}>
                  <InputControl
                    required
                    name={'shopping_team_score'}
                    label={"3 - Dê uma nota para equipe de suprimentos (compras)"}
                    value={record.get('shopping_team_score')}
                    inputComponent={InputSlider}
                    InputProps={sliderProps}
                    onChange={handleChangeFormAnswer}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputControl
                    required={ record.get('shopping_team_score') < 80 }
                    name="shopping_team_score_observation"
                    label={"Observação para equipe de suprimentos (compras)"}
                    value={record.get('shopping_team_score_observation')}
                    type="text"
                    inputComponent={Input}
                    InputProps={{multiline: true}}
                    onChange={handleChangeFormAnswer}
                  />
                </Col>
              </GridContainer>
            </CardContent>
          </Card>
        </GridContainer>
      }
    </DialogComponent>
  );
}
