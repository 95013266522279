import React, {useState} from 'react';
import Paper from '~/components/Paper';

import Button from '~/components/Button';
import Dialog from '~/components/Dialog';

import DeleteIcon from '@material-ui/icons/Delete';

function ExampleDialog () {
  const [isOpen, setIsOpen] = useState(false);

	function toogleDialog() {
		setIsOpen(!isOpen);
	}

  return(
    <Paper>
      <Button onClick={toogleDialog}>Abrir</Button>
      <Dialog
        // maxWidth='md'
        icon={<DeleteIcon />}
        // isFullScreen
        isOpen={isOpen}
        handleClose={toogleDialog}
        title={'Texto do título'}
        dialogActions={
          <React.Fragment>
            <Button onClick={toogleDialog} color="red" variant="text">
              Cancel
            </Button>
            <Button onClick={toogleDialog} color="primary" variant="text">
              Subscribe
            </Button>
          </React.Fragment>
        }
      >
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci sunt laudantium labore voluptates fugiat quae magni laboriosam ea consequuntur unde! Totam accusantium earum harum nobis repellat autem quam ipsum voluptas!</p>
      </Dialog>
    </Paper>
  );
}

export default ExampleDialog;
