import SchemaBuilder from './SchemaBuilder';

const columns = {
  id:     { type: 'integer', title: 'ID' },
  name:   { type: 'string', title: 'Nome' },
  code:   { type: 'string', title: 'Código' },
  parent: { type: 'string', title: 'Nível superior' },
  full_code: { type: 'string', title: 'Código' },
};

const accountPlanSchema = new SchemaBuilder( columns );

export default accountPlanSchema;