import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import IconEdit from '@material-ui/icons/Edit';
import PlanningIcon from '@material-ui/icons/Dvr';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Send';

import {
  updateRecordRequest
} from '~/services/fetchApi/constructions';
import deserialize from '~/utils/deserialize';
import sortArray from '~/utils/sortArray';

import Dialog from '~/components/Dialog';
import Button from '~/components/Button';

import { Container, CardContent } from './styles';

import StatusPercent from './components/StatusPercent';
import DialogForm from './components/DialogForm';

function getCode(obj){
  return obj?.account_plan?.code || 0
}
let selectedConstructionPlannings = [];

const generateKey = () => Math.random().toString();

function PercentTab({ construction, findConstructionDispatch }) {
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [forceUpdateKey, setForceUpdateKey] = useState(generateKey());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    selectedConstructionPlannings = construction?.construction_plannings || [];
    setForceUpdateKey(generateKey());
    return () => {
      selectedConstructionPlannings = [];
    }
  }, [construction]);

  const closeDialog = useCallback(
    () => setOpenDialogForm(false),
    []
  );

  const openDialog = useCallback(
    () => setOpenDialogForm(true),
    [setOpenDialogForm]
  );

  const onSubmit = useCallback(
    () => {
      setIsLoading(true)
      updateRecordRequest(construction.id,
        {
          data: {attributes: {
            construction_plannings_attributes: selectedConstructionPlannings.map(
              ({id, account_plan_id, construction_id, percentage, }) => ({
                id, account_plan_id, construction_id, percentage,
              })
            )
          } }
        },
        {include: 'construction_plannings.account_plan'}
      ).then( async (resp) => {
        const data = await deserialize(resp.data)
        selectedConstructionPlannings = data.construction_plannings
        toast.success(
          'Atualizado com sucesso',
          { position: 'top-left' }
        );
        setForceUpdateKey(generateKey());
        setOpenDialogForm(false);
        setIsLoading(false)
        findConstructionDispatch()
      }).catch( error => {
        console.error(error)
        toast.error(
          'Oops, não foi possível salvar!',
          { position: 'top-left' }
        );
        setIsLoading(false)
      })
    },
    [setOpenDialogForm, construction]
  );

  const handleChange = useCallback(
    (newConstructionPlanning) => {
      selectedConstructionPlannings =
        selectedConstructionPlannings.map(constructionPlanning => {
          if (constructionPlanning.id === newConstructionPlanning.id) {
            return newConstructionPlanning;
          }

          return constructionPlanning;
        });
    },
    [setOpenDialogForm]
  );

  return (
    <Container>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          <ul key={forceUpdateKey}>
            {sortArray(selectedConstructionPlannings || [], 'asc', getCode).map(constructionPlanning => (
              <li key={constructionPlanning.id}>
                <strong>
                  {`${constructionPlanning.account_plan.code} - ${constructionPlanning.account_plan.name}`}
                </strong>
                <StatusPercent percentage={constructionPlanning.percentage} />
                <IconButton
                  style={{ padding: 5 }}
                  onClick={openDialog}
                >
                  <IconEdit color="primary" />
                </IconButton>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <Dialog
        icon={<PlanningIcon />}
        isOpen={openDialogForm}
        handleClose={closeDialog}
        title={'Planejamento'}
        color="primary"
        fullWidth
        maxWidth="xs"
        keepMounted
        dialogActions={
          <>
            <Button
              startIcon={<IconCancel />}
              onClick={closeDialog}
              color="red"
              variant="text"
            >
              Cancelar
            </Button>
            <Button
              disabled={isLoading}
              startIcon={<IconSave />}
              onClick={onSubmit}
              color="green"
              variant="text"
            >
              Salvar
            </Button>
          </>
        }
      >
        <DialogForm
          defaultData={selectedConstructionPlannings}
          onChange={handleChange}
        />
      </Dialog>
    </Container>
  );
}

export default PercentTab;
