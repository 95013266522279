import Route from '~/services/route';

export const peopleRoute = {
  list:  new Route('/sys/people'),
  show:  new Route('/sys/people/:id'),
  clone: new Route('/sys/people/new/:id'),
  new:   new Route('/sys/people/new'),
  edit:  new Route('/sys/people/:id/edit'),
}

export const metadataRoute = {
  list:  new Route('/sys/metadata'),
  show:  new Route('/sys/metadata/:id'),
  clone: new Route('/sys/metadata/new/:id'),
  new:   new Route('/sys/metadata/new'),
  edit:  new Route('/sys/metadata/:id/edit'),
}

export const fieldsRoute = {
  list:  new Route('/sys/fields'),
  show:  new Route('/sys/fields/:id'),
  clone: new Route('/sys/fields/new/:id'),
  new:   new Route('/sys/fields/new'),
  edit:  new Route('/sys/fields/:id/edit'),
}

export const customRecordsRoute = {
  list:  new Route('/sys/r/:sys_key'),
  show:  new Route('/sys/r/:sys_key/:id'),
  clone: new Route('/sys/r/:sys_key/new/:id'),
  new:   new Route('/sys/r/:sys_key/new'),
  edit:  new Route('/sys/r/:sys_key/:id/edit'),
}

export const rolesRoute = {
  list:  new Route('/sys/roles'),
  show:  new Route('/sys/roles/:id'),
  clone: new Route('/sys/roles/new/:id'),
  new:   new Route('/sys/roles/new'),
  edit:  new Route('/sys/roles/:id/edit'),
}

export const usersRoute = {
  list:  new Route('/sys/users'),
  show:  new Route('/sys/users/:id'),
  clone: new Route('/sys/users/new/:id'),
  new:   new Route('/sys/users/new'),
  edit:  new Route('/sys/users/:id/edit'),
}
export const dashboardRoute = {
  self: new Route('/sys/dashboard')
}

export const httpStatusRoute = {
  notFound: new Route('/sys/404')
}
export const authRoute = {
  signIn: new Route('/sys/signin'),
  forgotPassword: new Route('/sys/forgot-password'),
  resetPassword: new Route('/sys/reset-password')
}

export const constructionsRoute = {
  list:  new Route('/sys/constructions'),
  show:  new Route('/sys/constructions/:id'),
  clone: new Route('/sys/constructions/new/:id'),
  new:   new Route('/sys/constructions/new'),
  edit:  new Route('/sys/constructions/:id/edit'),
}

export const suppliersRoute = {
  list:  new Route('/sys/suppliers'),
  show:  new Route('/sys/suppliers/:id'),
  clone: new Route('/sys/suppliers/new/:id'),
  new:   new Route('/sys/suppliers/new'),
  edit:  new Route('/sys/suppliers/:id/edit'),
}

export const segmentsRoute = {
  list:  new Route('/sys/segments'),
  show:  new Route('/sys/segments/:id'),
  clone: new Route('/sys/segments/new/:id'),
  new:   new Route('/sys/segments/new'),
  edit:  new Route('/sys/segments/:id/edit'),
}

export const accountPlansRoute = {
  list:  new Route('/sys/account_plans'),
  new:   new Route('/sys/account_plans/new'),
  edit:  new Route('/sys/account_plans/:id/edit'),
}

export const productsRoute = {
  list:  new Route('/sys/products'),
  show:  new Route('/sys/products/:id'),
  clone: new Route('/sys/products/new/:id'),
  new:   new Route('/sys/products/new'),
  edit:  new Route('/sys/products/:id/edit'),
}

export const invoicesRoute = {
  list:  new Route('/sys/invoices'),
  show:  new Route('/sys/invoices/:id'),
  clone: new Route('/sys/invoices/new/:id'),
  new:   new Route('/sys/invoices/new'),
  edit:  new Route('/sys/invoices/:id/edit'),
  newXml: new Route('/sys/invoices/import_xml'),
}

export const buildersRoute = {
  list:  new Route('/sys/builders'),
  show:  new Route('/sys/builders/:id'),
  clone: new Route('/sys/builders/new/:id'),
  new:   new Route('/sys/builders/new'),
  edit:  new Route('/sys/builders/:id/edit')
}

export const engineersRoute = {
  list:  new Route('/sys/engineers'),
  show:  new Route('/sys/engineers/:id'),
  clone: new Route('/sys/engineers/new/:id'),
  new:   new Route('/sys/engineers/new'),
  edit:  new Route('/sys/engineers/:id/edit')
}

export const contractorsRoute = {
  list:  new Route('/sys/contractors'),
  show:  new Route('/sys/contractors/:id'),
  clone: new Route('/sys/contractors/new/:id'),
  new:   new Route('/sys/contractors/new'),
  edit:  new Route('/sys/contractors/:id/edit')
}

export const incomingEmailsRoute = {
  list:  new Route('/sys/incoming_emails')
}

export const npsFormsRoute = {
  list:  new Route('/sys/nps/forms'),
  show:  new Route('/sys/nps/forms/:id'),
  clone: new Route('/sys/nps/forms/new/:id'),
  new:   new Route('/sys/nps/forms/new'),
  edit:  new Route('/sys/nps/forms/:id/edit')
}

export const npsFormAnswersRoute = {
  list:  new Route('/sys/nps/answers'),
  show:  new Route('/sys/nps/answers/:id')
}

export const reportsRoute = {
  expenses:          new Route('/sys/reports/construction_expenses'),
  accountPlansTree:  new Route('/sys/reports/account_plans_tree'),
  expensesHistory:   new Route('/sys/reports/expenses_history'),
}

export const customerInvoicesRoute = {
  self:  new Route('/sys/customer/invoices')
}

