import React, { memo } from 'react';

import Spinner from '../../Spinner';

import { Container } from './styles';

function Loading() {
  return (
    <Container>
      <Spinner
        fillColor="#1B2430"
        loadingText="Buscando dados..."
        activeBorderWidth={4.5}
        loadingTextStyle={{
          fontWeight: '600',
          color: '#4F3A8B',
          fontSize: '1.3rem'
        }}
      />
    </Container>
  );
}

export default memo(Loading);
