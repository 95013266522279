import Request from '~/services/request';
import deserialize from '~/utils/deserialize';
import { customerInvoiceApi } from '~/routes/api';
const findIncludeQuery = (
  'include=expenses.account_plan-supplier' +
  '-invoice_items.product-attachments.file_attachments' +
  '-bank_slips.attachment.file_attachments-construction' +
  '-bank_slips.attachment_attachment'
)

export default async function findInvoice(construction_id, id){
  const resp = await Request.get(`${customerInvoiceApi.show.build({construction_id, id})}?${findIncludeQuery}`);
  const data = await deserialize(resp.data);
  data.expenses.forEach( expense => {
    if(expense.account_plan){
      const acPlan = expense.account_plan;
      expense.account_plan.name = `${acPlan.full_code} - ${acPlan.name}`
    }
  });
  return data
}