import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '~/components/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';

import MetaFieldRecord from '~/records/MetaFieldRecord';
import metaFieldSizeEnum from '~/enums/metaFieldSizeEnum';
import fieldKindEnum from '~/enums/fieldKindEnum';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const attrName = (name) => MetaFieldRecord.hummanAttributeName(name);

const fieldSizeObjs = metaFieldSizeEnum().objects();
const kinds = fieldKindEnum().objects();

export default function MetaFieldsTable({metaFields, handleEdit, onDelete}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{ attrName('field') }</TableCell>
            <TableCell align="right">Tipo</TableCell>
            <TableCell align="right">Chave</TableCell>
            <TableCell align="right">{ attrName('field_size') }</TableCell>
            <TableCell align="right">{ attrName('required') }</TableCell>
            <TableCell align="right">{ attrName('active') }</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { metaFields.sortBy( r => r.get('sorter') ).entrySeq().map( ([key, record]) =>
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {record.get('field')?.name}
              </TableCell>
              <TableCell align="right">{kinds[record.get('field')?.kind]}</TableCell>
              <TableCell align="right">{ record.get('field')?.get('key') }</TableCell>
              <TableCell align="right">{fieldSizeObjs[record.get('field_size')]}</TableCell>
              <TableCell align="right">{record.get('required') ? 'SIM' : 'NÃO'}</TableCell>
              <TableCell align="right">{record.get('active') ? 'SIM' : 'NÃO'}</TableCell>
              <TableCell align="right">
                <Tooltip title="Editar">
                  <IconButton onClick={ () => handleEdit(record) }>
                    <IconEdit htmlColor="#FF9800" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Excluir">
                  <IconButton onClick={ () => onDelete(record) }>
                    <IconDelete htmlColor="#FF5252" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}