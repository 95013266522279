import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { rolesRoute } from '~/routes'
import { updateRecord, findRecord, clearState } from '~/store/modules/roles';
import RoleRecord from '~/records/RoleRecord';
import CrudEdit from '~/containers/CrudEdit';
import isPresent from '~/utils/isPresent';
import FormFields from './components/FormFields';

export default function Edit() {
  const [record, setRecord] = useState();
  const dispatch = useDispatch();
  const { isLoading, errors } = useSelector(state => state.roles);
  const { id } = useParams();

  useEffect(() => {
    dispatch( findRecord(
      id,
      {include: 'users-role_permissions'},
      (data) => {
        setRecord( new RoleRecord({
          ...data,
          users: data.users.filter(isPresent).map( u => ({
            ...u,
            id: Number(u.id),
            name: `${u.name} <${u.email}>`
          }))
        }) )
        dispatch( clearState() )
      }
    ) );
    return () => {
      dispatch( clearState() )
    };
  }, [])

  const onSubmit = useCallback( () => {
    const data = record.toParams();
    dispatch( updateRecord(id, data) );
  }, [record, id]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  return (
    <CrudEdit
      isLoading={isLoading}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: rolesRoute.list.path, text: 'Permissões' },
        { text: 'Editando Permissão' },
      ]}
      footerActionsProps={{isLoading, showSaveAndContinue: false}}
    >
      {record &&
        <FormFields
          record={record}
          onChange={handleChange}
        />
      }
    </CrudEdit>
  );
}