import RecordBase from './Base';
import segmentSchema from './schema/segmentSchema';

export default class SegmentRecord extends RecordBase {
  static defaultAttributes = segmentSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return segmentSchema.attributeNames }

  toParams() {
    return {
      data: {
        attributes: {
          name: this.get('name'),
        }
      }
    };
  }
}