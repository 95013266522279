import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { peopleRoute } from '~/routes'
import { updateRecord, findRecord, clearState } from '~/store/modules/people';
import PersonRecord from '~/records/PersonRecord';
import CrudEdit from '~/containers/CrudEdit';

import FormFields from './components/FormFields';

// TODO: Aplicar regras dos campos extras (Usuário, contatos)

export default function Edit() {
  const [record, setRecord] = useState(new PersonRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.people);
  const { id } = useParams();

  useEffect(() => {
    dispatch( findRecord(id, {include: 'attachments-user-address.city.state-contacts'}) );
    return () => {
      dispatch( clearState() )
    };
  }, [])

  useEffect(() => {
    if(current){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback( () => {
    const data = record.toParams();
    dispatch( updateRecord(id, data) );
  }, [record, id]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  return (
    <CrudEdit
      isLoading={isLoading}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: peopleRoute.list.path, text: 'Clientes' },
        { text: 'Editando Cliente' },
      ]}
      footerActionsProps={{isLoading, showSaveAndContinue: false}}
    >
      <FormFields
        record={record}
        onChange={handleChange}
      />
    </CrudEdit>
  );
}