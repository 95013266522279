export default function updateTreeRow(updatedRow, rows) {
  const updatedRows = [...rows];
  let found = false;

  for (const grandPaPos in rows) {
    const grandPaRow = rows[grandPaPos];
    if (updatedRow.id === grandPaRow.id) {
      updatedRows[grandPaPos] = updatedRow;
      break;
    }

    if (!grandPaRow?.items) continue;

    for (const fatherPos in grandPaRow.items) {      
      const fatherRow = grandPaRow.items[fatherPos];
      if (updatedRow.id === fatherRow.id) {
        found = true;
        updatedRows[grandPaPos].items[fatherPos] = updatedRow;
        break;
      }

      if (!fatherRow?.items) continue;

      for (const sonPos in fatherRow.items) {
        const sonRow = fatherRow.items[sonPos];
        if (updatedRow.id === sonRow.id) {
          found = true;
          updatedRows[grandPaPos].items[fatherPos].items[sonPos] = updatedRow;
          break;
        }
      }

      if (found) break;
    }

    if (found) break;
  }

  return updatedRows;
}
