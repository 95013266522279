// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputTextEditor   from '~/components/Input/InputTextEditor';

// material-ui
import Grid from '@material-ui/core/Grid';

class InputExample extends Component {
	state = {
		input1: '<p>Olá Mundo</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque vel nihil quae a esse! Debitis corporis velit a quae nemo blanditiis nulla consectetur tempore incidunt. Quis neque obcaecati enim adipisci?</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo pariatur quos cumque magnam ratione odio, voluptatem adipisci ex provident atque! At ducimus soluta nulla fuga porro, veniam fugit numquam eveniet.</p>',
		input2: null,
		modalIsOpen: false,
	}

	handleChangeKey = ( valKey ) => {
		this.setState(valKey);
	}

	handleChangeEvent = (attr) => ( valKey, event ) => {
		this.setState({[attr]: event.target.value});
	};

	render(){
		return(
			<div>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<InputControl
							id={'ID-CUSTOMIZADO-TEXTO'}
							value={this.state.input1}
							name={'input1'}
							label={'Input 1'}
							inputComponent={InputTextEditor}
							onChange={this.handleChangeKey}
						/>
						<div>
							VALOR: {this.state.input1}
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default InputExample;
