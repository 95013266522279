import Request from '~/services/request';
import deserialize from '~/utils/deserialize';
import { metadataApi } from '~/routes/api';

const FIXED_OPTIONS = [
  {id: 'Person', name: 'Pessoas'},
  {id: 'User', name: 'Usuários'},
]

const isFixed = (value) => FIXED_OPTIONS.map( op => op.id ).includes(value)

export const fetchRecordTypeOptions = (inputValue, page, doneCallback) => {
  const uri = `${metadataApi.list.path}?sort=name.asc&qs=${inputValue}&page=${page}&per_page=50`;

  return Request.get(uri).then( async resp => {
    const data = await deserialize( resp.data );
    const items = [...FIXED_OPTIONS,
      ...data.map( ({id, record_plural_name}) => ({
        name: record_plural_name,
        id: `${id}`,
      }))
    ]
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: items
      }
    )
  })
  .catch(console.error);
};

export const recordTypeAsyncShow =  (id) => {
  if( isFixed(id) ){
    return new Promise( (resolve) => {
      resolve( FIXED_OPTIONS.find( op => op.id === id ) )
    } )
  }

  return Request.get(`${metadataApi.list.path}/${id}?adapter=json`).then(resp => {
    if(resp.data && resp.data.metadatum){
      const metad = resp.data.metadatum;
      return { id: metad.id, name: metad.record_plural_name };
    }
    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}