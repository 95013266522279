import React from 'react';

import InputControl from '~/components/Input/InputControl';
import InputDate from '~/components/Input/InputDate';
import SelectedConstructionButton from '~/components/SelectedConstructionButton';

import { Container } from './styles';

function Filter({ startDate, endDate, onChange, setOpenDialog, hideDate=false }) {
  return (
    <Container>
      <SelectedConstructionButton
        customStyle={{ marginRight: 10, marginTop: 17 }}
        onClick={() => setOpenDialog(true)}
      />

      { !hideDate &&
        <>
          <div className="filterDate startDateFilter">
            <label>Data Inícial</label>
            <InputControl
              name="start_date"
              value={startDate}
              type="date"
              inputComponent={InputDate}
              InputProps={{ inputProps: { disabled: true } }}
              onChange={({ start_date }) => onChange('start', start_date)}
            />
          </div>
          <div className="filterDate">
            <label>Data Final</label>
            <InputControl
              name="end_date"
              value={endDate}
              type="date"
              inputComponent={InputDate}
              InputProps={{ inputProps: { disabled: true } }}
              onChange={({ end_date }) => onChange('end', end_date)}
            />
          </div>
        </>
      }
    </Container>
  )
}

export default Filter;
