import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/products';
import { productsRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import ProductRecord from '~/records/ProductRecord';

const attrName = (attr) => ProductRecord.hummanAttributeName(attr);

function Show({ product, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const acPlanName = useMemo(() => {
    const acPlan = product?.account_plan;
    if(!acPlan) return ''

    return `${acPlan.full_code} - ${acPlan.name}`
  }, [product?.account_plan])

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'account_plan'}));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: productsRoute.list.path, text: 'Produtos' },
        { text: 'Exibindo produto' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   productsRoute.new.build(),
        routeCloneRegister: productsRoute.clone.build({ id }),
        routeEditRegister:  productsRoute.edit.build({ id }),
        permissionKeyCode:  'Product'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col sm={6}>
              <InputShow
                label={attrName('name')}
                type="string"
                value={product?.name}
              />
            </Col>
            <Col sm={6}>
              <InputShow
                label={attrName('ncm')}
                type="string"
                value={product?.ncm}
              />
            </Col>
            <Col sm={6}>
              <InputShow
                label={attrName('ean')}
                type="string"
                value={product?.ean}
              />
            </Col>
            <Col sm={6}>
              <InputShow
                label={attrName('base_value')}
                type="float"
                value={product?.base_value}
              />
            </Col>
            <Col sm={6}>
              <InputShow
                label={attrName('account_plan')}
                type="string"
                value={acPlanName}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.products.isLoading,
  product: state.products.current,
});

export default connect(mapStateToProps)(Show);