import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker
} from "@material-ui/pickers";
import MaskedInput from "react-text-mask";
import "moment/locale/pt-br";
moment.locale("pt-br");

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TodayIcon from "@material-ui/icons/Today";

import useStyles from "./styles";

const Types = {
  date: DatePicker,
  datetime: DateTimePicker
};

const MASK_DATE = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
const MASK_DATETIME = [...MASK_DATE, " ", /\d/, /\d/, ":", /\d/, /\d/];

const HUMANIZE_FORMAT_DATETIME = "DD/MM/YYYY HH:mm";
const HUMANIZE_FORMAT_DATE = "DD/MM/YYYY";

const DateTimeMaskCustom = props => {
  const { inputRef, dateType, ...other } = props;
  const [mask, setMask] = useState(
    dateType === "datetime" ? MASK_DATETIME : MASK_DATE
  );

  useEffect(() => {
    if (dateType === "date") setMask(MASK_DATE);

    if (dateType === "datetime") setMask(MASK_DATETIME);
  }, [dateType]);

  return (
    <MaskedInput
      {...other}
      render={(setRef, props) => (
        <input
          ref={ref => {
            inputRef(ref);
            setRef(ref);
          }}
          {...props}
        />
      )}
      guide={false}
      placeholderChar={"\u2000"}
      mask={mask}
    />
  );
};

const validateDateTime = inputValue => {
  const value = `${inputValue}`;
  const regex = /\d\d\/\d\d\/\d\d\d\d(\s)\d\d:\d\d/;
  if (value.match(regex)) {
    const dt = moment(value, HUMANIZE_FORMAT_DATETIME);
    if (dt.isValid()) {
      return dt.format("YYYY-MM-DDTHH:mm:ssZ");
    }
  }

  return null;
};

const validateDate = inputValue => {
  const value = `${inputValue}`;
  const regex = /\d\d\/\d\d\/\d\d\d\d/;
  if (value.match(regex)) {
    const dt = moment(value, HUMANIZE_FORMAT_DATE);
    if (dt.isValid()) {
      return dt.format("YYYY-MM-DD");
    }
  }

  return null;
};

function InputDate(props) {
  const { root } = useStyles();

  const {
    classes,

    type,
    minDate,
    maxDate,
    value,
    onChange,
    inputProps,
    isNotEdit,
    name,
    ...ownProps
  } = props;

  const Picker = Types[type];
  const [locale] = useState("pt-br");
  const [open, setOpen] = useState(false);
  const format =
    type === "date" ? HUMANIZE_FORMAT_DATE : HUMANIZE_FORMAT_DATETIME;
  const formatOut = type === "date" ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm:ssZ";

  function handleChange(date) {
    return onChange({ [name]: moment(date).format(formatOut) });
  }

  function handleInputChange(event) {
    const inputValue = event.currentTarget.value;
    let value = null;

    if (type === "date") {
      value = validateDate(inputValue);
    } else {
      value = validateDateTime(inputValue);
    }

    if (value) return onChange({ [name]: value });
  }

  function humanizeInputValue() {
    const regex =
      type === "date"
        ? /\d{4}-\d{2}-\d{2}/
        : /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2}/;

    if (typeof value !== "undefined" && value.match(regex)) {
      const date = moment(value, formatOut);
      return date.isValid() ? date.format(format) : "";
    } else {
      return value;
    }
  }

  function pickerValue(value, defaultValue = null) {
    const date = moment(value, formatOut);
    if (date.isValid()) {
      return date.toDate();
    } else {
      return defaultValue;
    }
  }

  function onBlurInput(event) {
    const inputValue = event.currentTarget.value;
    let value = null;

    if (type === "date") {
      value = validateDate(inputValue);
    } else {
      value = validateDateTime(inputValue);
    }

    if (!value) return onChange({ [name]: '' });

    onChange({ [name]: value });
  }

  const handleKeyPress = event => {
    if (event.key === "h")
      return onChange({ [name]: moment().format(formatOut) });

    return;
  };

  return (
    <>
      <Input
        fullWidth
        value={humanizeInputValue()}
        inputComponent={DateTimeMaskCustom}
        inputProps={{
          ...inputProps,
          dateType: type,
          className: root
        }}
        classes={{ root: classes.inputRoot }}
        type="text"
        autoComplete="off"
        onKeyPress={event => handleKeyPress(event)}
        onChange={handleInputChange}
        onBlur={onBlurInput}
        {...ownProps}
        endAdornment={
          <InputAdornment position="end">
            {!isNotEdit && (
              <IconButton
                href={null}
                focusable="false"
                aria-label=""
                onClick={() => setOpen(true)}
              >
                <TodayIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        placeholder={`Ex: ${moment().format(format)}`}
      />

      <MuiPickersUtilsProvider libInstance={moment} locale={locale} utils={DateFnsUtils}>
        <Picker
          autoOk
          showTodayButton
          animateYearScrolling
          minDate={pickerValue(minDate, new Date("1900-01-01"))}
          maxDate={pickerValue(maxDate, new Date("2100-01-01"))}
          open={open}
          ampm={false}
          label={false}
          clearLabel="Limpar"
          cancelLabel="Cancelar"
          todayLabel="Hoje"
          invalidDateMessage="Data/Hora inválida"
          onChange={handleChange}
          value={pickerValue(value)}
          onClose={() => setOpen(false)}
          style={{ display: "none" }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

InputDate.defaultProps = {
  type: "date"
};

InputDate.propTypes = {
  type: PropTypes.oneOf(["date", "datetime"]),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
  isNotEdit: PropTypes.bool
};

export default InputDate;
