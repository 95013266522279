export default function updateRowsBySelection(rows, selectedRows) {
  let indexCounter = 0;

  const updatedRows = rows.map(grandPaRow => {
    grandPaRow.selected = selectedRows.includes(indexCounter);
    indexCounter++;

    if (grandPaRow.items) {
      grandPaRow.items.map(fatherRow => {
        fatherRow.selected = selectedRows.includes(indexCounter);
        indexCounter++;

        if (fatherRow.items) {
          fatherRow.items.map(sonRow => {
            sonRow.selected = selectedRows.includes(indexCounter);
            indexCounter++;

            return sonRow;
          });
        }

        return fatherRow;
      });
    }

    return grandPaRow;
  });

  return updatedRows;
}
