import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import useStyles from './styles';

const colors = {
  primary:   'Primary',
  secondary: 'Secondary',
  success:   'Success',
  warning: 'Warning',
  error:  'Error',
  blue:   'Blue',
  purple: 'Purple',
  green:  'Green',
  orange: 'Orange',
  red:    'Red',
  black: 'Black',
  grey:  'Grey',
  rose:  'Rose',
  white:  'White',
};

function CustomButton(props) {
  const classesDefault = useStyles();

  const {
    color,
    classes,
    ...ownProps
  } = props;

  const btnProps = useMemo(() => {
    if(!!ownProps.href && !ownProps.component){
      ownProps.to = ownProps.href
      ownProps.component = Link;
      ownProps.href = undefined
    }
    return ownProps
  }, [ownProps])


  return (
    <Button
      color="default"
      {...btnProps}
      classes={{
        text: classesDefault[`text${colors[color]}`],
        outlined: classesDefault[`outlined${colors[color]}`],
        contained: classesDefault[`contained${colors[color]}`],
        ...classes,
      }}
    />
  );
}

CustomButton.defaultProps = {
  ...Button.defaultProps,
  color: 'primary',
  variant: 'contained',
};

CustomButton.propTypes = {
  ...Button.propTypes,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'blue',
    'purple',
    'green',
    'orange',
    'red',
    'black',
    'grey',
    'rose',
    'inherit',
    'white'
  ]),
};

export default memo(CustomButton);

