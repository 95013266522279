import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default makeStyles( () =>
  ({
  buttonSave: {
    marginRight: 10,
  },
  checkBox: {
    padding: 0,
    marginTop: 6,
    marginRight: 10,
  }
}));