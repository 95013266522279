import RecordBase from './Base';
import customRecordSchema from './schema/customRecordSchema';

export default class CustomRecordRecord extends RecordBase {
  static defaultAttributes = customRecordSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return customRecordSchema.attributeNames }

  toParams(extraParams = {}) {
    return {
      data:{
        attributes:{
          name: this.get('name'),
          custom_fields: this.get('custom_fields'),
          ...extraParams
        }
      }
    };
  }
}
