import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
`;

export const PercentText = styled.strong`
  font-size: 10px;
  padding-left: ${props => props.paddingLeft}px;
`;

export const Percent = styled.div`
  width: 180px;
  height: 8px;
  border-radius: 4px;
  background-color: #F0F0F0;
`;

export const PercentageCompleted = styled.div`
  width: ${props => props.width}px;
  height: 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #9C55F5;

  ${props => props.width === 180 && css`
    border-radius: 4px;
  `}
`;
