import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import InputShow   from '~/components/Input/InputShow';

function Observation({ kind, label, value }) {
  return(
    <Container kind={kind}>
      <InputShow
        label={label}
        value={value}
      />
    </Container>
  );
}

Observation.defaultProps = {
  kind:   '',
  label:  '',
  value:  '',
};

Observation.propTypes = {
  kind:   PropTypes.string,
  label:  PropTypes.string,
  value:  PropTypes.string,
};

export default Observation;
