import styled from 'styled-components';

import relaxImg from '~/assets/images/relax.png';

export const EmptyPendencies = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  background-image: url(${relaxImg});
  background-repeat: no-repeat;
  background-position: center;
`;
