import React, { useCallback } from 'react';

import { invoicesRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import incomingEmailSchema from '~/records/schema/incomingEmailSchema';
import invoiceStatusEnum from '~/enums/incomingEmailStatusEnum';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/incomingEmails';

import Permission from '~/components/Permission';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import IconButton from '~/components/IconButton';
import Chip from '~/components/Chip';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {

      let requestParams = 'include=invoice';
      if(params){
        requestParams = `${params}&${requestParams}`
      }
      dispatch( fetchRecords(requestParams, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'E-mails recebidos' }]}
      permissionKeyCode="IncomingEmail"
      tableProps={{
        extraActions: (row) => {
          return(
            <Permission keyCode={'IncomingEmail'} permission="edit">
              { !!row.invoice && row.invoice.id ?
              <IconButton component={Link} to={`${invoicesRoute.show.build({id: row.invoice.id})}`}>
                <Tooltip title="Ver nota fiscal">
                  <FindInPageIcon />
                </Tooltip>
              </IconButton>
              :
              <IconButton component={Link} to={`${invoicesRoute.newXml.build()}?incoming_email_id=${row.id}`}>
                <Tooltip title="Continuar Importação">
                  <AutorenewIcon htmlColor="#FF9800" />
                </Tooltip>
              </IconButton>
              }
            </Permission>
          )
        },
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: incomingEmailSchema,
        actions: ['delete'],
        permissionKeyCode: 'IncomingEmail',
        filterProps: {
          columns: incomingEmailSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'status',
            formatterComponent: (_, row) => (
              <div>
                <Chip
                  color={row.status === 'pendent' ? 'orange' : 'green' }
                  variant="outlined"
                  size="small"
                  label={invoiceStatusEnum().objects()[row.status]} />
              </div>
            )
          }
        ]
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.incomingEmails.listIsLoading,
  rows: state.incomingEmails.data,
  total: state.incomingEmails.totalData,
});

export default connect(mapStateToProps)(List);