import React     from 'react';
import PropTypes from 'prop-types';

// jodit editor lib
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';
// -----------------------------------------------

import './editorStyle.sass';

// material-ui components
import Dialog        from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import withStyles    from '@material-ui/core/styles/withStyles';
// -----------------------------------------------
import getConfig from './config'

const styles = () => ({
  root: {
    padding: 5
  },
});

const DialogContent = withStyles(styles)(MuiDialogContent);

class InputEditor extends React.Component {
  static inputType = 'WYSIWYG';

  static propTypes = {
    'state-name': PropTypes.string.isRequired,
    'name':       PropTypes.string.isRequired,
    config:       PropTypes.object,
  };

  fullScreenContainer = document.createElement('div');
  inputValue = ''

  toggleFullscreen = () => {
    this.handleChange()
    const {isFullScreen} = this.state;
    if(isFullScreen){
      setTimeout(() => {
        document.body.removeChild(this.fullScreenContainer);
      }, 100);
    }else{
      document.body.appendChild(this.fullScreenContainer);
      try {
        setTimeout(function(container) {
          // Isso resolve um problema que ao abrir o editor em fullscreen, não
          // estava deixando editar as propriedades da imagem pelo modal do jodit.
          container.querySelector('[tabindex="-1"]').removeAttribute('tabindex')
        }, 200, this.fullScreenContainer);
      } catch(e) {}
    }

    this.setState({isFullScreen: !isFullScreen});
  };

  state = {
    isFullScreen:          false,
    joditConfigNormal:     getConfig(false, this.toggleFullscreen, this.props.config) ,
    joditConfigFullscreen: getConfig(true, this.toggleFullscreen, this.props.config) ,
  };

  joditNormal = undefined;
  joditFullscreen = undefined;
  setRefNormal = jodit => this.joditNormal = jodit;
  setRefFullscreen = jodit => this.joditFullscreen = jodit;

  handleChange = () => {
    const { onChange, 'state-name': stateName } = this.props;
    const fakeEvent = {type: 'change', target: {value: this.inputValue}};
    onChange({[stateName]: this.inputValue }, fakeEvent);
  };

  componentDidUpdate(prevProps, prevState) {
    const { isFullScreen } = this.state;

    if(!isFullScreen && prevState.isFullScreen){
      setTimeout(() => {
        this.joditNormal.editor.focus();
      }, 200);
    }else if(isFullScreen && !prevState.isFullScreen){
      setTimeout(() => {
        if(this.joditFullscreen){
          this.joditFullscreen.editor.focus();
        }
      }, 200);
    }
  }

  render() {
    const {
      value,
      name,
      classes
    } = this.props;

    this.inputValue = value;

    const { isFullScreen, joditConfigNormal, joditConfigFullscreen } = this.state;

    return (
      <React.Fragment>
        <div className="tw-jodit-editor--container">
          <Dialog
            scroll='body'
            container={ () => this.fullScreenContainer }
            fullScreen
            open={isFullScreen}
            classes={{root: classes.rootDialog }}
          >
            <DialogContent>
              { isFullScreen &&
                <JoditEditor
                  editorRef={this.setRefFullscreen}
                  value={value}
                  config={joditConfigFullscreen}
                  onBlur={this.handleChange}
                  onChange={ value => this.inputValue = value }
                />
              }
            </DialogContent>
          </Dialog>
          { !isFullScreen &&
            <JoditEditor
              editorRef={this.setRefNormal}
              value={value}
              config={joditConfigNormal}
              onBlur={this.handleChange}
              onChange={ value => this.inputValue = value }
            />
          }
        </div>
        <textarea name={name} value={value} onChange={ () => {}} style={{display: 'none'}}/>
      </React.Fragment>
    );
  }
}

export default InputEditor;