// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl      from '~/components/Input/InputControl';
import InputColorPicker  from '~/components/Input/InputColorPicker';

// material-ui
import Grid from '@material-ui/core/Grid';

class InputExample extends Component {
	state = {
		input1: '',
	}

	handleChangeKey = ( valKey ) => {
		this.setState(valKey);
	};

	render(){

		const { input1} = this.state;

		return(
			<div>
				<Grid container spacing={4}>
					<Grid item xs={4}>
            <InputControl
              value={input1}
              name="input1"
              label={'Input 1'}
              inputComponent={InputColorPicker }
              InputProps={{
                color: input1,
              }}
              onChange={this.handleChangeKey}
              />
          </Grid>
				</Grid>
			</div>
		);
	}
}

export default InputExample;