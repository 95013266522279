import React from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import InputControl from '~/components/Input/InputControl';
import MuiInputDate from '~/components/Input/InputDate';

import { editFilter } from '../../../../store/actions';

function InputDate(props) {
  const dispatch = useDispatch();
  const {
    column,
    filter,
    indexGroup,
    indexFilter,
    inputProps,
    ...ownProps
  } = props;

  if (filter.get('ope') === 'between') {
    const [ start = '', end = '' ] = `${filter.get('val')}`.split('|');

    return (
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={6}>
          <InputControl
            value={start}
            name={'newStart'}
            inputComponent={MuiInputDate}
            onChange={ ({newStart}) => dispatch(editFilter(indexGroup, indexFilter, 'val', `${newStart}|${end}`)) }
            InputProps={{
              type: column.type,
              maxDate: end,
              ...inputProps,
            }}
            {...ownProps}
          />
        </Grid>
        <Grid item xs={6}>
          <InputControl
            value={end}
            name={'newEnd'}
            inputComponent={MuiInputDate}
            onChange={ ({newEnd}) => dispatch(editFilter(indexGroup, indexFilter, 'val', `${start}|${newEnd}`)) }
            InputProps={{
              type: column.type,
              minDate: start,
              ...inputProps,
            }}
            {...ownProps}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <InputControl
      value={filter.get('val')}
      name={'val'}
      inputComponent={MuiInputDate}
      onChange={({ val }) => dispatch(editFilter(indexGroup, indexFilter, 'val', val))}
      InputProps={{
        placeholder: 'Valor',
        type: column.type,
        ...inputProps,
      }}
      {...ownProps}
    />
  );
}

export default React.memo(InputDate);
