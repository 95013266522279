import React from 'react';

import InputRegex from '../InputRegex';

const MASK_CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

class InputCEP extends React.Component {
  static getDerivedStateFromProps() {
    return { mask: MASK_CEP };
  }

  state = {
    mask: MASK_CEP,
  };

  render() {
    const { inputProps, ...rest } = this.props;
    return (
      <InputRegex
        {...rest}
        inputProps={{
          ...inputProps,
          type: 'cep'
        }}
        mask={this.state.mask}
      />
    );
  }
}

export default InputCEP;
