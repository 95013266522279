import styled from 'styled-components';

function getColor(kind) {
  if (kind === 'discount') return 'red';
  if (kind === 'addition') return 'green';
  return 'rgba(0, 0, 0, 0.87)';
}

export const Container = styled.div`
  .MuiFormControl-root {
    border: 0px;
    margin-top: 0px;

    label,
    > div {
      color: ${props => (getColor(props.kind))} !important;
    }
  }

  @media print {
    label,
    input {
      color: ${props => (getColor(props.kind))} !important;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }
  }
`;
