import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;

  & .filterDate {
    display: flex;
    flex-direction: column;

    label {
      color: #29292e;
      font-size: 0.75rem;
      font-weight: 600;
      margin-left: 8px;
    }

    div {
      padding: 0;
      margin: 0;
    }
  }

  & .startDateFilter {
    margin-right: 10px;
  }

  & .MuiFormControl-fullWidth {
    width: 145px;
    background-color: #4F3A8B;
    border-radius: 10px;

    input {
      padding-left: 11px;
      color: #fff;
    }

    svg {
      color: #fff;
    }

    & .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
  }
  
  & .MuiInput-underline::before {
    display: none;
  }
`;
