import { underscore } from 'inflected';
import isObject from '~/utils/isObject';

const getValue = (value) => {
  if( isObject(value) && value.id ){ return value.id; }

  return value;
};


export default function(groups) {
  return groups.reduce((groups, group) => {
    const filtered = group.get('filters').reduce((filters, filter) => {
      const hasValue = (
        !!filter.get('col') &&
        !!filter.get('ope') &&
        (!!filter.get('val') || filter.get('val') === 0 || filter.get('val') === false)
      ) || filter.get('ope') === 'isBlank' || filter.get('ope') === 'isPresent';

      if (hasValue)
        filters.push({
          col: underscore(filter.get('col')),
          ope: filter.get('ope'),
          val: getValue(filter.get('val')),
        });

      return filters;
    }, []);

    if (filtered.length)
      groups.push({
        condition: group.get('condition'),
        filters: filtered,
      });

    return groups;
  }, []);
}
