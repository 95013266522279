/* eslint-disable react/prop-types */
import React     from 'react';
import PropTypes from 'prop-types';

import MaskedInput   from 'react-text-mask';
import Input        from '@material-ui/core/Input';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      render={(setRef, props) => (
        <input
          ref={ref => {
            inputRef(ref);
            setRef(ref);
          }}
          {...props}
        />
      )}
      {...other}
    />
  );
}

class InputRegex extends React.Component {
  static propTypes = {
    'state-name': PropTypes.string.isRequired,
    'classes':      PropTypes.object,
    'mask': PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.func,
      PropTypes.bool,
      PropTypes.shape({
        mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
        pipe: PropTypes.func,
      }),
    ]).isRequired,
    'guide':             PropTypes.bool,
    'pipe':              PropTypes.func,
    'placeholderChar':   PropTypes.string,
    'keepCharPositions': PropTypes.bool,
    'showMask':          PropTypes.bool,
  };

  static defaultProps = {
    guide:           false,
    showMask:        true,
    placeholderChar: '\u2000',
  };

  render() {
    const {
      mask,
      guide,
      pipe,
      placeholderChar,
      keepCharPositions,
      showMask,
      inputProps,
      ...other
    } = this.props;

    return (
      <Input
        inputComponent={TextMaskCustom}
        inputProps={{
          ...inputProps,
          mask,
          guide,
          pipe,
          placeholderChar,
          keepCharPositions,
          showMask,
        }}
        {...other}
      />
    );
  }
}

export default InputRegex;
