import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/metadata';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { metadataRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const METADATA_SET_CURRENT = '@App/metadata/METADATA_SET_CURRENT';
export const METADATA_FETCH_START = '@App/metadata/METADATA_FETCH_START';
export const METADATA_FETCH_DONE  = '@App/metadata/METADATA_FETCH_DONE';
export const METADATA_FETCH_LIST_START = '@App/metadata/METADATA_FETCH_LIST_START';
export const METADATA_FETCH_LIST_DONE = '@App/metadata/METADATA_FETCH_LIST_DONE';
export const METADATA_SET_ERRORS = '@App/metadata/METADATA_SET_ERRORS';
export const CLEAR_STATE = '@App/metadata/CLEAR_STATE';
export const FIND_METADATUM_REQUEST = '@App/metadata/FIND_METADATUM_REQUEST';
export const DESTROY_METADATA_REQUEST = '@App/metadata/DESTROY_METADATA_REQUEST';
export const UPDATE_METADATA_REQUEST = '@App/metadata/UPDATE_METADATA_REQUEST';
export const CREATE_METADATA_REQUEST = '@App/metadata/CREATE_METADATA_REQUEST';
export const FETCH_METADATA_REQUEST  = '@App/metadata/FETCH_METADATA_REQUEST';
export const METADATA_CHANGE_LIST  = '@App/metadata/METADATA_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [METADATA_FETCH_START]: fetchStart,
    [METADATA_FETCH_DONE]:  fetchDone,
    [METADATA_FETCH_LIST_START]: fetchListStart,
    [METADATA_FETCH_LIST_DONE]: fetchListDone,
    [METADATA_SET_ERRORS]: setErrors,
    [METADATA_SET_CURRENT]: setCurrent,
    [DESTROY_METADATA_REQUEST]: initLoading,
    [CREATE_METADATA_REQUEST]: initLoading,
    [UPDATE_METADATA_REQUEST]: initLoading,
    [METADATA_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id) {
  return {
    type: FIND_METADATUM_REQUEST,
    payload: { id }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_METADATA_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_METADATA_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_METADATA_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_METADATA_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'metadata',

  routes: {
    list: () => metadataRoute.list.build(),
    show: (id) => metadataRoute.show.build({id}),
    new: () => metadataRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      METADATA_FETCH_START,
    FETCH_DONE:       METADATA_FETCH_DONE,
    FETCH_LIST_START: METADATA_FETCH_LIST_START,
    FETCH_LIST_DONE:  METADATA_FETCH_LIST_DONE,
    CHANGE_LIST:      METADATA_CHANGE_LIST,
    CHANGE_CURRENT:   METADATA_SET_CURRENT,
    SET_ERRORS:       METADATA_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_METADATUM_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_METADATA_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_METADATA_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_METADATA_REQUEST, crudActions.createAction),
  takeLatest(FETCH_METADATA_REQUEST, crudActions.fetchListAction),
]);