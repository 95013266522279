import React from 'react';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import useStyles from './styles';

function ToggleCondition({
  condition,
  setCondition,
}) {
  const classes = useStyles();

  return (
    <Grid container component="label" alignItems="center" justify="flex-end" direction="row">
      <Grid item>Todas</Grid>
      <Grid item>
        <Switch
          classes={classes}
          checked={condition === 'OR'}
          onChange={() => setCondition(condition === 'OR' ? 'AND' : 'OR')}
        />
      </Grid>
      <Grid item>Qualquer</Grid>
    </Grid>
  );
}

export default React.memo(
  ToggleCondition,
  (prevProps, nextProps) => prevProps.condition === nextProps.condition
);
