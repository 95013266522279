import styled from 'styled-components';
import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import MUITypography from '@material-ui/core/Typography';
import MUILink from '@material-ui/core/Link';

export const Typography = styled(MUITypography)`
  color: #4F3A8B;
  font-size: 1rem;
  font-weight: 600;
`;

export const Link = styled(MUILink)`
  color: #898886;
  font-size: 1rem;
  font-weight: 300;
`;

export const Breadcrumbs = styled(MUIBreadcrumbs)`
  margin-left: 30px;
`;
