/* eslint-disable react/prop-types */
import React     from 'react';
import PropTypes from 'prop-types';

import InputRegex from '../InputRegex';

const CPF_MASK  = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
const CPF_MASK_FORCED  = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const CNPJ_MASK = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const getMaskFromValue = (value) => {
  const arrayValue = (`${value}`).match(/\d/g);
  let numb = '';
  if (Array.isArray(arrayValue)){
    numb = arrayValue.join('');
  }

  if(numb.length > 11){
    return CNPJ_MASK;
  }else{
    return CPF_MASK;
  }
};

class InputCpfCnpj extends React.Component {

  static propTypes = {
    force: PropTypes.oneOf(['cpf', 'cnpj'])
  }

  static getDerivedStateFromProps(nextProps) {
    const { value, force } = nextProps;
    if( force ){
      return {mask: (force === 'cpf' ? CPF_MASK_FORCED : CNPJ_MASK) };
    }else{
      return {mask: getMaskFromValue(value) };
    }
  }

  state = {
    mask: CPF_MASK, // CPF
  };

  render() {
    // eslint-disable-next-line
    const { force, ...otherProps } = this.props;
    
    return (
      <InputRegex
        {...otherProps}
        mask={this.state.mask}
      />
    );
  }
}

export default InputCpfCnpj;
