import React, { useCallback } from 'react';
import moment from 'moment';
import Tooltip from '~/components/Tooltip';

import {
  Container,
  PercentText,
  Percent,
  PercentageCompleted
} from './styles';

const calculateDatePercentage = (startDate, date) => {
  const endDateInDays = moment(date).diff(startDate, 'days');
  const currentDateInDays = moment(date).diff(moment(), 'days');
  const percentage = (currentDateInDays * 100) / endDateInDays;
  const formattedPercentage = Math.abs(percentage - 100).toFixed(2);

  if (formattedPercentage >= 100) return 100;

  return formattedPercentage;
};

function StatusPercent({ startDate, endDate, extraEndDate }) {
  const getTextPercentage = useCallback(() => {
    const percentage = calculateDatePercentage(startDate, extraEndDate || endDate);
    if (percentage > 98) {
      return 98;
    }

    return percentage;
  }, [startDate, extraEndDate, endDate]);

  const getPercentage = useCallback(() => {
    const percentage = calculateDatePercentage(startDate, extraEndDate || endDate);
    if (percentage >= 100) {
      return 100;
    }

    const [integerPart, decimalPart] = percentage.split('.');
    if (parseInt(decimalPart) > 0) {
      return percentage;
    }

    return parseInt(integerPart);
  }, [startDate, endDate, extraEndDate]);

  const textPercentage = getTextPercentage();
  const percentage = getPercentage();

  return (
    <Container>
      <PercentText paddingLeft={textPercentage}>
        {percentage}%
      </PercentText>

      <div className="percent-container">
        <Percent>
          {!!extraEndDate && <div className="divisor" />}
          <PercentageCompleted width={percentage || 1} />
        </Percent>
      </div>
      <div className="dates">
        <Tooltip title="Início da obra">
          <span className="start">
            {moment(startDate).format("DD/MM/YYYY")}
          </span>
        </Tooltip>
        <Tooltip title="Previsão de conclusão inicial">
          <span className={`end ${extraEndDate ? 'with_extra' : ''}`}>
            {moment(endDate).format("DD/MM/YYYY")}
          </span>
        </Tooltip>
        { extraEndDate && (
          <Tooltip title="Previsão de conclusão atual">
            <span className="extra_end">
              {moment(extraEndDate).format("DD/MM/YYYY")}
            </span>
          </Tooltip>
        )}
      </div>
    </Container>
  );
}

export default StatusPercent;
