import SchemaBuilder from './SchemaBuilder';

const columns = {
  due_date:   {type: 'date',   title: 'Vencimento'},
	slip_value: {type: 'float',  title: 'Valor'},
	attachment: {type: 'object', title: 'Arquivo'},
	created_at:  { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:  { type: 'datetime', default: '', title: 'Atualizado em'},
};

const bankSlipSchema = new SchemaBuilder( columns );

export default bankSlipSchema;