import RecordBase from './Base';
import personSchema from './schema/personSchema';
import ArchiveRecord from '~/records/ArchiveRecord';
import AddressRecord from '~/records/AddressRecord';
import UserRecord from '~/records/UserRecord';
import ContactRecord from '~/records/ContactRecord';

export default class PersonRecord extends RecordBase {
  static defaultAttributes = personSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    if(!this._attributeNames){
      this._attributeNames = personSchema.attributeNames;
    }
    return this._attributeNames;
  }

  static hasMany = {
    attachments: ArchiveRecord,
    contacts: ContactRecord,
  };

  static belongsTo = {
    avatar: ArchiveRecord,
    address: AddressRecord,
    user: UserRecord,
  };

  updateAddress(attrs) {
    const address = this.address || new AddressRecord();

    return { address: address.updateAttributes(attrs) };
  }

  updateUser(attrs) {
    const user = this.user || new UserRecord();
    return { user: user.updateAttributes(attrs) };
  }

  toParams(extra={}) {
    const {
      name,
      is_legal,
      rg,
      legal_name,
      cpf_cnpj,
      email,
      birth_date,
      phone,
      cell_phone,
      comercial_phone,
      residential_phone,
      state_registration,
      municipal_registration,
      icms_exempt,
      is_simple,
      share_capital,
      observation,
      address
    } = this.toObject();

    return {
      data:{
        attributes:{
          name,
          legal_name,
          is_legal,
          rg,
          cpf_cnpj: cpf_cnpj?.replace(/\D/g, ''),
          email,
          birth_date,
          phone: phone?.replace(/\D/g, ''),
          cell_phone: cell_phone?.replace(/\D/g, ''),
          comercial_phone: comercial_phone?.replace(/\D/g, ''),
          residential_phone: residential_phone?.replace(/\D/g, ''),
          is_customer: true,
          state_registration,
          municipal_registration,
          is_simple: is_simple?.id,
          icms_exempt: icms_exempt?.id,
          share_capital,
          observation,
          address_attributes: address ? address.nestedParams() : undefined,
          avatar_id: this.get('avatar')?.get('id') || null,
          attachments_ids:     ArchiveRecord.getNewFiles( this.get('attachments') ),
          del_attachments_ids: ArchiveRecord.getDeleteFiles( this.get('attachments') ),
          user_attributes: this.get('user') ? this.get('user').nestedParams()  : undefined,
          contacts_attributes: this.get('contacts').toArray().map( ([, c]) => c.nestedParams() ),
          ...extra
        }
      }
    };
  }
}

window.PersonRecord = PersonRecord