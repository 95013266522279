import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/builders';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { buildersRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const BUILDERS_SET_CURRENT = '@App/builders/BUILDERS_SET_CURRENT';
export const BUILDERS_FETCH_START = '@App/builders/BUILDERS_FETCH_START';
export const BUILDERS_FETCH_DONE  = '@App/builders/BUILDERS_FETCH_DONE';
export const BUILDERS_FETCH_LIST_START = '@App/builders/BUILDERS_FETCH_LIST_START';
export const BUILDERS_FETCH_LIST_DONE = '@App/builders/BUILDERS_FETCH_LIST_DONE';
export const BUILDERS_SET_ERRORS = '@App/builders/BUILDERS_SET_ERRORS';
export const CLEAR_STATE = '@App/builders/CLEAR_STATE';
export const FIND_BUILDER_REQUEST = '@App/builders/FIND_BUILDER_REQUEST';
export const DESTROY_BUILDERS_REQUEST = '@App/builders/DESTROY_BUILDERS_REQUEST';
export const UPDATE_BUILDERS_REQUEST = '@App/builders/UPDATE_BUILDERS_REQUEST';
export const CREATE_BUILDERS_REQUEST = '@App/builders/CREATE_BUILDERS_REQUEST';
export const FETCH_BUILDERS_REQUEST  = '@App/builders/FETCH_BUILDERS_REQUEST';
export const BUILDERS_CHANGE_LIST  = '@App/builders/BUILDERS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [BUILDERS_FETCH_START]: fetchStart,
    [BUILDERS_FETCH_DONE]:  fetchDone,
    [BUILDERS_FETCH_LIST_START]: fetchListStart,
    [BUILDERS_FETCH_LIST_DONE]: fetchListDone,
    [BUILDERS_SET_ERRORS]: setErrors,
    [BUILDERS_SET_CURRENT]: setCurrent,
    [DESTROY_BUILDERS_REQUEST]: initLoading,
    [CREATE_BUILDERS_REQUEST]: initLoading,
    [UPDATE_BUILDERS_REQUEST]: initLoading,
    [BUILDERS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}, callbackSuccess) {
  return {
    type: FIND_BUILDER_REQUEST,
    payload: { id, params, callbackSuccess }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_BUILDERS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_BUILDERS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_BUILDERS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_BUILDERS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: BUILDERS_SET_CURRENT,
    payload: { current }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'builders',

  routes: {
    list: () => buildersRoute.list.build(),
    show: (id) => buildersRoute.show.build({id}),
    new: () => buildersRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      BUILDERS_FETCH_START,
    FETCH_DONE:       BUILDERS_FETCH_DONE,
    FETCH_LIST_START: BUILDERS_FETCH_LIST_START,
    FETCH_LIST_DONE:  BUILDERS_FETCH_LIST_DONE,
    CHANGE_LIST:      BUILDERS_CHANGE_LIST,
    CHANGE_CURRENT:   BUILDERS_SET_CURRENT,
    SET_ERRORS:       BUILDERS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_BUILDER_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_BUILDERS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_BUILDERS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_BUILDERS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_BUILDERS_REQUEST, crudActions.fetchListAction),
]);