import React, { useState, useRef, memo, forwardRef, useImperativeHandle } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import IconSearch from '@material-ui/icons/SearchOutlined';
import PropTypes from 'prop-types';

import { Container, Input } from './styles';

const KEY_CODE_ENTER = 13;

function InputSearchComponent({
  containerStyle,
  inputStyle,
  iconColor,
  onSubmit,
  initialValue
}, inputSearchRef) {
  const [inputValue, setInputValue] = useState(initialValue);
  const inputRef = useRef(null);

  useImperativeHandle(inputSearchRef, () => ({
    getValue() {
      return inputValue;
    }
  }));

  function onClick() {
    inputRef.current.focus();
  }

  function onChange({ target }) {
    setInputValue(target.value);
  }

  function onKeyDown(event) {
    if (event.keyCode === KEY_CODE_ENTER) {
      onSubmit(inputValue);
    }
  }

  function handleButtonClick() {
    onSubmit(inputValue)
  }

  return (
    <Container
      onClick={onClick}
      style={containerStyle}
    >
      <Input
        ref={inputRef}
        style={inputStyle}
        value={inputValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />

      <Tooltip disableTouchListener title="Pesquisar">
        <IconButton onClick={handleButtonClick}>
          <IconSearch htmlColor={iconColor} />
        </IconButton>
      </Tooltip>
    </Container>
  );
}

const InputSearch = forwardRef(InputSearchComponent);

InputSearch.defaultProps = {
  containerStyle: {},
  inputStyle: {},
  iconColor: '#9C55F5',
  initialValue: '',
  onSubmit() {}
}

InputSearch.propTypes = {
  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  iconColor: PropTypes.string,
  initialValue: PropTypes.string,
  onSubmit: PropTypes.func
}

export default memo(InputSearch);
