function isSelected(row) {
  return row.selected || !!row.construction_account_plan_id;
}

export default function getSelectedRows(rows) {
  const selectedRows = [];

  for (const grandPaRow of rows) {
    if (isSelected(grandPaRow)) {
      selectedRows.push(grandPaRow);
    }

    if (grandPaRow.items) {
      for (const fatherRow of grandPaRow.items) {
        if (isSelected(fatherRow)) {
          selectedRows.push(fatherRow);
        }

        if (fatherRow.items) {
          fatherRow.items.forEach(sonRow => {
            if (isSelected(sonRow)) {
              selectedRows.push(sonRow);
            }
          })
        }
      }
    }
  }

  return selectedRows;
}
