import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import isPresent from '~/utils/isPresent';
import Button from '~/components/Button';
import Dialog from '~/components/Dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: '100%',
  },
}));

function RateItemsDialog({expense, invoiceItems, handleClose, onSelect}) {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([])

  useEffect( () => {
    if(!expense){
      setSelectedItems([])
    } else {
      setSelectedItems(
        invoiceItems.filter(item => `${item.accountPlanID}` === `${expense.get('account_plan')?.get('id')}` ).map( e => e.idOrToken )
      )
    }

  }, [invoiceItems])

  const handleToggle = useCallback(
    (idOrToken) => () => {
      const currentIndex = selectedItems.indexOf(idOrToken);
      const newChecked = [...selectedItems];

      if (currentIndex === -1) {
        newChecked.push(idOrToken);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setSelectedItems(newChecked);
    },
    [selectedItems, invoiceItems],
  )

  return(
    <Dialog
      maxWidth="sm"
      fullWidth
      isOpen={ isPresent(expense) }
      handleClose={handleClose}
      title={'Rateio de itens'}
      dialogActions={
        <>
          <Button onClick={handleClose} color="primary" variant="text">
            FECHAR
          </Button>
          <Button onClick={() => onSelect(selectedItems) } color="success" variant="text">
            APLICAR
          </Button>
        </>
      }
    >
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Produto</TableCell>
              <TableCell align="right">Quantidade</TableCell>
              <TableCell align="right">Val. Unit</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { invoiceItems.map( (item) => {
              const labelId = `checkbox-list-label-${item.idOrToken}`;

              return <TableRow key={item.idOrToken}>
                <TableCell>
                  <Checkbox
                    edge="start"
                    checked={selectedItems.indexOf(item.idOrToken) !== -1}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onChange={ handleToggle(item.idOrToken) }
                  />
                </TableCell>
                <TableCell>
                  <label htmlFor={labelId}>{`${item.product}`}</label>
                </TableCell>
                <TableCell align="right">{item.amount}</TableCell>
                <TableCell align="right">{item.unit_value}</TableCell>
                <TableCell align="right">{item.total_value}</TableCell>
              </TableRow>
            } ) }
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}

export default RateItemsDialog