import Request from '~/services/request';
import queryStringParser from '~/utils/queryStringParser';

export const constructionsAsyncFetch = (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/constructions?&qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data
      }
    )
  })
  .catch(err => err);
};

export const constructionsSiwthParamsAsyncFetch = params => (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/constructions?&${queryStringParser({
    qs: inputValue,
    page,
    ...params
  })}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data
      }
    )
  })
  .catch(err => err);
};

export const constructionAsyncShow =  (id) => {
  return Request.get(`/api/v1/search/constructions/${id}`).then(resp => {
    if(resp.data && resp.data.data){
      return resp.data.data;
    }
    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}