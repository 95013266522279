import React from 'react';

import BaseLayout from '~/components/BaseLayout';
import CrudActions from '~/components/CrudActions';
import Spinner from '~/components/Spinner';

function LayoutShow({ loading, breadcrumbs, crudActionsProps, children }) {
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <CrudActions {...crudActionsProps} />
      { loading &&  <Spinner fullScreen loadingText="Carregando.." /> }
      {children}
    </BaseLayout>
  );
}

export default LayoutShow;
