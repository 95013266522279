import React, { useMemo, useCallback } from 'react';

import Card        from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input       from '@material-ui/core/Input';

import CustomRecordRecord from '~/records/CustomRecordRecord';

import InputControl  from '~/components/Input/InputControl';
import GridContainer from '~/components/GridContainer';
import Col           from '~/components/Col';

import CustomInput from './components/CustomInput'
import getMetaFields from '../../utils/getMetaFields';

const attrName = (attr) => CustomRecordRecord.hummanAttributeName(attr);

export default function FormFields({ record, onChange, metaSettings }) {
  const metaFields = useMemo(
    () => getMetaFields(metaSettings)
    , [metaSettings]
  )

  const onChangeCustomField = useCallback( attrs => {
    const customFields = record.get('custom_fields');
    onChange( {
      custom_fields: {...customFields, ...attrs}
    } )
  }, [record, onChange]);

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col md={12}>
            <InputControl
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <GridContainer>
          { Object.values(metaFields).filter( metaField => metaField.active ).map( (metaField) => {
            const fieldKey = metaField.field.key;
            return(
              <Col key={fieldKey} xs={12} sm={6} md={metaField.field_size}>
                <CustomInput
                  fieldKey={fieldKey}
                  metaField={metaField}
                  onChange={onChangeCustomField}
                  value={ (record.get('custom_fields') || {})[fieldKey] || null }
                />
              </Col>
            )
          } ) }
        </GridContainer>
      </CardContent>
    </Card>
  );
}