import RecordBase from './Base';
import invoiceItemSchema from './schema/invoiceItemSchema';
import ProductRecord from './ProductRecord';
import AccountPlanRecord from './AccountPlanRecord';

export default class InvoiceItemRecord extends RecordBase {
  static defaultAttributes = invoiceItemSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return invoiceItemSchema.attributeNames }

  static belongsTo = {
    product:   ProductRecord,
    account_plan: AccountPlanRecord
  };

  nestedParams() {
    return {
      id:              this.get('id') || undefined,
      product_id:      this.get('product')?.get('id') || null,
      code:            this.get('code'),
      amount:          this.get('amount'),
      unit_value:      this.get('unit_value'),
      total_value:     this.get('total_value'),
      account_plan_id: this.get('account_plan')?.get('id') || null,
      _destroy:    this.get('_destroy') || undefined
    };
  }
}
