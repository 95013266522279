export function initLoading(draft){
  draft.errors = [];
  draft.isLoading = true;
}

export function fetchListStart(draft){
  draft.listIsLoading = true;
}

export function fetchListDone(draft){
  draft.listIsLoading = false;
}

export function setErrors(draft, {payload}){
  draft.errors = payload ? payload.errors : [];
  draft.isLoading = false;
}

export function clearCurrent(draft){
  draft.current = null;
  draft.isLoading = false;
}

export function resetState(draft){
  draft.current    = undefined;
  draft.data       = [];
  draft.errors     = [];
  draft.isLoading  = false;
  draft.listIsLoading  = false;
}

export function fetchStart(draft, {payload}){
  draft.current = payload?.current;
  draft.isLoading = true;
}

export function fetchDone(draft){
  draft.isLoading = false;
}

export function setCurrent(draft, {payload}){
  draft.current = payload?.current;
}

export function setChangeList(draft, {payload}){
  draft.listIsLoading  = false;
  draft.data = payload?.data;
  draft.errors = payload?.errors || [];
}