import React from 'react';
import Typography from '@material-ui/core/Typography';
import formatNumber from '~/utils/Number/formatNumber';

export default function ListShowValue({register, metaField}){
  const { field } = metaField;
  const value = register[field.key];

  if( ['record', 'unique_choice'].includes(field.kind) ){
    return <Typography>{value && value.name ? value.name : ''}</Typography>
  }

  if( field.kind === 'multiple_choice' ){
    return <Typography>{value && value.length ? value.map(e => `[${e.name}]`).join(' ') : ''}</Typography>
  }

  if( field.kind === 'float' && value ){
    return <Typography>{ formatNumber(value, { precision: field.size_value }) }</Typography>
  }

  if( field.kind === 'boolean' ){
    return <Typography>{ value ? 'Sim' : 'Não' }</Typography>
  }

  return <Typography>{value ? String(value) : ''}</Typography>
}