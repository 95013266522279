import RecordBase from './Base';
import AddressRecord from './AddressRecord';
import ArchiveRecord from '~/records/ArchiveRecord';
import userSchema from './schema/userSchema';

export default class UserRecord extends RecordBase {
  static defaultAttributes = userSchema.defaultAttributes;

  static _attributeNames = null;

  static belongsTo = { address: AddressRecord, avatar: ArchiveRecord };

  static get attributeNames() {
    return userSchema.attributeNames;
  }

  toParams() {
    const {
      name,
      email,
      password,
      access_type,
      password_confirmation,
    } = this.toObject();

    return {
      data:{
        attributes:{
          name,
          email,
          access_type,
          avatar_id: this.get('avatar')?.get('id') || null,
          password: password || undefined,
          password_confirmation: password_confirmation || undefined,
          address_attributes: this.get('address')?.nestedParams(),
        }
      }
    };
  }

  nestedParams() {
    const {
      id,
      name,
      email,
      password,
      password_confirmation,
      disabled_at
    } = this.toObject();

    if( !!disabled_at && !id){
      return
    }

    return {
      id,
      name,
      email,
      avatar_id: this.get('avatar')?.get('id') || null,
      password: password || undefined,
      password_confirmation: password_confirmation || undefined,
      disabled_at
    }
  }

  updateAddress(attrs) {
    const address = this.get('address') || new AddressRecord();
    return this.updateAttributes({ address: address.updateAttributes(attrs) });
  }
}
