import React from 'react';
import MuiButtonBase from '@material-ui/core/Button';
import Button        from '~/components/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const MuiButton = (props) =>
  <MuiButtonBase {...props} style={{padding: '12px 30px', margin: '.3125rem 1px'}} />;

const RenderButtons = ({color}) => (
  <Grid container spacing={3}>
    <Grid item xs={2}>
      <Typography variant='subtitle1'>{color ? color : 'Default'}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Button color={color}>Default</Button>
    </Grid>
    <Grid item xs={2}>
      <Button variant="outlined" color={color}>
        Outlined
      </Button>
    </Grid>
    <Grid item xs={2}>
      <Button variant="contained" color={color}>
        Contained
      </Button>
    </Grid>
    <Grid item xs={2}>
      <Button fullWidth variant="text" color={color}>
        Text btn
      </Button>
    </Grid>
  </Grid>
);

const ExampleButton = () => {
  return(
    <div style={{margin: 15}}>
      <div>
        <Typography>Default material-ui</Typography>
        <div>
          <MuiButton color="primary">Default</MuiButton>
          <MuiButton variant="outlined" color="primary">
            Outlined
          </MuiButton>
          <MuiButton variant="contained" color="primary">
            Contained
          </MuiButton>
          <MuiButton variant="text" color="primary">
            Text
          </MuiButton>
        </div>
        <div>
          <MuiButton color="secondary">Default</MuiButton>
          <MuiButton variant="outlined" color="secondary">
            Outlined
          </MuiButton>
          <MuiButton variant="contained" color="secondary">
            Contained
          </MuiButton>
          <MuiButton variant="text" color="secondary">
            Text
          </MuiButton>
        </div>
      </div>
      <hr />
      <div>
        <Typography>Customizados</Typography>
        <br/>
        <RenderButtons />
        <RenderButtons color="primary"/>
        <RenderButtons color="secondary"/>
        <RenderButtons color="success"/>
        <RenderButtons color="warning"/>
        <RenderButtons color="error"/>
        <RenderButtons color="rose"/>
        <RenderButtons color="blue"/>
        <RenderButtons color="purple"/>
        <RenderButtons color="black"/>
        <RenderButtons color="green"/>
        <RenderButtons color="orange"/>
        <RenderButtons color="red"/>
        <RenderButtons color="grey"/>
      </div>
      <hr />
      <div>
        <Typography>Tamanhos</Typography>
        <Button color="primary" size='small' >button small</Button>
        <span>{' > '}</span>
        <Button color="primary" size='medium' >button medium</Button>
        <span>{' > '}</span>
        <Button color="primary" size='large' >button large</Button>
      </div>
    </div>
  );
};

export default ExampleButton;
