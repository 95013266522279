import React, { memo } from 'react';
import dig from 'object-dig';
import moment from 'moment';
import PropTypes from 'prop-types';

import isPresent from '~/utils/isPresent';
import formatNumber from '~/utils/Number/formatNumber';
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import formatPhone from '~/utils/formatPhone';

import { ReadOnlyInput, BoxInputHtml } from './styles';

const TYPES = {
  BOOLEAN: 'boolean',
  FLOAT: 'float',
  STRING: 'string',
  DATE: 'date',
  DATETIME: 'datetime',
  CPF_CNPJ: 'cpf_cnpj',
  PHONE_CELLPHONE: 'phone_cellphone',
  INTEGER: 'integer',
  TEXT: 'text'
};

function InputShow({
  type,
  value,
  floatPrecision,
  digObject,
  isHtml,
  ...props
}) {
  function getValue() {
    if (digObject) {
      return dig(value, ...digObject);
    }

    return value;
  }

  function formatValue() {
    switch (type) {
      case TYPES.BOOLEAN:
        return isPresent(getValue()) ? 'Sim' : 'Não';
      case TYPES.FLOAT:
        return formatNumber(getValue(), { precision: floatPrecision });
      case TYPES.INTEGER:
        if (!getValue()) return '';
        return formatNumber(getValue(), { precision: 0 });
      case TYPES.DATE:
        if (!getValue()) return '';
        return moment(getValue()).format('DD/MM/YYYY');
      case TYPES.DATETIME:
        if (!getValue()) return '';
        return moment(getValue()).format('DD/MM/YYYY HH:mm:ss');
      case TYPES.CPF_CNPJ:
        return formatCpfCnpj(getValue());
      case TYPES.PHONE_CELLPHONE:
        return formatPhone(getValue());
      default:
        return getValue() ?? '';
    }
  }

  if( isHtml ){
    props.InputProps = {
      inputComponent: BoxInputHtml
    }
  }

  return <ReadOnlyInput {...props} value={formatValue()} />;
}

InputShow.defaultProps = {
  type: 'string',
  floatPrecision: 2,
  isHtml: false,
};

InputShow.propTypes = {
  type: PropTypes.oneOf([
    TYPES.STRING,
    TYPES.BOOLEAN,
    TYPES.FLOAT,
    TYPES.DATE,
    TYPES.DATETIME,
    TYPES.CPF_CNPJ,
    TYPES.PHONE_CELLPHONE,
    TYPES.INTEGER,
    TYPES.TEXT,
  ]),
  digObject: PropTypes.arrayOf(PropTypes.string),
  floatPrecision: PropTypes.number,
  isHtml: PropTypes.bool,
};

export default memo(InputShow);
