import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

import { findRecord, destroyRecord, clearState } from '~/store/modules/invoices';
import { invoicesRoute } from '~/routes'
import formatToMoney from '~/utils/formatToMoney';
import invoiceStatusEnum from '~/enums/invoiceStatusEnum';
import invoiceExpenseTypeEnum from '~/enums/invoiceExpenseTypeEnum';

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import InvoiceRecord from '~/records/InvoiceRecord';
import Divider from '~/components/Divider';
import AttachmentsPreview from '~/components/AttachmentsPreview';

import findIncludeQuery from './utils/findIncludeQuery'
import ItemTable from './components/ItemTable'

const attrName = (attr) => InvoiceRecord.hummanAttributeName(attr);

function Show({ invoice, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [record, setRecord] = useState( new InvoiceRecord() )

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch( findRecord(id, findIncludeQuery) );

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if(invoice){
      setRecord( new InvoiceRecord(invoice) )
    }
  }, [invoice]);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: invoicesRoute.list.path, text: 'Notas fiscais' },
        { text: 'Exibindo nota fiscal' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   invoicesRoute.new.build(),
        routeCloneRegister: invoicesRoute.clone.build({ id }),
        routeEditRegister:  invoicesRoute.edit.build({ id }),
        permissionKeyCode:  'Invoice'
      }}
    >
      { !isLoading && <>
        <Card>
          <CardHeader title="Dados Básicos" />
          <CardContent>
            <GridContainer>
              <Col md={4}>
                <InputShow
                  label={attrName('construction')}
                  type="string"
                  value={record.get('construction')?.get('name')}
                />
              </Col>
              <Col md={4}>
                <InputShow
                  label={attrName('supplier')}
                  type="string"
                  value={record.get('supplier')?.get('name')}
                />
              </Col>
              <Col md={4}>
                <InputShow
                  label={attrName('nf_date')}
                  type="date"
                  value={record.get('nf_date')}
                />
              </Col>

              <Col md={4} lg={3}>
                <InputShow
                  label={attrName('nf_number')}
                  type="string"
                  value={record.get('nf_number')}
                />
              </Col>
              <Col md={4} lg={3}>
                <InputShow
                  label={attrName('expense_type')}
                  type="string"
                  value={invoiceExpenseTypeEnum().find(record.get('expense_type'))?.name}
                />
              </Col>
              <Col md={4} lg={3}>
                <InputShow
                  label={attrName('status')}
                  type="string"
                  value={invoiceStatusEnum().objects()[record.get('status')]}
                />
              </Col>
              <Col md={4} lg={3}>
                <InputShow
                  label={attrName('total_value')}
                  type="float"
                  value={record.get('total_value')}
                />
              </Col>
            </GridContainer>

            <Divider/>

            <GridContainer>
              <Col xs={12}>
                <Typography variant="h6">Rateio de planos de conta</Typography>
              </Col>
              <Col xs={12}>
                <TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Plano de conta</TableCell>
                        <TableCell>Observações</TableCell>
                        <TableCell align="right">Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.get('expenses').valueSeq().toArray().map((row) => (
                        <TableRow key={row.idOrToken}>
                          <TableCell>{row.get('account_plan')?.get('name')}</TableCell>
                          <TableCell>{row.get('notes')}</TableCell>
                          <TableCell align="right">R$ {formatToMoney( row.get('total_value') )}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </GridContainer>

            <Divider/>

            <GridContainer>
              <Col xs={12}>
                <Typography variant="h6">Boletos</Typography>
              </Col>
              <Col xs={12}>
                <TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Vencimento</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell align="right">Arquivo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.get('bank_slips').valueSeq().toArray().map((row) => (
                        <TableRow key={row.idOrToken}>
                          <TableCell>{row.get('due_date')}</TableCell>
                          <TableCell>R$ {formatToMoney(row.get('slip_value'))}</TableCell>
                          <TableCell align="right">
                            {row.get('attachment')?.url && (
                              <IconButton component='a' href={row.get('attachment')?.url} target='_blank'>
                                <GetAppIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </GridContainer>
          </CardContent>
        </Card>
        { record.get('invoice_items').size > 0 && <ItemTable items={record.get('invoice_items')} /> }

        <AttachmentsPreview
          title="Outros Anexos"
          attachments={record.get('attachments')}
        />
      </> }
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.invoices.isLoading,
  invoice: state.invoices.current,
});

export default connect(mapStateToProps)(Show);