import React, { createContext, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import isBlank from '~/utils/isBlank';

const PermissionsContext = createContext({
  permissions: [],
});

export const PermissionsProvider = ({ children }) => {
  const { permissions = {}, access_type } = useSelector(state => state.userProfile.data);

  const can = useCallback((keyCode, permission, onlyMaster=false, allowCustomer=false) => {
    if( isBlank(keyCode) ) return true;

    if(onlyMaster){ return access_type === 'master' }

    if(allowCustomer && access_type === 'customer'){ return true }

    if(['admin','master'].includes(access_type)){ return true }

    if (permission === 'see') return !!permissions[keyCode];

    return !!permissions[keyCode]?.[permission];
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={{ can }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
};

export default PermissionsContext;
