import React, { useState, memo } from 'react';
import { SketchPicker } from 'react-color';

function InputColorPicker(props) {
  const {
    value,
    onChange,
    'state-name': stateName,
    ...rest
  } = props;
  const [ showPicker, setShowPicker ] = useState( false );

  function handleChange(data, event){
    onChange({
      [stateName]: data.hex
    }, event);
  }

  const switchPicker = ()=> setShowPicker(!showPicker)

  return (
    <div style={{ paddingTop: 20 }}>
      <div style={{
        padding: 5,
        backgroundColor: '#e3e3e3',
        borderRadius: 2,
        display: 'inline-block',
        cursor: 'pointer',
      }} onClick={ switchPicker }>
        <div style={{ backgroundColor: value, width: 60, height: 15 }}/>
      </div>
      { showPicker &&
        <div onClick={ switchPicker }>
          <SketchPicker color={ value } onChangeComplete={ handleChange } {...rest}/>
        </div>}
    </div>
  );
}
export default memo(InputColorPicker);