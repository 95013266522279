import React, { useState, useLayoutEffect, createContext, useMemo } from 'react';

import Route from '../services/route';

const initialParams = {
  page: 1,
  per_page: 20,
  sort: '',
  direction: '',
  filters: '',
  qs: '',
  size: 0, // Filters quantity
  total: 0,
  finishedInitialLoading: false,
}

const QueryParamsContext = createContext({
  params: initialParams,
  setParams() {},
  paramsAlreadyIncluded() {}
});

export function QueryParamsProvider({ children }) {
  const [params, setQueryParams] = useState(initialParams);
  const route = useMemo(() => new Route('/'), []);

  useLayoutEffect(() => {
    const urlParams = route.getCurrentUrlParams();
    const newParams = {};

    Object.keys(urlParams).map(param => {
      if (param in params) {
        const toInt = ['page', 'per_page', 'size'].includes(param);
        newParams[param] = toInt
          ? Number(urlParams[param])
          : urlParams[param];
      }
    });

    newParams.finishedInitialLoading = true;

    setParams({ ...params, ...newParams });
  }, []);

  function setParams(newParams) {
    const { page, per_page, sort, direction } = params;
    let sortParams = {};

    if (sort && direction) {
      sortParams = { sort, direction };
    }

    const queryParams = Object.assign(
      { page, per_page },
      sortParams,
      newParams
    );

    if ('total' in queryParams) {
      delete queryParams['total'];
    }

    if ('finishedInitialLoading' in queryParams) {
      delete queryParams['finishedInitialLoading'];
    }

    route.setParams(queryParams);
    setQueryParams({ ...params, ...newParams });
  }

  function paramsAlreadyIncluded(params = {}) {
    const urlParams = route.getCurrentUrlParams();
    let paramsIncludedCounter = 0;

    for (const key in params) {
      if (key in urlParams && params[key] == urlParams[key]) {
        paramsIncludedCounter++;
      }
    }

    const totalParams = Object.keys(params).length;

    return paramsIncludedCounter === totalParams;
  }

  return (
    <QueryParamsContext.Provider value={{ params, setParams, paramsAlreadyIncluded }}>
      {children}
    </QueryParamsContext.Provider>
  );
}

export default QueryParamsContext;
