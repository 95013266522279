import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
}));

export default function Toolbar() {
  const classes = useStyles();

  return <div className={cx(classes.toolbar, 'hidden-print') } />;
}
