import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';

import { editFilter } from '../../../../store/actions';

function InputBoolean({
  filter,
  indexGroup,
  indexFilter,
  inputProps,
}) {
  const dispatch = useDispatch();
  const options = useMemo(() => ([
    { id: true, name: 'Sim' },
    { id: false, name: 'Não' },
  ]), []);

  return (
    <InputControl
      value={filter.get('val')}
      name={'val'}
      inputComponent={InputSelect}
      InputProps={{
        placeholder: 'Valor',
        outValueType: 'bool',
        options,
        ...inputProps,
      }}
      onChange={({ val }) => {
        dispatch(editFilter(indexGroup, indexFilter, 'val', val))
      }}
    />
  );
}

export default React.memo(InputBoolean);
