import React, {useMemo} from 'react';
import { Iterable } from 'immutable';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ContactRecord from '~/records/ContactRecord';
import isPresent from '~/utils/isPresent';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';

const isImmutable = arg => Iterable.isIterable(arg);
const attrName = (attr) => ContactRecord.hummanAttributeName(attr)

export default function ContactsShow({ contacts, title }) {
  const contactsArray = useMemo(() => {
    if( isImmutable(contacts) ){
      return contacts.sortBy( (v) => (v.id)  ).toArray().map( ([, contact]) => contact )
    }
    if(Array.isArray(contacts)){ return contacts }

    return []
  }, [contacts])

  return (
    <>
      { !!title && contactsArray.length > 0 && <Typography variant="h6">{title}</Typography> }
      { contactsArray.filter(isPresent).map( contact =>
        <Card key={contact.id} style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
          <CardContent style={{ width: '100%' }}>
            <GridContainer>
              <Col sm={12} md={4}>
                <InputShow
                  label={ attrName('name') }
                  type="string"
                  value={contact.name}
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label={ attrName('email') }
                  type="string"
                  value={contact.email}
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label={ attrName('job_title') }
                  value={contact.job_title}
                />
              </Col>
            </GridContainer>
            <GridContainer>
              <Col sm={12} md={4}>
                <InputShow
                  label={ attrName('comercial_phone') }
                  type="phone_cellphone"
                  value={contact.comercial_phone}
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label={ attrName('cell_phone') }
                  type="phone_cellphone"
                  value={contact.cell_phone}
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label={ attrName('residential_phone') }
                  type="phone_cellphone"
                  value={contact.residential_phone}
                />
              </Col>
            </GridContainer>
          </CardContent>
        </Card>
      ) }
    </>
  );
}
