import createReducer from '~/store/utils/createReducer';
import { takeLatest, all, put, select } from 'redux-saga/effects';

// action types
export const CRUD_FORM_INIT                     = '@App/crud/FORM_INIT';
export const CRUD_FORM_SUBMIT_TOGGLE            = '@App/crud/FORM_SUBMIT_TOGGLE';
export const CRUD_DESTROY_LOADING_CHANGE        = '@App/crud/DESTROY_LOADING_CHANGE';
export const CRUD_FORM_SAVE_AND_CONTINUE_TOGGLE = '@App/crud/FORM_SAVE_AND_CONTINUE_TOGGLE';
export const CRUD_FORM_TOGGLE_SAVE_AND_CONTINUE_SAGA = '@App/crud/CRUD_FORM_TOGGLE_SAVE_AND_CONTINUE_SAGA';
export const CRUD_DIALOG_FORM_CHANGE = '@App/crud/CRUD_DIALOG_FORM_CHANGE';

// reducer
const initialState = {
  dialogFormIsOpen: false,
  form: {
    isSaving:        false,
    saveAndContinue: false,
  },
  destroy: {
    loading: false,
  }
};

export default createReducer(
  initialState,
  {
    [CRUD_FORM_INIT]: (draft, p, state) => {
      const form = { ...state.form };
      form.isSaving = false;
      draft.form = form;
    },
    [CRUD_DIALOG_FORM_CHANGE]: (draft, {payload}) => {
      draft.dialogFormIsOpen = payload.dialogFormIsOpen;
    },
    [CRUD_FORM_SUBMIT_TOGGLE]: (draft, {payload}, state) => {
      const form = { ...state.form };
      form.isSaving = payload.isSaving;
      draft.form = form;
    },
    [CRUD_FORM_TOGGLE_SAVE_AND_CONTINUE_SAGA]: (draft, {payload}, state) => {
      const form = { ...state.form };
      form.saveAndContinue = payload.saveAndContinue;
      draft.form = form;
    },
    [CRUD_DESTROY_LOADING_CHANGE]: (draft, {payload}, state) => {
      const destroy = { ...state.destroy };
      destroy.loading = payload.loading;
      draft.destroy = destroy;
    },
  }
);

// Actions

export const formInit = () => ({type: CRUD_FORM_INIT});

export const formToggleSubmit = (isSaving) => ({
  type: CRUD_FORM_SUBMIT_TOGGLE,
  payload: { isSaving }
});

export const dialogFormToggle = (dialogFormIsOpen) => ({
  type: CRUD_DIALOG_FORM_CHANGE,
  payload: { dialogFormIsOpen }
});

export const formToggleSaveAndContinue = () => ({type: CRUD_FORM_SAVE_AND_CONTINUE_TOGGLE});

export const crudDestroyStart = () => ({
  type: CRUD_DESTROY_LOADING_CHANGE,
  payload: { loading: true }
});

export const crudDestroyDone = () => ({
  type: CRUD_DESTROY_LOADING_CHANGE,
  payload: { loading: false }
}) ;

function getStore(store){ return store.crud  }

function* handleFormToggleSaveAndContinue() {
  const current = yield select(getStore)
  yield put({
    type: CRUD_FORM_TOGGLE_SAVE_AND_CONTINUE_SAGA,
    payload: {saveAndContinue: !current.form.saveAndContinue}
  })
}

export const saga = all([
  takeLatest(CRUD_FORM_SAVE_AND_CONTINUE_TOGGLE, handleFormToggleSaveAndContinue),
]);