import React, { useState, useCallback } from 'react';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import InputCEP from '~/components/Input/InputCEP';
import FullScreenLoading from '~/components/FullScreenLoading';

import Request from '~/services/request';
import { cityAsyncFetch } from '~/services/selectFetch/citiesFetch';
import { utilsApi } from '~/routes/api';
import AddressRecord from '~/records/AddressRecord';

const attrName = (attr) => AddressRecord.hummanAttributeName(attr);

function Address ({
  record,
  onChangeAddress,
  requiredFields = {},
}) {
  const [loading, setLoading] = useState(false);

  const handleSearchCEPByKeyEvent = useCallback(
    async (event) => {
      const KEY_CODE_ENTER = 13;

      if (event.keyCode === KEY_CODE_ENTER) {
        event.preventDefault()
        handleSearchCEP();
      }
    },
    [record]
  );

  const handleSearchCEP = useCallback(
    async () => {
      const typedCep = record.get('address')?.zip_code;
      const CEP_IS_VALID = typedCep?.length === 9;

      if (CEP_IS_VALID) {
        try {
          setLoading(true);

          const response = await Request.get(
            utilsApi.searchCep.build({cep: typedCep})
          );

          const cep = response.data?.data;
          if (cep) {
            const { city, district, state, street } = cep;
            onChangeAddress({ city, district, state, street });
          }

          setLoading(false);
        } catch(err) {
          setLoading(false);
        }
      }
    },
    [record]
  );

  return (
    <>
      <GridContainer>
        <Col md={4} lg={3}>
          <InputControl
            required={requiredFields?.zip_code}
            name="zip_code"
            label={attrName('zip_code')}
            value={record.get('address')?.zip_code || null}
            inputComponent={InputCEP}
            endAdornment={(
              <IconButton onClick={handleSearchCEP}>
                <SearchIcon />
              </IconButton>
            )}
            InputProps={{
              maxLength: 8,
              onKeyDown: handleSearchCEPByKeyEvent,
            }}
            onChange={onChangeAddress}
          />
        </Col>
        <Col md={4}>
          <InputControl
            required={requiredFields?.city}
            name="city"
            stateName={'city'}
            label={attrName('city')}
            value={record.get('address')?.city || null}
            inputComponent={InputSelectAsync}
            onChange={onChangeAddress}
            InputProps={{
              handleFetch: cityAsyncFetch
            }}
          />
        </Col>

        <Col md={4} lg={5}>
          <InputControl
            required={requiredFields?.district}
            name="district"
            label={attrName('district')}
            value={record.get('address')?.district || null}
            inputComponent={Input}
            onChange={onChangeAddress}
          />
        </Col>

        <Col md={7}  lg={5}>
          <InputControl
            required={requiredFields?.street}
            name="street"
            label={attrName('street')}
            value={record.get('address')?.street || null}
            inputComponent={Input}
            onChange={onChangeAddress}
          />
        </Col>
        <Col md={5} lg={3}>
          <InputControl
            required={requiredFields?.building_number}
            name="building_number"
            label={attrName('building_number')}
            value={record.get('address')?.building_number || null}
            inputComponent={Input}
            InputProps={{
              config: {
                allowDecimal: false,
                allowNegative: false,
                thousandsSeparatorSymbol: ''
              }
            }}
            onChange={onChangeAddress}
          />
        </Col>
        <Col md={12} lg={4}>
          <InputControl
            required={requiredFields?.complement}
            name="complement"
            label={attrName('complement')}
            value={record.get('address')?.complement || null}
            inputComponent={Input}
            onChange={onChangeAddress}
          />
        </Col>
      </GridContainer>
      {loading && <FullScreenLoading loadingText="Buscando CEP" />}
    </>
  );
}

export default Address;
