import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  .item {
    padding: 6px 0 12px 0;

    span {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .status-percent {
      margin-top: 4px;
    }
  }

  .no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    span {
      font-size: 1rem;
    }
  }
`;
