import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  h3 {
    margin: 0;
    font-size: 1.3rem;
    margin-bottom: 16px;
  }

  .item {
    padding: 6px 0 12px 0;

    .header {
      display: flex;
      flex-direction: row;

      span {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .name {
        width: 100%;
        padding-right: 15px;
      }

      .value {
        width: 280px;
      }

      .aditional {
        width: 280px;
      }

      .final_value {
        width: 280px;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-top: 4px;

      span {
        font-size: 0.8rem;
        font-weight: 500;
      }

      .name {
        width: 100%;
        padding-right: 15px;
      }

      .value {
        width: 280px;
      }

      .aditional {
        width: 280px;
      }

      .final_value {
        width: 280px;
        font-size: 0.85rem;
        color: #00E676;
      }
    }
  }

  .no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    span {
      font-size: 1rem;
    }
  }
`;
