import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  rootSmall: {
    height: 30,
    marginLeft: 42,
    marginBottom: -18,
  },
  line: {
    position: 'absolute',
    backgroundColor: theme.palette.grey[200],
    height: 50,
    width: 5,
  },
  condition: {
    paddingTop: 2,
    paddingBottom: 2,
    width: 50,
    borderRadius: 2,
    color: theme.palette.common.white,
    fontWeight: '500',
    position: 'relative',
    textAlign: 'center',
  },
  AND: {
    backgroundColor: theme.palette.red.main,
  },
  OR: {
    backgroundColor: theme.palette.orange.main,
  },
}));
