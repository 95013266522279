import React, { useState, useEffect } from 'react';

import { useGlobalFilters } from '~/hooks/useGlobalFilters';

import BaseLayout from '~/components/BaseLayout';
import CustomerFilter from './components/CustomerFilter';
import SearchConstructionsDialog from '~/components/SearchConstructionsDialog';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import TotalAmountSpentOnConstructionChart from './components/TotalAmountSpentOnConstructionChart';
import LastInvoicesData from './components/LastInvoicesData';
import Pendencies from './components/Pendencies';
import FinancialConstructionIndicators from './components/FinancialConstructionIndicators';
import PhysicalConstructionIndicators from './components/PhysicalConstructionIndicators';
import TimeLine from './components/TimeLine';

export default function Dashboard() {
  const [openDialog, setOpenDialog] = useState(false);
  const {
    construction,
    setConstruction
  } = useGlobalFilters();

  useEffect(() => {
    if( !construction || !construction.id ){
      setOpenDialog(true)
    }
  }, [construction]);

  return (
    <BaseLayout>
      <CustomerFilter
        hideDate
        setOpenDialog={setOpenDialog}
      />

      <SearchConstructionsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onSelect={(newConstruction) => {
          setConstruction(newConstruction);
          setOpenDialog(false);
        }}
      />

      <GridContainer>
        <Col xs={12} md={5}>
          { construction && <LastInvoicesData constructionID={construction.id} />   }
        </Col>
        <Col xs={12} md={7}>
          { construction && <Pendencies constructionID={construction.id} /> }
        </Col>
      </GridContainer>
      <GridContainer alignItems="stretch">
        <Col xs={12} md={5}>
          { construction && <TotalAmountSpentOnConstructionChart constructionID={construction.id} />   }
        </Col>
        <Col xs={12} md={7}>
          <GridContainer>
            <Col xs={12}>
              {construction &&
                <FinancialConstructionIndicators
                  constructionID={construction.id}
                />
              }
            </Col>
            <Col xs={12}>
              {construction &&
                <PhysicalConstructionIndicators
                  constructionID={construction.id}
                />
              }
            </Col>
          </GridContainer>
        </Col>
      </GridContainer>

      { construction.id &&
        <GridContainer>
          <Col xs={12}>
            <TimeLine construction={construction} />
          </Col>
        </GridContainer>
      }
    </BaseLayout>
  );
}
