import React, {useState} from 'react';
import PropTypes from 'prop-types';
import uuid from '~/utils/createUUID';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Paper from '~/components/Paper';
import Button from '~/components/Button';
import Dialog from '~/components/Dialog';

import Input from '@material-ui/core/Input';
import InputControl from '~/components/Input/InputControl';
import InputNumber   from '~/components/Input/InputNumber';
import InputSelect   from '~/components/Input/InputSelect';

function Modal({
  isOpen,
  handleClose,
  observations,
  setObservations,
}) {
  const [newItem] = useState({
    kind: null,
    value: '',
    description: '',
  });
  
  const [items, setItems] = useState(observations || [{...newItem, id: uuid()}]);
  const [suggestions] = useState([
    { id: 'addition', name: 'Acréscimo' },
    { id: 'discount', name: 'Desconto' },
  ]);

	function addItem() {
    const current = [...items];
    current.push({...newItem, id: uuid()});
    setItems(current);
	}

	function onDelete(id) {
    const newList = items.filter(i => i?.id !== id)
    setItems(newList);
	}

	function handleChangeItem(id, attr, value) {
    const newList = [...items];
    const find = newList.find(i => i.id === id);
    if (find) find[attr] = value;
    setItems(newList);
	}

	function onSubmit() {
    const empty = items.filter(i => (!i.value || !i.kind));
    if (empty?.length) return;
    setObservations(items);
	}

  return(
    <Paper>
      <Dialog
        fullWidth
        isOpen={isOpen}
        handleClose={handleClose}
        title={'Observações'}
        dialogActions={
          <>
            <Button onClick={handleClose} color="red" variant="text">
              Cancelar
            </Button>
            <Button onClick={onSubmit} color="primary" variant="text">
              Salvar
            </Button>
          </>
        }
      >
        {items?.map((item, index) => {
          return (
            <div key={`form-grid${index}`}>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <InputControl
                    value={item?.description}
                    name={'input-desc'}
                    label={'Observação'}
                    inputComponent={Input}
                    onChange={(e) => handleChangeItem(item.id, 'description', e['input-desc'] || '')}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputControl
                    required
                    value={item?.kind}
                    label={'Tipo'}
                    inputComponent={InputSelect}
                    name={'input-kind'}
                    onChange={(e) => handleChangeItem(item.id, 'kind', e['input-kind'] || null)}
                    InputProps={{
                      options: suggestions,
                    }}
                  />
                </Grid>
                
                <Grid item xs={4}>
                  <InputControl
                    required
                    value={item?.value}
                    name={'input-value'}
                    label={'Valor'}
                    inputComponent={InputNumber}
                    InputProps={{
                      precision: 0,
                      placeholder: 'R$'
                    }}
                    onChange={(e) => handleChangeItem(item.id, 'value', e['input-value']) || ''}
                  />
                </Grid>

                <Grid item xs={1}>
                  <IconButton aria-label="delete" onClick={() => onDelete(item.id)}>
                    <DeleteIcon style={{ color: 'red', fontSize: 25 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          )
        })}

        <Grid item>
          <Button onClick={addItem} variant="contained" color="green">
            Novo
          </Button>
        </Grid>
      </Dialog>
    </Paper>
  );
}

Modal.defaultProps = {
  isOpen:       false,
  observations: null,
  handleClose:  () => null,
  setObservations:  () => null,
};

Modal.propTypes = {
  isOpen:       PropTypes.bool.isRequired,
  observations: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  setObservations:     PropTypes.func,
  handleClose:  PropTypes.func,
  setItems:  PropTypes.func,
};

export default Modal;
