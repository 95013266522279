import React, { useRef, useState, useEffect, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch } from 'react-redux';

import Request from '~/services/request';
import { invoicesRoute } from '~/routes';
import { invoicesApi } from '~/routes/api';
import { setNewRecord } from '~/store/modules/invoices';
import history from '~/services/history';
import InvoiceRecord from '~/records/InvoiceRecord';
import isPresent from '~/utils/isPresent';
import toFloat from '~/utils/Number/toFloat';
import ArchiveRecord from '~/records/ArchiveRecord';
import {findRecordRequest as findIncomingEmail} from '~/services/fetchApi/incomingEmails';
import deserialize from '~/utils/deserialize';

import LayoutNew from '~/components/LayoutNew';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import InputFile from '~/components/Input/InputFile';
import InputControl from '~/components/Input/InputControl';
import InputShow from '~/components/Input/InputShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import FullScreenLoading from '~/components/FullScreenLoading';

import XMLProducts from './components/XMLProducts';

export default function ImportXML() {
  const [loading, setLoading] = useState(false);
  const [archive, setArchive] = useState(null);
  const [data, setData] = useState({});
  const [incomingEmailID, setIncomingEmailID] = useState();

  const confirmationDialogRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchIncomingEmail = async () => {
      var incoming_email = new URL( window.location.href ).searchParams.get('incoming_email_id');
      if ( isPresent(incoming_email) ) {
        setIncomingEmailID(incoming_email);
        setLoading(true);
        const resp = await findIncomingEmail(incoming_email, {include: 'annex'});
        const data = await deserialize(resp.data);

        setArchive( new ArchiveRecord(data.annex) )
      }
    }
    fetchIncomingEmail();
  }, []);

  useEffect(() => {
    if(archive){
      loadXmlData( archive.get('uuid') )
    }
  }, [archive])

  const onChangeItem = useCallback(
    (index, item) => {
      const newData = {...data};
      newData.items[index] = item;
      setData( newData )
    },
    [data, setData],
  )

  const loadXmlData = useCallback(
    async (uuid) => {
      try {
        setLoading(true);
        setData({});

        const response = await Request.get( invoicesApi.getXmlInfo.build({uuid}) );
        const { data } = response.data;

        data.incomingEmailID = incomingEmailID;

        if ( isPresent(data) ) {
          setData(data);
        }
        setLoading(false);
      } catch(err) {
        console.error(err)
        setLoading(false);
      }
    },
    [incomingEmailID]
  );

  const handleSubmit = useCallback(
    async () => {
      const hasProductsNotCreated = data.items.filter(
        e => !e.product?.id
      ).length > 0

      if ( hasProductsNotCreated ) {
        const { current: dialog } = confirmationDialogRef;
        dialog.open();
      } else {
        const newInvoice = new InvoiceRecord(
          handleInvoiceParams(data, archive)
        )

        dispatch( setNewRecord( newInvoice ) );
        history.push( invoicesRoute.new.build() );
      }
    },
    [data, archive]
  );

  return (
    <LayoutNew
      breadcrumbs={[
        { path: invoicesRoute.list.path, text: 'Notas Fiscais' },
        { path: invoicesRoute.new.path, text: 'Cadastrar Nota Fiscal' },
        { text: 'Importar XML' }
      ]}
      footerActionsProps={{
        showSaveAndContinue: false,
        onSubmit: handleSubmit,
        submitText: 'Continuar'
      }}
    >
      { !incomingEmailID &&
        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <GridContainer>
              <Col xs={12}>
                <InputControl
                  name="file"
                  label={"Arquivo XML da nota fiscal"}
                  value={archive}
                  inputComponent={InputFile}
                  onChange={({file}) => { setArchive(file) }}
                  InputProps={{
                    options: {
                      accept: 'text/xml'
                    }
                  }}
                />
              </Col>
            </GridContainer>
          </CardContent>
        </Card>
      }

      {!!data.nf_number && (
        <Card>
          <CardContent>
            <GridContainer>
              <Col sm={12} md={12}>
                <InputShow
                  label="Fornecedor"
                  value={data.supplier?.name}
                  type="string"
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label="Nota Fiscal"
                  value={data.nf_number}
                  type="string"
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label="Data da NF"
                  value={data.nf_date}
                  type="date"
                />
              </Col>
              <Col sm={12} md={4}>
                <InputShow
                  label="Valor Total"
                  value={data.total}
                  type="float"
                />
              </Col>
            </GridContainer>
          </CardContent>
        </Card>
      )}

      { !!data && (data.items || data.supplier) &&
        <XMLProducts
          items={data.items}
          supplier={data.supplier}
          onChangeItem={onChangeItem}
        />
      }

      {loading && (
        <FullScreenLoading loadingText="Buscando informações do XML..." />
      )}

      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Atenção"
        description="É necessário vincular todos so produtos para continuar"
        cancelText=""
        confirmText="OK"
      />
    </LayoutNew>
  );
}

const handleInvoiceParams = (xmlData, archive) => {
  const params = { nf_date: '' }
  if ( isPresent(xmlData) ){
    params.construction  = xmlData.construction;
    params.nf_date       = xmlData.nf_date;
    params.nf_number     = xmlData.nf_number;
    params.total_value     = toFloat(xmlData.total);
    params.supplier      = {
      id: xmlData.supplier.id,
      name: xmlData.supplier.name,
    };
    params.bank_slips    = xmlData?.duplicates?.map( dup => ({
      due_date:   dup.dVenc,
      slip_value: toFloat(dup.vDup),
    }) );
    params.invoice_items = xmlData?.items?.map( item => ({
      product:     {
        id: item.product.id,
        name: item.product.name
      },
      account_plan: item.product.account_plan,
      code:        item.supplier_code,
      amount:      item.quantity,
      unit_value:  item.base_value,
      total_value: item.total,
    }) );
    params.expenses = (
      xmlData.expenses.length ? xmlData.expenses
      : [{
        account_plan: xmlData.account_plan,
        total_value: toFloat(xmlData.total)
      }]
    )

    params.incoming_email_id = xmlData?.incomingEmailID
    params.attachments = [archive]
  }
  return params;
}