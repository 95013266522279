import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { addFilter } from '../../../../store/actions';
import useStyles from './styles';

function AddFilter({ indexGroup }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid container alignItems="center" justify="flex-end" direction="row">
      <IconButton
        onClick={() => dispatch(addFilter(indexGroup))}
        classes={{
          root: classes.button,
        }}
      >
        <AddCircleIcon className={classes.icon} />
      </IconButton>
    </Grid>
  );
}

export default React.memo(AddFilter);
