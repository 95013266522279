import SchemaBuilder from './SchemaBuilder';

const string = {
  type: 'string',
  default: '',
}

const hidden = {hidden: true}

const columns = {
  id:              { type: 'integer',  title: 'ID' },
  name:            { ...string, title: 'Nome', width: 150 },
  custom_fields:   { type: 'object',   default: {}, title: 'Campos customizados', ...hidden },
  amount_done:     { type: 'integer',  default: 0, width: 100, title: 'Quantidade de campos preenchids'},
  percentage_done: { type: 'float',    default: 0, width: 100, title: 'Percentual dos campos preenchidos'},
  created_at:      { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:      { type: 'datetime', default: '', title: 'Atualizado em', ...hidden },
};

const customRecordSchema = new SchemaBuilder( columns );

export default customRecordSchema;