import SchemaBuilder from './SchemaBuilder';

import {
  constructionsAsyncFetch,
  constructionAsyncShow,
} from '~/services/selectFetch/constructionsFetch';
import {
  peopleFetchWithParams,
  peopleAsyncShow,
} from '~/services/selectFetch/peopleFetch';

const columns = {
  id: { type: 'integer', title: 'ID' },
  construction: {
    type: 'async',
    default: null,
    title: 'Obra',
    width: 200,
    filterOptions: {
      asyncFetch: constructionsAsyncFetch,
      asyncShow:  constructionAsyncShow,
    }
  },

  customer: {
    type: 'async',
    default: null,
    title: 'Cliente',
    width: 200,
    filterOptions: {
      asyncFetch: peopleFetchWithParams({ customer: true }),
      asyncShow:  peopleAsyncShow,
    },
    hidden: true
  },

  engineer: {
    type: 'async',
    default: null,
    title: 'Engenheiro',
    width: 200,
    filterOptions: {
      asyncFetch: peopleFetchWithParams({ engineer: true }),
      asyncShow:  peopleAsyncShow,
    },
    hidden: true
  },
  start_date: { type: 'datetime', title: 'Data de Início' },
  is_active:  { type: 'boolean', title: 'Ativo' },
  created_at: { type: 'datetime', hidden: true, title: 'Criado em' },
  updated_at: { type: 'datetime', hidden: true, title: 'Atualizado em',  },
}

const npsFormSchema = new SchemaBuilder( columns );

export default npsFormSchema;