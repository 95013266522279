import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { findRecord, destroyRecord, clearState } from '~/store/modules/suppliers';
import { suppliersRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';

import AddressShow from '~/components/AddressShow';
import ContactsShow from '~/containers/ContactsShow';

function Show({ supplier, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'address.city-segment-contacts'}) );

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: suppliersRoute.list.path, text: 'Fornecedores' },
        { text: 'Exibindo fornecedor' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   suppliersRoute.new.build(),
        routeCloneRegister: suppliersRoute.clone.build({ id }),
        routeEditRegister:  suppliersRoute.edit.build({ id }),
        permissionKeyCode:  'Supplier'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col md={6}>
              <InputShow
                label="Nome"
                type="string"
                value={supplier?.name}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label="Segmento"
                type="string"
                value={supplier?.segment?.name}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label="CPF/CNPJ"
                type="cpf_cnpj"
                value={supplier?.cpf_cnpj}
              />
            </Col>
            { !supplier?.is_legal &&
              <Col md={6}>
                <InputShow
                  label="RG"
                  type="string"
                  value={supplier?.rg}
                />
              </Col>
            }
            <Col md={6}>
              <InputShow
                label={supplier?.is_legal ? 'Data da fundação' : 'Data de nascimento'}
                type="date"
                value={supplier?.birth_date}
              />
            </Col>
          </GridContainer>

          <br/>
          <Typography variant="h6">Endereço</Typography>
          { !!supplier?.address && <AddressShow address={supplier.address} /> }

          <br/>
          <ContactsShow title="Contatos" contacts={supplier?.contacts} />
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.suppliers.isLoading,
  supplier: state.suppliers.current,
});

export default connect(mapStateToProps)(Show);