import React, { useState, useEffect, useCallback, useMemo } from 'react';
import QueryString from 'query-string';
import { useDispatch, connect } from 'react-redux';

import { customRecordsRoute } from '~/routes'
import BaseLayout from '~/components/BaseLayout';
import Spinner from '~/components/Spinner';
import CrudLayoutList from '~/containers/CrudLayoutList';
import { fetchRecords, destroyRecord } from '~/store/modules/customRecords';

import ListShowValue from './components/ListShowValue';
import getMetaFields from './utils/getMetaFields';

// eslint-disable-next-line
const filterTableFields = (metaField, idx) => true
  // !['multiple_choice', 'unique_choice'].includes(metaField.field.kind) &&
  // idx < 5

const handleColumnsOverride = (metaFields) => {
  return Object.values(metaFields).map( mf => ({
    name: mf.field.key,
    formatterComponent: function ShowColumn(columnName, row){
      return <ListShowValue register={row} metaField={mf} />
    }
  }) )
}

function List({ sys_key, metaSettings, isLoading, rows, total }) {
  const dispatch = useDispatch();
  const [ metaFields, setMetaFields] = useState(null)
  const [ tableCellColumns, setTableCellColumns] = useState(null)
  const [ tableRowColumns, setTableRowColumns] = useState(null)
  const [ columnsOverride, setColumnsOverride] = useState(null)

  // O timeout é necessário pois toda vez que altera a sys_key é necessário
  // desmotar e montar o componente da tabela, pois o componente de tabela
  // não tem suporte para mudar de colunas com o componente montado.
  const [ timeoutLoading, setTimeoutLoading] = useState(false)

  const handleFetch = useCallback(
    async (params, requestSource) => {
      let args = {sys_key};
      try {
        args = {...QueryString.parse(params), ...args}
      } catch {}

      dispatch( fetchRecords(args, requestSource) )
    },
    [sys_key],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id, {sys_key}) )
    },
    [sys_key]
  )

  const rowData = useMemo(() => {
    return rows.map( ({custom_fields, ...rest}) => ({...rest, ...custom_fields}) )
  }, [rows])

  useEffect(() => {
    const currentMetaFields = getMetaFields(metaSettings);

    const currentTableCellColumns = [
      {name: 'id',   type: 'integer', title: 'ID', width: 80},
      {name: 'name', type: 'string', title: 'Nome', width: 150},
      ...Object.values(currentMetaFields).filter(filterTableFields).map( (metaField, idx) => ({
        name: metaField.field.key,
        title: metaField.field.name,
        width: 150,
        type: metaField.field.kind,
        hidden: idx > 2
      }) ),
      {name: 'created_at', type: 'datetime', title: 'Criado em', width: 150},
      {name: 'actions', title: 'Ações', width: 200}
    ]

    const currentTableRowColumns = [
      {columnName: 'id',   type: 'integer', title: 'ID', width: 80},
      {columnName: 'name', type: 'string', title: 'Nome', width: 150},
      ...Object.values(currentMetaFields).filter(filterTableFields).map( (metaField) => ({
        columnName: metaField.field.key,
        width: 150,
        type: metaField.field.kind
      }) ),
      {columnName: 'created_at', type: 'datetime', title: 'Criado em', width: 150},
      {columnName: 'actions', width: 200, align: 'center' }
    ]

    const currentColumnsOverride = handleColumnsOverride(currentMetaFields)

    setTimeoutLoading(true)
    setTableCellColumns( currentTableCellColumns );
    setTableRowColumns( currentTableRowColumns );
    setColumnsOverride( currentColumnsOverride );
    setMetaFields( currentMetaFields );
  }, [sys_key, metaSettings])

  useEffect(() => {
    if( timeoutLoading ){
      setTimeout( () => {
        setTimeoutLoading(false)
      }, 10);
    }
  }, [timeoutLoading, setTimeoutLoading])


  if(!metaFields || timeoutLoading){
    return <BaseLayout><Spinner /></BaseLayout>
  }

  return (
    <RenderLayoutList
      key={sys_key}
      isLoading={isLoading}
      rows={rowData}
      total={total}
      handleFetch={handleFetch}
      onDelete={onDelete}
      metaSettings={metaSettings}
      sys_key={sys_key}
      metaFields={metaFields}
      tableCellColumns={tableCellColumns}
      tableRowColumns={tableRowColumns}
      columnsOverride={columnsOverride}
    />
  );
}


const RenderLayoutList = ({
  metaSettings,
  sys_key,
  tableCellColumns,
  tableRowColumns,
  columnsOverride,

  isLoading,
  rows,
  total,
  handleFetch,
  onDelete
}) => {
  return (
    <CrudLayoutList
      breadcrumbs={[{ text: metaSettings.get('record_plural_name') }]}
      permissionKeyCode={`CustomRecord-${sys_key}`}
      routes={{
        newPath:  ()  =>  customRecordsRoute.new.build( {sys_key} ),
        showPath: (id) => customRecordsRoute.show.build({id, sys_key}),
        editPath: (id) => customRecordsRoute.edit.build({id, sys_key})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        tableCellColumns,
        tableRowColumns,
        columnsOverride,
        columnsVisibility: {
          visible: true,
          defaultHidden: tableCellColumns.filter(e => e.hidden ).map( e => e.name ),
          columnsExtensions: [
            { columnName: 'actions', togglingEnabled: false },
          ]
        },
        actions: ['show', 'edit', 'delete'],
        filterProps: {
          columns: [
            {name: 'id',         type: 'integer', title: 'ID'},
            {name: 'name',       type: 'string', title: 'Nome'},
            {name: 'created_at', type: 'datetime', title: 'Criado em'},
            {name: 'updated_at', type: 'datetime', title: 'Atualizado em'}
          ]
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  isLoading: state.customRecords.listIsLoading,
  rows:  state.customRecords.data,
  total: state.customRecords.totalData,
});

export default connect(mapStateToProps)(List);