import RecordBase from './Base';
import builderSchema from './schema/builderSchema';

export default class BuilderRecord extends RecordBase {
  static defaultAttributes = builderSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return builderSchema.attributeNames }

  toParams() {
    const {
      id,
      name,
    } = this.toObject();

    return {
      data:{
        attributes:{
          id,
          name
        }
      }
    };
  }
}