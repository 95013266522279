import axios from 'axios';
import { toast } from 'react-toastify';

import { refreshToken, signOut } from '../store/modules/auth';
import { authApi } from '../routes/api';
import { store } from '../store';

const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DEL: 'delete'
};

const HTTP_STATUS = {
  OK: 200,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422
};

const api = axios.create();

api.interceptors.response.use(
  response => response,
  error => {
    const response = error.response;

    return Promise.reject({
      status: response.status,
      name: response.data.error,
      description: response.data.error_description
    });
  }
);

class Request {
  static HTTP_STATUS = HTTP_STATUS;

  static CancelToken = axios.CancelToken;

  static isCancel = axios.isCancel;

  static setHeader(name, value) {
    api.defaults.headers[name] = value;
  }

  static get(path, config = {}) {
    return Request._request({ method: HTTP_METHOD.GET, path, config });
  }

  static post(path, data, config = {}) {
    return Request._request({ method: HTTP_METHOD.POST, path, data, config });
  }

  static put(path, data) {
    return Request._request({ method: HTTP_METHOD.PUT, path, data });
  }

  static patch(path, data) {
    return Request._request({ method: HTTP_METHOD.PATCH, path, data });
  }

  static del(path, config = {}) {
    return Request._request({ method: HTTP_METHOD.DEL, path, config });
  }

  static async _request({ method, path, data = {}, config = {} }) {
    try {
      const response = await Request._httpRequest(method, path, data, config);

      return response;
    } catch(err) {
      const requestIsCanceled = !err.response;
      if (requestIsCanceled) {
        return Promise.reject(err);
      }

      const { status, data: errData } = err.response;

      if (
        status === Request.HTTP_STATUS.UNAUTHORIZED &&
        await Request._refreshToken()
      ) {
        return await Request._httpRequest(method, path, data, config);
      }

      return Promise.reject({ status, ...errData });
    }
  }

  static async _httpRequest(method, path, data, config) {
    const axiosMethod = axios[method];
    const reqConfig = Request._getConfig(config);

    if ([HTTP_METHOD.GET, HTTP_METHOD.DEL].includes(method)) {
      return await axiosMethod(path, reqConfig);
    }

    return await axiosMethod(path, data, reqConfig);
  }

  static _getConfig(config) {
    return {
      responseType: 'json',
      headers: {
        Authorization: api.defaults.headers.Authorization,
        'Content-Type': 'application/json'
      },
      ...config
    };
  }

  static async _refreshToken() {
    try {
      const { auth } = store.getState();
      const response = await api.post(authApi.getToken.path, {
        grant_type: 'refresh_token',
        refresh_token: auth.refreshToken
      });

      if (!response.data) {
        Request._redirectToLogin();
        return false;
      }

      store.dispatch(refreshToken({
        token: response.data.access_token,
        refreshToken: response.data.refresh_token
      }));

      Request.setHeader(
        'Authorization',
        `Bearer ${response.data.access_token}`
      );

      return true;
    } catch (e) {
      Request._redirectToLogin();
      return false;
    }
  }

  static _redirectToLogin() {
    store.dispatch(signOut());
    toast.error('Sua sessão encerrou. Faça Login para continuar navegando!');
  }
}

export default Request;
