import React, { useState, useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import BaseLayout from '~/components/BaseLayout';
import Spinner from '~/components/Spinner';
import Filter from './components/Filter';
import SearchConstructionsDialog from '~/components/SearchConstructionsDialog';
import formatToMoney from '~/utils/formatToMoney';

import Request from '~/services/request';
import { useGlobalFilters } from '~/hooks/useGlobalFilters';
import { useBreadcrumbs } from '~/hooks/useBreadcrumbs';
import { dashboardRoute } from '~/routes';
import { expensesApi } from '~/routes/api';
import formatExpenseHistory from './utils/formatExpenseHistory';

const getData = chartInfo => ({
  labels: chartInfo.labels,
  datasets: [{
		label: ' Despesas',
		backgroundColor: 'rgba(255,82,82, 0.4)',
		borderColor: '#FF5252',
		borderWidth: 1,
		hoverBackgroundColor: 'rgba(255,82,82, 0.6)',
		hoverBorderColor: '#FF5252',
		data: chartInfo.data
  }]
});

const options = {
  legend: { display: false },
  tooltips: {
    callbacks: {
      label: function (item, data) {
        const idx = item.index;
        return `Total: R$ ${formatToMoney(data.datasets[0].data[idx])}`;
      },
    },
  },
}

export default function List() {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({
    labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    datasets: [{
      label: ' Despesas',
      backgroundColor: 'rgba(255,82,82, 0.4)',
      borderColor: '#FF5252',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,82,82, 0.6)',
      hoverBorderColor: '#FF5252',
      data: []
    }]
  });
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    construction,
    setConstruction
  } = useGlobalFilters();

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      { text: 'Início', path: dashboardRoute.self.build()},
      { text: 'Histórico de Despesa' }
    ]);
  }, []);

  useEffect(() => {
    if (construction.id) {
      handleFindExpenseHistory(startDate, endDate);
    } else {
      const formattedData = formatExpenseHistory(startDate, endDate, null);

      setData(getData(formattedData));
      setLoading(false);
      setOpenDialog(true);
    }
  }, [construction, startDate, endDate]);

  const handleChange = useCallback(
    (key, value) => {
      if (key === 'start') {
        setStartDate(value);
      } else {
        setEndDate(value);
      }
    },
    []
  );

  const handleFindExpenseHistory = useCallback(
    async (startDate, endDate) => {
      setLoading(true);

      try {
        const queryString = `start_date=${startDate}&end_date=${endDate}&construction_id=${construction.id || null}`;
        const response = await Request.get(
          `${expensesApi.history.build()}?${queryString}`
        );
        const { data: expenseHistory } = response;
        const formattedData = formatExpenseHistory(startDate, endDate, expenseHistory.data);

        setData(getData(formattedData));
      } catch(err) {
      } finally {
        setLoading(false);
      }
    },
    [construction]
  );

  if (loading) {
    return (
      <BaseLayout>
        <Spinner loadingText="" />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Filter
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        setOpenDialog={setOpenDialog}
      />
      <Bar height={120} data={data} options={options} />
      <SearchConstructionsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onSelect={(newConstruction) => {
          setConstruction(newConstruction);
          setOpenDialog(false);
        }}
      />
    </BaseLayout>
  );
}
