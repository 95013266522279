import { createMuiTheme } from '@material-ui/core/styles';
import theme from './theme';

export default function(color) {
  return createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: theme.palette[color],
    },
  });
}
