import React from 'react';
import moment from 'moment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const DateTimeFormatter = ({ value }) => {
  const valueDT = moment(value);
  if( !valueDT.isValid() ){ return ''; }
  return valueDT.format('DD/MM/YYYY H:mm');
}
const DateTimeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DateTimeFormatter}
    {...props}
  />
);

export default DateTimeTypeProvider;