import React, { useMemo } from 'react';
import styled from 'styled-components';
import MDIconButton from '@material-ui/core/IconButton';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import makeTheme from '~/assets/styles/makePrimaryColorTheme';

const IconButtonStyled = styled(MDIconButton)`
  margin-right: 7px;
  padding: 0;
`;

const IconButton = ({color, ...props}) => {
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <IconButtonStyled {...props} color='primary'/>
  </ThemeProvider>
}

export default React.memo(IconButton)