import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  fetchListRecordsRequest,
} from '~/services/fetchApi/reportExpenses';
import {
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { reportsRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const REPORT_EXPENSES_SET_CURRENT = '@App/report_expenses/REPORT_EXPENSES_SET_CURRENT';
export const REPORT_EXPENSES_FETCH_START = '@App/report_expenses/REPORT_EXPENSES_FETCH_START';
export const REPORT_EXPENSES_FETCH_DONE  = '@App/report_expenses/REPORT_EXPENSES_FETCH_DONE';
export const REPORT_EXPENSES_FETCH_LIST_START = '@App/report_expenses/REPORT_EXPENSES_FETCH_LIST_START';
export const REPORT_EXPENSES_FETCH_LIST_DONE = '@App/report_expenses/REPORT_EXPENSES_FETCH_LIST_DONE';
export const REPORT_EXPENSES_SET_ERRORS = '@App/report_expenses/REPORT_EXPENSES_SET_ERRORS';
export const CLEAR_STATE = '@App/report_expenses/CLEAR_STATE';
export const FETCH_REPORT_EXPENSES_REQUEST  = '@App/report_expenses/FETCH_REPORT_EXPENSES_REQUEST';
export const REPORT_EXPENSES_CHANGE_LIST  = '@App/report_expenses/REPORT_EXPENSES_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [REPORT_EXPENSES_FETCH_START]: fetchStart,
    [REPORT_EXPENSES_FETCH_DONE]:  fetchDone,
    [REPORT_EXPENSES_FETCH_LIST_START]: fetchListStart,
    [REPORT_EXPENSES_FETCH_LIST_DONE]: fetchListDone,
    [REPORT_EXPENSES_SET_ERRORS]: setErrors,
    [REPORT_EXPENSES_SET_CURRENT]: setCurrent,
    [REPORT_EXPENSES_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_REPORT_EXPENSES_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: REPORT_EXPENSES_SET_CURRENT,
    payload: { current }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'reportExpenses',

  routes: {
    list: () => reportsRoute.expenses.build(),
  },

  apiRequests: {
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      REPORT_EXPENSES_FETCH_START,
    FETCH_DONE:       REPORT_EXPENSES_FETCH_DONE,
    FETCH_LIST_START: REPORT_EXPENSES_FETCH_LIST_START,
    FETCH_LIST_DONE:  REPORT_EXPENSES_FETCH_LIST_DONE,
    CHANGE_LIST:      REPORT_EXPENSES_CHANGE_LIST,
    CHANGE_CURRENT:   REPORT_EXPENSES_SET_CURRENT,
    SET_ERRORS:       REPORT_EXPENSES_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FETCH_REPORT_EXPENSES_REQUEST, crudActions.fetchListAction),
]);
