import React, {useCallback, memo} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import BankSlipRecord from '~/records/BankSlipRecord'

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import InputDate from '~/components/Input/InputDate';
import InputFile from '~/components/Input/InputFile';

const attrName = (attr) => BankSlipRecord.hummanAttributeName(attr);

function BankSlipItem({ bankSlip, handleChange, handleDelete}) {
  const onChange = useCallback(
    (changes) => {
      const newBankSlip = bankSlip.updateAttributes(changes);
      handleChange( newBankSlip )
    },
    [bankSlip, handleChange]
  );

  const onDelete = useCallback(
    () => {
      handleDelete(bankSlip)
    },
    [bankSlip, handleDelete]
  );

  return (
    <Col xs={12} sm={6} md={4}>
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={10}>
              <InputControl
                required
                name="due_date"
                label={attrName('due_date')}
                value={bankSlip.get('due_date')}
                inputComponent={InputDate}
                onChange={onChange}
              />
            </Col>
            <Col xs={2}>
              <IconButton onClick={onDelete} aria-label="delete">
                <DeleteIcon style={{ color: 'red', fontSize: 24 }} />
              </IconButton>
            </Col>
            <Col xs={12}>
              <InputControl
                required
                name="slip_value"
                label={attrName('slip_value')}
                value={bankSlip.get('slip_value')}
                inputComponent={InputNumber}
                onChange={onChange}
                startAdornment={<span>R$</span>}
              />
            </Col>
            <Col xs={12}>
              <InputControl
                name="attachment"
                label={attrName('attachment')}
                value={bankSlip.get('attachment')}
                inputComponent={InputFile}
                onChange={onChange}
                InputProps={{
                  options: {
                    accept: 'application/pdf'
                  }
                }}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </Col>
  );
}

export default memo(BankSlipItem);
