import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { findRecord, destroyRecord, clearState } from '~/store/modules/roles';
import { rolesRoute } from '~/routes'
import sortArray from '~/utils/sortArray';

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import Chip        from '~/components/Chip';
import IconMakeColor from '~/components/IconMakeColor';

function Show({ isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [role, setRole] = useState(null)

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(
      id,
      {include: 'users-role_permissions.permission'},
      (current) => {
        setRole(current);
        dispatch(clearState());
      }
    ));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: rolesRoute.list.path, text: 'Permissões' },
        { text: 'Visualizando Permissão' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   rolesRoute.new.build(),
        routeCloneRegister: rolesRoute.clone.build({ id }),
        routeEditRegister:  rolesRoute.edit.build({ id }),
        permissionKeyCode:  'Person'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={12}>
              <InputShow
                label="Nome"
                value={role?.name}
              />
            </Col>
            <Col xs={12}>
              <Typography>Usuários</Typography>
              { role?.users?.map( u =>
                <Chip key={u.id} label={`${u.name} <${u.email}>`} variant='outlined' color='primary' /> )
              }
            </Col>
          </GridContainer>
          <GridContainer>
            <Col xs={12}>
              <br />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Permissão</TableCell>
                      <TableCell>Ver</TableCell>
                      <TableCell>Adicionar</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Excluir</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { Array.isArray(role?.role_permissions) &&
                      sortArray(role.role_permissions, 'asc', (v) => v.permission.name).map( rlp =>
                        <TableRow key={rlp.id}>
                          <TableCell>{rlp.permission?.name}</TableCell>
                          { rlp.permission?.is_crud &&
                            <>
                              <TableCell><IconMakeColor color="success" Icon={CheckIcon} /></TableCell>
                              <TableCell>
                                {rlp.add ?
                                  <IconMakeColor color="success" Icon={CheckIcon} />
                                  : <IconMakeColor color="red" Icon={ClearIcon} />
                                }
                              </TableCell>
                              <TableCell>
                                {rlp.edit ?
                                  <IconMakeColor color="success" Icon={CheckIcon} />
                                  : <IconMakeColor color="red" Icon={ClearIcon} />
                                }
                              </TableCell>
                              <TableCell>
                                {rlp.del ?
                                  <IconMakeColor color="success" Icon={CheckIcon} />
                                  : <IconMakeColor color="red" Icon={ClearIcon} />
                                }
                              </TableCell>
                            </>
                          }
                          { !rlp.permission?.is_crud &&
                            <TableCell colSpan={4}>
                              <IconMakeColor color="success" Icon={CheckIcon} />
                            </TableCell>
                          }
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.roles.isLoading
});

export default connect(mapStateToProps)(Show);