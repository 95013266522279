export default function deleteTreeRow(deletedRowId, rows) {
  const updatedRows = [...rows];
  let found = false;

  for (const grandPaPos in rows) {
    const grandPaRow = rows[grandPaPos];
    if (deletedRowId === grandPaRow.id) {
      delete updatedRows[grandPaPos];
      break;
    }

    if (!grandPaRow?.items) continue;

    for (const fatherPos in grandPaRow.items) {      
      const fatherRow = grandPaRow.items[fatherPos];
      if (deletedRowId === fatherRow.id) {
        found = true;
        delete updatedRows[grandPaPos].items[fatherPos];
        break;
      }

      if (!fatherRow?.items) continue;

      for (const sonPos in fatherRow.items) {
        const sonRow = fatherRow.items[sonPos];
        if (deletedRowId === sonRow.id) {
          found = true;
          delete updatedRows[grandPaPos].items[fatherPos].items[sonPos];
          break;
        }
      }

      if (found) break;
    }

    if (found) break;
  }

  return updatedRows.filter(row => !!row);
}
