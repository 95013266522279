import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputIcon from '@material-ui/icons/Input';

import { createRecord, updateRecord, setCurrentRecord } from '~/store/modules/fields';
import { dialogFormToggle } from '~/store/modules/crud';
import FieldRecord from '~/records/FieldRecord';
import { fieldsRoute } from '~/routes';
import isPresent from '~/utils/isPresent';

import CrudFormDialog from '~/containers/CrudFormDialog';
import FormFieldsOfField from '~/components/FormFieldsOfField';

export default function Form({handleFetch, history}) {
  const [record, setRecord] = useState(new FieldRecord());
  const [callFetch, setCallFetch] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, current, errors = {} } = useSelector(state => state.fields);
  const { dialogFormIsOpen } = useSelector(state => state.crud);

  useEffect(() => {
    if(current){
      setRecord( record.updateAttributes(current) )
    }else{
      setRecord( new FieldRecord() )
    }
  }, [setRecord, current]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const closeDialog = useCallback(
    () => {
      dispatch( dialogFormToggle(false) );
      dispatch( setCurrentRecord(null) );

      if(callFetch){
        handleFetch()
        setCallFetch(false)
      }

      if( location.pathname !== fieldsRoute.list.path){
        setTimeout(function() {history.push( fieldsRoute.list.build() )}, 10);
      }
    },
    [callFetch, setCallFetch, location.pathname, history]
  );

  const handleSave = useCallback(() => {
    const data = record.toParams();
    if ( isPresent(current?.id) ) {
      dispatch( updateRecord(current.id, data) );
    } else {
      dispatch( createRecord(data) );
    }
  }, [record, current]);

  const clearAfterSaved = useCallback(() => {
    setRecord( new FieldRecord({kind: record.get('kind') }) )
    dispatch( setCurrentRecord( {kind: record.get('kind')} ) )
    setCallFetch(true)
  }, [setRecord, setCallFetch, record?.get('kind')]);

  return (
    <CrudFormDialog
      clearAfterSaved={clearAfterSaved}
      onSubmit={handleSave}
      icon={<InputIcon />}
      isOpen={dialogFormIsOpen}
      handleClose={closeDialog}
      title={ !current?.id ? 'Novo campo' : 'Editando campo'}
      errors={errors}
      isLoading={isLoading}
      isRouteNew={!current?.id}
    >
      <FormFieldsOfField record={record} handleChange={handleChange} />
    </CrudFormDialog>
  );
}
