import React from 'react';
import filesize from 'filesize';
import {CircularProgressbar} from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdDelete} from 'react-icons/md';
import EditIcon from '@material-ui/icons/Edit';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Col from '~/components/Col'
import Tooltip from '~/components/Tooltip';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  imgContainer: {
    width: '100%',
    height: 100,
    overflow: 'hidden'
  },
  img: {
    maxWidth: '100%',
    height:   '100%',
    margin:   'auto',
    padding:   5,
    objectFit: 'contain'
  },
  cardContent: {
    padding: 5
  },
  cardActions: {
    marginLeft: 0,
  },
  iconButtom: {
    marginLeft: 0,
    padding: 6
  },
  fileSize: {
    fontSize: '0.8rem',
    color: '#999',
    marginTop: '5px'
  }
});

function ArchivePreview({ file, onDelete, viewMode, onEdit }) {
  const classes = useStyles();

  return (
    <Col xs={12} md={2}>
      <Card className={classes.root}>
        <CardActionArea component="a" target="_blank" href={file.get('url') || file.get('icon')}>
          <div className={classes.imgContainer}>
            <CardMedia
              className={classes.img}
              component="img"
              alt={ file.get('description') || '' }
              image={file.get('icon')}
              title={file.get('filename')}
            />
          </div>
        </CardActionArea>
        <CardContent className={classes.cardContent}>
          <Tooltip title={file.get('filename') || ''}>
            <Typography noWrap gutterBottom variant="caption" component={'p'}>
              <strong>{file.get('filename')}</strong>
            </Typography>
          </Tooltip>
          { file.get('description') &&
            <Typography variant="caption" color="textSecondary" component="span">
              { file.get('description') || '' }
              <br/>
            </Typography>
          }
          <span className={classes.fileSize}>{ filesize( file.get('file_size') ) }</span>
        </CardContent>
        { !viewMode &&
          <CardActions className={classes.cardActions}>
            { file.isNewRecord() && file.isValid() && (
              <CircularProgressbar
                styles={{
                  root: { width: 38 },
                  path: { stroke: '#66BB6A' },
                  text: {
                    fill: '#666',
                    fontSize: '31px',
                    fontWeight: 'bold'
                  },
                }}
                strokeWidth={10}
                value={file.get('progress')}
                text={`${file.get('progress')}%`}
                textSize='20px'
              />
            )}

            { file.isPersisted() && file.isValid() && (
              <Tooltip title={'Tudo certo com este arquivo.'}>
                <IconButton
                  type="button"
                  classes={{root: classes.iconButtom}}
                >
                  <MdCheckCircle size={26} color="#66BB6A" />
                </IconButton>
              </Tooltip>
            )}

            { !file.isValid() && (
              <IconButton
                type="button"
                classes={{root: classes.iconButtom}}
              >
                <MdError size={26} color="#EF5350" />
              </IconButton>
            )}

            { onEdit && (
              <Tooltip title={'Editar'}>
                <IconButton
                  onClick={ () => onEdit(file) }
                  classes={{root: classes.iconButtom}}
                >
                  <EditIcon size={24}/>
                </IconButton>
              </Tooltip>
            )}

            { file.isPersisted() &&
              <Tooltip title={'Excluir'}>
                <IconButton
                  type="button"
                  onClick={() => onDelete( file.idOrToken ) }
                  classes={{root: classes.iconButtom}}
                >
                  <MdDelete size={24} color="#EF5350" />
                </IconButton>
              </Tooltip>
            }
          </CardActions>
        }
      </Card>
    </Col>
  );
}

ArchivePreview.defaultProps = {
  viewMode: false
}

export default ArchivePreview;