import React from 'react';
import CheckBox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '16px',
    height: '36px',
    padding: '0px 0px 0px 0px',
    width: '24px',
  },
}));

function InputCheckBox(props){
  /* eslint-disable */
  const {
    value,
    onChange,
    'state-name': stateName,
    startAdornment,
    endAdornment,
    classes,
    ...ownProps
  } = props;
  /* eslint-enable */

  const { root } = useStyles();

  function handleChange(e, isChecked){
    onChange({
      [stateName]: isChecked
    }, e);
  }

  return(
    <CheckBox
      value={value}
      checked={Boolean(value)}
      onChange={handleChange}
      color='primary'
      classes={{root}}
      {...ownProps}
    />
  );
}

export default InputCheckBox;
