import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import isPresent from '~/utils/isPresent';

const BooleanFormatter = ({ value }) => {
  return isPresent(value) ? 'SIM' : 'NÃO';
}
const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    {...props}
  />
);

export default BooleanTypeProvider;