import React, { useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '~/components/Typography';
import Tooltip from '~/components/Tooltip';
import formatToMoney from '~/utils/formatToMoney';

import {
  Container,
  PercentText,
  Percent,
  PercentageCompleted
} from './styles';

function StatusPercent({ percentage, total, color }) {
  const getPercentage = useCallback(() => {
    if (percentage > 100) {
      return 100;
    }

    return percentage;
  }, [percentage]);

  return (
    <Container>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <PercentText>
            {percentage}%
          </PercentText>
        </Grid>
        <Grid item>
          <Typography color="grey" variant="caption" display="block" gutterBottom>
            <Tooltip title="Valor gasto">
              <span>R$ {formatToMoney(total)}</span>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
      <Percent>
        <PercentageCompleted color={color} width={getPercentage()} />
      </Percent>
    </Container>
  );
}

export default StatusPercent;
