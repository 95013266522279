import SchemaBuilder from './SchemaBuilder';

const columns = {
  id:              { type: 'string', title: 'Id' },
  city:            { type: 'string', title: 'Cidade' },
  state:           { type: 'string', title: 'Estado' },
  name:            { type: 'string', default: '', title: 'Nome' },
  district:        { type: 'string', default: '', title: 'Bairro' },
  street:          { type: 'string', default: '', title: 'Logradouro' },
  building_number: { type: 'string', default: '', title: 'Número' },
  complement:      { type: 'string', default: '', title: 'Complemento' },
  zip_code:        { type: 'string', default: '', title: 'CEP' },
};

const addressSchema = new SchemaBuilder( columns );

export default addressSchema;