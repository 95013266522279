import React, { memo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Paper from '~/components/Paper';

import toFloat from '~/utils/Number/toFloat';
import formatToMoney from '~/utils/formatToMoney';

function sum(collection) {
  return collection.reduce((tot, rec) => toFloat(rec?.get('total_value')) + tot, 0)
}

function ItemTable({items}) {
  return (
    <Paper>
      <Typography variant="h6">Itens da NF-e</Typography>
      <div>
        <TableContainer>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell align="right">Quantidade</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell align="right">Subtotal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.valueSeq().toArray().map((row) => (
                <TableRow key={row.get('_token')}>
                  <TableCell>{row.get('product')?.get('name')}</TableCell>
                  <TableCell align="right">{row.get('amount')}</TableCell>
                  <TableCell align="right">{formatToMoney( row.get('unit_value') )}</TableCell>
                  <TableCell align="right">{formatToMoney( row.get('total_value') )}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography><strong>Subtotal:</strong> R${formatToMoney( items.toMap().update(sum) )}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  )
}

export default memo(ItemTable);
