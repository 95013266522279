import React, { useState } from "react";


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";

import InputControl from "~/components/Input/InputControl";
import InputFile from '~/components/Input/InputFile';

export default function Examples() {
  const [state, setState] = useState({
    file1: null,
    file2: null,
  });

  const handleChangeKey = (valKey) => {
    setState({ ...state, ...valKey });
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <InputControl
              value={state.file1}
              name="file1"
              label={"File 1"}
              inputComponent={InputFile}
              onChange={handleChangeKey}
              InputProps={{
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputControl
              id={"ID-CUSTOMIZADO2"}
              value={state.file2}
              name="file2"
              label={"File 2"}
              inputComponent={InputFile}
              onChange={handleChangeKey}
              placeholder="Anexe"
              disabled
              InputProps={{
                options: {
                  accept: 'image/jpeg, image/png'
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
