import styled from 'styled-components';
import Color from 'color';

export const Container = styled.div`
  display: flex;
  width: 280px;
  height: 45px;
  align-items: center;
  background-color: ${Color('#EBDDFD').alpha(0.3).toString()};
  border-radius: 5px;
  transition: width 250ms ease-in-out;
`;

export const Input = styled.input.attrs({
  type: 'text',
  placeholder: 'Pesquisar...'
})`
  width: 81%;
  height: 70%;
  padding: 0 10px 0 15px;
  border: none;
  background: transparent;
  outline-style: none;
  font-size: 1rem;
  color: #9C55F5;

  ::-webkit-input-placeholder { /* Edge */
    color:  #9C55F5;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:  #9C55F5;
  }

  ::placeholder { /* Chrome and Firefox */
    color:  #9C55F5;
  }
`;
