import Request from '~/services/request';
import queryStringParser from '~/utils/queryStringParser';

export default function peopleFetch(inputValue, page, doneCallback) {
  return Request.get(`/api/v1/search/people?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name,
        }))
      }
    )
  })
  .catch(err => err);
}

export const peopleFetchWithParams = (params) => (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/people?${queryStringParser({
    qs: inputValue,
    page,
    ...params
  })}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name,
        }))
      }
    )
  })
  .catch(err => err);
}

export const peopleAsyncShow = (id) => {
  return Request.get(`/api/v1/search/people/${id}`).then(resp => {
    if (resp.data && resp.data.data) {
      return resp.data.data;
    }

    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}
