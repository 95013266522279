import React from 'react';
import moment from 'moment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const DateFormatter = ({ value }) => {
  const valueDT = moment(value);
  if( !valueDT.isValid() ){ return ''; }
  return valueDT.format('DD/MM/YYYY');
}
const DateTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    {...props}
  />
);

export default DateTypeProvider;