import RecordBase from './Base';
import constructionSchema from './schema/constructionSchema';
import AddressRecord from './AddressRecord';
import PersonRecord from './PersonRecord';
import ArchiveRecord from '~/records/ArchiveRecord';


export default class ConstructionRecord extends RecordBase {
  static defaultAttributes = constructionSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return constructionSchema.attributeNames }

  static belongsTo = {
    address: AddressRecord ,
    customer: PersonRecord,
    engineer: PersonRecord,
    builder: PersonRecord,
    contractor: PersonRecord,
  };

  static hasMany = {
    attachments:   ArchiveRecord,
  };

  toParams(extraValue = {}) {
    const {
      customer,
      name,
      adm_percent,
      status,
      construction_type,
      engineer,
      // builder,
      contractor,
      start_date,
      end_date,
      latitude,
      longitude,
      cpf_cnpj,
      address
    } = this.toObject();
    const { data: addressData } = address?.toParams() || {};

    const { id } = ( this.get('customer_construction') || {} )
    const customerConstruction = {
      id,
      customer_id: customer.id || null
    };

    return {
      data: {
        attributes: {
          customer_construction_attributes: customerConstruction,
          name,
          cpf_cnpj,
          adm_percent,
          status: status?.id,
          construction_type: construction_type?.id,
          engineer_id: engineer?.id || null,
          // builder_id: builder?.id || null,
          contractor_id: contractor?.id || null,
          start_date,
          end_date,
          latitude,
          longitude,
          address_attributes: addressData?.attributes,
          attachments_ids:     ArchiveRecord.getNewFiles( this.get('attachments') ),
          del_attachments_ids: ArchiveRecord.getDeleteFiles( this.get('attachments') ),
          ...extraValue
        }
      }
    };
  }

  attrName(attr) {
    const [attrName, attrParentName] = attr.split('.');
    const isBelongToAttr = ConstructionRecord.belongsTo[attrName];

    if (isBelongToAttr) {
      return isBelongToAttr.hummanAttributeName(attrParentName);
    }

    return ConstructionRecord.hummanAttributeName(attrName);
  }

  updateAddress(attrs) {
    const address = this.address || new AddressRecord();

    return { address: address.updateAttributes(attrs) };
  }
}
