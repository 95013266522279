import React, { useCallback } from 'react';

import { constructionsRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import constructionSchema from '~/records/schema/constructionSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/constructions';
import Chip from '~/components/Chip';
import constructionStatusEnum from '~/enums/constructionStatusEnum'

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      let requestParams = 'include=customer-engineer';
      if(params){
        requestParams = `${params}&${requestParams}`
      }

      dispatch( fetchRecords(requestParams, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Obras' }]}
      permissionKeyCode="Construction"
      routes={{
        newPath:  ()  =>  constructionsRoute.new.build(),
        showPath: (id) => constructionsRoute.show.build({id}),
        editPath: (id) => constructionsRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: constructionSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Construction',
        filterProps: {
          columns: constructionSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'adm_percent',
            formatterComponent: (columnName, row) => {
              const percent = row.adm_percent ?? 0;

              return <div>{Number(percent).toFixed(1)}%</div>;
            }
          },
          {
            name: 'customer',
            formatterComponent: (columnName, row) => {
              return <div>{row.customer?.name}</div>;
            }
          },
          {
            name: 'engineer',
            formatterComponent: (columnName, row) => {
              return <div>{row.engineer?.name}</div>;
            }
          },
          {
            name: 'status',
            formatterComponent: (columnName, row) => {
              const value = constructionStatusEnum().find(row.status)
              return <Chip
                color={value?.color || 'red'}
                label={value?.name}
                variant="outlined"
                size="small"
              />;
            }
          }
        ]
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.constructions.listIsLoading,
  rows: state.constructions.data,
  total: state.constructions.totalData,
});

export default connect(mapStateToProps)(List);