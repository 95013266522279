import React from 'react';
import { Switch } from 'react-router-dom';

import { usersRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';
import Show from './Show';
import Edit from './Edit';
import New  from './New';

export default function UsersRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={usersRoute.new.path}
        component={New}
        permissionKeyCode="User"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={usersRoute.clone.path}
        component={New}
        permissionKeyCode="User"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={usersRoute.show.path}
        component={Show}
        permissionKeyCode="User"
        permission="see"
      />
      <RouteWrapper
        exact
        isPrivate
        path={usersRoute.edit.path}
        component={Edit}
        permissionKeyCode="User"
        permission="edit"
      />

      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={usersRoute.list.path}
        component={List}
        permissionKeyCode="User"
        permission="see"
      />
    </Switch>
  );
}
