import React, { useState } from 'react';
import { Deserializer } from 'jsonapi-serializer';
import Request from '~/services/request';
import MetaFieldRecord from '~/records/MetaFieldRecord';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';
import InputCheckBox   from '~/components/Input/InputCheckBox';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import metaFieldSizeEnum from '~/enums/metaFieldSizeEnum';
import metaFieldCompetenceEnum from '~/enums/metaFieldCompetenceEnum';

import SuggestionInput from './components/SuggestionInput'
import InputSelectField from './components/InputSelectField'
import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import isPresent from '~/utils/isPresent';
import Errors from '~/components/Errors';
import formatResponseError from '~/utils/formatResponseError';

const { deserialize } = new Deserializer({
  keyForAttribute: 'snake_case',
});

const attrName = (name) => MetaFieldRecord.hummanAttributeName(name);

const  MetaFieldForm = ({currentFieldIds, referenceType, referenceID, onSave, metaField, onCloseForm}) => {
  const [record, setRecord] = useState(metaField);
  const [errors, setErrors] = useState([]);

  const isPersisted = isPresent(record.get('id'));

  const onSubmitForm = async (event) => {
    event.preventDefault();
    let resp = null;
    try {
      if (record.get('id')){
        resp = await Request.put(`/api/v1/meta_fields/${referenceType}/${referenceID}/${record.get('id')}?include=field`, record.toParams() )
      }else{
        resp = await Request.post(`/api/v1/meta_fields/${referenceType}/${referenceID}?include=field`, record.toParams() )
      }
      const data = await deserialize(resp.data)
      onSave( new MetaFieldRecord(data) );
    } catch(err) {
      setErrors( formatResponseError(err.errors) )
    }
  }

  const competencesOptions = () =>{
    if( referenceType === 'Metadatum' ){
      return metaFieldCompetenceEnum().array
    }else if (referenceType === 'CustomRecord'){
      return metaFieldCompetenceEnum().array.filter( e => e.id === 'items')
    }else{ return metaFieldCompetenceEnum().array.filter( e => e.id === 'record') }
  }

  const handleUpdate = (attrs) => setRecord( record.updateAttributes(attrs) )

  return(
    <Dialog
      PaperProps={{
        component: 'form',
        onSubmit: onSubmitForm
      }}
      icon={<FeaturedPlayListIcon/>}
      fullWidth
      maxWidth='sm'
      isOpen={true}
      color={ isPersisted ? 'primary' : 'green' }
      handleClose={onCloseForm}
      title={isPersisted ? 'Editando campo': 'Novo campo'}
      dialogActions={
        <React.Fragment>
          <Button onClick={onCloseForm} color="red" variant="text">
            Cancelar
          </Button>
          <Button type='submit' color="primary" >
            Salvar
          </Button>
        </React.Fragment>
      }
    >
      <div style={{width: '100%'}}>
        <Errors errors={errors} />
        <GridContainer>
          <Col xs={12} sm={6}>
            <InputSelectField
              currentFieldIds={currentFieldIds}
              record={record}
              isPersisted={isPersisted}
              attrName={attrName}
              handleUpdate={handleUpdate}
            />
          </Col>
          <Col xs={12} sm={3}>
            <InputControl
              name="required"
              label={attrName('required')}
              value={record.get('required')}
              inputComponent={InputCheckBox}
              onChange={handleUpdate}
            />
          </Col>
          <Col xs={12} sm={3}>
            <InputControl
              name="active"
              label={attrName('active')}
              value={record.get('active')}
              inputComponent={InputCheckBox}
              onChange={handleUpdate}
            />
          </Col>
          <Col xs={12} sm={4}>
            <InputControl
              name="field_size"
              label={attrName('field_size')}
              value={record.get('field_size')}
              inputComponent={InputSelect}
              onChange={handleUpdate}
              InputProps={{
                options: metaFieldSizeEnum().array,
                outValueType: 'number'
              }}
            />
          </Col>
          <Col xs={12} sm={4}>
            <InputControl
              name="competence"
              label={attrName('competence')}
              value={record.get('competence')}
              inputComponent={InputSelect}
              onChange={handleUpdate}
              InputProps={{
                options: competencesOptions(),
                outValueType: 'string'
              }}
            />
          </Col>
          <Col xs={12} sm={4}>
            <SuggestionInput record={record} handleUpdate={handleUpdate} />
          </Col>
        </GridContainer>
      </div>
    </Dialog>
  )
}

export default MetaFieldForm;