import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Item = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  justify-content: space-between;



  h3 {
    margin: 0;
    font-size: 1.3rem;
  }
`;
