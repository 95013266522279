import React, { useRef, useContext, useMemo } from 'react';
import Badge from '@material-ui/core/Badge';
import FilterListIcon from '@material-ui/icons/Tune';
import QueryString from 'query-string';

import QueryParamsContext from '~/contexts/QueryParamsContext';
import FilterContext from '~/contexts/FilterContext';
import Button from '~/components/Button';
import InputSearch from '~/components/Input/InputSearch';
import Permission from '~/components/Permission';

import { Container, LeftContent, RightContent, Link } from './styles';

export default function Nav({
  inputSearchProps = {},
  leftComponent,
  rightComponent,
  permissionKeyCode,
  withFilters,
  onNew,
  routes
}) {
  const { params: { size }, setParams } = useContext(QueryParamsContext);
  const { setFilterOpen } = useContext(FilterContext);
  const inputSearchRef = useRef(null);
  const qs = useMemo(() => {
    try {
      return QueryString.parse(location.search)?.qs
    } catch(e) {
      return ''
    }
  }, [])

  const btnNewProps = useMemo(() => {
    const newProps = {to: routes?.newPath ? routes.newPath() : '#'}
    if(typeof onNew === 'function'){
      newProps.onClick = onNew;
    }
    return newProps;
  }, [onNew, routes?.newPath])

  function handleSubmit(value) {
    const { onSubmit } = inputSearchProps;

    setParams({ qs: value });
    onSubmit && onSubmit(value);
  }

  return (
    <Container className="hidden-print">
      <LeftContent>
        <InputSearch
          initialValue={qs}
          ref={inputSearchRef}
          {...inputSearchProps}
          onSubmit={handleSubmit}
        />
        { withFilters &&
          <Badge badgeContent={size} color="primary">
            <Button
              variant="text"
              startIcon={<FilterListIcon />}
              style={{ marginLeft: 10, textTransform: 'none' }}
              onClick={() => setFilterOpen(true)}
            >
              Filtros
            </Button>
          </Badge>
        }
        {leftComponent}
      </LeftContent>
      <RightContent>
        {rightComponent}
        { (routes?.newPath || false) &&
          <Permission keyCode={permissionKeyCode} permission="add">
            <Button component={Link} {...btnNewProps} variant="contained" color="green">
              Novo
            </Button>
          </Permission>
        }
      </RightContent>
    </Container>
  );
}
