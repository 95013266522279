import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const CustomSpinner = styled.div`
  width: ${props => props.width || 80}px;
  height: ${props => props.height || 80}px;
  background: transparent;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid ${props => props.fillColor || '#DDD'};
  border-right: 2px solid ${props => props.fillColor || '#DDD'};
  border-bottom: 2px solid ${props => props.fillColor || '#DDD'};
  border-left:
    ${props => props.activeBorderWidth || 4}px
    solid
    ${props => props.activeColor || '#4F3A8B'};
  border-radius: 50%;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: inline-block;
  padding: 20px;
  align-content: center;
  width: 100%;
  &.fullScreen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #9292926b;
  }
`;

export const Text = styled.span`
  display: block;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 15px;
`;
