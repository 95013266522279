import styled from 'styled-components';
import MUCardContent from '@material-ui/core/CardContent';

export const Container = styled.div`
  padding-bottom: 100px;
`;

export const CardContent = styled(MUCardContent)`
  display: flex;
  padding-top: 40px;

  ul {
    display: flex;
    flex-direction: column;
    flex: 1;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0 5px 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      &:last-child{
        border: none;
      }

      &:first-child {
        font-size: 1rem;
      }

      strong {
        display: flex;
        flex: 1;
      }
    }
  }
`;
