import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const ReadOnlyInput = styled(TextField).attrs( props => ({
  fullWidth: true,
  InputLabelProps: { shrink: true },
  margin: 'normal',
  InputProps: {
    readOnly: true,
    disableUnderline: true,
    ...props.InputProps
  }
}))`
  border-bottom: 1px solid #989795;
`;

export const BoxInputHtml = styled.div.attrs( props => ({
  dangerouslySetInnerHTML: {
    __html: props.value ?? '<p></p>'
  },
  ...props.InputProps
}))`
  min-height: 1rem;
  height: auto;
`;
