import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';
import Address from '~/components/Address';
import InputFile from '~/components/Input/InputFile';

import UserRecord from '~/records/UserRecord';
import userAcessTypeEnum from '~/enums/userAcessTypeEnum';

const attrName = (attr) => UserRecord.hummanAttributeName(attr);

export default function FormFields({record, onChange}) {
  const handleChangeAddress = useCallback(attrs => {
    const newRecord = record.updateAddress(attrs);
    onChange({address: newRecord.get('address') });
  }, [record, onChange]);


  return (
    <div>
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={12} sm={6}>
              <InputControl
                required
                name="name"
                label={attrName('name')}
                value={record.get('name')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={6}>
              <InputControl
                required
                name="email"
                label={attrName('email')}
                value={record.get('email')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col xs={12} sm={4}>
              <InputControl
                required
                value={record.get('access_type')}
                name={'access_type'}
                label={attrName('access_type')}
                inputComponent={InputSelect}
                onChange={onChange}
                InputProps={{
                  options: userAcessTypeEnum().array,
                  outValueType: 'string'
                }}
              />
            </Col>
            <Col xs={12} sm={4}>
              <InputControl
                name="password"
                label={attrName('password')}
                value={record.get('password')}
                inputComponent={Input}
                autoComplete="off"
                InputProps={{
                  type: 'password'
                }}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={4}>
              <InputControl
                name="password_confirmation"
                label={attrName('password_confirmation')}
                value={record.get('password_confirmation')}
                inputComponent={Input}
                autoComplete="off"
                InputProps={{
                  type: 'password'
                }}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <InputControl
                name="avatar"
                label={attrName('avatar')}
                value={record.get('avatar')}
                inputComponent={InputFile}
                onChange={onChange}
                InputProps={{
                  options: {
                    accept: 'image/jpeg, image/png'
                  }
                }}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>

      <h3 style={{ margin: '35px 0px 15px 35px' }}>Endereço</h3>
      <Address
        record={record}
        onChangeAddress={handleChangeAddress}
        disableForm={false}
      />
    </div>
  );
}