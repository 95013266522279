import SchemaBuilder from './SchemaBuilder';

const columns = {
  id:           { type: 'integer',  title: 'ID' },
  name:         { type: 'string',   default: '', title: 'Nome', width: 250 },
  ncm:          { type: 'string',   default: '', title: 'NCM' },
  ean:          { type: 'string',   default: '', title: 'Código de Barras', hidden: true },
  base_value:   { type: 'currency', default: '', title: 'Valor Unitário'},
  account_plan: { type: 'object',   title: 'Plano de conta padrão', isFilter: false, width: 250},
  account_plan_name: { type: 'string',   title: 'Nome plano de conta padrão', isFilter: true, tableView: false},
  created_at:   { type: 'datetime', default: '', title: 'Criado em', width: 150 },
  updated_at:   { type: 'datetime', default: '', title: 'Atualizado em', hidden: true },
};

const productSchema = new SchemaBuilder( columns );

export default productSchema;