import React from 'react';
import { Switch } from 'react-router-dom';

import { npsFormAnswersRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';
import Show from './Show';

export default function NpsFormAnswersRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={npsFormAnswersRoute.show.path}
        component={Show}
        permissionKeyCode="NpsFormAnswer"
        permission="see"
      />
      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={npsFormAnswersRoute.list.path}
        component={List}
        permissionKeyCode="NpsFormAnswer"
        permission="see"
      />
    </Switch>
  );
}
