import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { findRecord, destroyRecord, clearState } from '~/store/modules/people';
import { peopleRoute } from '~/routes'

import AddressShow from '~/components/AddressShow';
import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import AttachmentsPreview from '~/components/AttachmentsPreview';
import PersonRecord from '~/records/PersonRecord';
import isPresent from '~/utils/isPresent';
import ContactsShow from '~/containers/ContactsShow';

const attrName = (attr) => PersonRecord.hummanAttributeName(attr);

function Show({ person, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'attachments-user-address.city.state-contacts'}));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: peopleRoute.list.path, text: 'Clientes' },
        { text: 'Exibir Cliente' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   peopleRoute.new.build(),
        routeCloneRegister: peopleRoute.clone.build({ id }),
        routeEditRegister:  peopleRoute.edit.build({ id }),
        permissionKeyCode:  'Person'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={12} sm={6}>
              <InputShow
                label="Nome"
                value={person?.name}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label="Email"
                value={person?.email}
              />
            </Col>
            { !!person?.is_legal &&
              <Col xs={12}>
                <InputShow
                  label="Razão social"
                  value={person?.legal_name}
                />
              </Col>
            }
          </GridContainer>

          <GridContainer>
            <Col xs={12} sm={4}>
              <InputShow
                label={attrName('cpf_cnpj')}
                type="cpf_cnpj"
                value={person?.cpf_cnpj}
              />
            </Col>

            { !person?.is_legal &&
              <Col sm={6} md={4}>
                <InputShow
                  label={attrName('rg')}
                  type="string"
                  value={person?.rg}
                />
              </Col>
            }

            <Col md={4}>
              <InputShow
                label={attrName('comercial_phone')}
                type="phone_cellphone"
                value={person?.comercial_phone}
              />
            </Col>
            <Col md={4}>
              <InputShow
                label={attrName('residential_phone')}
                type="phone_cellphone"
                value={person?.residential_phone}
              />
            </Col>
            <Col md={4}>
              <InputShow
                label={attrName('cell_phone')}
                type="phone_cellphone"
                value={person?.cell_phone}
              />
            </Col>
            <Col md={4}>
              <InputShow
                label={person?.is_legal ? 'Data da fundação' : 'Data de nascimento'}
                type="date"
                value={person?.birth_date}
              />
            </Col>

            { !!person?.is_legal && (
              <Col md={4}>
                <InputShow
                  label={attrName('is_simple')}
                  type="boolean"
                  value={person?.is_simple}
                />
              </Col>
            )}
          </GridContainer>

          { !!person?.is_legal && (
            <GridContainer>
              <Col sm={6} md={3}>
                <InputShow
                  label={attrName('state_registration')}
                  type="string"
                  value={person?.state_registration}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputShow
                  label={attrName('municipal_registration')}
                  type="string"
                  value={person?.municipal_registration}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputShow
                  label={attrName('icms_exempt')}
                  type="boolean"
                  value={person?.icms_exempt}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputShow
                  label="Capital social"
                  type="float"
                  value={person?.share_capital}
                  floatPrecision={2}
                />
              </Col>
            </GridContainer>
          )}

          { isPresent(person?.user) && !person.user.disabled_at &&
            <Paper elevation={2} style={{padding: 16, margin: '16px 0px'}}>
              <GridContainer>
                <Col>
                  <Typography variant="h6">Usuário</Typography>
                </Col>
                <Col md={4}>
                  <InputShow
                    label="Nome"
                    value={person.user.name}
                  />
                </Col>
                <Col md={4}>
                  <InputShow
                    label="Email"
                    value={person.user.email}
                  />
                </Col>
                <Col md={4}>
                  <InputShow
                    label="Criado em"
                    type="datetime"
                    value={person.user.created_at}
                  />
                </Col>
              </GridContainer>
            </Paper>
          }

          <ContactsShow title="Contatos" contacts={person?.contacts} />

          <br/>
          <Typography variant="h6">Endereço</Typography>
          { !!person?.address && <AddressShow address={person.address} /> }

          <GridContainer>
            <Col>
              <InputShow
                isHtml
                label="Observacão"
                value={person?.observation}
              />
            </Col>
          </GridContainer>

          <AttachmentsPreview
            title="Anexos"
            attachments={person?.attachments}
          />
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.people.isLoading,
  person: state.people.current,
});

export default connect(mapStateToProps)(Show);