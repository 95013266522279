/* eslint-disable react/prop-types */
import React     from 'react';
// import PropTypes from 'prop-types';

import InputRegex from '../InputRegex';

const MASK_10  = ['(', /[1-9+]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
const MASK_11  = ['(', /[1-9+]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
const MASK_12  = [/[1-9+]/, /\d/, /[0-9\s]/, ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
const MASK_13  = [/[1-9+]/, /\d/, /[0-9\s]/, ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const getMaskFromValue = (value) => {
  const arrayValue = (`${value}`).match(/[\d+]/g);
  let numb = '';
  if (Array.isArray(arrayValue)){
    numb = arrayValue.join('');
  }
  let firstLetter = '';
  if( Array.isArray(arrayValue) ){
    firstLetter = arrayValue[0];
  }

  if( firstLetter === '+'){
    return numb.length > 13 ? MASK_13 : MASK_12;
  }else if(numb.length > 11){
    return numb.length > 12 ? MASK_13 : MASK_12;
  }else if(numb.length === 11){
    return MASK_11;
  }else{
    return MASK_10;
  }
};

class InputPhone extends React.Component {

  static getDerivedStateFromProps(nextProps) {
    return {mask: getMaskFromValue(nextProps.value) };
  }

  state = {
    mask: MASK_10,
  };

  render() {
    const { inputProps, ...rest } = this.props;
    return (
      <InputRegex
        {...rest}
        inputProps={{
          ...inputProps,
          type: 'tel'
        }}
        mask={this.state.mask}
      />
    );
  }
}

export default InputPhone;