import RecordBase from './Base';
import engineerSchema from './schema/engineerSchema';

export default class EngineerRecord extends RecordBase {
  static defaultAttributes = engineerSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return engineerSchema.attributeNames }

  toParams() {
    const {
      id,
      name,
    } = this.toObject();

    return {
      data:{
        attributes:{
          id,
          name
        }
      }
    };
  }
}