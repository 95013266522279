import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearState,
  findDashboardDataRequest
} from '~/store/modules/dashboard';
import { useGlobalFilters } from '~/hooks/useGlobalFilters';

import BaseLayout from '~/components/BaseLayout';
import Spinner from '~/components/Spinner';

import Filter from './components/Filter';
import Totalizers from './components/Totalizers';
import ExpensesPerMonthChart from './components/ExpensesPerMonthChart';
import DoughnutCharts from './components/DoughnutCharts';

export default function Dashboard() {
  const { startDate, setStartDate, endDate, setEndDate } = useGlobalFilters();
  const dispatch = useDispatch();
  const { searchingRegisters } = useSelector(state => state.dashboard);

  useEffect(() => {
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(findDashboardDataRequest(startDate, endDate));
  }, [startDate, endDate]);

  const handleChange = useCallback(
    (key, value) => {
      if (key === 'start') {
        setStartDate(value);
      } else {
        setEndDate(value);
      }
    },
    []
  );

  if (searchingRegisters) {
    return (
      <BaseLayout>
        <Spinner loadingText="" />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Filter
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
      />
      <Totalizers />
      <ExpensesPerMonthChart />
      <DoughnutCharts />
    </BaseLayout>
  );
}