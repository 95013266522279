import React, { useCallback } from 'react';
import CrudFixedFooterActions from '~/containers/CrudFixedFooterActions';
import { Form } from './styles';

export default function CrudForm({footerActionsProps, children, onSubmit, ...props}) {

  const handleSubmit = useCallback(
    (event) => {
      if(typeof onSubmit === 'function'){
        event.preventDefault();
        onSubmit(event)
      }
    },
    [onSubmit],
  )

  return (
    <Form {...props} onSubmit={handleSubmit}>
      {children}
      <CrudFixedFooterActions showSaveAndContinue {...footerActionsProps} />
    </Form>
  );
}