import React, { useContext, useEffect } from "react";

import DrawerMenuContext from '~/contexts/DrawerMenuContext';
import { useBreadcrumbs } from '~/hooks/useBreadcrumbs';
import { usePermissions } from '~/hooks/usePermissions';
import { dashboardRoute } from '~/routes';
import isPresent from '~/utils/isPresent';

import Toolbar from '../Toolbar';
import Breadcrumbs from '../Breadcrumbs';

import { Section } from './styles';

export default function Container({
  breadcrumbs,
  showDefaultBreadcrumb = true,
  children
}) {
  const { drawerMenuStorageState } = useContext(DrawerMenuContext);
  const { can } = usePermissions();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    const hasPermissionToAccessDashboard = can('Dashboard', 'see');
    const newBreadcrumbs = breadcrumbs || [];
    let defaultBreadcrumb = null;

    if (showDefaultBreadcrumb && hasPermissionToAccessDashboard) {
      defaultBreadcrumb = { path: dashboardRoute.self.path, text: 'Início' };
    }

    setBreadcrumbs(
      isPresent(defaultBreadcrumb)
        ? [defaultBreadcrumb, ...newBreadcrumbs]
        : newBreadcrumbs
    );
  }, []);

  return (
    <Section className="printable" drawerOpen={drawerMenuStorageState}>
      <Toolbar />
      <Breadcrumbs />

      {children}
    </Section>
  );
}
