import React, {useCallback, useEffect, memo, useState, useMemo} from 'react';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import Request from '~/services/request';
import sortArray from '~/utils/sortArray'
import isPresent from '~/utils/isPresent';
import round from '~/utils/Number/round';
import formatToMoney from '~/utils/formatToMoney';

import CustomTypography from '~/components/Typography';
import Tooltip from '~/components/Tooltip';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import InputSelect from '~/components/Input/InputSelect';
import Button from '~/components/Button';

import ExpenseRecord from '~/records/ExpenseRecord'

const attrName = (attr) => ExpenseRecord.hummanAttributeName(attr);

function Expenses({ record, onChange, setRateExpense, invoiceItems}) {
  const [accountPlans, setAccountPLans] = useState([])

  const currentExpenses = useMemo(
    () => record.get('expenses').filter( r => !r.get('_destroy') ).valueSeq().toArray()
  , [record.get('expenses')])

  const totalSum = useMemo(
    () => round( currentExpenses.reduce((a,v) =>  a = a + round(v.get('total_value'), 2) , 0.0 ), 2)
  , [record.get('expenses')])

  const totalColor = useMemo( () => {
    if(round(record.get('total_value'), 2) === totalSum) return 'green'
    return 'red'
  } , [record.get('total_value'), totalSum])


  useEffect(() => {
    const constructionID = record.get('construction')?.get('id');
    if(constructionID){
      const fetch = async () => {
        const resp = await Request.get(`/api/v1/constructions/${constructionID}/account_plans`);
        const parents = resp.data.data.map(e => e.parent_id);
        let options = resp.data.data.filter(e => !parents.includes(e.id) )
          .map( ap => ({id: ap.id, name: `${ap.full_code} - ${ap.name}`, code: parseFloat(ap.full_code) }) )

        options = sortArray( options, 'asc', 'code' )

        setAccountPLans(options)
      }
      fetch()
    }
  }, [record.get('construction')?.get('id')])

  const handleChange = useCallback(
    (expense) => (changes) => {
        const newExpense = expense.updateAttributes(changes);
        const expenses = record.get('expenses').setIn(
          [expense.idOrToken],
          newExpense
        );

        if( Object.keys(changes).includes('account_plan') ){
          let invoice_items = invoiceItems;

          invoiceItems.filter(  item =>
            isPresent( item.get('account_plan') ) &&
            `${item.get('account_plan').get('id')}` === `${expense?.get('account_plan')?.get('id')}`
          ).valueSeq().toArray().forEach( invoiceItem => {
            invoice_items = invoice_items.setIn(
              [invoiceItem.idOrToken],
              invoiceItem.updateAttributes({ account_plan: changes.account_plan })
            )
          })
          onChange( {expenses, invoice_items} );
          return
        }
        onChange( {expenses} );
    },
    [record, onChange]
  );

  const handleDelete = useCallback(
    (expense) => () => {
      let expenses = record.get('expenses')
      if(expenses.size > 0){
        if( expense.isPersisted() ){
          expenses = expenses.set(
            expense.idOrToken,
            expense.set('_destroy', true)
          );
        }else{
          expenses = expenses.delete(expense.idOrToken);
        }

        const relatedItems = invoiceItems.filter(  item =>
          isPresent( item.get('account_plan') ) &&
          `${item.get('account_plan').get('id')}` === `${expense?.get('account_plan')?.get('id')}`
        )

        if( relatedItems.size > 0 ){
          let invoice_items = invoiceItems;

          relatedItems.valueSeq().toArray().forEach( invoiceItem => {
            invoice_items = invoice_items.setIn(
              [invoiceItem.idOrToken],
              invoiceItem.updateAttributes({ account_plan: null })
            )
          })
          onChange( {expenses, invoice_items} );
          return
        }

        onChange( {expenses} );
      }
    },
    [record, onChange]
  );

  const handleAddExpense = useCallback(
    () => {
      const newRecord = record.addExpense();
      onChange( {expenses: newRecord.get('expenses') } );
    },
    [record, onChange]
  );

  return (
    <GridContainer>
      <Col xs={12}>
        <Typography variant="h6">Rateio de planos de conta</Typography>
      </Col>
      <Col xs={12}>
        { currentExpenses.map( (expense) => (
          <GridContainer key={expense.idOrToken} direction="row" alignItems="center">
            <Col xs={12} sm={4}>
              <InputControl
                required
                name="account_plan"
                label={attrName('account_plan')}
                value={expense.get('account_plan')}
                inputComponent={InputSelect}
                InputProps={{
                  options: accountPlans
                }}
                onChange={handleChange(expense)}
              />
            </Col>
            <Col xs={12} sm={3} md={4}>
              <InputControl
                name="notes"
                label={attrName('notes')}
                value={expense.get('notes')}
                inputComponent={Input}
                onChange={handleChange(expense)}
              />
            </Col>
            <Col xs={12} sm={3}>
              <InputControl
                required
                name="total_value"
                label={attrName('total_value')}
                value={expense.get('total_value')}
                inputComponent={InputNumber}
                onChange={handleChange(expense)}
                startAdornment={<span>R$</span>}
              />
            </Col>
            <Col xs={12} sm={2} md={1}>
              { !!invoiceItems && !!expense.get('account_plan') && invoiceItems.size > 0 &&
                <IconButton onClick={ () => setRateExpense(expense)}>
                  <Tooltip title="Selecionar itens para rateio">
                    <PlaylistAddCheckIcon style={{ color: 'blue', fontSize: 24 }} />
                  </Tooltip>
                </IconButton>
              }
              { record.get('expenses').size > 1 &&
                <IconButton onClick={handleDelete(expense)}>
                  <DeleteIcon style={{ color: 'red', fontSize: 24 }} />
                </IconButton>
              }
            </Col>
          </GridContainer>
        )) }

        <GridContainer direction="row" alignItems="center">
          <Col xs={12} sm={10} md={11} style={{textAlign: 'right'}}>
            <CustomTypography color={totalColor}>Soma: R$ { formatToMoney(totalSum) }</CustomTypography>
            { totalColor === 'red' &&
              <CustomTypography>Diferença: R$ { formatToMoney(round(record.get('total_value'), 2) - totalSum) }</CustomTypography>
            }
          </Col>
        </GridContainer>

        <GridContainer direction="row" justify="flex-end" alignItems="center">
          <Col xs={12} sm={3} style={{textAlign: 'right'}}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#00D1FF', marginTop: 15 }}
              onClick={handleAddExpense}
            >
              + Rateio
            </Button>
          </Col>
        </GridContainer>
      </Col>
    </GridContainer>
  );
}

export default memo(Expenses);
