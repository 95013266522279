import React, {useEffect, useState, useCallback} from 'react';
import filesize from 'filesize';

import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Input  from '@material-ui/core/Input';
import Box  from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';

import Request from '~/services/request';
import { fileUploadsApi } from '~/routes/api';

import Button from '~/components/Button';
import InputControl from '~/components/Input/InputControl';

const useStyles = makeStyles(() => ({
  imgContainer: {
    width: '100%',
    maxWidth: 350,
    height: 150,
    overflow: 'hidden'
  },
  img: {
    maxWidth: '100%',
    height:   '100%',
    margin:   'auto',
    padding:   5,
    objectFit: 'contain'
  },
  drawer: {
    padding: 16
  }
}));

export default function RecipeReviewCard({archive, onChange, onClose, isOpen}) {
  const classes = useStyles();
  const [current, setCurrent] = useState(null)

  useEffect(() => {
    if(isOpen){
      setCurrent( archive )
    }else{
      setCurrent( null )
    }
  }, [archive, isOpen])

  const handleChange = useCallback(
    (changes) => {
      setCurrent( current.updateAttributes(changes) )
    },
    [current, setCurrent],
  )

  const onSave = useCallback(
    () => {
      const uuid = archive.get('uuid');
      Request.put( fileUploadsApi.update.build({uuid}), current.toParams() )
        .then( resp => {
          onChange(
            archive.updateAttributes( resp.data.archive )
          )
        } )
    },
    [current, archive, onChange],
  )

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <div className={classes.drawer}>
        { current &&
          <>
            <Typography gutterBottom variant="subtitle1" >{current.get('filename')}</Typography>
            <div className={classes.imgContainer}>
              <CardMedia
                className={classes.img}
                component="img"
                alt={ current.get('description') || '' }
                image={current.get('icon')}
                title={current.get('filename')}
              />
            </div>
            <span>{ filesize( current.get('file_size') ) }</span>
            <InputControl
              label={'Descrição'}
              value={current.get('description')}
              inputComponent={Input}
              name={'description'}
              onChange={handleChange}
              InputProps={{multiline: true}}
            />
            <Box display="flex" p={1} justifyContent="space-between">
              <Button variant="text" color="red" startIcon={ <CloseIcon /> } onClick={onClose} >
                Cancelar
              </Button>
              <Button variant="text" startIcon={<SaveIcon />} onClick={onSave}>Salvar</Button>
            </Box>
          </>
        }
      </div>
    </Drawer>
  );
}