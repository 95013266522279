/*eslint prefer-const: "off"*/
'use strict';

/* Método que retorna apenas os valores que são iguais em dois Arrays.
  Examples
    intersectionArray([1,2,3], [4,5,2,7]) => [2]
    intersectionArray([1,2,3], [1,2,3,7]) => [1,2,3]
    intersectionArray([1,3,3], [3,3,1,1]) => [1,3]
    intersectionArray([1,2,3], [4,5,6,7]) => []
*/
export default function intersectionArray(arr1, arr2) {
  let a = new Set(arr1);
  let b = new Set(arr2);

  return Array.from(
    new Set([...a].filter( x =>
      b.has(x)
    ))
  );
}