import RecordBase from './Base';
import metaFieldSchema from './schema/metaFieldSchema';
import FieldRecord from './FieldRecord';

export default class MetaFieldRecord extends RecordBase {
  static defaultAttributes = metaFieldSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return metaFieldSchema.attributeNames }

  static belongsTo = { field:   FieldRecord };

  toParams(extraParams = {}) {
    return {
      data:{
        attributes:{
          suggestion:    this.get('suggestion'),
          competence:    this.get('competence'),
          behavior:      this.get('behavior'),
          field_size:    this.get('field_size'),
          required:      this.get('required'),
          active:        this.get('active'),
          sorter:        this.get('sorter'),
          field_id:      this.get('field')?.id || null,
          ...extraParams,
        }
      }
    };
  }
}
