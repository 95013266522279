import React, {
  useState,
  useCallback,
} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '~/components/Button';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputControl from '~/components/Input/InputControl';
import InputSelectAsync from '~/components/Input/InputSelectAsync';

import isPresent from '~/utils/isPresent';
import Request from '~/services/request';
import { productsApi } from '~/routes/api';
import productsFetch from '~/services/selectFetch/productsFetch'

import { Item } from './styles';

function XMLProductsComponent({  items , supplier, onChangeItem }) {
  const [isLoading, setIsLoading] = useState({})

  const handleCreateProduct = useCallback(
    async (index, item) => {
      try {
        setIsLoading({...isLoading, [index]: true})

        const response = await Request.post(`${productsApi.create.build()}?adapter=json`, {
          data: {
            attributes: {
              name: item.xml_name,
              ncm: item.ncm,
              ean: item.ean,
              base_value: item.base_value,
              supplier_products_attributes: [{
                supplier_id: supplier.id,
                supplier_code: item.supplier_code
              }]
            }
          }
        });
        const { product } = response.data;

        const newItem = {...items[index]}
        newItem.product = product

        onChangeItem(index, newItem)

      } catch(err) {
        console.error(err)
      }

      setIsLoading({...isLoading, [index]: false})
    },
    [supplier, items, onChangeItem, setIsLoading, isLoading]
  );

  const handleUpdateProduct = useCallback(
    async (index, product) => {
      try {
        const item = {...items[index]}
        item.product = product;

        setIsLoading({...isLoading, [index]: true})

        await Request.put(
          productsApi.linkSupplierCode.build({id: product.id}),
          { supplier_id: supplier.id, supplier_code: item.supplier_code }
        );
        onChangeItem(index, item)
      } catch(err) {
        console.error(err)
      }

      setIsLoading({...isLoading, [index]: false})
    },
    [supplier, items, onChangeItem, isLoading]
  );

  if (!items?.length) {
    return <></>;
  }

  return (
    <Card style={{ marginTop: 30 }}>
      <CardContent>
        {items.map( (item, idx) => (
          <Item key={idx}>
            <strong>{item.xml_name}</strong>

            <InputControl
              name="product"
              disabled={!!item.product}
              label="Produto"
              value={item.product}
              inputComponent={InputSelectAsync}
              onChange={({ product }) => {
                handleUpdateProduct(idx, product);
              }}
              style={{ width: 200 }}
              InputProps={{
                handleFetch: productsFetch
              }}
            />

            { isPresent(item.product)
              ? (
                <Button startIcon={<CheckIcon />} disabled variant="outlined">
                  Identificado
                </Button>
              ) : (
                <Button
                  startIcon={ isLoading[idx] ? null : <SaveIcon style={{ fontSize: 18 }} />}
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreateProduct(idx, item)}
                >
                  { isLoading[idx]
                    ? (
                      <>
                        <CircularProgress style={{ color: '#fff' }} size={19} />
                        <p>Salvando...</p>
                      </>
                    ) : 'Criar Produto'
                  }
                </Button>
              )
            }
          </Item>
        ))}
      </CardContent>
    </Card>
  );
}

export default XMLProductsComponent;