import React, { useState } from 'react';
import Input from '@material-ui/core/Input';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import InputControl from '~/components/Input/InputControl';

import { forgotPasswordRequest } from '~/store/modules/forgotPassword';
import { authRoute } from '~/routes'
import Button from '~/components/Button';

import {
  Container,
  BackgroundColorAbsolute,
  ContainerLogin,
  Logo
} from './styles';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector(state => state.forgotPassword.loading);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(forgotPasswordRequest(email));
  }

  return (
    <Container>
      <BackgroundColorAbsolute />
      <ContainerLogin>
        <form onSubmit={handleSubmit}>
          <Logo />

          <div style={{width: '80%', marginBottom: 30}}>
            <InputControl
              name="email"
              label="Informe um e-mail para recuperação de senha"
              value={email}
              inputComponent={Input}
              InputProps={{ type: 'email' }}
              onChange={(value) => setEmail(value.email)}
            />
            <Button variant="text" color="white" component={Link} to={authRoute.signIn.build()}>Login</Button>
          </div>

          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="green"
            fullWidth
          >
            Enviar
          </Button>
        </form>
      </ContainerLogin>
    </Container>
  );
}
