import RecordBase from './Base';
import npsFormAnswerSchema from './schema/npsFormAnswerSchema';
import PersonRecord from './PersonRecord';
import ConstructionRecord from '~/records/ConstructionRecord';
import EngineerRecord from '~/records/EngineerRecord';
import NpsFormRecord from '~/records/NpsFormRecord';

export default class NpsFormAnswerRecord extends RecordBase {
  static defaultAttributes = npsFormAnswerSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return npsFormAnswerSchema.attributeNames }

  static belongsTo = {
    form: NpsFormRecord,
    construction: ConstructionRecord,
    customer: PersonRecord,
    engineer: EngineerRecord,
  };

  toParams() {
    const {
      engineering_score_observation,
      engineering_score,
      execution_team_score_observation,
      execution_team_score,
      shopping_team_score_observation,
      shopping_team_score,
    } = this.toObject();

    return {
      data: {
        attributes: {
          engineering_score_observation,
          engineering_score,
          execution_team_score_observation,
          execution_team_score,
          shopping_team_score_observation,
          shopping_team_score,
        }
      }
    };
  }
}