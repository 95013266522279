import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';

import InputSelect from '~/components/Input/InputSelect';
import InputControl from '~/components/Input/InputControl';
import InputCheckBox   from '~/components/Input/InputCheckBox';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import MetadatumRecord from '~/records/MetadatumRecord';
import metadatumCompetenceEnum from '~/enums/metadatumCompetenceEnum';

const attrName = (attr) => MetadatumRecord.hummanAttributeName(attr);

export default function FormFields({ record, onChange}) {
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col xs={12} sm={4}>
            <InputControl
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col xs={12} sm={4}>
            <InputControl
              required
              value={record.get('competence')}
              name={'competence'}
              label={attrName('competence')}
              inputComponent={InputSelect}
              onChange={onChange}
              InputProps={{
                options: metadatumCompetenceEnum().array,
                outValueType: 'string'
              }}
            />
          </Col>
          <Col xs={12} sm={4}>
            <InputControl
              name="sys_key"
              label={attrName('sys_key')}
              value={record.get('sys_key')}
              type="text"
              InputProps={{
                placeholder: 'Gerado automaticamnete'
              }}
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
        </GridContainer>
        <Typography variant="h6">Registro</Typography>
        <GridContainer>
          <Col xs={12} sm={3}>
            <InputControl
              name="record_singular_name"
              label={attrName('record_singular_name')}
              value={record.get('record_singular_name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col xs={12} sm={3}>
            <InputControl
              name="record_plural_name"
              label={attrName('record_plural_name')}
              value={record.get('record_plural_name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col xs={12} sm={3}>
            <InputControl
              name="active"
              label={attrName('active')}
              value={record.get('active')}
              type="text"
              inputComponent={InputCheckBox}
              onChange={onChange}
            />
          </Col>
          <Col xs={12} sm={3}>
            <InputControl
              name="icon_lib_record"
              label={attrName('icon_lib_record')}
              value={record.get('icon_lib_record')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
              startAdornment={
                <a href="https://material.io/resources/icons" target="_blank" rel="noreferrer">
                  <Icon>help</Icon>
                </a>
              }
              endAdornment={<Icon>{record.get('icon_lib_record') || 'dynamic_form'}</Icon>}
            />
          </Col>
        </GridContainer>
        { record.get('competence') === 'record_items' &&
          <GridContainer>
            <Col xs={12} sm={3}>
              <InputControl
                name="record_item_singular_name"
                label={attrName('record_item_singular_name')}
                value={record.get('record_item_singular_name')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={3}>
              <InputControl
                name="record_item_plural_name"
                label={attrName('record_item_plural_name')}
                value={record.get('record_item_plural_name')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={3}>
              <InputControl
                name="define_fields_in_record"
                label={attrName('define_fields_in_record')}
                value={record.get('define_fields_in_record')}
                type="text"
                inputComponent={InputCheckBox}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={3}>
              <InputControl
                name="icon_lib_record_item"
                label={attrName('icon_lib_record_item')}
                value={record.get('icon_lib_record_item')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
                startAdornment={
                  <a href="https://material.io/resources/icons" target="_blank" rel="noreferrer">
                    <Icon>help</Icon>
                  </a>
                }
                endAdornment={<Icon>{record.get('icon_lib_record_item') || 'dynamic_form'}</Icon>}
              />
            </Col>
          </GridContainer>
        }
      </CardContent>
    </Card>
  );
}
