// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputPhone   from '~/components/Input/InputPhone';

// material-ui
import Grid from '@material-ui/core/Grid';

class InputExample extends Component {
	state = {
		input1: '',
		input2: null,
		input3: null,
	}

	handleChangeKey = ( valKey) => {
		this.setState(valKey);
	}

	handleChangeEvent = (attr) => ( valKey, event ) => {
		this.setState({[attr]: event.target.value});
	}

	render(){

		return(
			<div>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<InputControl
							id={'ID-CUSTOMIZADO-PHONE'}
							value={this.state.input1}
							name={'input1'}
							label={'Phone 1'}
							inputComponent={InputPhone}
							onChange={this.handleChangeKey}
						/>
					</Grid>
					<Grid item xs={4}>
						<InputControl
							required
							value={this.state.input2}
							label={'Phone 2'}
							// inputComponent={Input}
							inputComponent={InputPhone}
							name={'input2'}
							onChange={this.handleChangeEvent('input2')}
						/>
					</Grid>
					<Grid item xs={4}>
						<InputControl
							required
							value={this.state.input3}
							label={'Phone 3'}
							// inputComponent={Input}
							inputComponent={InputPhone}
							name={'input3'}
							onChange={this.handleChangeEvent('input3')}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default InputExample;