import React, { useMemo } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import MuiTypography from '@material-ui/core/Typography';
import makeTheme from '~/assets/styles/makePrimaryColorTheme';

const Typography = ({color, ...props}) => {
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <MuiTypography {...props} color='primary'/>
  </ThemeProvider>
}

export default React.memo(Typography)