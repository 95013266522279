import React from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import BaseLayout from '~/components/BaseLayout';
import Example from '~/components/Input/Examples';
import ButtonsExample from '~/components/Button/Example';
import DialogExample from '~/components/Dialog/Example';
import ChipExample from '~/components/Chip/Example';
import uuid from '~/utils/createUUID';

import InputDropzoneFile from '~/containers/InputDropzoneFile/Example';

const AcordeonExample = ({header, example})=> {
  return(
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id={`${uuid()}-header`}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>
        {example}
      </AccordionDetails>
    </Accordion>
  )
}

export default function Examples() {
  return (
    <BaseLayout>
      <AcordeonExample
        header={<Typography variant="h5">Dialog</Typography>}
        example={<DialogExample />}
      />

      <AcordeonExample
        header={<Typography variant="h5">Chips</Typography>}
        example={<ChipExample />}
      />

      <AcordeonExample
        header={<Typography variant="h5">Dropzone File</Typography>}
        example={<InputDropzoneFile />}
      />

      <AcordeonExample
        header={<Typography variant="h5">Botões</Typography>}
        example={<ButtonsExample />}
      />

      <AcordeonExample
        header={<Typography variant="h5">Inputs</Typography>}
        example={<Example />}
      />
    </BaseLayout>
  );
}
