// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelect/Example';

import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputSlider   from '~/components/Input/InputSlider';

// material-ui
import Grid from '@material-ui/core/Grid';

class InputExample extends Component {
  state = {
    input1: 0,
    input2: [10, 30],
    input3: null,
  }

  handleChangeKey = ( valKey ) => {
    this.setState(valKey);
  };

  render(){

    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <InputControl
              id={'ID-CUSTOMIZADO'}
              value={this.state.input1}
              name={'input1'}
              label={'Valor 1'}
              inputComponent={InputSlider}
              onChange={this.handleChangeKey}
            />
          </Grid>
          <Grid item xs={4}>
            <InputControl
              required
              value={this.state.input2}
              label={'Valor 2'}
              inputComponent={InputSlider}
              name={'input2'}
              InputProps={{
                precision: 0
              }}
              onChange={this.handleChangeKey}
            />
          </Grid>
          <Grid item xs={4}>
            <InputControl
              required
              value={this.state.input3}
              label={'Valor 3'}
              inputComponent={InputSlider}
              name={'input3'}
              onChange={this.handleChangeKey}
              InputProps={{
                step: 10,
                min: 10,
                max: 110,
                valueLabelFormat: (v) => `${v}%`
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default InputExample;