import React from 'react';
import { useSelector } from 'react-redux';

import CustomerDashboard from './Customer';
import AdminDashboard from './Admin';

export default function Dashboard() {
  const { data } = useSelector(state => state.userProfile);

  if (data.access_type === 'customer') {
    return <CustomerDashboard />;
  }

  return <AdminDashboard />;
}