import createReducer from '~/store/utils/createReducer';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Request from '~/services/request';
import { dashboardApi } from '~/routes/api';

/* =================================================
  REDUX TYPES
================================================= */
export const FIND_DASHBOARD_REQUEST = '@App/dashboard/FIND_DASHBOARD_REQUEST';
export const FIND_DASHBOARD_SUCCESS = '@App/dashboard/FIND_DASHBOARD_SUCCESS';
export const FIND_DASHBOARD_FAILURE = '@App/dashboard/FIND_DASHBOARD_FAILURE';
export const CLEAR_STATE = '@App/dashboard/CLEAR_STATE';

/* =================================================
  REDUX REDUCER
================================================= */
const INITIAL_STATE = {
  data: {},
  errors: [],
  loading: false,
  searchingRegisters: false,
};

export default createReducer(
  INITIAL_STATE,
  {
    [FIND_DASHBOARD_REQUEST]: (draft) => {
      draft.errors = [];
      draft.searchingRegisters = true;
    },
    [FIND_DASHBOARD_SUCCESS]: (draft, {payload},) => {
      draft.data = payload.data;
      draft.searchingRegisters = false;
    },
    [FIND_DASHBOARD_FAILURE]: (draft, {payload}) => {
      draft.errors = payload ? payload.errors : [];
      draft.loading = false;
      draft.searchingRegisters = false;
    },
    [CLEAR_STATE]: (draft) => {
      draft.data = {};
      draft.errors = [];
      draft.loading = false;
      draft.searchingRegisters = false;
    }
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */

// Actions
export function findDashboardDataRequest(
  startDate,
  endDate,
  constructionId = null,
  alternativeUrl = null
) {
  return {
    type: FIND_DASHBOARD_REQUEST,
    payload: { startDate, endDate, constructionId, alternativeUrl }
  }
}

export function findDashboardDataSuccess(data) {
  return {
    type: FIND_DASHBOARD_SUCCESS,
    payload: { data }
  }
}

export function findDashboardDataFailure(errors) {
  return {
    type: FIND_DASHBOARD_FAILURE,
    payload: { errors }
  }
}

export function clearState() {
  return {
    type: CLEAR_STATE
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */

export function* findDashboardData({ payload }) {
  try {
    const { startDate, endDate, constructionId, alternativeUrl } = payload;
    const url = alternativeUrl || dashboardApi.self.build();
    const response = yield call(
      Request.get,
      `${url}?start_date=${startDate}&end_date=${endDate}&construction_id=${constructionId}`
    );
    const responseData = response.data;

    yield put(findDashboardDataSuccess(responseData.data));
  } catch(err) {
    toast.error('Nenhuma informação para demonstrar!');
    yield put(findDashboardDataFailure());
  }
}
export const saga = all([
  takeLatest(FIND_DASHBOARD_REQUEST, findDashboardData),
]);
