import { all } from 'redux-saga/effects';

import {saga as forgotPassword} from './modules/forgotPassword';
import {saga as resetPassword} from './modules/resetPassword';
import {saga as userProfile} from './modules/userProfile';
import {saga as auth} from './modules/auth';
import {saga as crud} from './modules/crud';
import {saga as fields} from './modules/fields';
import {saga as people} from './modules/people';
import {saga as metadata} from './modules/metadata';
import {saga as customRecords} from './modules/customRecords';
import {saga as roles} from './modules/roles';
import {saga as users} from './modules/users';

import {saga as suppliers} from './modules/suppliers';
import {saga as segments} from './modules/segments';
import {saga as products} from './modules/products';
import {saga as invoices} from './modules/invoices';
import {saga as accountPlans} from './modules/accountPlans';
import {saga as constructions} from './modules/constructions';
import {saga as dashboard} from './modules/dashboard';
import {saga as builders} from './modules/builders';
import {saga as engineers} from './modules/engineers';
import {saga as contractors} from './modules/contractors';
import {saga as incomingEmails} from './modules/incomingEmails';
import {saga as npsForms} from './modules/npsForms';
import {saga as npsFormAnswers} from './modules/npsFormAnswers';
import {saga as reportExpenses} from './modules/reportExpenses';

export default function* rootSaga() {
  return yield all([
    crud,
    auth,
    userProfile,
    people,
    metadata,
    roles,
    forgotPassword,
    resetPassword,
    users,
    fields,
    customRecords,
    suppliers,
    segments,
    products,
    invoices,
    accountPlans,
    constructions,
    dashboard,
    builders,
    engineers,
    contractors,
    incomingEmails,
    npsForms,
    npsFormAnswers,
    reportExpenses,
  ]);
}
