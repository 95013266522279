import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import { Container, CustomSpinner, Text } from './styles';

function Spinner({
  loading,
  loadingText,
  loadingTextStyle,
  width,
  height,
  activeBorderWidth,
  activeColor,
  fillColor,
  fullScreen
}) {
  if (!loading) return <Fragment />;

  return (
    <Container className={fullScreen ? 'fullScreen' : ''}>
      <CustomSpinner
        width={width}
        height={height}
        activeBorderWidth={activeBorderWidth}
        activeColor={activeColor}
        fillColor={fillColor}
      />

      {!!loadingText && (
        <Text style={loadingTextStyle}>{loadingText}</Text>
      )}
    </Container>
  );
}

Spinner.defaultProps = {
  loading: true,
  fullScreen: false,
  loadingText: 'Carregando...',
  loadingTextStyle: {}
}

Spinner.propTypes = {
  loading: PropTypes.bool,
  fullScreen: PropTypes.bool,
  loadingText: PropTypes.string,
  activeColor: PropTypes.string,
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  activeBorderWidth: PropTypes.number,
  loadingTextStyle: PropTypes.object
}

export default Spinner;
