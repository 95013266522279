import * as types from './types';

export const init = (filter, columns) => ({
  type: types.INIT,
  payload: {
    filter,
    columns,
  },
});

export const setCondition = (condition) => ({
  type: types.SET_CONDITION,
  payload: {
    condition
  },
});

export const addGroup = () => ({
  type: types.ADD_GROUP,
  payload: {},
});

export const delGroup = (indexGroup) => ({
  type: types.DEL_GROUP,
  payload: {
    indexGroup,
  },
});

export const setGroupCondition = (indexGroup, condition) => ({
  type: types.SET_GROUP_CONDITION,
  payload: {
    indexGroup,
    condition,
  },
});

export const addFilter = (indexGroup) => ({
  type: types.ADD_FILTER,
  payload: {
    indexGroup,
  },
});

export const delFilter = (indexGroup, indexFilter) => ({
  type: types.DEL_FILTER,
  payload: {
    indexGroup,
    indexFilter,
  },
});

export const editFilter = (indexGroup, indexFilter, key, value) => ({
  type: types.EDIT_FILTER,
  payload: {
    indexGroup,
    indexFilter,
    key,
    value,
  },
});
