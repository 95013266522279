import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { formToggleSaveAndContinue } from '~/store/modules/crud'
import Dialog from '~/components/Dialog';
import Button from '~/components/Button';
import Errors from '~/components/Errors';

import useStyles, { Form } from './styles';

const PaperForm = ({children, onSubmit, ...props}) =>
  <Paper {...props}>
    <Form onSubmit={onSubmit} action='#'>
      {children}
    </Form>
  </Paper>

function CrudFormDialog({
  children,
  onSubmit,
  location,
  clearAfterSaved,
  isRouteNew,
  handleClose,
  isLoading,
  errors,

  saveAndContinue,
  dispatch,

  color,
  fullWidth,
  icon,
  isOpen,
  keepMounted,
  maxWidth,
  title
}) {
  const [key, setKey] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if( key && key !== location.key && typeof clearAfterSaved === 'function' ){
      clearAfterSaved()
    }
    setKey(location.key)
  }, [location.key])

  const handleSubmit = useCallback(
    (event) => {
      if(typeof onSubmit === 'function'){
        event.preventDefault();
        onSubmit(event)
      }
    },
    [onSubmit],
  );

  return (
    <Dialog
      color={color}
      fullWidth={fullWidth}
      icon={icon}
      isOpen={isOpen}
      keepMounted={keepMounted}
      maxWidth={maxWidth}
      title={title}
      handleClose={handleClose}
      PaperComponent={PaperForm}
      PaperProps={{onSubmit: handleSubmit}}
      dialogActions={
        <>
          {isRouteNew && (
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  checked={saveAndContinue}
                  onChange={() => dispatch( formToggleSaveAndContinue() )}
                  color="primary"
                />
              )}
              label="Salvar e continuar"
            />
          )}

          <Button
            // startIcon={<CancelIcon />}
            onClick={handleClose}
            color="grey"
          >
            Cancelar
          </Button>
          <Button
            // startIcon={<SaveIcon />}
            className={classes.buttonSave}
            disabled={isLoading}
            type="submit"
            color="success"
          >
            Salvar
          </Button>
        </>
      }
    >
      <Errors errors={errors} />
      {children}
    </Dialog>
  );
}

CrudFormDialog.defaultProps = {
  isOpen: false,
  color: "primary",
  fullWidth: true,
  maxWidth: "sm",
  keepMounted: false,
  isRouteNew: true,
}

CrudFormDialog.propTypes = {
  clearAfterSaved: PropTypes.func,
  onSubmit:    PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title:       PropTypes.string.isRequired,
  color:       PropTypes.string.isRequired,
  maxWidth:    PropTypes.string.isRequired,
  isOpen:      PropTypes.bool.isRequired,
  fullWidth:   PropTypes.bool.isRequired,
  keepMounted: PropTypes.bool.isRequired,
  isRouteNew:  PropTypes.bool.isRequired,
  isLoading:   PropTypes.bool,
  errors:      PropTypes.array,
}

const mapStateToProps = state => ({
  isSaving: state.crud.form.isSaving,
  saveAndContinue: state.crud.form.saveAndContinue,
});

export default connect( mapStateToProps )( withRouter(CrudFormDialog) );