import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import FirstStepIcon from '@material-ui/icons/LibraryAdd';
import SecondStepIcon from '@material-ui/icons/LocalAtm';
import ThirdStepIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import IconEdit from '@material-ui/icons/Edit';
import PlanningIcon from '@material-ui/icons/Dvr';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Send';

import {
  updateRecordRequest
} from '~/services/fetchApi/constructions';

import Table from '~/components/Table';
import Dialog from '~/components/Dialog';
import Button from '~/components/Button';
import FooterActions from '~/components/FooterActions';
import { QueryParamsProvider } from '~/contexts/QueryParamsContext';
import { accountPlansApi } from '~/routes/api';
import formatToMoney from '~/utils/formatToMoney';
import getSelectedRows from './utils/getSelectedRows';

import { CardContent, TableContainer, ButtonContainer } from './styles';

import Step from '../Step';
import StatusPercent from './components/StatusPercent';
import DialogForm from './components/DialogForm';
import updateSelectedRowsByConstructionAccountPlans from './utils/updateSelectedRowsByConstructionAccountPlans';

let selectedPlanning = null;
let accountPlans = null;
let constructionAccountPlans = null;
let selectedAccountPlannings = [];

const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

function PlanningTab({response, construction: responseConstruction, findConstructionDispatch}) {
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [step, setStep] = useState(STEPS.FIRST);
  const [construction, setConstruction] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const tableRef = useRef(null);

  const isFirstConfig = useMemo(() => {
    return !construction?.constructionAccountPlans?.length
  }, [construction])

  useEffect(() => {
    if (responseConstruction) {
      const customerConstruction =  responseConstruction.customer_construction || {}
      const constructionPlannings = responseConstruction .construction_plannings || {}

      setConstruction(
        {
          ...responseConstruction,
          constructionAccountPlans: response.data?.data?.relationships?.construction_account_plans?.data,
          customerConstruction,
          constructionPlannings,
        }
      )
    }

    return () => {
      selectedPlanning = null;
      accountPlans = null;
      constructionAccountPlans = null;
      selectedAccountPlannings = [];
    }
  }, [responseConstruction])

  useEffect(() => {
    constructionAccountPlans = construction?.constructionAccountPlans;

    if (constructionAccountPlans?.length && accountPlans?.length) {
      setSelectedTableRows();
    }

    if (!isFirstConfig) {
      setStep(STEPS.THIRD);
    }
  }, [construction]);

  const onSubmit = useCallback(
    () => {
      setIsLoading(true)

      const destroy = row => {
        const shouldDestroy = !row.selected && !!row.construction_account_plan_id;
        return shouldDestroy ? { _destroy: true } : {};
      };

      const formattedRows = selectedAccountPlannings.map(row => ({
        id: row.construction_account_plan_id,
        account_plan_id: row.id,
        estimated_value: row.estimated_value || 0,
        extra_value: row.extra_value || 0,
        ...destroy(row),
      }));

      updateRecordRequest(responseConstruction.id,
        {
          data: {attributes: {
            construction_account_plans_attributes: formattedRows
          } }
        },
        {include: 'construction_account_plans'}
      ).then( (resp) => {
        findConstructionDispatch()
        setConstruction({
          ...construction,
          constructionAccountPlans: resp.data?.data?.relationships?.construction_account_plans?.data,
        })
        toast.success(
          'Atualizado com sucesso',
          { position: 'top-left' }
        );
        setIsLoading(false)
        tableRef?.current?.searchRecords()
      }).catch( error => {
        console.error(error)
        toast.error(
          'Oops, não foi possível salvar!',
          { position: 'bottom-center' }
        );
        setIsLoading(false)
      })
    },
    [responseConstruction, setIsLoading, construction, setConstruction]
  )

  const onChange = useCallback(
    (accountPlannings) => {
      selectedAccountPlannings = accountPlannings;
    },
    [],
  )

  const closeDialog = useCallback(
    () => {
      setOpenDialogForm(false);
    },
    []
  );

  const setSelectedTableRows = useCallback(
    () => {
      const { updatedRows, selectedRows } = updateSelectedRowsByConstructionAccountPlans(
        accountPlans,
        constructionAccountPlans
      );

      constructionAccountPlans = null;
      tableRef.current.setRows(updatedRows);
      tableRef.current.setSelectedRows(selectedRows);
      selectedRowsChange(updatedRows);
    },
    []
  );

  const onTableDataChange = useCallback(
    (data) => {
      accountPlans = data;

      if (constructionAccountPlans?.length && accountPlans?.length) {
        setSelectedTableRows();
      }
      setIsLoading(false)
    },
    [setIsLoading]
  );

  const openDialog = useCallback(
    (planning) => {
      selectedPlanning = planning;
      setOpenDialogForm(true);
    },
    [setOpenDialogForm]
  );

  const calculatePercentage = useCallback(
    (rowData, total) => {
      if (!rowData.selected) return rowData;

      const estimatedValue = rowData.estimated_value || 0;
      const extraValue = rowData.extra_value || 0;
      const totalValueRow = estimatedValue + extraValue;

      if (!totalValueRow || !total) {
        return { ...rowData, percentage: 0 };
      }

      rowData.percentage = (totalValueRow * 100) / total;
      rowData.percentage = Number(rowData.percentage.toFixed(2));

      return rowData;
    },
    []
  );

  const sum = useCallback(
    (num1, num2) => (num1 || 0) + (num2 || 0),
    []
  );

  const calculateRowsPercentage = useCallback(updatedRows => {
    const total = updatedRows.reduce(
      (accumulatedTotal, rowData) => {
        if (!rowData.selected) return accumulatedTotal;

        const estimatedValue = rowData.estimated_value || 0;
        const extraValue = rowData.extra_value || 0;
        const totalValueRow = estimatedValue + extraValue;

        return accumulatedTotal + totalValueRow;
      },
      0
    );

    const newRows = updatedRows.map(grandPaRow => {
      const updatedGrandPaRow = calculatePercentage(grandPaRow, total);

      if (updatedGrandPaRow.items) {
        updatedGrandPaRow.items.map(fatherRow => {
          const grandPaTotal = sum(
            updatedGrandPaRow.estimated_value,
            updatedGrandPaRow.extra_value
          );
          const updatedFatherRow = calculatePercentage(
            fatherRow,
            grandPaTotal
          );

          if (updatedFatherRow.items) {
            updatedFatherRow.items.map(sonRow => {
              const fatherTotal = sum(
                updatedFatherRow.estimated_value,
                updatedFatherRow.extra_value
              );
              const updatedSonRow = calculatePercentage(
                sonRow,
                fatherTotal
              );

              return updatedSonRow;
            });
          }

          return updatedFatherRow;
        });
      }

      return updatedGrandPaRow;
    });

    tableRef.current.setRows(newRows);

    const selectedRows = getSelectedRows(newRows);
    onChange(selectedRows);
  }, []);

  const handleSave = useCallback(
    () => {
      setOpenDialogForm(false);
      const updatedRows = tableRef.current.updateRows(selectedPlanning);
      selectedRowsChange(updatedRows);
      selectedPlanning = null;
    },
    [setOpenDialogForm]
  );

  const handleNext = useCallback(
    () => {
      setStep(oldStep => {
        const selectedRows = tableRef.current.selectedRows();

        if (oldStep === STEPS.FIRST && !selectedRows.length) {
          toast.error(
            'Oops, é necessário selecionar no mínimo um plano de contas para continuar',
            { position: 'bottom-center' }
          );

          return oldStep;
        }

        return oldStep + 1;
      });
    },
    []
  );

  const updateValuesUnselectedRows = useCallback(
    (rows) => {
      return rows.map(grandPaRow => {
        if (!grandPaRow.selected) {
          grandPaRow.estimated_value = 0;
          grandPaRow.estimated_value = 0;
          grandPaRow.percentage = 0;
        }

        if (grandPaRow.items) {
          grandPaRow.items = grandPaRow.items.map(fatherRow => {
            if (!fatherRow.selected) {
              fatherRow.estimated_value = 0;
              fatherRow.extra_value = 0;
              fatherRow.percentage = 0;
            }

            if (fatherRow.items) {
              fatherRow.items = fatherRow.items.map(childRow => {
                if (!childRow.selected) {
                  childRow.estimated_value = 0;
                  childRow.extra_value = 0;
                  childRow.percentage = 0;
                }

                return childRow;
              });
            }

            return fatherRow;
          });
        }

        return grandPaRow;
      });
    },
    []
  );

  const sumSelectedItems = useCallback(
    (items, key) => {
      return items.reduce((total, item) => {
        if (!item.selected) return total;

        return sum(total, item[key]);
      }, 0);
    },
    []
  );

  const calculateRowsTotalizer = useCallback(
    (rows) => {
      return rows.map(grandPaRow => {
        if (grandPaRow.items) {
          grandPaRow.items = grandPaRow.items.map(fatherRow => {
            if (fatherRow.items) {
              fatherRow.estimated_value = sumSelectedItems(fatherRow.items, 'estimated_value');
              fatherRow.extra_value = sumSelectedItems(fatherRow.items, 'extra_value');
            }

            return fatherRow;
          });

          grandPaRow.estimated_value = sumSelectedItems(grandPaRow.items, 'estimated_value');
          grandPaRow.extra_value = sumSelectedItems(grandPaRow.items, 'extra_value');
        }

        return grandPaRow;
      });
    },
    []
  );

  const selectedRowsChange = useCallback(
    (rows) => {
      let updatedRows = updateValuesUnselectedRows(rows);
      updatedRows = calculateRowsTotalizer(rows);
      calculateRowsPercentage(updatedRows);
    },
    []
  );

  const handleSubmit = useCallback(
    () => {
      const tableRows = tableRef.current.getRows();
      const selectedRows = getSelectedRows(tableRows);

      onChange(selectedRows);
      onSubmit();
    },
    []
  );

  return (
    <QueryParamsProvider>
      {isFirstConfig && (
        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <Step
              Icon={FirstStepIcon}
              text="Passo 1: Definir Plano de Contas"
              showLeftLine={false}
              completed={true}
              onClick={() => setStep(STEPS.FIRST)}
            />
            <Step
              Icon={SecondStepIcon}
              text="Passo 2: Atribuir valor e status"
              completed={[STEPS.SECOND, STEPS.THIRD].includes(step)}
              onClick={() => {
                if (step === STEPS.THIRD) {
                  setStep(STEPS.SECOND);
                }
              }}
            />
            <Step
              Icon={ThirdStepIcon}
              text="Passo 3: Conferir e salvar"
              showRightLine={false}
              completed={step === STEPS.THIRD}
            />
          </CardContent>
        </Card>
      )}
      <TableContainer>
        <Table
          ref={tableRef}
          route={accountPlansApi.list}
          enableColumnsResizing={false}
          showPaggination={false}
          showSorting={false}
          onTableDataChange={onTableDataChange}
          tableCellColumns={[
            { name: 'name', title: 'Serviço' },
            { name: 'estimated_value', title: 'Valores' },
            { name: 'percentage', title: 'Porcentagem' },
            { name: 'extra_value', title: 'Valores Adicionais' },
            { name: 'custom_actions', title: ' ' },
          ]}
          tableRowColumns={[
            { columnName: 'name' },
            { columnName: 'estimated_value', width: 200 },
            { columnName: 'percentage', width: 200 },
            { columnName: 'extra_value', width: 200 },
            { columnName: 'custom_actions', width: 100 },
          ]}
          tableTree={{
            enable: true,
            showSelectionControls: true,
            onSelectedRowsChange: selectedRowsChange,
            formatTreeColumnData: columnsGrandpa => {
              if (columnsGrandpa.items) {
                columnsGrandpa.items.forEach(columnFather => {
                  columnFather.code = `${columnsGrandpa.code}.${columnFather.code}`;

                  if (columnFather.items) {
                    columnFather.items.forEach(sonColumn => {
                      sonColumn.code = `${columnFather.code}.${sonColumn.code}`;
                    });
                  }
                });
              }

              return columnsGrandpa;
            }
          }}
          columnsOverride={[{
            name: 'name',
            formatterComponent: (_, row) => {
              const name = `${row.code} - ${row.name}`;

              if (row.level === 0) {
                return <strong>{name}</strong>;
              }

              return <div>{name}</div>;
            }
          }, {
            name: 'estimated_value',
            formatterComponent: (_, row) => {
              if ( (step === STEPS.FIRST || !row.selected) ) return <></>;

              return <div>R$ {formatToMoney(row.estimated_value || 0)}</div>;
            }
          }, {
            name: 'percentage',
            formatterComponent: (_, row) => {
              if (step === STEPS.FIRST || !row.selected) return <></>;

              return <StatusPercent percentage={row.percentage || 0} />;
            }
          }, {
            name: 'extra_value',
            formatterComponent: (_, row) => {
              if (step === STEPS.FIRST || !row.selected) return <></>;

              return <div>R$ {formatToMoney(row.extra_value || 0)}</div>;
            }
          }, {
            name: 'custom_actions',
            formatterComponent: (_, row) => {
              if (
                !!row.items ||
                step === STEPS.FIRST ||
                !row.selected
              ) return <></>;

              return (
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => openDialog({ ...row })}
                >
                  <IconEdit color="primary" />
                </IconButton>
              )
            }
          }]}
          defaultPaging={{
            direction: 'asc',
            sort: 'code',
            per_page: 1000000
          }}
        />

        <FooterActions
          loading={isLoading}
          showSaveAndContinue={false}
          showCancelButton={false}
          onSubmit={handleSubmit}
          Component={step !== STEPS.THIRD && (() => (
            <ButtonContainer>
              {step !== STEPS.FIRST && (
                <Button
                  onClick={() => setStep(step - 1)}
                  style={{ backgroundColor: '#4CAF50', marginRight: 20 }}
                >
                  Voltar
                </Button>
              )}
              <Button
                onClick={handleNext}
                style={{ backgroundColor: '#0094FF' }}
              >
                Próximo
              </Button>
            </ButtonContainer>
          ))}
        />
      </TableContainer>

      <Dialog
        icon={<PlanningIcon />}
        isOpen={openDialogForm}
        handleClose={closeDialog}
        title={'Planejamento'}
        color="primary"
        fullWidth
        maxWidth="xs"
        keepMounted
        dialogActions={
          <>
            <Button
              startIcon={<IconCancel />}
              onClick={closeDialog}
              color="red"
              variant="text"
            >
              Cancelar
            </Button>
            <Button
              startIcon={<IconSave />}
              onClick={handleSave}
              color="green"
              variant="text"
            >
              Salvar
            </Button>
          </>
        }
      >
        <DialogForm
          steps={STEPS}
          currentStep={step}
          hasContructionAccountPlans={
            !isFirstConfig
          }
          defaultData={selectedPlanning}
          onChange={(value) => {
            Object.assign(selectedPlanning, value);
          }}
        />
      </Dialog>
    </QueryParamsProvider>
  );
}

export default PlanningTab;
