import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createRecord, findRecord, clearState } from '~/store/modules/metadata';
import MetadatumRecord from '~/records/MetadatumRecord';

import CrudNew from '~/containers/CrudNew';
import { metadataRoute } from '~/routes'
import FormFields from './components/FormFields';

export default function New() {
  const { id } = useParams();
  const [record, setRecord] = useState(new MetadatumRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.metadata);

  useEffect(() => {
    if(id){
      dispatch( findRecord(id) );
    }
    return () => {
      dispatch( clearState() )
    };
  }, [])

  useEffect(() => {
    if(current && id){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback( () => {
    const data = record.toParams();
    dispatch( createRecord(data) );
  }, [record]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const clearAfterSaved = useCallback(() => {
    setRecord( new MetadatumRecord() )
  }, [setRecord]);

  return (
    <CrudNew
      clearAfterSaved={clearAfterSaved}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: metadataRoute.list.path, text: 'Metadados' },
        { text: 'Cadastrando Metadado' },
      ]}
      footerActionsProps={{ isLoading }}
    >
      <FormFields
        record={record}
        onChange={handleChange}
      />
    </CrudNew>
  );
}
