import React, { useCallback } from 'react';

import { contractorsRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import contractorSchema from '~/records/schema/contractorSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/contractors';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch( fetchRecords(params, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Empreiteiros' }]}
      permissionKeyCode="Contractor"
      routes={{
        newPath:  ()  =>  contractorsRoute.new.build(),
        showPath: (id) => contractorsRoute.show.build({id}),
        editPath: (id) => contractorsRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: contractorSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Contractor',
        filterProps: {
          columns: contractorSchema.filterColumns
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.contractors.listIsLoading,
  rows: state.contractors.data,
  total: state.contractors.totalData,
});

export default connect(mapStateToProps)(List);