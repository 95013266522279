import React, { useCallback , useState, useEffect } from 'react';
import moment from 'moment';

import VisibilityIcon from '@material-ui/icons/Visibility';

import Request from '~/services/request';
import isPresent from '~/utils/isPresent';
import formatToMoney from '~/utils/formatToMoney';
import { customerInvoiceApi } from '~/routes/api';
import { useGlobalFilters } from '~/hooks/useGlobalFilters';
import deserialize from '~/utils/deserialize';

import SelectedConstructionButton from '~/components/SelectedConstructionButton';
import SearchConstructionsDialog from '~/components/SearchConstructionsDialog';
import IconButton from '~/components/IconButton';
import CrudLayoutList from '~/containers/CrudLayoutList';

import invoiceSchema from '~/records/schema/invoiceSchema';
import Show from '~/containers/CustomerInvoicesShow';

const ALLOWED_COLUMNS = ['nf_date', 'nf_number', 'supplier', 'total_value']
const filterColumns = (column) => ALLOWED_COLUMNS.includes(column.name || column.columnName)
const COLUMN_WIDTH = {
  nf_date: 130,
  nf_number: 150,
  supplier: 450,
  total_value: 140,
  customActions: 110,
}
const handleWidth = (column) => ({
  ...column,
  width: COLUMN_WIDTH[column.columnName]
})

export default function List() {
  const [openDialog, setOpenDialog] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showID, setShowID] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)

  const { construction, setConstruction } = useGlobalFilters();
  let initialConstruction = null;

  const openShow = useCallback(
    (id) => {
      setShowID(id)
    },
    [setShowID],
  )

  const closeShow = useCallback(
    () => {
      setShowID(null)
    },
    [setShowID],
  );

  const handleFetch = useCallback(
    async (params, requestSource) => {
      setIsLoading(true)
      let path = `${customerInvoiceApi.list.build({construction_id: construction?.id || 0})}`;
      path = `${path}${params ? `${params}&` : '?' }include=construction-expenses-supplier`;

      const resp = await Request.get(path, {
        cancelToken: requestSource.token
      });

      const invoices = await deserialize(resp.data);
      setRows( invoices )
      setTotal( resp.data?.meta?.total || 0 )
      setIsLoading(false)
    },
    [construction?.id],
  )

  useEffect(() => {
    if (!construction?.id) {
      initialConstruction = construction;
      setOpenDialog(true);
    }
  }, []);

  useEffect(() => {
    if(isInitialized && initialConstruction?.id !== construction?.id){
      handleFetch()
    }
    if(!isInitialized){ setIsInitialized(true) }
  }, [construction?.id])

  return (
    <>
      <SearchConstructionsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onSelect={(newConstruction) => {
          setConstruction(newConstruction);
          setOpenDialog(false);
        }}
      />
      <Show
        id={showID}
        constructionID={construction?.id}
        onClose={closeShow}
        isOpen={ isPresent(showID) }
      />
      <CrudLayoutList
        breadcrumbs={[{ text: 'Notas fiscais' }]}
        permissionKeyCode=""
        navProps={{
          rightComponent: <SelectedConstructionButton onClick={() => setOpenDialog(true)} />
        }}
        tableProps={{
          isLoading,
          rows,
          total,
          handleFetch,
          schema: invoiceSchema,
          enableColumnsResizing: false,
          tableCellColumns: [
            ...invoiceSchema.tableCellColumns.filter(filterColumns),
            {name: 'customActions', title: 'Detalhar', width: 110}
          ],
          tableRowColumns:  [
            ...invoiceSchema.tableRowColumns.filter(filterColumns).map(handleWidth),
            {columnName: 'customActions', title: 'Detalhar', width: 110}
          ],
          columnsVisibility: {
            visible: false,
            columnsExtensions: [
              { columnName: 'customActions', togglingEnabled: false },
            ]
          },
          columnsOverride: [
            {
              name: 'customActions',
              formatterComponent: (_, row) => (
                <IconButton color="primary" onClick={ () => openShow(row.id)} >
                  <VisibilityIcon />
                </IconButton>
              )
            },{
              name: 'supplier',
              formatterComponent: (_, row) => (
                <div>{row.supplier?.name}</div>
              )
            }, {
              name: 'nf_date',
              formatterComponent: (_, row) => (
                <div>{moment(row.nf_date).format('DD/MM/YYYY')}</div>
              )
            }, {
              name: 'total_value',
              formatterComponent: (_, row) => {
                const total = row.expenses?.reduce(
                  (initialValue, newValue) => initialValue + newValue.total_value,
                  0
                );

                return <div>R$ {formatToMoney(total)}</div>;
              }
            }
          ],
          fixedColumns: {
            rightColumns: ['customActions']
          },
          filterProps: {
            columns: invoiceSchema.filterColumns.filter(filterColumns)
          },
          defaultPaging: {
            direction: 'desc',
            sort: 'id',
            per_page: 20
          }
        }}
      />
    </>
  );
}