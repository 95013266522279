import RecordBase from './Base';
import formAnswerSchema from './schema/formAnswerSchema';
import PersonRecord from './PersonRecord';
export default class FormAnswerRecord extends RecordBase {
  static defaultAttributes = formAnswerSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return formAnswerSchema.attributeNames }

  static belongsTo = {
    construction: PersonRecord,
    customer: PersonRecord,
    engineer: PersonRecord,
  }

  toParams() {
    const {
      engineering_score_observation,
      engineering_score,
      execution_team_score_observation,
      execution_team_score,
      shopping_team_score_observation,
      shopping_team_score,
    } = this.toObject();

    return {
      data: {
        attributes: {
          engineering_score_observation,
          engineering_score,
          execution_team_score_observation,
          execution_team_score,
          shopping_team_score_observation,
          shopping_team_score,
        }
      }
    };
  }
}
