import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/invoices';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { invoicesRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const INVOICES_SET_CURRENT = '@App/invoices/INVOICES_SET_CURRENT';
export const INVOICES_FETCH_START = '@App/invoices/INVOICES_FETCH_START';
export const INVOICES_FETCH_DONE  = '@App/invoices/INVOICES_FETCH_DONE';
export const INVOICES_FETCH_LIST_START = '@App/invoices/INVOICES_FETCH_LIST_START';
export const INVOICES_FETCH_LIST_DONE = '@App/invoices/INVOICES_FETCH_LIST_DONE';
export const INVOICES_SET_ERRORS = '@App/invoices/INVOICES_SET_ERRORS';
export const CLEAR_STATE = '@App/invoices/CLEAR_STATE';
export const FIND_INVOICE_REQUEST = '@App/invoices/FIND_INVOICE_REQUEST';
export const DESTROY_INVOICES_REQUEST = '@App/invoices/DESTROY_INVOICES_REQUEST';
export const UPDATE_INVOICES_REQUEST = '@App/invoices/UPDATE_INVOICES_REQUEST';
export const CREATE_INVOICES_REQUEST = '@App/invoices/CREATE_INVOICES_REQUEST';
export const FETCH_INVOICES_REQUEST  = '@App/invoices/FETCH_INVOICES_REQUEST';
export const INVOICES_CHANGE_LIST  = '@App/invoices/INVOICES_CHANGE_LIST';
export const INVOICES_SET_NEW_RECORD  = '@App/invoices/INVOICES_SET_NEW_RECORD';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
  newRecord: undefined,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [INVOICES_FETCH_START]: fetchStart,
    [INVOICES_FETCH_DONE]:  fetchDone,
    [INVOICES_FETCH_LIST_START]: fetchListStart,
    [INVOICES_FETCH_LIST_DONE]: fetchListDone,
    [INVOICES_SET_ERRORS]: setErrors,
    [INVOICES_SET_CURRENT]: setCurrent,
    [DESTROY_INVOICES_REQUEST]: initLoading,
    [CREATE_INVOICES_REQUEST]: initLoading,
    [UPDATE_INVOICES_REQUEST]: initLoading,
    [INVOICES_CHANGE_LIST]: setChangeList,
    [INVOICES_SET_NEW_RECORD]: (draft, {payload}) => {
      draft.newRecord = payload.newRecord
    },
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}, callbackSuccess) {
  return {
    type: FIND_INVOICE_REQUEST,
    payload: { id, params, callbackSuccess }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_INVOICES_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_INVOICES_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_INVOICES_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_INVOICES_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: INVOICES_SET_CURRENT,
    payload: { current }
  }
}

export function setNewRecord(newRecord) {
  return {
    type: INVOICES_SET_NEW_RECORD,
    payload: { newRecord }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'invoices',

  routes: {
    list: () => invoicesRoute.list.build(),
    show: (id) => invoicesRoute.show.build({id}),
    new: () => invoicesRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      INVOICES_FETCH_START,
    FETCH_DONE:       INVOICES_FETCH_DONE,
    FETCH_LIST_START: INVOICES_FETCH_LIST_START,
    FETCH_LIST_DONE:  INVOICES_FETCH_LIST_DONE,
    CHANGE_LIST:      INVOICES_CHANGE_LIST,
    CHANGE_CURRENT:   INVOICES_SET_CURRENT,
    SET_ERRORS:       INVOICES_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_INVOICE_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_INVOICES_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_INVOICES_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_INVOICES_REQUEST, crudActions.createAction),
  takeLatest(FETCH_INVOICES_REQUEST, crudActions.fetchListAction),
]);