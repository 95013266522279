import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .percent-container {
    display: flex;
    flex-direction: row;
  }

  .dates {
    position: relative;
    width: 100%;
    padding: 7px 0 16px 0;

    span {
      position: absolute;
      font-size: 0.7rem;
    }

    .start {
      left: 0;
    }

    .end {
      right: 0;
      &.with_extra{
        right: calc(25% - 25px);
      }
    }

    .extra_end {
      right: 0;
      color: #FF0000;
    }
  }
`;

export const PercentText = styled.strong`
  font-size: 10px;
  padding-left: ${props => props.paddingLeft}%;
`;

export const Percent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  border-radius: 2px;
  background-color: #eeeeee;

  .divisor {
    position: absolute;
    left: 75%;
    width: 10px;
    height: 100%;
    background-color: #fff;
  }
`;

export const PercentageCompleted = styled.div`
  width: ${props => props.width}%;
  height: 20px;
  border-radius: 2px;
  background-color: #8C00FA;
`;
