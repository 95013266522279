import React, { useState } from 'react';
import Input from '@material-ui/core/Input';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

import InputControl from '~/components/Input/InputControl';

import { signInRequest } from '~/store/modules/auth';
import { authRoute } from '~/routes'

import {
  Container,
  BackgroundColorAbsolute,
  ContainerLogin,
  Logo,
  Button
} from './styles';

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <BackgroundColorAbsolute />
      <ContainerLogin>
        <form onSubmit={handleSubmit}>
          <Logo />
          <InputControl
            name="email"
            label="E-mail *"
            value={email}
            inputComponent={Input}
            InputProps={{ type: 'email' }}
            onChange={(value) => setEmail(value.email)}
          />

          <InputControl
            name="password"
            label="Senha *"
            value={password}
            type="password"
            inputComponent={Input}
            InputProps={{ type: 'password' }}
            onChange={(value) => setPassword(value.password)}
          />

          <Link to={authRoute.forgotPassword.build()}>
            Esqueceu sua senha?
          </Link>

          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="green"
          >
            Acessar
          </Button>
        </form>
      </ContainerLogin>
    </Container>
  );
}
