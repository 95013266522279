const FILTER_TYPES = [
  'boolean',
  'numeric',
  'integer',
  'float',
  'date',
  'datetime',
  'currency',
  'string',
  'options',
  'object',
  'enum',
  'async'
]

export default FILTER_TYPES;