import React from 'react';
import { Switch } from 'react-router-dom';

import { reportsRoute } from '~/routes';
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';

export default function ExpenseHistoryReportRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        allowCustomer
        path={reportsRoute.expensesHistory.path}
        component={List}
        permissionKeyCode="ExpenseHistoryReport"
        permission="see"
      />
    </Switch>
  );
}
