import React from 'react';
import styled from 'styled-components';

import ArchivePreview from '~/components/ArchivePreview';
import GridContainer from '~/components/GridContainer'

const Container = styled.div`
  margin-top: 20px;
`;

export default function FileListPreview({ files, persistedFiles, onDelete, onEdit }) {
  return (
    <Container>
      <GridContainer>
        {files.map(file => (
          <ArchivePreview
            key={file.idOrToken}
            file={file}
            onDelete={onDelete}
          />
        ))}
        {persistedFiles.map(file => (
          <ArchivePreview
            key={file.idOrToken}
            file={file}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </GridContainer>
    </Container>
  );
}