import SchemaBuilder from './SchemaBuilder';
import invoiceStatusEnum from '~/enums/invoiceStatusEnum';
import invoiceExpenseTypeEnum from '~/enums/invoiceExpenseTypeEnum';
import {
  constructionsAsyncFetch,
  constructionAsyncShow
} from '~/services/selectFetch/constructionsFetch'

const disableList = {hidden: true, tableView: false, isFilter: false}
const hidden = {hidden: true}

const columns = {
  nf_date:       {type: 'date',    default: '',   title: 'Data', width: 150},
  nf_number:     {type: 'string',  default: '',   ...hidden, title: 'Número da NF'},
  status:        {
    type: 'enum',
    default: 'reconciled',
    title: 'Status',
    _enum_: invoiceStatusEnum
  },
  construction:  {
    type: 'async',
    default: null,
    title: 'Obra',
    width: 200,
    filterOptions: {
      asyncFetch: constructionsAsyncFetch,
      asyncShow:  constructionAsyncShow
    }
  },
  expense_type:        {
    type: 'enum',
    default: 'normal',
    title: 'Tipo da despesa',
    _enum_: invoiceExpenseTypeEnum,
    ...hidden
  },
  supplier:      {type: 'object',  default: null, title: 'Fornecedor', width: 150},
  total_value:   {type: 'currency',   default: null, title: 'Valor da NF', width: 150},

  bank_slips:    {type: 'object', default: null, ...disableList, title: 'Boletos'},
  invoice_items: {type: 'object', default: null, ...disableList, title: 'Itens da NF'},
  expenses:      {type: 'object', default: null, ...disableList, title: 'Rateio das despesas'},
  incoming_email_id: {type: 'integer', default: null, ...disableList, title: 'Email Recebido'},

  attachments:      {type: 'object', default: null, ...disableList, title: 'Anexos'},
  created_at:  { type: 'datetime', default: '', ...hidden, title: 'Criado em', width: 200 },
  updated_at:  { type: 'datetime', default: '', ...hidden, title: 'Atualizado em'},

  password: {type: 'string', default: null, ...disableList, title: 'Senha de confirmação'},
};

const invoiceSchema = new SchemaBuilder( columns );

export default invoiceSchema;