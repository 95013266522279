import SchemaBuilder from './SchemaBuilder';

const columns = {
  uuid:         { type: 'string', title: 'UUID' },
  filename:     { type: 'string', title: 'Nome do arquivo' },
  content_type: { type: 'string', title: 'Tipo' },
  file_size:    { type: 'integer', title: 'Tamanho' },
  url:          { type: 'string', title: 'URL' },
  icon:         { type: 'string', title: 'Icone' },
  img:          { type: 'object', title: 'Imagem' },
  description:  { type: 'string', title: 'Descrição' },

  progress:     { type: 'integer', title: 'Progresso Upload', default: 100 },
  file:         { type: 'object', title: 'Blob' },
  isNew:        { type: 'boolean', title: 'Upado agora', default: false },
  fromGallery:  { type: 'boolean', title: 'Da galeria', default: false }, // Utilizado no Dropzone para saber que veio da galeria
};

const archiveSchema = new SchemaBuilder( columns );

export default archiveSchema;