import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import BaseLayout from '~/components/BaseLayout';
import Errors from '~/components/Errors';
import CrudForm from '~/containers/CrudForm';
import { ErrorsContainer } from './styles';
import PropTypes from 'prop-types';

function CrudNew({
  breadcrumbs,
  errors,
  footerActionsProps,
  children,
  location,
  clearAfterSaved,
  ...formProps
}) {
  const [key, setKey] = useState(null);

  useEffect(() => {
    if( key && key !== location.key ){
      clearAfterSaved()
    }
    setKey(location.key)
  }, [location.key])

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <ErrorsContainer>
        <Errors {...errors} />
      </ErrorsContainer>

      <CrudForm {...formProps} footerActionsProps={footerActionsProps}>
        {children}
      </CrudForm>
    </BaseLayout>
  );
}

CrudNew.propTypes = {
  breadcrumbs:        PropTypes.arrayOf(PropTypes.object).isRequired,
  errors:             PropTypes.object,
  footerActionsProps: PropTypes.object,
  clearAfterSaved:    PropTypes.func.isRequired,
}

export default withRouter(CrudNew)