import React, { useState, createContext } from 'react';

const FilterContext = createContext({
  filterOpen: false,
  setFilterOpen() {}
});

export function FilterProvider({ children }) {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <FilterContext.Provider value={{ filterOpen, setFilterOpen }}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContext;
