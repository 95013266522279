import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '~/components/Button';
import useStyles from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

function FooterActions({
  loading,
  showSaveAndContinue,
  showCancelButton,
  cancelRoute,
  cancelText,
  submitText,
  onSubmit,
  history,
  children,
  Component
}) {
  const classes = useStyles();
  const [saveAndContinue, setSaveAndContinue ] = useState(false);

  async function handleSubmit() {
    onSubmit(saveAndContinue);
  }

  function handleCancelRoute() {
    if (cancelRoute) history.push(cancelRoute);

    history.goBack();
  }

  return (
    <div>
      {children}

      <footer className={clsx('no-printable', classes.footerDrawerClosed)}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          {Component && <Component />}

          {!Component && (
            <>
              {showSaveAndContinue && (
                <FormControlLabel
                  className={classes.checkBox}
                  control={(
                    <Checkbox
                      checked={saveAndContinue}
                      onChange={() => setSaveAndContinue(!saveAndContinue)}
                      color="primary"
                    />
                  )}
                  label="Salvar e continuar"
                />
              )}
    
              {showCancelButton && (
                <Button
                  onClick={handleCancelRoute}
                  className={classes.button}
                  color="grey"
                >
                  {cancelText}
                </Button>
              )}
    
              <Button
                disabled={loading}
                onClick={() => handleSubmit()}
                className={classes.button}
                style={{ display: 'flex', minWidth: 85, minHeight: 36 }}
                color="success"
              >
                {loading
                  ? <CircularProgress style={{ color: '#1B2430' }} size={19} />
                  : submitText
                }
              </Button>
            </>
          )}
        </Grid>
      </footer>
    </div>
  );
}

FooterActions.defaultProps = {
  showSaveAndContinue: true,
  submitText: 'Salvar',
  cancelText: 'Cancelar',
  showCancelButton: true,
};

FooterActions.propTypes = {
  cancelRoute: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  showSaveAndContinue: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default withRouter(FooterActions);
