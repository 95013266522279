import Request from '~/services/request';
import queryStringParser from '~/utils/queryStringParser';
import { productsApi } from '~/routes/api';

export default function productsFetch(inputValue, page, doneCallback) {
  return Request.get(`${productsApi.search.build()}?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data
      }
    )
  })
  .catch(console.error);
}

export const productsFetchWithParams = (params) => (inputValue, page, doneCallback) => {
  return Request.get(`${productsApi.search.build()}?${queryStringParser({
    qs: inputValue,
    page,
    ...params
  })}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data
      }
    )
  })
  .catch(err => err);
};