import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

import formatToMoney from '~/utils/formatToMoney';
import InvoiceRecord from '~/records/InvoiceRecord';
import invoiceStatusEnum from '~/enums/invoiceStatusEnum';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import Spinner from '~/components/Spinner';
import InputShow from '~/components/Input/InputShow';
import Divider from '~/components/Divider';
import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import AttachmentsPreview from '~/components/AttachmentsPreview';
import ItemTable   from '~/pages/Invoices/components/ItemTable'
import findInvoice from './utils/findInvoice';

const attrName = (attr) => InvoiceRecord.hummanAttributeName(attr)

export default function Show({id, constructionID, onClose, isOpen}) {
  const [record, setRecord] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const data = await findInvoice(constructionID, id);
      const newRecord = new InvoiceRecord(data);

      setRecord( newRecord )
      setIsLoading( false )
    }

    if(id && constructionID){
      fetchData()
    }
  }, [id]);

  return (
    <>
      <Dialog
        isFullScreen
        isOpen={isOpen}
        handleClose={onClose}
        title={'Visualizando nota fiscal'}
        dialogActions={
          <React.Fragment>
            <Button onClick={onClose} color="red" variant="text">
              Fechar
            </Button>
          </React.Fragment>
        }
      >
        { isLoading && <Spinner loadingText="Carregando dados" /> }
        { !isLoading && record &&
          <>
            <Card>
              <CardHeader title="Dados Básicos" />
              <CardContent>
                <GridContainer>
                  <Col md={4}>
                    <InputShow
                      label={attrName('name')}
                      type="string"
                      value={record.get('construction')?.get('name')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputShow
                      label={attrName('name')}
                      type="string"
                      value={record.get('supplier')?.get('name')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputShow
                      label={attrName('nf_date')}
                      type="date"
                      value={record.get('nf_date')}
                    />
                  </Col>

                  <Col md={4}>
                    <InputShow
                      label={attrName('nf_number')}
                      type="string"
                      value={record.get('nf_number')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputShow
                      label={attrName('status')}
                      type="string"
                      value={invoiceStatusEnum().objects()[record.get('status')]}
                    />
                  </Col>
                  <Col md={4}>
                    <InputShow
                      label={attrName('total_value')}
                      type="float"
                      value={record.get('total_value')}
                    />
                  </Col>
                </GridContainer>

                <Divider/>

                <GridContainer>
                  <Col xs={12}>
                    <Typography variant="h6">Rateio de planos de conta</Typography>
                  </Col>
                  <Col xs={12}>
                    <TableContainer>
                      <Table aria-label="spanning table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Plano de conta</TableCell>
                            <TableCell>Observações</TableCell>
                            <TableCell align="right">Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {record.get('expenses').valueSeq().toArray().map((row) => (
                            <TableRow key={row.idOrToken}>
                              <TableCell>{row.get('account_plan')?.get('name')}</TableCell>
                              <TableCell>{row.get('notes')}</TableCell>
                              <TableCell align="right">R$ {formatToMoney( row.get('total_value') )}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </GridContainer>

                <Divider/>

                <GridContainer>
                  <Col xs={12}>
                    <Typography variant="h6">Boletos</Typography>
                  </Col>
                  <Col xs={12}>
                    <TableContainer>
                      <Table aria-label="spanning table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Vencimento</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell align="right">Arquivo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {record.get('bank_slips').valueSeq().toArray().map((row) => (
                            <TableRow key={row.idOrToken}>
                              <TableCell>{row.get('due_date')}</TableCell>
                              <TableCell>R$ {formatToMoney(row.get('slip_value'))}</TableCell>
                              <TableCell align="right">
                                {row.get('attachment')?.url && (
                                  <IconButton component='a' href={row.get('attachment')?.url} target='_blank'>
                                    <GetAppIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </GridContainer>
              </CardContent>
            </Card>
            { record.get('invoice_items').size > 0 && <ItemTable items={record.get('invoice_items')} /> }

            <AttachmentsPreview
              title="Outros Anexos"
              attachments={record.get('attachments')}
            />
          </>
        }
      </Dialog>
    </>
  );
}
