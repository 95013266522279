import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MUiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Request from '~/services/request';
import formatToMoney from '~/utils/formatToMoney';
import truncate from '~/utils/truncate';

import Spinner from '~/components/Spinner';
import Tooltip from '~/components/Tooltip';

const TableCell = withStyles({
  root: {
    fontSize: '0.7rem',
  }
})(MUiTableCell);

function LastInvoicesData({ constructionID }) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const resp = await Request.get(`/api/v1/customers/dashboards/${constructionID}/last_invoices`)
      setData(resp.data.data)
      setIsLoading(false)
    }
    fetchData()

  }, [constructionID])

  return (
    <Paper style={{padding: 16}}>
      <Typography variant="h6">Últimos lançamentos</Typography>
      { isLoading &&  <Spinner /> }
      { !isLoading && data.length > 0 &&
        <TableContainer>
          <Table size="small" aria-label="Últimos lançamentos">
            <TableHead>
              <TableRow>
                <TableCell component="th">Nota Fiscal</TableCell>
                <TableCell component="th"  align="right">Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { data.map( invoice => (
                <TableRow key={invoice.id}>
                  <TableCell component="th" scope="row">
                    {invoice.nf_number} -
                    <Tooltip title={invoice.supplier?.name}>
                      <span> {truncate(invoice.supplier?.name, 26)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">{`R$ ${formatToMoney(invoice.total_value)}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Paper>
  );
}

export default LastInvoicesData;
