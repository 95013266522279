import RecordBase from './Base';
import roleSchema from './schema/roleSchema';

export default class RoleRecord extends RecordBase {
  static defaultAttributes = roleSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return roleSchema.attributeNames }

  toParams(extraParams = {}) {
    const { name, } = this.toObject();
    const users = this.get('users') || [];
    const rolePermissions = this.get('role_permissions') || [];

    const role_permissions_attributes = rolePermissions
      .filter( role => role.see || role.id)
      .map( role => ({
        id: role.id,
        permission_id: role.permission_id,
        add: role.add,
        edit: role.edit,
        del: role.del,
        _destroy: role._destroy,
      }) )


    return {
      data:{
        attributes:{
          name,
          user_ids: users.map( u => u.id),
          role_permissions_attributes,
          ...extraParams,
        }
      }
    };
  }
}
