import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FieldsIcon from '@material-ui/icons/FeaturedPlayList';
import InfoIcon from '@material-ui/icons/AccountBalance';

import { findRecord, destroyRecord, clearState } from '~/store/modules/metadata';
import { metadataRoute } from '~/routes'
import isPresent from '~/utils/isPresent';
import MetadatumRecord from '~/records/MetadatumRecord';
import metadatumCompetenceEnum from '~/enums/metadatumCompetenceEnum';

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import MetaFields from '~/containers/MetaFields';
import TabContainer from '~/components/TabContainer';
import TabHeader from '~/components/TabHeader';
import Tab from '~/components/Tab';
import Button from '~/components/Button';

const TABS = {
  INFO: 'info',
  FIELDS: 'fields'
}
const attrName = (attr) => MetadatumRecord.hummanAttributeName(attr);

function Show({ metadatum, isLoading }) {
  const [selectedTab, setSelectedTab] = useState(TABS.INFO);
  const dispatch = useDispatch();
  const { id } = useParams();
  const metadatumPresent = useMemo(() => isPresent(metadatum), [metadatum])

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: metadataRoute.list.path, text: 'Metadados' },
        { text: 'Exibindo Metadado' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   metadataRoute.new.build(),
        routeCloneRegister: metadataRoute.clone.build({ id }),
        routeEditRegister:  metadataRoute.edit.build({ id }),
        permissionKeyCode:  'Metadatum'
      }}
    >
      <TabContainer>
        <TabHeader>
          <Tab isSelected={selectedTab === TABS.INFO}>
            <Button
              startIcon={<InfoIcon />}
              variant="text"
              onClick={() => setSelectedTab(TABS.INFO)}
            >
              Informações
            </Button>
          </Tab>
          <Tab isSelected={selectedTab === TABS.FIELDS}>
            <Button
              startIcon={<FieldsIcon />}
              variant="text"
              onClick={() => setSelectedTab(TABS.FIELDS)}
            >
              Campos customizados
            </Button>
          </Tab>
        </TabHeader>
        { metadatumPresent && selectedTab === TABS.INFO &&
          <Card>
            <CardContent>
              <GridContainer>
                <Col xs={12} sm={4}>
                  <InputShow
                    label={attrName('name')}
                    value={metadatum.name}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <InputShow
                    label={attrName('competence')}
                    value={ metadatumCompetenceEnum().objects()[metadatum.competence]}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <InputShow
                    label={attrName('sys_key')}
                    value={metadatum.sys_key}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('record_plural_name')}
                    value={metadatum.record_plural_name}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('record_singular_name')}
                    value={metadatum.record_singular_name}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('record_item_plural_name')}
                    value={metadatum.record_item_plural_name}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('record_item_singular_name')}
                    value={metadatum.record_item_singular_name}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('menu')}
                    value={metadatum.menu}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('define_fields_in_record')}
                    value={metadatum.define_fields_in_record}
                    type='boolean'
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('active')}
                    value={metadatum.active}
                    type='boolean'
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('icon_lib_record')}
                    value={metadatum.icon_lib_record}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('icon_lib_record_item')}
                    value={metadatum.icon_lib_record_item}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('created_at')}
                    value={metadatum.created_at}
                    type='datetime'
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <InputShow
                    label={attrName('updated_at')}
                    value={metadatum.updated_at}
                    type='datetime'
                  />
                </Col>
              </GridContainer>
            </CardContent>
          </Card>
        }
        { metadatumPresent && selectedTab === TABS.FIELDS &&
          <Card>
            <CardContent>
              <GridContainer>
                <Col xs={12}>
                  { metadatum.id && <MetaFields referenceType='Metadatum' referenceID={metadatum.id} /> }
                </Col>
              </GridContainer>
            </CardContent>
          </Card>
        }
      </TabContainer>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.metadata.isLoading,
  metadatum: state.metadata.current,
});

export default connect(mapStateToProps)(Show);