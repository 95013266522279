import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import InputControl from '~/components/Input/InputControl';
import InputCpfCnpj from '~/components/Input/InputCpfCnpj';
import InputDate from '~/components/Input/InputDate';
import InputPhone from '~/components/Input/InputPhone';
import InputNumber from '~/components/Input/InputNumber';
import InputTextEditor from '~/components/Input/InputTextEditor';
import InputCheckBox   from '~/components/Input/InputCheckBox';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import Address from '~/components/Address';
import Button from '~/components/Button';

import PersonRecord from '~/records/PersonRecord';
import UserRecord from '~/records/UserRecord';
import ContactsForm from '~/containers/ContactsForm';

import InputDropzoneFile from '~/containers/InputDropzoneFile';

const attrName = (attr) => PersonRecord.hummanAttributeName(attr);
const attrUser = (attr) => UserRecord.hummanAttributeName(attr);
// TODO: Aplicar campos extras

export default function FormFields({ record, onChange}) {
  const onChangeFiles = useCallback(
    (attachments) => {
      onChange( {attachments} )
    },
    [record, onChange],
  )

  const handleChangeAddress = useCallback(attrs => {
    const address = record.updateAddress(attrs);
    onChange(address);
  }, [record, onChange]);

  const handleChangePersonType = useCallback(
    (event) => {
      onChange({ is_legal: event.target.value === 'juridical' });
    },
    [onChange]
  );

  const handleChangeUser = useCallback(
    (changes) => {
      onChange( record.updateUser(changes) )
    },
    [onChange, record]
  );

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col xs={12} sm={6}>
            <InputControl
              autoFocus
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <InputControl
              required
              name="email"
              label={attrName('email')}
              value={record.get('email')}
              type="email"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              value={record.get('is_legal') ? 'juridical' : 'physical'}
              onChange={handleChangePersonType}
              label="Tipo"
            >
              <FormControlLabel
                value="physical"
                control={<Radio color="primary" />}
                label="Pessoa Física"
              />
              <FormControlLabel
                value="juridical"
                control={<Radio color="primary" />}
                label="Pessoa Jurídica"
              />
            </RadioGroup>
          </Col>
          { !!record.get('is_legal') &&
            <Col md={6}>
              <InputControl
                name="legal_name"
                label={attrName('legal_name')}
                value={record.get('legal_name')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
          }
        </GridContainer>

        <GridContainer>
          <Col xs={12} sm={4}>
            <InputControl
              required
              name="cpf_cnpj"
              label={record.get('is_legal') ? 'CNPJ' : 'CPF'}
              value={record.get('cpf_cnpj')}
              inputComponent={InputCpfCnpj}
              onChange={onChange}
              InputProps={{
                force: record.get('is_legal') ? 'cnpj' : 'cpf'
              }}
            />
          </Col>

          { !record.get('is_legal') &&
            <Col sm={6} md={4}>
              <InputControl
                name="rg"
                label={attrName('rg')}
                value={record.get('rg')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
          }

          <Col md={4}>
            <InputControl
              name="comercial_phone"
              label={attrName('comercial_phone')}
              value={record.get('comercial_phone')}
              type="number"
              inputComponent={InputPhone}
              onChange={onChange}
            />
          </Col>
          <Col md={4}>
            <InputControl
              name="residential_phone"
              label={attrName('residential_phone')}
              value={record.get('residential_phone')}
              type="number"
              inputComponent={InputPhone}
              onChange={onChange}
            />
          </Col>
          <Col md={4}>
            <InputControl
              required
              name="cell_phone"
              label={attrName('cell_phone')}
              value={record.get('cell_phone')}
              type="number"
              inputComponent={InputPhone}
              onChange={onChange}
            />
          </Col>
          <Col md={4}>
            <InputControl
              name="birth_date"
              label={record.get('is_legal') ? 'Data da fundação' : 'Data de nascimento'}
              value={record.get('birth_date')}
              type="date"
              inputComponent={InputDate}
              onChange={onChange}
            />
          </Col>

          { !!record.get('is_legal') && (
            <Col md={4}>
              <InputControl
                value={!!record.get('is_simple')}
                name={'is_simple'}
                label={attrName('is_simple')}
                inputComponent={InputCheckBox}
                onChange={onChange}
              />
            </Col>
          )}
        </GridContainer>

        { !!record.get('is_legal') && (
          <GridContainer>
            <Col sm={6} md={3}>
              <InputControl
                name="state_registration"
                label={attrName('state_registration')}
                value={record.get('state_registration')}
                inputComponent={Input}
                InputProps={{ type: 'number' }}
                onChange={onChange}
              />
            </Col>
            <Col sm={6} md={3}>
              <InputControl
                name="municipal_registration"
                label={attrName('municipal_registration')}
                value={record.get('municipal_registration')}
                inputComponent={Input}
                InputProps={{ type: 'number' }}
                onChange={onChange}
              />
            </Col>
            <Col sm={6} md={3}>
              <InputControl
                value={!!record.get('icms_exempt')}
                name={'icms_exempt'}
                label={attrName('icms_exempt')}
                inputComponent={InputCheckBox}
                onChange={onChange}
              />
            </Col>
            <Col sm={6} md={3}>
              <InputControl
                value={record.get('share_capital')}
                name={'share_capital'}
                label={attrName('share_capital')}
                inputComponent={ InputNumber }
                onChange={onChange}
              />
            </Col>
          </GridContainer>
        )}

        { (!record.get('user') || !!record.get('user')?.get('disabled_at'))  && (
          <Button
            variant="contained"
            onClick={() => handleChangeUser({ disabled_at: null })}
          >
            Adicionar Usuário
          </Button>
        )}

        { !!record.get('user') && !record.get('user').get('disabled_at') && (
          <Card>
            <CardContent style={{ width: '100%' }}>
              <GridContainer>
                <Col>
                  <Box display="flex" justifyContent="space-between" spacing={2}>
                    <Typography variant="h6">Usuário</Typography>
                    <IconButton onClick={() => handleChangeUser({ disabled_at: new Date() })}>
                      <DeleteIcon style={{ color: 'red', fontSize: 24 }} />
                    </IconButton>
                  </Box>
                </Col>
              </GridContainer>
              <GridContainer>
                <Col sm={6}>
                  <InputControl
                    name="name"
                    label={attrUser('name')}
                    value={record.get('user')?.name}
                    type="text"
                    inputComponent={Input}
                    onChange={handleChangeUser}
                  />
                </Col>
                <Col sm={6}>
                  <InputControl
                    name="email"
                    label={attrUser('email')}
                    value={record.get('user')?.email}
                    type="text"
                    inputComponent={Input}
                    onChange={handleChangeUser}
                  />
                </Col>
                <Col sm={6}>
                  <InputControl
                    autoComplete="off"
                    name="password"
                    label={attrUser('password')}
                    value={record.get('user')?.password}
                    inputComponent={Input}
                    InputProps={{
                      type: 'password'
                    }}
                    onChange={handleChangeUser}
                  />
                </Col>
                <Col sm={6}>
                  <InputControl
                    autoComplete="off"
                    name="password_confirmation"
                    label={attrUser('password_confirmation')}
                    value={record.get('user')?.password_confirmation}
                    inputComponent={Input}
                    InputProps={{
                      type: 'password'
                    }}
                    onChange={handleChangeUser}
                  />
                </Col>
              </GridContainer>
            </CardContent>
          </Card>
        )}

        <br/>
        <Typography variant="h6">Contatos</Typography>
        <ContactsForm onChange={onChange} contacts={record.get('contacts')} />

        <br/>
        <Typography variant="h6">Endereço</Typography>
        <Address
          record={record}
          onChangeAddress={handleChangeAddress}
          requiredFields={{
            district: true,
            city: true,
          }}
          errors={{}}
        />

        <br/>
        <InputControl
          name="observation"
          label={attrName('observation')}
          value={record.get('observation')}
          type="text"
          inputComponent={InputTextEditor}
          onChange={onChange}
        />

        <InputDropzoneFile
          title="Anexos"
          key={record.get('id') || 0}
          // onDelete={onDeleteFile}
          onChange={onChangeFiles}
          persistedFiles={ record.get('attachments') }
        />
      </CardContent>
    </Card>
  );
}