import React from 'react';
import { Switch } from 'react-router-dom';

import { customerInvoicesRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';

export default function ContractorsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        isPrivate
        path={customerInvoicesRoute.self.path}
        component={List}
        allowCustomer
      />
    </Switch>
  );
}