import React, {useRef, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import InputDropzoneFile from '~/containers/InputDropzoneFile';

import { OrderedMap } from "immutable";
import ArchiveRecord from '~/records/ArchiveRecord';

const INITIAL_FILES = [
  {
    "id": 14,
    "uuid": "00149e02-72a0-416e-b859-38d1687d4c6a",
    "filename": "Novas-funcionalidades-do-ANYMARKET.png",
    "content_type": "image/png",
    "file_size": 139303,
    "url": "https://material-ui.com/static/images/cards/live-from-space.jpg",
    "created_at": "2020-12-11T00:40:38.695-03:00",
    "icon": "https://material-ui.com/static/images/cards/live-from-space.jpg",
    "img": {
      "thumb": "https://material-ui.com/static/images/cards/live-from-space.jpg",
      "medium": "https://material-ui.com/static/images/cards/live-from-space.jpg"
    },
    "description": null
  }
]

export default function Examples() {
  const inputRef = useRef(null);
  const [archives, setArchives] = useState( OrderedMap( INITIAL_FILES.map( f => [f.id, new ArchiveRecord(f) ] ) ) )

  // const onDelete = (archive) => {
  //   setArchives( archives.filter( a => a.id !== archive.id) )
  // }

  console.log('ARCHIVES = ', archives)

  return (
    <Card variant="outlined">
      <CardContent>
        <InputDropzoneFile
          ref={inputRef}
          // onDelete={onDelete}
          onChange={ (files) => { setArchives(files) } }
          persistedFiles={archives}
        />
      </CardContent>
    </Card>
  );
}
