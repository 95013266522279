import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/npsFormAnswers';
import { npsFormAnswersRoute } from '~/routes'
import NpsFormAnswerRecord from '~/records/NpsFormAnswerRecord';

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';

const attrName = (attr) => NpsFormAnswerRecord.hummanAttributeName(attr);

function Show({ npsFormAnswer, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch( findRecord(id, {include: 'construction-customer-engineer'}) );

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: npsFormAnswersRoute.list.path, text: 'Formulários NPS' },
        { text: 'Exibindo formulário' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        permissionKeyCode:  'NpsFormAnswer'
      }}
    >
     <Card>
        <CardContent>
          <GridContainer>
            <Col sm={4}>
              <InputShow
                label={attrName('construction')}
                type="string"
                value={npsFormAnswer}
                digObject={['construction', 'name']}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('customer')}
                type="string"
                value={npsFormAnswer}
                digObject={['customer', 'name']}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('engineer')}
                type="string"
                value={npsFormAnswer}
                digObject={['engineer', 'name']}
              />
            </Col>
          </GridContainer>

          <GridContainer>
            <Col sm={3}>
              <InputShow
                label={attrName('engineering_score')}
                type="integer"
                value={npsFormAnswer?.engineering_score}
              />
            </Col>
            <Col sm={3}>
              <InputShow
                label={attrName('execution_team_score')}
                type="integer"
                value={npsFormAnswer?.execution_team_score}
              />
            </Col>
            <Col sm={3}>
              <InputShow
                label={attrName('shopping_team_score')}
                type="integer"
                value={npsFormAnswer?.shopping_team_score}
              />
            </Col>
            <Col sm={3}>
              <InputShow
                label={attrName('answered_at')}
                type="date"
                value={npsFormAnswer?.answered_at}
              />
            </Col>
          </GridContainer>

          <GridContainer>
            <Col sm={4}>
              <InputShow
                label={attrName('engineering_score_observation')}
                type="string"
                value={npsFormAnswer?.engineering_score_observation}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('execution_team_score_observation')}
                type="string"
                value={npsFormAnswer?.execution_team_score_observation}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('shopping_team_score_observation')}
                type="string"
                value={npsFormAnswer?.shopping_team_score_observation}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>

    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.npsFormAnswers.isLoading,
  npsFormAnswer: state.npsFormAnswers.current,
});

export default connect(mapStateToProps)(Show);