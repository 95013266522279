import RecordBase from './Base';
import invoiceSchema from './schema/invoiceSchema';
import PersonRecord from './PersonRecord';
import InvoiceItemRecord from './InvoiceItemRecord';
import BankSlipRecord from './BankSlipRecord';
import ExpenseRecord from './ExpenseRecord';
import ArchiveRecord from '~/records/ArchiveRecord';
import ConstructionRecord from '~/records/ConstructionRecord';

import round from '~/utils/Number/round';

function sumExpenses(collection) {
  return round( collection.reduce((tot, rec) => round(rec?.get('total_value'), 2) + tot, 0), 2 )
}

export default class InvoiceRecord extends RecordBase {
  static defaultAttributes = invoiceSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return invoiceSchema.attributeNames }

  static belongsTo = {
    construction:   ConstructionRecord,
    supplier:   PersonRecord
  };

  static hasMany = {
    invoice_items: InvoiceItemRecord,
    bank_slips:    BankSlipRecord,
    expenses:      ExpenseRecord,
    attachments:   ArchiveRecord,
  };

  get totalExpenses(){
    return this.get('expenses').update(sumExpenses)
  }

  addExpense(attrs){
    const expenseValue = round(this.get('total_value'), 2) - this.totalExpenses;

    const newExpense = new ExpenseRecord({
      total_value: (expenseValue > 0 ? expenseValue : 0),
      ...attrs
    });
    return this.set('expenses', this.get('expenses').set(
      newExpense.get('_token'),
      newExpense
    ) )
  }

  addBankSlip(attrs){
    const newBankSlip = new BankSlipRecord({
      slip_value: 0,
      ...attrs
    });
    return this.set('bank_slips', this.get('bank_slips').set(
      newBankSlip.get('_token'),
      newBankSlip
    ) )
  }

  toParams(extraParams = {}) {
    return {
      data:{
        attributes:{
          password:   this.get('password') || undefined,
          nf_date:   this.get('nf_date'),
          nf_number: this.get('nf_number'),
          status:    this.get('status'),
          expense_type:    this.get('expense_type'),
          total_value:    this.get('total_value'),
          incoming_email_id: this.get('incoming_email_id') || null,
          construction_id: this.get('construction')?.get('id') || null,
          supplier_id:     this.get('supplier')?.get('id') || null,
          invoice_items_attributes: this.get('invoice_items').valueSeq().toArray().map(e => e.nestedParams() ),
          bank_slips_attributes: this.get('bank_slips').valueSeq().toArray().map(e => e.nestedParams() ),
          expenses_attributes: this.get('expenses').valueSeq().toArray().map(e => e.nestedParams() ),
          attachments_ids:     ArchiveRecord.getNewFiles( this.get('attachments') ),
          del_attachments_ids: ArchiveRecord.getDeleteFiles( this.get('attachments') ),
          ...extraParams,
        }
      }
    };
  }
}
