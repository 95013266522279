import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import FormFields from './components/FormFields';
import CrudNew from '~/containers/CrudNew';

import { createRecord, findRecord, clearState } from '~/store/modules/customRecords';
import { customRecordsRoute } from '~/routes'

import CustomRecordRecord from '~/records/CustomRecordRecord';

export default function New({sys_key, metaSettings}) {
  const { id } = useParams();
  const [record, setRecord] = useState(new CustomRecordRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.customRecords);

  useEffect(() => {
    if(id){
      dispatch( findRecord(id, {sys_key}) );
    }
    return () => {
      dispatch( clearState() )
    };
  }, [])

  useEffect(() => {
    if(current && id){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback(() => {
    const data = record.toParams();
    dispatch( createRecord(data, {sys_key}) );
  }, [record]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const clearAfterSaved = useCallback(() => {
    setRecord( new CustomRecordRecord() )
  }, [setRecord]);

  return (
    <CrudNew
      clearAfterSaved={clearAfterSaved}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: customRecordsRoute.list.build({sys_key}), text: metaSettings.get('record_plural_name') },
        { text: `Cadastrando ${metaSettings.get('record_singular_name')}` },
      ]}
      footerActionsProps={{ isLoading }}
    >
      <FormFields
        sys_key={sys_key}
        metaSettings={metaSettings}
        record={record}
        onChange={handleChange}
      />
    </CrudNew>
  )
}