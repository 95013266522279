import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const filterLabel = {
  'filter-label-AND': 'E',
  'filter-label-OR': 'OU'
}

function Divider({ condition, small }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, small && classes.rootSmall)}>
      {!small && <div className={classes.line} />}

      <span className={clsx(classes.condition, classes[condition])}>
        {filterLabel[`filter-label-${condition}`]}
      </span>
    </div>
  );
}

Divider.defaultProps = {
  small: false,
};

Divider.propTypes = {
  small: PropTypes.bool.isRequired,
  condition: PropTypes.oneOf(['AND', 'OR']),
};

export default React.memo(Divider);
