// Para testar com este exemplo:
//
// import ExampleSelect from 'components/Input/InputSelectAsync/Example';
import React, { Component } from 'react';

import InputControl from '~/components/Input/InputControl';
import InputSelectAsync     from '~/components/Input/InputSelectAsync';
import InputPhone   from '~/components/Input/InputPhone';
import InputCpfCnpj   from '~/components/Input/InputCpfCnpj';
import Input        from '@material-ui/core/Input';

import Button from '~/components/Button';
import Dialog from '~/components/Dialog';
import PeopleIcon from '@material-ui/icons/People';

// material-ui
import Grid from '@material-ui/core/Grid';

import Paper from '~/components/Paper';

import Request from '~/services/request';
import isBlank from '~/utils/isBlank';

class FormPersonMmodal extends Component {

  state = {
    wasOpen: false,
    name:     '',
    phone:    '',
    cpf_cnpj: '',
    email:    '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { wasOpen } = prevState;
    const { formOptions, isOpen } = nextProps;
    const { inputValue } = formOptions;

    const nextState = {};

    if( !wasOpen && isOpen ){
      nextState.name = inputValue;
      nextState.wasOpen = isOpen;
    }

    if( isBlank(nextState) ){
      return null;
    }else{
      return nextState;
    }
  }

  handleChange = (attr) => {
    this.setState(attr);
  }

  handleSave = () => {
    const { name, phone, cpf_cnpj, email } = this.state;
    const { formOptions } = this.props;
    const { childRef } = formOptions;
    new Promise((resolve) => {
      Request.post('/api/v1/people', {
        data: {
          attributes: { name, phone, cpf_cnpj, email }
        }
      }).then( resp => {
        childRef.handleChange(
          childRef.handleOption( {
            id: resp.data.data.id,
            name: resp.data.data.attributes.name
          } )
        );
        resolve();
      })
    }).then(this.props.handleClose);
  };

  render(){
    const { name, phone, cpf_cnpj, email } = this.state;
    const { isOpen, handleClose } = this.props;
    return(
      <Dialog
        icon={<PeopleIcon />}
        isOpen={isOpen}
        handleClose={handleClose}
        title={'Incluíndo pessoa'}
        dialogActions={
          <React.Fragment>
            <Button onClick={handleClose} color="red" variant="text">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="green" variant="text">
              Salvar
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputControl
              required
              value={name}
              label={'Nome'}
              inputComponent={Input}
              name={'name'}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputControl
              required
              value={cpf_cnpj}
              label={'CPF/CNPJ'}
              // inputComponent={Input}
              inputComponent={InputCpfCnpj}
              name={'cpf_cnpj'}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputControl
              required
              value={email}
              label={'E-mail'}
              inputComponent={Input}
              name={'email'}
              onChange={this.handleChange}
              inputProps={{
                type: 'email'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputControl
              required
              value={phone}
              label={'Telefone'}
              inputComponent={InputPhone}
              name={'phone'}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

class SelectExample extends Component {
  state = {
    people1: {},
    inputSilge: null,
    isOpenForm: false,
    formOptions: {},
  }

  handleChangeKey = ( valKey) => {
    this.setState(valKey);
  }

  handleChangeEvent = (attr) => ( valKey, event ) => {
    console.log('attr: ', attr);
    console.log('valKey: ', valKey);
    console.log('event: ', event);
    this.setState({[attr]: event.target.value});
  };

  paramsToCreate = (inputValue) => {
    return {
      person: {
        name: inputValue
      }
    };
  };

  onCreateCallback = (inputValue, childRef, resolve) => {
    this.setState({
      isOpenForm: true,
      formOptions: {inputValue, childRef, resolve}
    });
  };

  handleClose = () => {
    this.setState({isOpenForm: false});
    this.state.formOptions.resolve();
  };

  fetchPerson = (input, page, resolve) => {
    Request.get(`/api/v1/people?sort=name&direction=asc&qs=${input}&page=${page}`).then(resp => {
      resolve(
        {
          total_count: resp.data.meta.total,
          items: resp.data.data.map( e => ({
            id: e.id,
            name: e.attributes.name
          }))
        }
      )
    })
    .catch(err => err);
  }

  render(){
    const { isOpenForm, formOptions } = this.state;
    return(
      <Paper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormPersonMmodal
              isOpen={isOpenForm}
              formOptions={formOptions}
              handleClose={this.handleClose}
            />

            <InputControl
              id={'ID-CUSTOMIZADO'}
              value={this.state.people1}
              name={'val_input'}
              stateName={'people1'}
              label={'Input Async'}
              inputComponent={InputSelectAsync}
              onChange={this.handleChangeKey}
              InputProps={{
                onCreateCallback: this.onCreateCallback,
                handleFetch: this.fetchPerson,
                // isMulti: true
              }}
            />
          </Grid>
          { /* <Grid item xs={12} sm={4}> */}
          { /*  <InputControl */}
          { /*    value={this.state.inputSilge} */}
          { /*    name={'val_input'} */}
          { /*    stateName={'inputSilge'} */}
          { /*    label={'Input inputSilge'} */}
          { /*    inputComponent={InputSelectAsync} */}
          { /*    onChange={this.handleChangeKey} */}
          { /*    InputProps={{ */}
          { /*      labelKey: 'text', */}
          { /*      isClearable: false, */}
          { /*      createURL:  '/people', */}
          { /*      url: '/load_people?view=full', */}
          { /*      handleCreateParams: this.paramsToCreate */}
          { /*      // options: suggestions, */}
          { /*      // isMulti: true */}
          { /*    }} */}
          { /*  /> */}
          { /* </Grid> */}
          {/* <Grid item xs={4}> */}
          {/*   <InputControl */}
          {/*     id={'ID-CUSTOMIZADO'} */}
          {/*     value={this.state.people1} */}
          {/*     name={'val_input'} */}
          {/*     stateName={'people1'} */}
          {/*     label={'Input Multi no Clearable'} */}
          {/*     inputComponent={InputSelectAsync} */}
          {/*     onChange={this.handleChangeKey} */}
          {/*     InputProps={{ */}
          {/*       options: suggestions, */}
          {/*       isMulti: true, */}
          {/*       isClearable: false, */}
          {/*     }} */}
          {/*   /> */}
          {/* </Grid> */}
        </Grid>
        {/* <Grid container spacing={16}> */}
        {/*   <Grid item xs={4}> */}
        {/*     <InputControl */}
        {/*       required */}
        {/*       value={this.state.inputSilge} */}
        {/*       label={'Input Simples'} */}
        {/*       // inputComponent={Input} */}
        {/*       inputComponent={InputSelectAsync} */}
        {/*       name={'inputSilge'} */}
        {/*       onChange={this.handleChangeEvent('inputSilge')} */}
        {/*       InputProps={{ */}
        {/*         options: suggestions, */}
        {/*       }} */}
        {/*     /> */}
        {/*   </Grid> */}
        {/*   <Grid item xs={4}> */}
        {/*     <InputControl */}
        {/*       required */}
        {/*       value={this.state.inputSilge} */}
        {/*       label={'Input Simples NOT clearable'} */}
        {/*       // inputComponent={Input} */}
        {/*       inputComponent={InputSelectAsync} */}
        {/*       name={'inputSilge'} */}
        {/*       onChange={this.handleChangeEvent('inputSilge')} */}
        {/*       InputProps={{ */}
        {/*         options: suggestions, */}
        {/*         isClearable: false */}
        {/*       }} */}
        {/*     /> */}
        {/*   </Grid> */}
        {/* </Grid> */}
      </Paper>
    );
  }
}

export default SelectExample;
