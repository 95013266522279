import styled from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';

export const Container = styled(Backdrop)`
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
