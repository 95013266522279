import styled from 'styled-components';

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4F3A8B;
  width: 250px;
  height: 35px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 15px;
  color: #fff;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
  }
`;
