import SchemaBuilder from './SchemaBuilder';

const columns = {
  total_value:  {type: 'float',    title: 'Valor'},
  notes:        {type: 'text',     default: '', title: 'Observações'},
	account_plan: {type: 'object',   title: 'Plano de conta'},
	created_at:  { type: 'datetime', default: '', title: 'Criado em', width: 200 },
  updated_at:  { type: 'datetime', default: '', title: 'Atualizado em'},
};

const expenseSchema = new SchemaBuilder( columns );

export default expenseSchema;