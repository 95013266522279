import React from 'react';

import RouteWrapper from './RouteWrapper';
import Dashboard from '~/pages/Dashboard';
import { dashboardRoute } from '~/routes'

export default function DefaultOriginRouteComponent(){
  return <RouteWrapper
    isPrivate
    redirectIfNotExact
    path={dashboardRoute.self.path}
    component={Dashboard}
    permissionKeyCode="Dashboard"
    permission="see"
  />
}