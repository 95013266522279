import React, { useMemo } from 'react';
import Input from '@material-ui/core/Input';

import CreatableSelect from 'react-select/creatable';

import InputControl    from '~/components/Input/InputControl';
import InputCheckBox   from '~/components/Input/InputCheckBox';
import InputDate       from '~/components/Input/InputDate';
import InputSelect     from '~/components/Input/InputSelect';
import InputNumber     from '~/components/Input/InputNumber';
import InputTextEditor from '~/components/Input/InputTextEditor';
import InputRegex      from '~/components/Input/InputRegex';
import InputSelectAsync     from '~/components/Input/InputSelectAsync';
import InputSlider     from '~/components/Input/InputSlider';

import getInputPropsByAsyncInput from './getInputPropsByAsyncInput';

// import isPresent from '~/utils/isPresent';
import toInt     from '~/utils/Number/toInt';
import round     from '~/utils/Number/round';

const INPUT_COMPONENTS = {
  float:           InputNumber,
  integer:         InputNumber,
  string:          Input,
  text:            Input,
  boolean:         InputCheckBox,
  date:            InputDate,
  datetime:        InputDate,
  time:            InputRegex,
  multiple_choice: InputSelect,
  unique_choice:   InputSelect,
  range:           InputSlider,
  record:          InputSelectAsync,
}

const getInputComponent = (field) => {
  if(field.kind === 'text' && field.apply_editor ){
    return InputTextEditor
  }
  return INPUT_COMPONENTS[field.kind]
}

const getInputProps = (metaField) => {
  const { field } = metaField;
  let props = {inputProps: {}};

  switch (field.kind) {
    case 'multiple_choice':
    case 'unique_choice':
      props.options = field.options;
      props.isMulti = (field.kind === 'multiple_choice')
      if(field.allow_other){
        props.selectComponent = CreatableSelect;
        props.formatCreateLabel = (inputValue) => `Outro: ${inputValue}`;
      }
      break;
    case 'integer':
      props.precision = 0
      props.config = {allowDecimal: false}
      if(field.min_value){ props.config.minValue = toInt(field.min_value) }
      if(field.max_value){ props.config.maxValue = toInt(field.max_value) }
      break;
    case 'float':
      props.precision = toInt(field.size_value || 2)
      props.config = {}
      if(field.min_value){ props.config.minValue = round(field.min_value) }
      if(field.max_value){ props.config.maxValue = round(field.max_value) }
      break;
    case 'datetime':
      props.type = 'datetime';
      break;
    case 'time':
      props.mask = [/\d/, /\d/, ':', /[0-5]/, /\d/];
      props.inputProps = {inputMode: 'numeric'}
      break;
    case 'text':
      if( !field.apply_editor ){
        props.multiline = true;
        props.rows = 3;
      }
      break;
    case 'range':
      if(field.min_value){ props.min = round(field.min_value, 0) }
      if(field.max_value){ props.max = round(field.max_value, 0) }
      break;
    case 'record':
      props = getInputPropsByAsyncInput( metaField );
      break;
    default:
      props
      break;
  }
  return props;

}

// const getInputControlProps = (metaField) => {
//   const { field } = metaField;
//   let props = {};
//   return props;
// }

function CustomInput({ onChange, value, fieldKey, metaField }) {
  const { field } = metaField;

  const inputProps = useMemo(() => getInputProps(metaField), [metaField])
  const InputComponent = useMemo(() => getInputComponent(field), [field])

  return (
    <InputControl
      required={ metaField.required }
      name={ fieldKey }
      label={ field.name }
      value={ value }
      inputComponent={InputComponent}
      onChange={ onChange }
      InputProps={ inputProps }
      // { ...getInputControlProps(metaField) }
    />
  );
}

export default CustomInput;
