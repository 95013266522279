import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { Item } from './styles';

export default function Totalizers() {
  const { data } = useSelector(state => state.dashboard);

  return (
    <Grid container spacing={2} style={{ flexGrow: 1, marginTop: 10 }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Item>
              <h3>Obras</h3>
              <Doughnut
                data={{
                  labels: [
                    ' Não Inciadas',
                    ' Em Andamento',
                    ' Finalizadas'
                  ],
                  datasets: [{
                    data: [
                      data.constuctions_per_status?.not_started || 0,
                      data.constuctions_per_status?.in_progress || 0,
                      data.constuctions_per_status?.finished || 0,
                    ],
                    backgroundColor: [
                      '#FF0000',
                      '#00FFA3',
                      '#FE8900'
                    ],
                    hoverBackgroundColor: [
                      '#FF0000',
                      '#00FFA3',
                      '#FE8900'
                    ]
                  }]
                }}
                height={80}
                options={{ legend: { position: 'bottom' } }}
              />
            </Item>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <Item>
              <h3>Boletos</h3>
              <Doughnut
                data={{
                  labels: [' Pagos', ' Não Pagos'],
                  datasets: [{
                    data: [
                      data.bank_slips_per_status?.payed || 0,
                      data.bank_slips_per_status?.not_payed || 0,
                    ],
                    backgroundColor: ['#FA00E1', '#4200FF'],
                    hoverBackgroundColor: ['#FA00E1', '#4200FF']
                  }]
                }}
                height={80}
                options={{ legend: { position: 'bottom' } }}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

