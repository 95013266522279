const GRANDPA_LEVEL = 0;
const FATHER_LEVEL = 1;
const SON_LEVEL = 2;

function hasParentInSameLevel(fatherIndex, childIndex, rows, selectedRows) {
  let indexCounter = fatherIndex + 1;
  let hasParent = false;

  for (const rowFather of rows) {
    if (
      indexCounter !== childIndex &&
      selectedRows.includes(indexCounter)
    ) {
      hasParent = true;
      break;
    }

    indexCounter++;

    if (rowFather.items) {
      rowFather.items.forEach(() => {
        indexCounter++;
      })
    }
  }

  return hasParent;
}

export default function findRowsByIndex(rowIndex, rows, selectedRows) {
  if (isNaN(rowIndex)) return [];

  let indexCounter = 0;
  let grandPaIndex = null;
  let fatherIndex = null;
  let found = false;
  let wantedRows = [rowIndex];

  const addNode = (index) => {
    if (!wantedRows.includes(index)) {
      wantedRows.push(index);
    }
  }

  for (const rowGrandPa of rows) {
    let level = null;

    if (
      (indexCounter === rowIndex && !rowGrandPa?.items) || found
    ) break;

    if (indexCounter === rowIndex) {
      found = true;
      level = GRANDPA_LEVEL;
    }

    grandPaIndex = indexCounter;
    indexCounter++;

    if (rowGrandPa?.items) {  
      rowGrandPa.items.forEach(rowFather => {
        if (indexCounter === rowIndex) {
          found = true;
          level = FATHER_LEVEL;

          const hasParent = hasParentInSameLevel(
            grandPaIndex,
            indexCounter,
            rowGrandPa.items,
            selectedRows
          );
          !hasParent && addNode(grandPaIndex);
        }

        if (Number.isInteger(level) && level === GRANDPA_LEVEL) {
          addNode(indexCounter);
        }

        fatherIndex = indexCounter;
        indexCounter++;

        if (rowFather?.items) {
          rowFather.items.forEach(() => {
            if (indexCounter === rowIndex) {
              found = true;
              level = SON_LEVEL;

              const hasParent = hasParentInSameLevel(
                fatherIndex,
                indexCounter,
                rowFather.items,
                selectedRows
              );

              if (!hasParent) {
                addNode(fatherIndex);

                const hasFatherParent = hasParentInSameLevel(
                  grandPaIndex,
                  fatherIndex,
                  rowGrandPa.items,
                  selectedRows
                );
                !hasFatherParent && addNode(grandPaIndex);
              }
            }

            if (Number.isInteger(level) && level !== SON_LEVEL) {
              addNode(indexCounter);
            }

            indexCounter++;
          });
        }
      });
    }
  }

  return wantedRows;
}
