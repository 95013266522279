import moment from 'moment';

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
const shortMonths = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

function getMonth(date) {
  const dateYear = new Date(date).getFullYear();
  const dateMonth = new Date(date).getMonth();
  const currentYear = new Date().getFullYear();

  if (dateYear === currentYear) {
    return months[dateMonth];
  }

  return `${shortMonths[dateMonth]}/${dateYear}`;
}

function getLabels(startDate, endDate) {
  let diffMonths = moment(endDate).diff(moment(startDate), 'months');

  if (diffMonths <= 0) return getMonth(startDate);

  const labels = [];
  const startDateDay = moment(startDate).date();
  const endDateDay = moment(endDate).date();

  if (endDateDay < startDateDay) {
    diffMonths++;
  }

  for (let month = 0; month <= diffMonths; month++) {
    const formattedLabel = getMonth(moment(startDate).add(month, 'months'));
    labels.push(formattedLabel);
  }

  return labels;
}

function getTotalByMonth(date, data) {
  let total = 0;
  const referenceMonth = moment(date).month();
  const referenceYear = moment(date).year();

  data.forEach((expense) => {
    const expenseMonth = moment(expense.invoice.nf_date).month();
    const expenseYear = moment(expense.invoice.nf_date).year();

    if (referenceMonth === expenseMonth && referenceYear === expenseYear) {
      total += expense.total_value;
    }
  });

  const formattedTotal = total.toFixed(2);

  return parseFloat(formattedTotal);
}

function getData(startDate, endDate, data) {
  if (!data) {
    return [];
  }

  let diffMonths = moment(endDate).diff(moment(startDate), 'months');

  if (diffMonths <= 0) {
    return [getTotalByMonth(startDate, data)];
  }

  const newData = [];
  const startDateDay = moment(startDate).date();
  const endDateDay = moment(endDate).date();

  if (endDateDay < startDateDay) {
    diffMonths++;
  }

  for (let month = 0; month <= diffMonths; month++) {
    const formattedTotal = getTotalByMonth(
      moment(startDate).add(month, 'months'),
      data,
    );
    newData.push(formattedTotal);
  }

  return newData;
}

export default function formatExpenseHistory(startDate, endDate, data) {
  return {
    labels: getLabels(startDate, endDate),
    data: getData(startDate, endDate, data),
  };
}
