import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { shade } from 'polished';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Request from '~/services/request';
import formatToMoney from '~/utils/formatToMoney';
import { reportsRoute } from '~/routes'

import Spinner from '~/components/Spinner';
import Button from '~/components/Button';

const formatData = (data) => ({
	labels: data?.map(d => d.name) || [],
	datasets: [{
		data: data?.map(d => d.total) || [],
		backgroundColor: [
      '#00FFFF',
      '#FF0000',
      '#FA00E1',
      '#FFF500',
      '#05FF00',
      '#4200FF',
      '#4F3A8B',
      '#1B2430',
		],
		hoverBackgroundColor: [
      shade(0.4, '#00FFFF'),
      shade(0.4, '#FF0000'),
      shade(0.4, '#FA00E1'),
      shade(0.4, '#FFF500'),
      shade(0.4, '#05FF00'),
      shade(0.4, '#4200FF'),
      shade(0.4, '#4F3A8B'),
      shade(0.4, '#1B2430'),
		]
	}]
});

const options = {
  tooltips: {
    callbacks: {
      label: function (item, data) {
        const idx = item.index;
        return `${data.labels[idx]}: R$ ${formatToMoney(data.datasets[0].data[idx])}`;
      },
    },
  },
}

export default function TotalAmountSpentOnConstructionChart({ constructionID }) {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const resp = await Request.get(`/api/v1/customers/dashboards/${constructionID}/construction_totals`)
      if( resp.data?.data?.length ){
        setChartData( formatData(resp.data.data) )
      }
      setIsLoading(false)
    }
    setIsLoading(true)
    fetchData()
  }, [constructionID])

  return (
    <Paper style={{padding: 16, height: '100%'}}>
      <Typography variant="h6">Análise das despesas</Typography>

      { isLoading &&  <Spinner /> }
      { !isLoading && !chartData && (
        <div className="no-item">
          <Typography variant="caption">Ops, nenhuma informação encontrada</Typography>
        </div>
      )}

      { !isLoading && !!chartData && (
        <Pie data={chartData} legend={{ position: 'bottom' }} options={options}/>
      )}
      <Box m={2} p={2} display="flex" justifyContent="center">
        <Button component={Link} to={reportsRoute.accountPlansTree.build()}> Detalhar </Button>
      </Box>
    </Paper>
  );
}

