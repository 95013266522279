import React, { Fragment, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import IconVisibility from '@material-ui/icons/Visibility';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';

import Permission from '~/components/Permission';

import IconButton from '~/components/IconButton';

function Actions({
  actions,
  extraActions,
  showPath,
  editPath,
  onEdit,
  onDelete,
  permissionKeyCode
}) {
  const editProps = useMemo(() => {
    let props = {to: editPath ? editPath : '#'}
    if( onEdit ){
      props.onClick = onEdit
    }
    return props;
  }, [editPath, onEdit])
  return (
    <Fragment>
      {actions.includes('show') && showPath && (
        <Link to={showPath}>
          <IconButton>
            <Tooltip title="Visualizar">
              <IconVisibility htmlColor="#35285B" />
            </Tooltip>
          </IconButton>
        </Link>
      )}

      {actions.includes('edit') && (editPath || onEdit) && (
        <Permission keyCode={permissionKeyCode} permission="edit">
          <Link {...editProps} >
            <IconButton>
              <Tooltip title="Editar">
                <IconEdit htmlColor="#FF9800" />
              </Tooltip>
            </IconButton>
          </Link>
        </Permission>
      )}

      {actions.includes('delete') && onDelete && (
        <Permission keyCode={permissionKeyCode} permission="del">
          <IconButton onClick={onDelete}>
            <Tooltip title="Excluir">
              <IconDelete htmlColor="#FF5252" />
            </Tooltip>
          </IconButton>
        </Permission>
      )}

      {extraActions && extraActions()}
    </Fragment>
  );
}

export default memo(Actions);
