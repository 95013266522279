import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';

import { editFilter } from '../../../../store/actions';

function InputNumeric(props) {
  const dispatch = useDispatch();
  const {
    column,
    filter,
    indexGroup,
    indexFilter,
    inputProps,
    ...ownProps
  } = props;

  const precision = column.precision || props.precision;

  if (filter.get('ope') === 'between') {
    const [start = '', end = ''] = `${filter.get('val')}`.split('|');

    return (
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={6}>
          <InputControl
            value={start}
            name={'start'}
            inputComponent={InputNumber}
            InputProps={{
              placeholder: 'De',
              precision,
              showMask: false,
              ...inputProps,
            }}
            onChange={({ start }) => dispatch(editFilter(indexGroup, indexFilter, 'val', `${start}|${end}`))}
            {...ownProps}
            autoComplete={false}
          />
        </Grid>
        <Grid item xs={6}>
          <InputControl
            value={end}
            name={'end'}
            inputComponent={InputNumber}
            InputProps={{
              placeholder: 'Até',
              precision,
              showMask: false,
              ...inputProps,
            }}
            onChange={({ end }) => dispatch(editFilter(indexGroup, indexFilter, 'val', `${start}|${end}`))}
            {...ownProps}
            autoComplete={false}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <InputControl
      value={filter.get('val')}
      name={'val'}
      inputComponent={InputNumber}
      InputProps={{
        placeholder: 'Valor',
        precision,
        showMask: false,
        ...inputProps,
      }}
      onChange={({ val }) => dispatch(editFilter(indexGroup, indexFilter, 'val', val))}
      {...ownProps}
      autoComplete={false}
    />
  );
}

export default React.memo(InputNumeric);
