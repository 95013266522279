import React, { useCallback } from 'react';

import { peopleRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import personSchema from '~/records/schema/personSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/people';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch( fetchRecords(params, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Clientes' }]}
      permissionKeyCode="Person"
      routes={{
        newPath:  ()  =>  peopleRoute.new.build(),
        showPath: (id) => peopleRoute.show.build({id}),
        editPath: (id) => peopleRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: personSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Person',
        filterProps: {
          columns: personSchema.filterColumns
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.people.listIsLoading,
  rows: state.people.data,
  total: state.people.totalData,
});

export default connect(mapStateToProps)(List);