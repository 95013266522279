import EnumBase from './Base';

export default function metaFieldSizeEnum(){
  return new EnumBase([
    {id: 3,  name: '25%'},
    {id: 4,  name: '33%'},
    {id: 6,  name: '50%'},
    {id: 9,  name: '75%'},
    {id: 12, name: '100%'}
  ]);
}
