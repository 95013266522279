/* Examples
  isObject( '   ' )      => false
  isObject( null )       => false
  isObject( undefined )  => false
  isObject( {} )         => true
  isObject( [] )         => false
  isObject( [''] )       => false
  isObject( 0 )          => false
  isObject( {k: 1} )     => true
  isObject( '0' )        => false
  isObject( ['1'] )      => false
  isObject( new Date )   => false
*/

export default function isObject( check ) {
  return( typeof check === 'object' && String(check) === '[object Object]' && !Array.isArray(check) );
}
