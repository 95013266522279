import RecordBase from './Base';
import bankSlipSchema from './schema/bankSlipSchema';
import ArchiveRecord from '~/records/ArchiveRecord';

export default class BankSlipRecord extends RecordBase {
  static defaultAttributes = bankSlipSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() { return bankSlipSchema.attributeNames }

  static belongsTo = {
    attachment: ArchiveRecord
  }

  nestedParams() {
    return {
      id:          this.get('id') || undefined,
      due_date:    this.get('due_date'),
      slip_value:  this.get('slip_value'),
      attachment_id:  this.get('attachment')?.get('id') || null,
      _destroy:    this.get('_destroy') || undefined
    };
  }
}