import React, { useState, useCallback } from 'react';
import Request from '~/services/request';
import isPresent from '~/utils/isPresent';
import formatResponseError from '~/utils/formatResponseError';

import FieldRecord from '~/records/FieldRecord';
import InputControl from '~/components/Input/InputControl';
import InputSelectAsync     from '~/components/Input/InputSelectAsync';
import InputIcon from '@material-ui/icons/Input';
import CancelIcon       from '@material-ui/icons/Cancel';
import SaveIcon         from '@material-ui/icons/Send';
import { fieldsAsyncFetch } from '~/services/selectFetch/fieldsFetch'

import Dialog       from '~/components/Dialog';
import Button       from '~/components/Button';
import Errors       from '~/components/Errors';
import FormFieldsOfField from '~/components/FormFieldsOfField';

import deserialize from '~/utils/deserialize';

import { fieldsApi } from '~/routes/api';

let childRef = null;
let selectResolve = null;

const  InputSelectField = ({currentFieldIds, record, isPersisted, attrName, handleUpdate}) => {
  const [newField, setNewField]   = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors]       = useState({});

  const handleChange = useCallback(attrs => {
    setNewField(newField.updateAttributes(attrs));
  }, [newField, setNewField]);

  const closeDialog = useCallback(
    () => {
      setNewField(null)
      setIsLoading(false)
      setErrors({})
      typeof selectResolve === 'function' ? selectResolve() : ''
      selectResolve = null;
      childRef = null;
    },
    [setNewField, setIsLoading, setErrors]
  );

  const handleNewField = useCallback(
    (inputValue, _childRef, _resolve) => {
      setNewField( new FieldRecord({name: inputValue}) )
      childRef = _childRef
      selectResolve = _resolve;

  }, [setNewField] )

  const handleSave = useCallback(() => {
    const submit = async (newField) => {
      setIsLoading(true)
      const data = newField.toParams();
      try {
        const response = await Request.post( fieldsApi.list.build(), data);
        const field = await deserialize( response.data )

        if(childRef){
          childRef.handleChange(
            childRef.handleOption( field )
          );
        }
        handleUpdate({ field })
        closeDialog()
      } catch(err) {
        setErrors( formatResponseError(err.errors) )
        setIsLoading(false)
      }
    }
    submit(newField)
  }, [newField]);


  return (
    <>
      <Dialog
        icon={<InputIcon />}
        isOpen={ isPresent(newField) }
        handleClose={closeDialog}
        title={ 'Incluíndo campo' }
        color="primary"
        fullWidth
        maxWidth="sm"
        keepMounted
        dialogActions={
          <>
            <Button
              startIcon={<CancelIcon />}
              onClick={closeDialog}
              color="red"
              variant="text"
            >
              Cancelar
            </Button>
            <Button
              startIcon={<SaveIcon />}
              disabled={isLoading}
              onClick={handleSave}
              color="green"
              variant="text"
            >
              Salvar
            </Button>
          </>
        }
      >
        <Errors errors={errors} />
        { newField && <FormFieldsOfField record={newField} handleChange={handleChange} /> }
      </Dialog>

      <InputControl
        required
        disabled={isPersisted}
        name="field"
        label={attrName('field')}
        value={record.get('field')}
        inputComponent={InputSelectAsync}
        onChange={handleUpdate}
        InputProps={{
          handleFetch: fieldsAsyncFetch(currentFieldIds),
          onCreateCallback:  handleNewField
        }}
      />
    </>
  )
}

export default InputSelectField;