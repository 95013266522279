import React, { useState, memo } from 'react';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import formatToMoney from '~/utils/formatToMoney';

const ExpenseProviderFormatter = ({ row, column }) => {
  switch (column.name) {
    case 'invoice':
      return <Typography>{row.invoice.nf_number}</Typography>
    case 'supplier':
      return <Typography>{row.invoice.supplier.name}</Typography>
    case 'nf_date':
      return <Typography>{moment(row.invoice.nf_date).format('DD/MM/YYYY')}</Typography>
    case 'nf_value':
      return <Typography>{`R$ ${formatToMoney( row.invoice.total_value )}`}</Typography>
    case 'total_value':
      return <Typography>{`R$ ${formatToMoney( row.total_value )}`}</Typography>
  }
}
const ColumnExpenseProvider = (props) => (
  <DataTypeProvider
    formatterComponent={ExpenseProviderFormatter}
    {...props}
  />
)

const RowExpenseDetail = ({ row }) => {
  const [columns] = useState([
    { name: 'invoice',  title: 'NF' },
    { name: 'supplier', title: 'Fornecedor' },
    { name: 'nf_date',  title: 'Data' },
    { name: 'nf_value', title: 'Total da NF' },
    { name: 'total_value', title: 'Valor do rateio' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'invoice',     width: 100},
    { columnName: 'supplier'},
    { columnName: 'nf_date',     width: 100, align: 'center'},
    { columnName: 'nf_value',    width: 180, align: 'right'},
    { columnName: 'total_value', width: 180, align: 'right'},
  ]);

  return (
    <Paper>
      <Grid
        rows={row.expenses}
        columns={columns}
      >
        <ColumnExpenseProvider for={columns.map( e => e.name )}/>
        <Table columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
      </Grid>
    </Paper>
  )
};

export default memo(RowExpenseDetail);