import { OrderedMap } from "immutable";

export default function parseToImmutable(data, Model) {
  if (!Array.isArray(data)) {
    return OrderedMap();
  }

  return OrderedMap(data.map(attrs =>
    [attrs[Model.primaryKey], new Model(attrs)]
  ))
}