/* eslint no-useless-escape: "off" */
import isBlank from './isBlank';

const CPF_REGEX = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
const CNPJ_REGEX = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
const ONLY_NUMBERS_REGEX = /\D/g;

export default function formatCpfCnpj(value) {
  if (isBlank(value)) return '';

  const cpfCnpj = value.replace(ONLY_NUMBERS_REGEX, '');
  
  const isCpf = cpfCnpj.length === 11;
  if (isCpf) {
    return cpfCnpj.replace(CPF_REGEX, "\$1.\$2.\$3-\$4");
  } 
  
  return cpfCnpj.replace(CNPJ_REGEX, "\$1.\$2.\$3/\$4-\$5");
}
