import React from 'react';
import MetaFieldRecord from '~/records/MetaFieldRecord';

import Input from '@material-ui/core/Input';
import InputControl from '~/components/Input/InputControl';
import InputCheckBox   from '~/components/Input/InputCheckBox';
import InputNumber     from '~/components/Input/InputNumber';

const attrName = (name) => MetaFieldRecord.hummanAttributeName(name);

const SuggestionInput = ({record, handleUpdate}) => {

  const handleUpdateCheckbox = ({suggestion}) => {
    handleUpdate({suggestion: suggestion ? '1' : '0'})
  }

  if ( ['date', 'datetime', 'hour', 'boolean'].includes( record.get('field')?.kind ) ){
    return (
      <InputControl
        name="suggestion"
        label={attrName('suggestion')}
        value={String(record.get('suggestion')) === '1'}
        inputComponent={InputCheckBox}
        onChange={handleUpdateCheckbox}
      />
    )
  }

  if ( ['string', 'text'].includes( record.get('field')?.kind ) ){
    return (
      <InputControl
        name="suggestion"
        label={attrName('suggestion')}
        value={record.get('suggestion')}
        inputComponent={Input}
        onChange={handleUpdate}
      />
    )
  }

  if ( ['float', 'integer'].includes( record.get('field')?.kind ) ){
    return (
      <InputControl
        name="suggestion"
        label={attrName('suggestion')}
        value={record.get('suggestion')}
        inputComponent={InputNumber}
        onChange={handleUpdate}
        InputProps={{
          precision: (record.get('field').kind === 'float' ? 2 : 0)
        }}
      />
    )
  }

  return null
}

export default SuggestionInput;