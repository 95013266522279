import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import StatusPercent from './StatusPercent';

import { Container } from './styles';

function Timeline({ construction }) {

  if(!construction.start_date || !construction.end_date){
    return <></>
  }

  return (
    <Paper style={{padding: 16}}>
      <Typography variant="h6">Linha do tempo</Typography>
      <Container>
        <StatusPercent
          startDate={construction.start_date}
          endDate={construction.end_date}
          extraEndDate={construction.extra_end_date}
        />
      </Container>
    </Paper>
  );
}

export default Timeline;
