import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import formatNumber from '~/utils/Number/formatNumber';

const NumberFormatter = ({ value, column }) => {
  if( typeof value === undefined || value === null ){ return ''}
    const { type, precision } = column;

  const fraction = type === 'integer' ? 0 : (precision || 2)

  switch (column.type) {
    case 'currency':
      return `R$ ${formatNumber(value, {precision: fraction})}`
    default:
      return formatNumber(value, {precision: fraction})
  }
}
const NumberTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NumberFormatter}
    {...props}
  />
);

export default NumberTypeProvider;