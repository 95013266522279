import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutList from '~/components/LayoutList';
import DialogForm from './Form/DialogForm';

import { accountPlansApi } from '~/routes/api';
import { accountPlansRoute } from '~/routes';
import {
  createRecord,
  updateRecord,
  findRecord,
  clearState
} from '~/store/modules/accountPlans';

import isPresent from '~/utils/isPresent';
import isBlank from '~/utils/isBlank';
import toInt from '~/utils/Number/toInt';
import AccountPlanRecord from '~/records/AccountPlanRecord';
import deserialize from '~/utils/deserialize';

const generateKey = () => Math.random().toString(36).substring(10);

function formatColumn(column, allData){
  const items = allData.filter(
    (acPlan) => toInt(acPlan.parent_id) === toInt(column.id)
  ).map( e => formatColumn(e, allData) )

  return {
    ...column,
    name: `${column.full_code} - ${column.name}`,
    items: items.length > 0 ? items : null
  }
}

async function formatResponseColumns(response, tableTree, setRows, setParams){
  if (response.data) {
    const { meta } = response.data;
    let allData = await deserialize(response.data);

    const columnsData = allData.filter(
      (acPlan) => isBlank(acPlan.parent_id)
    ).map( e => formatColumn(e, allData)  )

    setRows(columnsData);
    setParams({ total: meta.total });
  }
}

export default function List({ location, history }) {
  const [record, setRecord] = useState(new AccountPlanRecord());
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [listKey, setListKey] = useState(generateKey());
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, current } = useSelector(state => state.accountPlans);
  const tableRef = useRef()

  useEffect(() => {
    if (id) {
      dispatch( findRecord(id, {include: 'parent'}, (current) => {
        setRecord( new AccountPlanRecord(current) )
      } ) );
    } else if (!id && isPresent(data)) {
      dispatch(clearState());
    }
  }, [id, setRecord]);

  useEffect(
    () => {
      const isRouteList = location.pathname === accountPlansRoute.list.path
      const isRouteNew = location.pathname === accountPlansRoute.new.path;
      const isRouteEdit = !id
        ? false
        : location.pathname === accountPlansRoute.edit.build({ id });

      if (!openDialogForm && (isRouteNew || isRouteEdit)) {
        setOpenDialogForm(true);
      } else if (openDialogForm && isRouteList) {
        setOpenDialogForm(false);
        setListKey(generateKey());
      }
    },
    [location.pathname, current]
  );

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const closeDialog = useCallback(
    () => {
      history.push(accountPlansRoute.list.path);
      setOpenDialogForm(false);
      handleChange(AccountPlanRecord.defaultAttributes);
      dispatch(clearState());
    },
    [history]
  );

  const handleSave = useCallback( (saveAndContinue) => {
    const data = record.toParams();

    if (id) {
      dispatch(updateRecord(id, data, {include: 'parent'}, () => {
        setRecord( new AccountPlanRecord() )
        history.push(accountPlansRoute.list.path);
      }));
    } else {
      dispatch( createRecord(data, {include: 'parent'}, () => {
        setRecord( new AccountPlanRecord() )
        if(!saveAndContinue){
          history.push(accountPlansRoute.list.path);
        }
      }) );
    }
  }, [record]);

  const isRouteNew = location.pathname === accountPlansRoute.new.path;
  return (
    <LayoutList
      key={listKey}
      breadcrumbs={[{ text: 'Plano de Contas' }]}
      navProps={{
        routeNewRegister: accountPlansRoute.new.path,
        permissionKeyCode: 'RegisterPlan'
      }}
      tableProps={{
        ref: tableRef,
        route: accountPlansApi.list,
        actions: ['edit', 'delete'],
        permissionKeyCode: 'RegisterPlan',
        enableColumnsResizing: false,
        tableTree: {
          enable: true
        },
        tableCellColumns: [
          { name: 'name', title: 'Serviço' },
          { name: 'actions', title: 'Ações' },
        ],
        tableRowColumns: [
          { columnName: 'name', width: 740 },
          { columnName: 'actions', width: 120, align: 'center' },
        ],
        fixedColumns: {
          rightColumns: ['actions']
        },
        formatResponseColumns: formatResponseColumns,
        showPaggination: false,
        showSorting: false,
        defaultPaging: {
          direction: 'asc',
          sort: 'code',
          per_page: 1000000
        }
      }}
    >
      <DialogForm
        record={record}
        onChange={handleChange}
        isRouteNew={isRouteNew}
        isOpen={openDialogForm}
        onSave={handleSave}
        closeDialog={closeDialog}
      />
    </LayoutList>
  );
}
