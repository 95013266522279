import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InputControl from '~/components/Input/InputControl';
import InputSelectAsync   from '~/components/Input/InputSelectAsync';
import isObject from '~/utils/isObject';
import { editFilter } from '../../../../store/actions';


const getInitialValue = (value) => {
  if( isObject(value) && value.id && value.name ){
    return value;
  } else if( value ){
    return { id: value, name: 'Carregando...' };
  }
  return null;
};

function InputAsync(props) {
  const dispatch = useDispatch();
  const {
    filter,
    indexGroup,
    indexFilter,
    inputProps,
    asyncFetch,
    asyncShow,
    ...ownProps
  } = props;

  const [value, setValue] = useState( getInitialValue(filter.get('val')) );

  const handleUpdate = ({value}) => {
    dispatch(editFilter(indexGroup, indexFilter, 'val', value));
  };

  useEffect(() => {
    if(value){
      asyncShow(value.id).then(setValue);
    }
  }, []);

  useEffect(() => {
    setValue( getInitialValue(filter.get('val')) );
  }, [filter.get('val')]);

  return (
    <InputControl
      value={value}
      name={'value'}
      inputComponent={InputSelectAsync}
      InputProps={{
        placeholder: 'Selecione...',
        isClearable: false,
        handleFetch: asyncFetch,
        createEnabled: false,
        error: false,
        ...inputProps,
      }}
      onChange={handleUpdate}
      {...ownProps}
      autoComplete={false}
    />
  );
}

export default React.memo(InputAsync);
