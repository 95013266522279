import React, { useState, useRef, memo, forwardRef } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import CloneIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { toast } from 'react-toastify';

import ConfirmationDialog from '../ConfirmationDialog';
import Button from '../Button';
import FullScreenLoading from '../FullScreenLoading';
import PermissionComponent from '../Permission';

import Request from '../../services/request';
import history from '../../services/history';
import { usePermissions } from '../../hooks/usePermissions';

import {
  Container,
  Link as LinkComponent,
  IconButton,
  OptionsButton,
  ListItemIcon,
  Menu,
  MenuItem
} from './styles';

const Link = forwardRef((props, ref) => (
  <LinkComponent {...props} innerRef={ref} />
));

const Permission = forwardRef((props, ref) => (
  <PermissionComponent {...props} innerRef={ref} />
));

function CrudActions({
  routeListRegister,
  routeEditRegister,
  routeNewRegister,
  routeCloneRegister,
  routeDeleteRegister,
  onDelete: onDeleteProp,
  leftActionsComponent,
  extraOptionsComponent,
  permissionKeyCode,
}) {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const confirmationDialogRef = useRef(null);
  const { can } = usePermissions();

  function handleOpenMenuOptions(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenuOptions() {
    setAnchorEl(null);
  }

  async function onDelete() {
    if (onDeleteProp) return onDeleteProp();

    try {
      setLoading(true);
      await Request.del(routeDeleteRegister);
      if (!routeListRegister){
        history.goBack();
      } else {
        history.push(routeListRegister);
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao remover o registro. Tente novamente mais tarde!');
    }
  }

  function handleDelete() {
    handleCloseMenuOptions();

    const { current: dialog } = confirmationDialogRef;

    dialog.open();
    dialog.onConfirm(onDelete);
  }

  const showOptionsButton = (
    (can(permissionKeyCode, 'add') && !!routeCloneRegister) ||
    (can(permissionKeyCode, 'del') && (!!routeDeleteRegister || !!onDeleteProp) )
  );

  return (
    <Container>
      {leftActionsComponent && leftActionsComponent()}

      <div style={{ display: showOptionsButton ? 'auto' : 'none' }}>
        <OptionsButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="inherit"
          onClick={handleOpenMenuOptions}
          endIcon={<ArrowDropDownIcon style={{ fontSize: 24 }} />}
        >
          Opções
        </OptionsButton>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenuOptions}
        >
          {routeCloneRegister && (
            <Permission keyCode={permissionKeyCode} permission="add">
              <Link to={routeCloneRegister}>
                <MenuItem onClick={handleCloseMenuOptions}>
                  <ListItemIcon>
                    <CloneIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Clonar" />
                </MenuItem>
              </Link>
            </Permission>
          )}

          <Permission keyCode={permissionKeyCode} permission="del">
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Excluir" />
            </MenuItem>
          </Permission>
          {extraOptionsComponent && extraOptionsComponent(handleCloseMenuOptions)}
        </Menu>
      </div>

      {routeEditRegister && (
        <Permission keyCode={permissionKeyCode} permission="edit">
          <Link to={routeEditRegister}>
            <IconButton
              variant="contained"
              color="orange"
              startIcon={<EditIcon />}
            >
              Editar
            </IconButton>
          </Link>
        </Permission>
      )}

      {routeNewRegister && (
        <Permission keyCode={permissionKeyCode} permission="add">
          <Link to={routeNewRegister} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="green">
              Novo
            </Button>
          </Link>
        </Permission>
      )}

      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Atenção"
        description="Deseja excluir este registro?"
      />
      {loading && <FullScreenLoading loadingText="Deletando registro..." />}
    </Container>
  );
}

export default memo(CrudActions);
