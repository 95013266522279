import React, { useState, useCallback, useEffect, useMemo } from 'react';

import BaseLayout from '~/components/BaseLayout';
import Spinner from '~/components/Spinner';
import Filter from './components/Filter';
import Modal from './components/Modal';
import SearchConstructionsDialog from '~/components/SearchConstructionsDialog';
import { findRecord } from '~/store/modules/constructions';
import InputSelect from '~/components/Input/InputSelect';
import InputControl from '~/components/Input/InputControl';
import invoiceExpenseTypeEnum from '~/enums/invoiceExpenseTypeEnum';

import Request from '~/services/request';
import { useGlobalFilters } from '~/hooks/useGlobalFilters';
import { reportsApi } from '~/routes/api';
import CrudLayoutList from '~/containers/CrudLayoutList';
import reportExpenseSchema from '~/records/schema/reportExpenseSchema';
import { connect, useDispatch } from 'react-redux';
import { fetchRecords } from '../../store/modules/reportExpenses';
import Button from '~/components/Button';
import Grid from '@material-ui/core/Grid';
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from '@material-ui/icons/GetApp';
import Card from './components/Card';
import DialogDownloadDanfes from './components/DialogDownloadDanfes';
import Observation from './components/Observation';
import PrintView from './components/PrintView';
import formatToMoney from '~/utils/formatToMoney';
import { Container, Header, Footer, InputContent, ButtonContent, PrintFooterInfo } from './styles';
import moment from '~/utils/moment-br';
import { invoicesRoute } from '../../routes';
import { Link } from 'react-router-dom';
import formatNumber from '~/utils/Number/formatNumber';

function List({isLoading}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [finding, setFinding] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [expenseType, setExpenseType] = useState(null);
  const [expenseData, setExpenseData] = useState([]);
  const [observations, setObservations] = useState(null);
  const [expenseValue, setExpenseValue] = useState(null);
  const [isDownloadDanfes, setIsDownloadDanfes] = useState(false);

  const [address, setAddress] = useState(null);
  const [customer, setCustomer] = useState(null);

  const currentStartDate = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
  const currentEndDate   = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(currentStartDate);
  const [endDate, setEndDate] = useState(currentEndDate);

  const totalValue = useMemo(
    () => expenseData.reduce((a,v) =>  a = a + v?.total_value , 0 ),
    [expenseData]
  );
  const totalAdmValue = useMemo(
    () => expenseData.reduce((a,v) =>  a = a + v.adm_value , 0 ),
    [expenseData]
  );
  const mountAddress = useMemo(
    () => {
      let formated = '';
      const {
        city,
        street,
        district,
        zip_code,
        building_number,
      } = address || {};
      if (street) formated += `${street} `;
      if (building_number) formated += `${building_number}, `;
      if (district) formated += `${district} `;
      if (city?.name && city?.state_uf) formated += `${city?.name} - ${city?.state_uf}, `;
      if (zip_code) formated += `${zip_code}`;
      return formated;
    },
    [address]
  );

  const {
    construction,
    setConstruction
  } = useGlobalFilters();

  const queryString =  useMemo(() => {
    if(!construction){ return ''}
    let qrString = `start_date=${startDate}&end_date=${endDate}&construction_id=${construction.id || null}`;
    if (expenseType) qrString += `&expense_type=${expenseType}`;

    return qrString
  }, [construction?.id, startDate, endDate, expenseType])

  const [optionsExpense] = useState([
    { id: 'all', name: 'Todas as despesas' },
    ...invoiceExpenseTypeEnum().array,
  ]);

  useEffect(() => {
    if (expenseType) {
      const findIndex = optionsExpense.findIndex(i => i.id === expenseType);
      const index = findIndex >= 0 ? findIndex : 0;
      setExpenseValue(optionsExpense[index]);
    } else {
      setExpenseValue(optionsExpense[0]);
    }
  }, [expenseType]);

  const findConstructionDispatch = useCallback(
    () => {
      dispatch(
        findRecord(construction?.id, {
        include: 'customer-address.city'
        }, (current) => {
          setAddress( current?.address )
          setCustomer( current?.customer )
        })
      );
    },
    [construction?.id, setAddress, setCustomer],
  );

  useEffect(() => {
    findConstructionDispatch();
  }, [construction?.id]);

  useEffect(() => {
    if (!!construction.id && !loading) {
      setFinding(false);
      handleFindExpense();
    } else {
      setLoading(false);
      setOpenDialog(true);
    }
  }, [construction, startDate, endDate, expenseType]);

  const handleChange = useCallback(
    (key, value) => {
      if (key === 'expense_type') {
        setExpenseType(value);
      } else if (key === 'start') {
        setStartDate(value);
      } else {
        setEndDate(value);
      }
    },
    []
  );

  const onCloseDownloadDanfes = useCallback(
    () => setIsDownloadDanfes(false),
    [isDownloadDanfes],
  )

  const setReponseData = (item) => {
    if (item) {
      const customData = item.map(item => ({
        id: item?.id,
        invoice: item?.invoice,
        pdf_url: item?.pdf_url,
        services: item?.account_plan,
        nf_number: item?.invoice.nf_number,
        supplier: item?.invoice?.supplier?.name,
        nf_date: item?.invoice.nf_date,
        total_value: item?.total_value,
        adm_value: item?.adm_value  || 0,
        account_plan: item?.account_plan?.name || item?.services?.name || "",
      }));
  
      setExpenseData(customData || []);
    }
  };

  function handleChangeExpense(e) {
    handleChange('expense_type', e?.expense_type);
    const findIndex = optionsExpense.findIndex(i => i.id === e.expense_type);
    if (findIndex >= 0 ) setExpenseValue(optionsExpense[findIndex]);
  }

  const handleFindExpense = useCallback(
    async () => {
      setLoading(true);

      try {
        const response = await Request.get(
          `${reportsApi.expenses.build()}?${queryString}`
        );

        const { data: expense } = response;
        setReponseData(expense?.data);
      } catch(err) {
      } finally {
        setLoading(false);
        setTimeout(() => setFinding(true), 400);
      }
    },
    [construction, queryString]
  );

  const handleFetch = useCallback(
    async (params, requestSource) => {
      if (!loading && finding)  {
        let requestParams = `start_date=${startDate}&end_date=${endDate}&construction_id=${construction.id || null}`;
        if (expenseType) requestParams += `&expense_type=${expenseType}`;
        if (params) requestParams = `${params}&${requestParams}`;
  
        const callbackSuccess = (_current, response) => {
          const { data: expense } = response;
          const ids = expense?.data.map(i => Number(i.id)).sort();
          const newData = [];
          ids.map(id => {
            const find = expenseData.find(i => Number(i.id) === Number(id));
            if (find) newData.push(find);
          });

          setReponseData(newData);
        };
  
        dispatch(fetchRecords(requestParams, requestSource, callbackSuccess));
      }
    },
    [loading, finding],
  );

  if (loading) {
    return (
      <BaseLayout>
        <Spinner loadingText="" />
      </BaseLayout>
    );
  }

  return (
    <Container>
      <PrintView>
        <Header>
          { !!(construction && customer) &&
            <Card>
              <div>{construction?.name}</div>
              <div><b>Cliente</b>: {customer?.name}</div>
              {address && <div><b>Endereço da Obra</b>: {mountAddress}</div>}
            </Card>
          }
        </Header>
      </PrintView>

      <CrudLayoutList
        breadcrumbs={[{ text: 'Despesas da Obra' }]}
        permissionKeyCode="Report"
        routes={{}}
        navProps={{
          rightComponent: 
          <div style={{marginRight: 10}}>
            <Filter
              startDate={startDate}
              endDate={endDate}
              onChange={handleChange}
              setOpenDialog={setOpenDialog}
            />
            <Grid container direction="row" justify="flex-end">
              <InputContent>
                <InputControl
                  value={expenseValue}
                  name={'expense_type'}
                  inputComponent={InputSelect}
                  InputProps={{
                    options: optionsExpense,
                    outValueType: 'string'
                  }}
                  onChange={handleChangeExpense}
                />
              </InputContent>

              <ButtonContent>
                <Button onClick={() => setOpenModal(true)}>Observações</Button>
                <Button onClick={() => window.print()}>
                  <PrintIcon />
                </Button>
                { !!expenseData && expenseData.length > 0 &&
                  <Button onClick={() => setIsDownloadDanfes(true)}>
                    {"PDFs  "}
                    <GetAppIcon/>
                  </Button>
                }
              </ButtonContent>
            </Grid>
          </div>
        }}
        tableProps={{
          isLoading: (isLoading || loading),
          rows: expenseData,
          total: expenseData?.length || 0,
          handleFetch,
          schema: reportExpenseSchema,
          actions: [],
          permissionKeyCode: 'Report',
          showPaggination: false,
          filterProps: {
            columns: reportExpenseSchema.filterColumns
          },
          columnsOverride: [
            {
              name: 'nf_number',
              formatterComponent: (_, row) => {
                const url = row.pdf_url;

                if(url){
                  return <a href={url} target="_blank" rel="noreferrer">
                    <span>{row?.nf_number}</span>
                  </a>
                }

                return <Link to={invoicesRoute.show.build({ id: row?.invoice?.id })} target="_blank">
                  <span>{row?.nf_number}</span>
                </Link>
              }
            },
            {
              name: 'adm_value',
              formatterComponent: (_, row) => {
                return <Link to={invoicesRoute.show.build({ id: row?.invoice?.id })} target="_blank">
                  <span>{`R$ ${formatNumber(row?.adm_value, {precision: 2})}`}</span>
                </Link>
              }
            }
          ],
          defaultPaging: {
            sort: 'id',
            per_page: 1000000,
          },
        }}
      >
      </CrudLayoutList>

      <PrintView>
        <Footer>
          { !!observations?.length &&
            <Card>
              { observations?.map((item, index) => (
                <Observation
                  kind={item?.kind?.id}
                  key={`observation-${index}`}
                  label={`${item?.kind?.name}`}
                  value={`R$ ${formatToMoney(item?.value)} ${item?.description ? `(${item?.description})` : ''}`}
                />
              ))}
            </Card>
          }

          { !!(construction && expenseData?.length) &&
            <Card>
              <PrintFooterInfo>
                <p>
                  O valor total de Administração deste relatório é de <b>R$ {formatToMoney(totalAdmValue)}</b>,
                  referentes ao pagamento de <b>{parseFloat(construction?.adm_percent)}% </b>de Administração
                  do valor total das Notas Fiscais relacionadas acima (<b>R$ {formatToMoney(totalValue)}</b>).
                </p>
                <br/>
                <br/>
                <p>Chapecó-SC, {moment().tz('America/Sao_Paulo').format('DD [de] MMMM [de] YYYY')}</p>
                <br/>
                <p>
                  ____________________________________________
                  <br/>
                  ILHA DE OBRAS - 12.340.323/0001-03
                </p>
              </PrintFooterInfo>
            </Card>
          }
        </Footer>
      </PrintView>

      { openModal &&
        <Modal
          isOpen={openModal} 
          observations={observations}
          handleClose={() => setOpenModal(false)} 
          setObservations={(items) => {
            setOpenModal(false);
            setObservations(items);
          }} 
        />
      }

      {
        isDownloadDanfes &&
        <DialogDownloadDanfes
          handleClose={onCloseDownloadDanfes}
          queryString={queryString}
        />
      }

      <SearchConstructionsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onSelect={(newConstruction) => {
          setConstruction(newConstruction);
          setOpenDialog(false);
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => ({
  isLoading: state.reportExpenses.listIsLoading,
  rows: state.reportExpenses.data,
  total: state.reportExpenses.totalData,
});

export default connect(mapStateToProps)(List);
