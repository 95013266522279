import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 150px;
  width: 280px;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  height: 130px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Line = styled.div`
  width: 100px;
  height: 8px;
  background-color: ${props => props.visible ? '#7558C8' : 'transparent'};
  
  ${props => (props.visible && !props.completed) && css`
    background-color: #EEEEEE;
  `};
`;

export const Circle = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  background-color: ${props => props.completed ? '#7558C8' : '#EEEEEE'};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Description = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

export const Text = styled.div`
  width: 120px;
  text-align: center;
  font-weight: 500;
`;
