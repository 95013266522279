import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { customRecordsRoute } from '~/routes'
import { destroyRecord, findRecord, clearState } from '~/store/modules/customRecords';

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import CustomFieldInputShow from '~/components/CustomFieldInputShow';

import getMetaFields from './utils/getMetaFields';

function CustomRecordShow({ customRecord, isLoading, sys_key, metaSettings }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const metaFields = useMemo(
    () => getMetaFields(metaSettings)
    ,[metaSettings]
  )

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id, {sys_key}) )
    },
    [id]
  )

  useEffect(() => {
    dispatch( findRecord(id, {sys_key}) );

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: customRecordsRoute.list.build({sys_key}), text: metaSettings.get('record_plural_name') },
        { text: `Visualizando ${metaSettings.get('record_singular_name')}` },
      ]}
      loading={isLoading}
      crudActionsProps={{
        routeNewRegister:   customRecordsRoute.new.build({sys_key}),
        routeCloneRegister: customRecordsRoute.clone.build({ id, sys_key }),
        routeEditRegister:  customRecordsRoute.edit.build({ id, sys_key }),
        onDelete:           onDelete,
        permissionKeyCode:  `CustomRecord-${sys_key}`
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={12}>
              <InputShow
                label="Nome"
                value={customRecord?.name}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            { Object.values(metaFields).filter( metaField => metaField.active ).map( (metaField) => {
              const fieldKey = metaField.field.key;
              return(
                <Col key={fieldKey} xs={12} sm={6} md={metaField.field_size}>
                  <CustomFieldInputShow
                    metaField={metaField}
                    value={
                      (customRecord?.custom_fields || {})[fieldKey] || null
                    }
                  />
                </Col>
              )
            } ) }
          </GridContainer>
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.customRecords.isLoading,
  customRecord: state.customRecords.current,
});

export default connect(mapStateToProps)(CustomRecordShow);