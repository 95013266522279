import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/npsForms';
import { npsFormsRoute } from '~/routes'
import NpsFormRecord from '~/records/NpsFormRecord';

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';

const attrName = (attr) => NpsFormRecord.hummanAttributeName(attr);

function Show({ npsForm, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch( findRecord(id, {include: 'construction.customer-engineer'}) );

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: npsFormsRoute.list.path, text: 'Formulários NPS' },
        { text: 'Exibindo formulário' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   npsFormsRoute.new.build(),
        routeCloneRegister: npsFormsRoute.clone.build({ id }),
        routeEditRegister:  npsFormsRoute.edit.build({ id }),
        permissionKeyCode:  'NpsForm'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col sm={5}>
              <InputShow
                label={attrName('construction')}
                type="string"
                value={npsForm?.construction?.name}
              />
            </Col>
            <Col sm={5}>
              <InputShow
                label={attrName('customer')}
                type="string"
                value={npsForm?.construction?.customer?.name}
              />
            </Col>
            <Col sm={2}>
              <InputShow
                label={attrName('is_active')}
                type="boolean"
                value={npsForm?.is_active}
              />
            </Col>
          </GridContainer>

          <GridContainer>
            <Col sm={6}>
              <InputShow
                label={attrName('engineer')}
                type="string"
                value={npsForm?.engineer?.name}
              />
            </Col>
            <Col sm={6}>
              <InputShow
                label={attrName('start_date')}
                type="date"
                value={npsForm?.start_date}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.npsForms.isLoading,
  npsForm: state.npsForms.current,
});

export default connect(mapStateToProps)(Show);